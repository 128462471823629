import React, { useCallback, useEffect, useRef } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledAlert,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  ModalFooter,
} from "reactstrap"
import Dropdown from 'react-bootstrap/Dropdown';
import { Table } from "react-bootstrap"
import { ERROR_KEY, SUCCESS_KEY, allValidFileTypes, fielsLengths } from '../../../Constants/MainKeys'
import { addButtonSpinner, addRightSectionSpinner, removeButtonSpinner, removeRightSectionSpinner } from '../../../store/spinner/actions'
import AlertService from '../../../Services/alertService'
import { useDispatch, useSelector } from 'react-redux'
import HtmlHead from '../../../components/HtmlHead/HtmlHead'
import RightSectionSpinner from '../../../components/Spinners/RightSectionSpinner'
import uuid from 'react-uuid'
import ApiService from '../../../Services/ApiService'
import { useState } from 'react'
import NoData from '../../../components/NoData/NoData'
import MainService from '../../../Services/MainService'
import ActionButton from '../../../components/Buttons/ActionButton'
import LevelOneRequirementsForm from './components/LevelOneRequirementsForm'
import LevelOneRequirements from './components/LevelOneRequirements'
import LevelTwoRequirementsForm from './components/LevelTwoRequirementsForm'
import LevelTwoRequirements from './components/LevelTwoRequirements'
import Auxiliary from '../../../hoc/auxiliary/auxiliary'
import LevelThreeRequirements from './components/LevelThreeRequirements'
import LevelThreeRequirementsForm from './components/LevelThreeRequirementsForm'

function useOutsideAlerter(ref, cb) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        cb(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const buttonSpinnerId = uuid();

const OrganizationKYC = (props) => {

  const { organizationId } = props.match.params;

  const dispatch = useDispatch();
  const title = "Organizaton KYC";
  const description = "";
  const { buttonSpinners } = useSelector(state => state.Spinners);
  const { rightSectionSpinners } = useSelector(state => state.Spinners);
  const { language } = useSelector(state => state.Languages);
  const { user } = useSelector(state => state.Login);
  const mainService = new MainService();
  const wrapperRef = useRef(null);
  const [zoomImagePath, setZoomImagePath] = useState(null);
  useOutsideAlerter(wrapperRef, setZoomImagePath);
  const [isForm, setIsForm] = useState(false);
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
  const [customActiveTab, setCustomIconActiveTab] = useState(localStorage.getItem("activeTab") || "1");
  const [organizationData, setOrganizationData] = useState(null);
  const [_organizationData, _setOrganizationData] = useState(null);
  const [countries, setCountries] = useState([]);
  const [isDisabledFields, setIsDisabledFields] = useState(false);
  const [kycLevels, setKycLevels] = useState([]);
  const [toggleCollapse, setToggleCollapse] = useState(false);
  const [confirmLoaderId, setConfirmLoaderId] = useState(null);

  const [isShowRejectModal, setIsShowRejectModal] = useState(false);
  const [rejectMessage, setRejectMessage] = useState("");
  const [currencies, setCurrencies] = useState([]);

  useEffect(() => {
    getOrganizationById(organizationId)
  }, [organizationId])

  useEffect(() => {
    getCountries();
    getKycLevels();
    getFiatCurrencies();
  }, [])


  useEffect(() => {
    if (organizationData) {
      let kycData = [...kycLevels];
      if (organizationData && !organizationData.levelOne) {
        kycData = kycData.filter(item => item.key !== 1)
      }
      if (!organizationData.levelTwo) {
        kycData = kycData.filter(item => item.key !== 2)
      }
      if (!organizationData.levelThree) {
        kycData = kycData.filter(item => item.key !== 3)
      }
      setKycLevels(kycData)
    }
  }, [organizationData])

  const getFiatCurrencies = () => {
    const spinnerId = uuid();
    setRightSectionSpinner(spinnerId)
    ApiService.getFiatCurrencies().then(response => {
      if (response && response.data) {
        setCurrencies(response.data)
      }
    }).catch(error => getFail(error)).finally(() => {
      extractRightSectionSpinner(spinnerId)
    })
  }

  const getCountries = () => {
    const spinnerId = uuid();
    setRightSectionSpinner(spinnerId)
    ApiService.getCountries().then(response => {
      if (response && response.data) {
        setCountries(response.data)
      }
    }).catch(error => getFail(error)).finally(() => {
      extractRightSectionSpinner(spinnerId)
    })
  }

  const getKycLevels = () => {
    const spinnerId = uuid();
    setRightSectionSpinner(spinnerId)
    ApiService.getKycLevels().then(response => {
      if (response && response.data) {
        setKycLevels(response.data)
      }
    }).catch(error => getFail(error)).finally(() => {
      extractRightSectionSpinner(spinnerId)
    })
  }

  const _onChange = (event, cb, maxLength = Infinity) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    cb(event.target.value)
    setIsInvalidSubmit(false);
  }

  const getOrganizationById = (organizationId) => {
    if (!organizationId) { return false; }
    const spinnerId = uuid();
    setRightSectionSpinner(spinnerId);
    ApiService.getOrganizationById(organizationId).then(response => {
      if (response && response.data) {
        let data = { ...response.data }
        if (response.data.levelTwo && data.levelTwo.files && data.levelTwo.files.length) {
          const groupedFiles = data.levelTwo.files.reduce((groups, file) => {
            const key = file.fileType;
            (groups[key] = groups[key] || []).push(file);
            return groups;
          }, {});
          const arrayOfArrays = Object.values(groupedFiles);
          if (arrayOfArrays && arrayOfArrays.length) {
            arrayOfArrays.forEach(item => {
              data.levelTwo[MainService.getOrganizationDetailsFileNameByType(item[0].fileType)] = item;
            })
          }
        }
        if (data.levelOne.userFiles && data.levelOne.userFiles.length) {
          data.levelOne.userFiles.forEach(item => {
            data.levelOne[MainService.getUserFileNameByType(item.fileType)] = item;
          })
        }
        setOrganizationData(data);
        _setOrganizationData(data);

      }
    }).catch(error => getFail(error)).finally(() => {
      extractRightSectionSpinner(spinnerId);
    })
  }

  const onChange = (event, obj, fieldName, maxLength = Infinity) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    setOrganizationData((values) => ({
      ...values,
      [obj]: {
        ...values[obj],
        [fieldName]: event.target.value,
      }
    }));
    setIsInvalidSubmit(false);
  }

  const onNumberChange = (event, obj, fieldName, maxLength = Infinity) => {
    if (event.target.value.includes("e")) { return false };
    if (event.target.value === '' || (typeof +event.target.value === "number" && Number(event.target.value) >= 0 && Number(event.target.value) <= maxLength)) {
      setOrganizationData((values) => ({
        ...values,
        [obj]: {
          ...values[obj],
          [fieldName]: event.target.value,
        }
      }));
    }
    setIsInvalidSubmit(false);
  };

  const onSelectOptionChange = (value, obj, fieldName) => {
    setOrganizationData((values) => ({
      ...values,
      [obj]: {
        ...values[obj],
        [fieldName]: value,
      }
    }));
  }

  const onSwitcherChange = (bool, obj, fieldName) => {
    AlertService.alertConfirm(
      `Are you sure ?`,
      "You want to update organization detail liveness test status.",
      "Yes",
      "No"
    ).then(() => {
      let data = {
        id: +organizationData[obj]?.id || null,
        updaterUserId: null,
        status: bool,
      }
      setIsDisabledFields(true);
      ApiService.updateOrganizationDetailLivenessTestStatus(data).then(response => {
        setOrganizationData((values) => ({
          ...values,
          [obj]: {
            ...values[obj],
            [fieldName]: bool,
          }
        }));
        AlertService.alert(SUCCESS_KEY, "Organization detail liveness test status updated successfully")
      }).catch(error => getFail(error)).finally(() => {
        setIsDisabledFields(false);
      })
    })
  }

  const onStatusSwitcherChange = (bool, obj, fieldName) => {
    AlertService.alertConfirm(
      `Are you sure ?`,
      `You want to ${bool ? "approve" : "reject"} bank account.`,
      "Yes",
      "No"
    ).then(() => {
      let data = {
        id: +organizationData[obj]?.id || null,
        updaterUserId: null,
        status: bool,
        entityType: 1,
      }
      setIsDisabledFields(true);
      ApiService.updateBankAccountStatusAsAdmin(data).then(response => {
        setOrganizationData((values) => ({
          ...values,
          [obj]: {
            ...values[obj],
            [fieldName]: bool,
          }
        }));
        _setOrganizationData((values) => ({
          ...values,
          [obj]: {
            ...values[obj],
            [fieldName]: bool,
          }
        }));
        AlertService.alert(SUCCESS_KEY, "Bank account status updated successfully")
      }).catch(error => getFail(error)).finally(() => {
        setIsDisabledFields(false);
      })
    })
  }

  const uploadFile = async (data) => {
    const { event, obj, fieldName, checkFiltType, method, id } = data;
    if (!event.target.files.length) { return false; }
    const formData = new FormData();

    let files = [...event.target.files];
    let file = files[0];
    const fileName = file.name;
    const lastDotIndex = fileName.lastIndexOf('.');
    const fileExtention = lastDotIndex !== -1 ? fileName.substring(lastDotIndex + 1).toLowerCase() : ' ';
    if (allValidFileTypes.includes(fileExtention.toLowerCase())) {
      formData.append("id", id);
      formData.append("document", file);
      formData.append("type", checkFiltType(fieldName));
      method(formData).then(response => {
        setOrganizationData((values) => ({
          ...values,
          [obj]: {
            ...values[obj],
            [fieldName]: response.data,
          }
        }));
        AlertService.alert(SUCCESS_KEY, "Data saved")
      }).catch(error => getFail(error)).finally(() => {
        //
      })
    } else {
      AlertService.alert("error", "Invalid file format")
      return false;
    }
  };

  const bulkUploadFile = async (data) => {
    const { event, fieldName, checkFiltType, method, obj, id } = data;
    if (!event.target.files.length) { return false; }
    const formData = new FormData();
    formData.append("id", id);
    formData.append("type", checkFiltType(fieldName));
    let files = [...event.target.files];
    for (let i in files) {
      let file = files[i];
      let fileName = file.name;
      let lastDotIndex = fileName.lastIndexOf('.');
      let fileExtention = lastDotIndex !== -1 ? fileName.substring(lastDotIndex + 1).toLowerCase() : ' ';

      if (allValidFileTypes.includes(fileExtention.toLowerCase())) {
        await mainService.readFile(file, allValidFileTypes).then(uploadedFile => {
          formData.append("documents", file);
        }).catch(error => {
          error && AlertService.alert("error", "Invalid file format");
        });
      } else {
        AlertService.alert("error", "Invalid file format")
        return false;
      }
    }
    method(formData).then(response => {
      let _organizationData = { ...organizationData };
      _organizationData[obj][fieldName] = [..._organizationData[obj][fieldName] || [], ...response.data];
      setOrganizationData(_organizationData)
      AlertService.alert(SUCCESS_KEY, "Data saved")
    }).catch(error => getFail(error)).finally(() => { })
  };

  const deleteDocument = (data) => {
    const { fieldName, fileObj, fileLibraryId, getFileType, method, id } = data;
    if (!fileLibraryId || !getFileType) { return false; }
    AlertService.alertConfirm(
      `Are you sure you want to delete current file ?`,
      "",
      "Yes",
      "No"
    ).then(() => {
      (method ? method(fileLibraryId, id, getFileType(fieldName)) :
        ApiService.deleteDocumentAsAdmin(fileLibraryId, id, getFileType(fieldName))).then(() => {
          if (Array.isArray(organizationData[fileObj][fieldName])) {
            setOrganizationData((values) => ({
              ...values,
              [fileObj]: {
                ...values[fileObj],
                [fieldName]: organizationData[fileObj][fieldName].filter(item => item.fileLibraryId !== fileLibraryId),
              }
            }));
          } else {
            setOrganizationData((values) => ({
              ...values,
              [fileObj]: {
                ...values[fileObj],
                [fieldName]: null,
              }
            }));
          }
          AlertService.alert(SUCCESS_KEY, "File deleted successfully")
        }).catch(error => getFail(error)).finally(() => {
          //
        })
    })
  }

  const confirmProof = (data) => {
    const { fileObj, fieldName } = data;
    let entityId = organizationData?.id;
    let fileLibraryId = organizationData[fileObj][fieldName].fileLibraryId || null;
    if (confirmLoaderId === fileLibraryId) {
      return false;
    }
    AlertService.alertConfirm(
      `Are you sure?`,
      "Confirming the document makes it legal for Cryllex and partner agents.",
      "Yes",
      "No"
    ).then(() => {
      setConfirmLoaderId(fileLibraryId);
      let _data = { entityId: entityId, fileLibraryId: fileLibraryId, bankAccountEntityType: 1 };
      ApiService.approveBankAccountDocument(_data).then(response => {
        let _obj = organizationData[fileObj][fieldName];
        _obj.isActive = response.data || false;
        setOrganizationData((values) => ({
          ...values,
          [fileObj]: {
            ...values[fileObj],
            [fieldName]: _obj,
          }
        }));
        AlertService.alert(SUCCESS_KEY, "File confirmed successfully")
      }).catch(error => getFail(error)).finally(() => {
        setConfirmLoaderId(null);
      })
    })
  }

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  const onChangeKycLevel = (item, fieldName) => {
    if (!item) { return false; }
    AlertService.alertConfirm(
      `Are you sure`,
      "You want to change KYC level ?",
      "Yes",
      "No"
    ).then(() => {
      setIsDisabledFields(true);
      const data = {
        id: organizationData.id,
        updaterUserId: null,
        kycLevel: item
      }
      ApiService.updateOrganizationKycLevel(data).then(() => {
        setOrganizationData((values) => ({
          ...values,
          [fieldName]: item
        }))
        AlertService.alert(SUCCESS_KEY, "Organization KYC level updated successfully")
      }).catch(error => getFail(error)).finally(() => {
        setIsDisabledFields(false)
      })
    })
  }


  const accept = (spinnerId) => {
    AlertService.alertConfirm(
      `Are you sure ?`,
      "",
      "Yes",
      "No"
    ).then(() => {
      setButtonSpinner(spinnerId);
      const data = {
        id: organizationData.id,
        updaterUserId: null,
        status: true
      }
      ApiService.updateOrganizationStatus(data).then(response => {
        AlertService.alert(SUCCESS_KEY, "Data saved");
        setOrganizationData((values) => ({
          ...values,
          isApproved: true,
          isRejected: false,
          isPending: true,
        }))
      }).catch(error => getFail(error)).finally(() => {
        extractButtonSpinner(spinnerId)
      })
    })
  }

  const reject = (spinnerId) => {
    if (!rejectMessage.trim().length) {
      setIsInvalidSubmit(true);
      return false;
    }
    setButtonSpinner(spinnerId);
    const data = {
      id: organizationData.id,
      updaterUserId: null,
      status: false,
      rejectMessage,
    }
    ApiService.updateOrganizationStatus(data).then(response => {
      AlertService.alert(SUCCESS_KEY, "Data saved");
      setOrganizationData((values) => ({
        ...values,
        isApproved: false,
        isRejected: true,
        isPending: false,
      }))
      setRejectMessage("");
    }).catch(error => getFail(error)).finally(() => {
      extractButtonSpinner(spinnerId)
    }).finally(() => {
      setIsShowRejectModal(false);
    })
  }


  const disabledOrEnable = (spinnerId) => {
    AlertService.alertConfirm(
      `Are you sure ?`,
      "",
      "Yes",
      "No"
    ).then(() => {
      setButtonSpinner(spinnerId);
      const data = {
        id: organizationData.id,
        updaterUserId: null,
        status: !organizationData.isDisabled
      }
      ApiService.disabledOrEnableOrganizationStatus(data).then(response => {
        AlertService.alert(SUCCESS_KEY, "Data saved");
        setOrganizationData((values) => ({
          ...values,
          isDisabled: !organizationData.isDisabled
        }))
      }).catch(error => getFail(error)).finally(() => {
        extractButtonSpinner(spinnerId)
      })
    })
  }

  const onPhoneNumberChange = (value, obj, fieldName) => {
    setOrganizationData((values) => ({
      ...values,
      [obj]: {
        ...values[obj],
        [fieldName]: value
      }
    }));
  }

  const toggleCustom = (key) => {
    setCustomIconActiveTab(key);
    localStorage.setItem("activeTab", key);
  }

  const getData = () => {
    if (!customActiveTab) { return null; }
    switch (customActiveTab) {
      case "1":
        return organizationData.levelOne;
      case "2":
        return organizationData.levelTwo;
      case "3":
        return organizationData.levelThree;
      default:
        break;
    }
  }

  const updateForm = (event) => {
    event && event.preventDefault();
    setButtonSpinner(buttonSpinnerId);
    (
      +customActiveTab === 1 ?
        ApiService.updateOrganizationDetailOneLevel(getData()) :
        +customActiveTab === 2 ?
          ApiService.updateOrganizationDetailTwoLevel(getData()) :
          +customActiveTab === 3 ?
            ApiService.updateOrganizationBankAccountAsAdmin(getData()) : () => { }
    ).then(response => {
      AlertService.alert("success", "Data saved");
      organizationId && getOrganizationById(organizationId)
    }).catch(error => getFail(error)).finally(() => {
      setIsForm(false);
      extractButtonSpinner(buttonSpinnerId);
    })
  }

  const onDisabledEdirtButton = (data) => {
    if (!data) { return true; }
    if ((data.levelOne && +customActiveTab === 1) || (data.levelTwo && +customActiveTab === 2) || (data.levelThree && +customActiveTab === 3)) {
      return false;
    } else {
      return true;
    }
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const setRightSectionSpinner = useCallback(spinner => {
    dispatch(addRightSectionSpinner(spinner));
  }, []);

  const extractRightSectionSpinner = useCallback(spinner => {
    dispatch(removeRightSectionSpinner(spinner));
  }, []);

  const getFail = (error) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
  }

  return (
    <>
      <div className={`zoom-image-modal ${!zoomImagePath ? "d-none" : ""}`}>
        <img
          src={zoomImagePath ? zoomImagePath : ""}
          ref={wrapperRef}
        />
        <i className='bx bx-x close-icon'></i>
      </div>
      <HtmlHead title={title} description={description} />
      <div className='page-content position-relative min-h-100vh'>
        <RightSectionSpinner spinner={rightSectionSpinners} />
        <div className="container-fluid">
          {
            organizationData ?
              <div>
                <UncontrolledAlert color={organizationData.isApproved ? "success" : "danger"} role="alert">
                  {
                    organizationData.isApproved ?
                      "Current organization has received approval from the Cryllex administration."
                      :
                      "Current organization has not been approved by the Cryllex administration."
                  }
                </UncontrolledAlert>

                {
                  organizationData.isDisabled ?
                    <UncontrolledAlert color="danger" role="alert">
                      Current organization has been disabled by the Cryllex administration.
                    </UncontrolledAlert>
                    : null
                }
              </div>
              : null
          }


          <Breadcrumbs
            title="Cryllex"
            breadcrumbItem="Organizaton KYC"
            isShowGoBackButton={true}
            goBack={() => {
              localStorage.removeItem("activeTab");
              window.history.back();
            }}
          />
          <Row>
            <Col lg={12}>
              <Card className='mb-5'>
                <CardBody>
                  {
                    organizationData ?
                      <>
                        <Nav tabs className="nav-tabs-custom nav-justified">
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={`${customActiveTab === "1" ? "active" : ""}`}
                              disabled={buttonSpinners.length ? true : false}
                              onClick={() => {
                                toggleCustom("1");
                                setIsForm(false);
                              }}
                            >
                              <span style={{ fontSize: "14px" }}>Level one</span>
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={`${customActiveTab === "2" ? "active" : ""}`}
                              disabled={buttonSpinners.length ? true : false}
                              onClick={() => {
                                toggleCustom("2");
                                setIsForm(false);
                              }}
                            >
                              <span style={{ fontSize: "14px" }}>Level two</span>
                            </NavLink>
                          </NavItem>
                          {
                            organizationData.levelThree || organizationData.significantControllers ?

                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={`${customActiveTab === "3" ? "active" : ""}`}
                                  disabled={buttonSpinners.length ? true : false}
                                  onClick={() => {
                                    toggleCustom("3");
                                    setIsForm(false);
                                  }}
                                >

                                  <span style={{ fontSize: "14px" }}>
                                    {
                                      organizationData.classificationType === 2 ? "Bank Account" : " Level three"
                                    }
                                  </span>
                                </NavLink>
                              </NavItem>
                              : null
                          }
                        </Nav>
                        <TabContent activeTab={customActiveTab} className="p-3 text-muted">
                          <TabPane tabId="1">
                            {
                              organizationData.levelOne ?
                                <Row>
                                  {
                                    isForm ?
                                      <LevelOneRequirementsForm
                                        data={organizationData}
                                        onChange={onChange}
                                        onSwitcherChange={onSwitcherChange}
                                        uploadFile={uploadFile}
                                        onPhoneNumberChange={onPhoneNumberChange}
                                        setZoomImagePath={setZoomImagePath}
                                        deleteDocument={deleteDocument}
                                        isDisabledFields={isDisabledFields}
                                        confirmProof={confirmProof}
                                        confirmLoaderId={confirmLoaderId}
                                      />
                                      : <LevelOneRequirements
                                        data={organizationData}
                                        setZoomImagePath={setZoomImagePath}
                                      />
                                  }
                                </Row>
                                : <NoData />
                            }
                          </TabPane>
                          <TabPane tabId="2">
                            {
                              organizationData.levelTwo ?
                                <Row>
                                  {
                                    isForm ?
                                      <LevelTwoRequirementsForm
                                        data={organizationData}
                                        onChange={onChange}
                                        uploadFile={uploadFile}
                                        bulkUploadFile={bulkUploadFile}
                                        setZoomImagePath={setZoomImagePath}
                                        onSelectOptionChange={onSelectOptionChange}
                                        deleteDocument={deleteDocument}
                                        countries={countries}
                                        confirmProof={confirmProof}
                                        confirmLoaderId={confirmLoaderId}
                                      />
                                      : <LevelTwoRequirements
                                        data={organizationData}
                                        setZoomImagePath={setZoomImagePath}
                                      />

                                  }
                                </Row> : <NoData />
                            }
                          </TabPane>
                          <TabPane tabId="3">
                            {
                              (organizationData.significantControllers && organizationData.significantControllers.length) || organizationData.levelThree ?

                                <div>
                                  {
                                    organizationData.significantControllers && organizationData.significantControllers.length && !isForm ?
                                      <div>
                                        <h5 className='bold my-3'>Significant controllers</h5>
                                        <Table striped bordered hover responsive>
                                          <thead>
                                            <tr className='cursor-default'>
                                              <th className="text-nowrap">Full name</th>
                                              <th className="text-nowrap">Email</th>
                                              <th className="text-nowrap">Phone Number</th>
                                              <th className="text-nowrap">Type</th>
                                              <th className="text-nowrap">Percentage</th>
                                              <th className="text-nowrap text-center">Action</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {
                                              organizationData.significantControllers.map((item, index) => {
                                                return <tr key={index} className='cursor-default'>
                                                  <td className='fw-500'>{item.fullName}</td>
                                                  <td className='fw-500'>{item.email}</td>
                                                  <td className='fw-500'>{MainService.formatPhoneNumber(item.phoneNumber)}</td>
                                                  <td className='fw-500'>{item.typeName}</td>
                                                  <td className='fw-500'>{item.percentage}%</td>
                                                  <td>
                                                    <div className='d-flex justify-content-center'>
                                                      <Button
                                                        type="button"
                                                        color="primary"
                                                        className='shadow-sm me-1'
                                                        onClick={() => { props.history.push(`/${language}/significant-controllers-form/${item.id}`) }}
                                                      >
                                                        Update
                                                      </Button>
                                                    </div>
                                                  </td>
                                                </tr>
                                              })
                                            }
                                          </tbody>
                                        </Table>
                                        {
                                          organizationData.levelThree ?
                                            <hr />
                                            : null
                                        }
                                      </div>
                                      : null
                                  }
                                  {
                                    organizationData.levelThree ?
                                      <Row>
                                        {
                                          isForm ?
                                            <LevelThreeRequirementsForm
                                              data={organizationData}
                                              onChange={onChange}
                                              uploadFile={uploadFile}
                                              setZoomImagePath={setZoomImagePath}
                                              onSelectOptionChange={onSelectOptionChange}
                                              deleteDocument={deleteDocument}
                                              onNumberChange={onNumberChange}
                                              countries={countries}
                                              onSwitcherChange={onStatusSwitcherChange}
                                              isDisabledFields={isDisabledFields}
                                              confirmProof={confirmProof}
                                              confirmLoaderId={confirmLoaderId}
                                              currencies={currencies}
                                            />
                                            : <div>
                                              {
                                                organizationData.classificationType === 2 ? null :
                                                  <h5 className='bold my-3'>Bank account</h5>
                                              }
                                              <LevelThreeRequirements
                                                data={organizationData}
                                                setZoomImagePath={setZoomImagePath}
                                                countries={countries}
                                                currencies={currencies}
                                              />
                                            </div>
                                        }
                                      </Row>
                                      : null
                                  }
                                </div>
                                : <NoData />
                            }
                          </TabPane>
                        </TabContent>
                      </>
                      : <NoData />
                  }
                  <hr />
                  <div className='my-2 d-flex justify-content-end flex-wrap'>
                    <div className='d-flex mt-3'>
                      {
                        kycLevels && kycLevels.length ?
                          <Dropdown drop='up' disabled={isDisabledFields}>
                            <Dropdown.Toggle tag="button" className="btn btn-warning">
                              {
                                kycLevels.find(level => level.key === organizationData?.kycLevel) ?
                                  <div className='d-flex'>
                                    {kycLevels.map((level, idx) => {
                                      if (level.key === organizationData?.kycLevel) {
                                        return <div key={idx}>{level.value}</div>
                                      }
                                    })}
                                    <i className="mdi mdi-chevron-up ms-2" />
                                  </div>
                                  : <div className='d-flex'>Update KYC Level <i className="mdi mdi-chevron-down ms-2" /></div>
                              }
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              {
                                kycLevels.map((level, idx) => {
                                  return <Dropdown.Item
                                    key={idx}
                                    onClick={() => {
                                      if (organizationData?.kycLevel === level.key) {
                                        return false;
                                      }
                                      onChangeKycLevel(level.key, "kycLevel")
                                    }}
                                  >
                                    {level.value}
                                  </Dropdown.Item>
                                })
                              }
                            </Dropdown.Menu>
                          </Dropdown>
                          : null
                      }
                      {
                        isForm ?
                          <div className='d-flex'>
                            <Button
                              color="primary"
                              className='mx-2 px-4'
                              outline
                              disabled={isDisabledFields}
                              onClick={() => {
                                setIsForm(false);
                                setOrganizationData(_organizationData)
                              }}
                            >
                              Cancel
                            </Button>
                            <ActionButton
                              type="button"
                              name="Save"
                              className="btn btn-primary btn-block px-4"
                              spinnerId={buttonSpinnerId}
                              disabled={isDisabledFields}
                              onClick={(event) => updateForm(event)}
                            />
                          </div> :
                          <Auxiliary>

                            <ActionButton
                              spinnerId={`accept_${buttonSpinnerId}`}
                              color="success"
                              className='ms-2 px-4'
                              disabled={organizationData && !organizationData.isApproved && !organizationData.isPending ? false : true}
                              onClick={() => accept(`accept_${buttonSpinnerId}`)}
                            >
                              Accept
                            </ActionButton>

                            <Button
                              color="danger"
                              className='ms-2 px-4'
                              disabled={organizationData && organizationData.isRejected}
                              onClick={() => setIsShowRejectModal(true)}
                            >
                              Reject
                            </Button>

                            <ActionButton
                              spinnerId={`disabled_${buttonSpinnerId}`}
                              color={organizationData?.isDisabled ? "info" : "secondary"}
                              className='ms-2 px-4'
                              onClick={() => disabledOrEnable(`disabled_${buttonSpinnerId}`)}
                            >
                              {
                                organizationData?.isDisabled ? "Enable" : "Disable"
                              }

                            </ActionButton>
                            <Button color="primary" className='ms-2 px-4'
                              onClick={() => {
                                setIsForm(true);
                                scrollTop();
                              }}
                              disabled={onDisabledEdirtButton(organizationData)}
                            >
                              Edit
                            </Button>
                          </Auxiliary>
                      }
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>



        <Modal
          isOpen={isShowRejectModal}
          size="lg"
          role="dialog"
          className="exampleModal"
          tabIndex="-1"
          toggle={() => {
            setIsShowRejectModal(!isShowRejectModal)
          }}
        >
          <div className="modal-content">
            <ModalHeader
              toggle={() => {
                setIsShowRejectModal(!isShowRejectModal)
              }}
            >
              Reject
            </ModalHeader>
            <ModalBody>
              <form>
                <Label htmlFor='rejectMessage'>Rejection message</Label>
                <Input
                  id="rejectMessage"
                  type="textarea"
                  value={rejectMessage}
                  rows={4}
                  autoFocus
                  style={{ resize: "none" }}
                  placeholder='Please enter your rejection message'
                  className={`form-control ${isInvalidSubmit && !rejectMessage.trim().length ? "error-border" : ""}`}
                  onChange={(event) => _onChange(event, setRejectMessage, fielsLengths.length_2000)}
                />
              </form>
            </ModalBody>
            <ModalFooter>
              <div className='d-flex justify-content-end gap-2'>
                <Button
                  color="primary"
                  className='px-4'
                  outline
                  disabled={isDisabledFields}
                  onClick={() => {
                    setIsShowRejectModal(false);
                    setRejectMessage("");
                  }}
                >
                  Cancel
                </Button>
                <ActionButton
                  spinnerId={`reject_${buttonSpinnerId}`}
                  color="primary"
                  className='px-4'
                  onClick={() => reject(`reject_${buttonSpinnerId}`)}
                >
                  Save
                </ActionButton>
              </div>
            </ModalFooter>
          </div>
        </Modal>
      </div>
    </>
  )
}

export default OrganizationKYC