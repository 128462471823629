import React, { useState } from 'react'
import Breadcrumbs from './../../../components/Common/Breadcrumb'
import {
  Col,
  Row,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap"
import ReactPaginate from 'react-paginate'
import NoData from '../../../components/NoData/NoData';

export default function Notifications() {

  const [activePageNumber, setActivePageNumber] = useState(0);

  const handlePageClick = (event) => {
    setActivePageNumber(event.selected);
  };


  return (
    <div className='page-content'>
      <div className="container-fluid">
        <Breadcrumbs
          title="Cryllex"
          breadcrumbItem="Notifications"
        />
        <Card>
          <CardBody>
            <NoData />
            {/* <Row>
              <div className='col-12'>
                <Card outline color="primary" className="border mb-2">
                  <CardHeader className="bg-transparent">
                    <h5 className="my-0 text-primary">
                      <i className="mdi mdi-bullseye-arrow me-3" />Primary Notification
                    </h5>
                  </CardHeader>
                  <CardBody className='pt-0 pb-2'>
                    <p className='card-text font-size-14 mb-1'>
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                    </p>
                    <small className="text-muted">25 days ago</small>
                  </CardBody>
                </Card>
              </div>

              <div className='col-12'>
                <Card outline color="danger" className="border mb-2">
                  <CardHeader className="bg-transparent">
                    <h5 className="my-0 text-danger">
                      <i className="mdi mdi-block-helper me-3" />Danger Notification
                    </h5>
                  </CardHeader>
                  <CardBody className='pt-0 pb-2'>
                    <p className='card-text font-size-14 mb-1'>
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                    </p>
                    <small className="text-muted">a month ago</small>
                  </CardBody>
                </Card>
              </div>

              <div className='col-12'>
                <Card outline color="success" className="border mb-2">
                  <CardHeader className="bg-transparent">
                    <h5 className="my-0 text-success">
                      <i className="mdi mdi-check-all me-3" />Success Notification
                    </h5>
                  </CardHeader>
                  <CardBody className='pt-0 pb-2'>
                    <p className='card-text font-size-14 mb-1'>
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                    </p>
                    <small className="text-muted">2 months ago </small>
                  </CardBody>
                </Card>
              </div>
              <div className='col-12'>
                <Card outline color="primary" className="border mb-2">
                  <CardHeader className="bg-transparent">
                    <h5 className="my-0 text-primary">
                      <i className="mdi mdi-bullseye-arrow me-3" />Primary Notification
                    </h5>
                  </CardHeader>
                  <CardBody className='pt-0 pb-2'>
                    <p className='card-text font-size-14 mb-1'>
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                    </p>
                    <small className="text-muted">25 days ago</small>
                  </CardBody>
                </Card>
              </div>

              <div className='col-12'>
                <Card outline color="danger" className="border mb-2">
                  <CardHeader className="bg-transparent">
                    <h5 className="my-0 text-danger">
                      <i className="mdi mdi-block-helper me-3" />Danger Notification
                    </h5>
                  </CardHeader>
                  <CardBody className='pt-0 pb-2'>
                    <p className='card-text font-size-14 mb-1'>
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                    </p>
                    <small className="text-muted">a month ago</small>
                  </CardBody>
                </Card>
              </div>

              <div className='col-12'>
                <Card outline color="success" className="border mb-2">
                  <CardHeader className="bg-transparent">
                    <h5 className="my-0 text-success">
                      <i className="mdi mdi-check-all me-3" />Success Notification
                    </h5>
                  </CardHeader>
                  <CardBody className='pt-0 pb-2'>
                    <p className='card-text font-size-14 mb-1'>
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                    </p>
                    <small className="text-muted">2 months ago </small>
                  </CardBody>
                </Card>
              </div>

              <div className='mt-4 d-flex justify-content-end'>
                <Row>
                  <Col>
                    <ReactPaginate
                      nextLabel="Next"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={3}
                      marginPagesDisplayed={2}
                      pageCount={100}
                      previousLabel="Previous"
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakLabel="..."
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      containerClassName="pagination"
                      activeClassName="active"
                      renderOnZeroPageCount={null}
                      forcePage={activePageNumber}
                    />
                  </Col>
                </Row>
              </div>
            </Row> */}
          </CardBody>
        </Card>

      </div>
    </div>
  )
}
