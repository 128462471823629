import React, { useEffect, useState } from 'react'
// import { compose, withProps } from "recompose"
// import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import HtmlHead from '../../../components/HtmlHead/HtmlHead'
import RightSectionSpinner from '../../../components/Spinners/RightSectionSpinner'
import { useSelector } from 'react-redux'
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  CardHeader,
} from "reactstrap"
import Breadcrumbs from './../../../components/Common/Breadcrumb'
import ProgressBar from 'react-bootstrap/ProgressBar';


// const MyMapComponent = compose(
//   withProps({
//     googleMapURL: "https://maps.googleapis.com/maps/api/js?key=&callback=initMap",
//     loadingElement: <div style={{ height: `100%` }} />,
//     containerElement: <div style={{ height: `600px` }} />,
//     mapElement: <div style={{ height: `100%` }} />,
//   }),
//   withScriptjs,
//   withGoogleMap
// )((props) =>
//   <GoogleMap
//     defaultZoom={3}
//     defaultCenter={{ lat: props.lat, lng: props.lng }}
//   >
//     {
//       props.isMarkerShown ?
//         <>
//           <Marker
//             position={{ lat: props.lat, lng: props.lng }}
//             onClick={props.onMarkerClick}
//           />
//           <Marker
//             position={{ lat: 41.881832, lng: -87.623177 }}
//             onClick={props.onMarkerClick}
//           />
//           <Marker
//             position={{ lat: 48.864716, lng: 2.349014 }}
//             onClick={props.onMarkerClick}
//           />
//           <Marker
//             position={{ lat: 22.302711, lng: 114.177216 }}
//             onClick={props.onMarkerClick}
//           />
//         </>
//         : null
//     }

//   </GoogleMap>
// )


const PaymentsAnalysis = () => {

  const title = "Transactions";
  const description = "";
  const { rightSectionSpinners } = useSelector(state => state.Spinners);
  const [isMarkerShown, setIsMarkerShown] = useState(false)
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);

  useEffect(() => {
    delayedShowMarker();
    getCoordinates();
  })

  const getCoordinates = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          setLat(latitude);
          setLng(longitude);
        },
        function (error) {
          console.error("Error getting location: " + error.message);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }

  const delayedShowMarker = () => {
    setTimeout(() => {
      setIsMarkerShown(true);
    }, 3000)
  }

  const handleMarkerClick = () => {
    setIsMarkerShown(false);
    delayedShowMarker()
  }

  return (

    <>
      <HtmlHead title={title} description={description} />
      <div className='page-content position-relative min-h-100vh'>
        <RightSectionSpinner spinner={rightSectionSpinners} />
        <div className="container-fluid">
          <Breadcrumbs
            title="Cryllex"
            breadcrumbItem="Payments Analysis"
          />
          <Row>
            <Col lg={12}>

              <Card className="position-relative">
                <CardHeader className="bg-transparent">
                  <div className='d-flex justify-content-between'>
                    <h5 className="mt-2">
                      Most Frequently Used Payment Methods
                    </h5>
                  </div>
                  <hr />
                </CardHeader>
                <CardBody className='pt-0'>
                  <Row className='my-3'>
                    <Col sm={12} md={3} lg={2}>
                      <div className='font-weight-500 font-size-18 d-flex align-items-center'>
                        <span className="">1:</span>
                        <i className='bx bxl-bitcoin text-warning ms-2 me-1' style={{ fontSize: "35px" }}></i>
                        <span>Bitcoin</span>
                      </div>
                    </Col>
                    <Col sm={12} md={9} lg={10}>
                      <div className='h-100 d-flex align-items-center'>
                        <ProgressBar className='w-100 ms-3' now={80} />
                      </div>
                    </Col>
                  </Row>
                  <Row className='my-3'>
                    <Col sm={12} md={3} lg={2}>
                      <div className='font-weight-500 font-size-18 d-flex align-items-center'>
                        <span className=''>2:</span>
                        <i className='bx bxs-dollar-circle text-warning ms-2 me-1' style={{ fontSize: "35px" }}></i>
                        <span>Ethereum</span>
                      </div>
                    </Col>
                    <Col sm={12} md={9} lg={10}>
                      <div className='d-flex align-items-center h-100'>
                        <ProgressBar className='w-100 ms-3' now={60} />
                      </div>
                    </Col>
                  </Row>
                  <Row className='my-3'>
                    <Col sm={12} md={3} lg={2}>
                      <div className='font-weight-500 font-size-18 d-flex align-items-center'>
                        <span className=''>3:</span>
                        <i className='bx bx-dollar-circle text-warning ms-2 me-1' style={{ fontSize: "35px" }}></i>
                        <span>Shiba</span>
                      </div>
                    </Col>
                    <Col sm={12} md={9} lg={10}>
                      <div className='d-flex align-items-center h-100'>
                        <ProgressBar className='w-100 ms-3' now={40} />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card className="position-relative">
                <CardBody className='d-flex justify-content-between flex-wrap'>
                  <span className='font-weight-500 font-size-18'>Average Transaction Size</span>
                  <span className='font-weight-500 font-size-18'>$100</span>
                </CardBody>
              </Card>

              <Card className="position-relative">
                <CardHeader className="bg-transparent">
                  <div className='d-flex justify-content-between'>
                    <h5 className="mt-2">
                      Consumer Demographics
                    </h5>
                  </div>
                  <hr />
                </CardHeader>
                <CardBody className='pt-0'>
                  {/* <MyMapComponent
                    isMarkerShown={isMarkerShown}
                    onMarkerClick={handleMarkerClick}
                    lat={lat}
                    lng={lng}
                  /> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default PaymentsAnalysis