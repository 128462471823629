// import React, { useCallback, useState, useEffect, useRef } from 'react'
// import Breadcrumbs from './../../../components/Common/Breadcrumb'
// import {
//   Card,
//   CardBody,
//   CardTitle,
//   Col,
//   Row,
//   Button,
//   Label,
//   Input
// } from "reactstrap"
// import { Table } from "react-bootstrap"
// import ReactPaginate from 'react-paginate'
// import ReactSelectOption from './../../../components/SelectOptions/ReactSelectOption'
// import { withTranslation } from "react-i18next"
// import ApiService from './../../../Services/ApiService';
// import AlertService from './../../../Services/alertService'
// import { ERROR_KEY, SPINNER_COLOR, fielsLengths } from './../../../Constants/MainKeys'
// import uuid from 'react-uuid'
// import { addRightSectionSpinner, removeRightSectionSpinner } from './../../../store/spinner/actions'
// import { useDispatch, useSelector } from 'react-redux'
// import * as moment from 'moment';
// import MainService from './../../../Services/MainService'
// import PuffLoader from "react-spinners/PuffLoader"
// import HtmlHead from './../../../components/HtmlHead/HtmlHead'
// import RightSectionSpinner from './../../../components/Spinners/RightSectionSpinner'
// import NoData from '../../../components/NoData/NoData'

// const Agents = (props) => {

//   const title = "Agents";
//   const description = "";
//   const pageSize = 10;
//   const dispatch = useDispatch();
//   const { rightSectionSpinners } = useSelector(state => state.Spinners);
//   const { language } = useSelector(state => state.Languages);
//   const [isShowFilter, setIsShowFilter] = useState(true);
//   const [pagination, setPagination] = useState(null);
//   const [activePageNumber, setActivePageNumber] = useState(0);
//   const [agents, setAgents] = useState([]);

//   const [searchRequestModel, setSearchRequestModel] = useState(null);
//   const [filterData, setFilterData] = useState(null);
//   const [filter, setFilter] = useState({
//     searchKey: [],
//     kycLevel: [],
//   })

//   useEffect(() => {
//     getAgents(1, filter);
//     return () => {
//       setAgents([]);
//     }
//   }, [])

//   const getAgents = (pageNumber, filter) => {
//     const spinnerId = uuid();
//     setRightSectionSpinner(spinnerId);
//     let _filter = { ...filter };
//     Object.entries(_filter).forEach(([key, item]) => {
//       if (Array.isArray(item) && !item.length) {
//         _filter[key] = null;
//       } else if (!item) {
//         if (typeof item !== "number") {
//           _filter[key] = null;
//         }
//       }
//     });
//     ApiService.getAgents(pageNumber, pageSize, _filter).then(response => {
//       if (response && response.pagination) setPagination(JSON.parse(response.pagination));
//       if (response && response.data) {
//         const data = { ...response.data };
//         if (data.filter) {
//           if (data.filter.serachRequestModel) {
//             setSearchRequestModel(data.filter.serachRequestModel);
//           }
//           setFilterData(data.filter);
//         }
//         if (data && data.agents) {
//           setAgents(data.agents);
//         } else {
//           setAgents([]);
//         }
//       }

//       extractRightSectionSpinner(spinnerId);
//     }).catch(error => getFail(error, spinnerId))
//   }

//   const handlePageClick = (event) => {
//     onFilter(event.selected + 1);
//   };

//   const onChange = (event, mainIndex, childIndex, maxLength = null) => {
//     if (maxLength && maxLength < event.target.value.length) { return; }
//     let _searchKey = [...filter.searchKey];
//     _searchKey[mainIndex].value[childIndex] = event.target.value;
//     setFilter(prevFilter => ({ ...prevFilter, searchKey: _searchKey }))
//   }

//   const removeSearchInput = (mainIndex, childIndex) => {
//     let _searchKey = [...filter.searchKey];
//     let currentObj = _searchKey[mainIndex];
//     currentObj.value = currentObj.value.filter((_, index) => index !== childIndex);
//     if (!currentObj.value.length) {
//       _searchKey = _searchKey.filter((_, idx) => idx !== mainIndex)
//     } else {
//       _searchKey[mainIndex] = currentObj;
//     }
//     setFilter(prevFilter => ({ ...prevFilter, searchKey: _searchKey }))
//   }

//   const addSearchInput = (mainIndex) => {
//     let _searchKey = [...filter.searchKey];
//     let currentObj = _searchKey[mainIndex];
//     const emptyString = currentObj.value.find(element => element.trim() === "");
//     if (typeof emptyString !== "undefined") { return false; }
//     currentObj.value = [...currentObj.value, ""];
//     _searchKey[mainIndex] = currentObj;
//     setFilter(prevFilter => ({ ...prevFilter, searchKey: _searchKey }))
//   }

//   const onFilter = (pageNumber = 1) => {
//     let _filter = { ...filter };
//     let newSearchKey = {};
//     _filter.searchKey.forEach(item => {
//       if (item.value.filter(value => value.trim().length > 0).length) {
//         newSearchKey[item.name] = item.value.filter(value => value.trim().length > 0);
//       }
//     });
//     if (Object.keys(newSearchKey).length) {
//       _filter.searchKey = newSearchKey;
//     } else {
//       _filter.searchKey = [];
//     };
//     _filter.kycLevel = _filter.kycLevel.map(item => item.value);

//     setFilter(prevFilter => ({ ...prevFilter, kycLevel: filter.kycLevel }));
//     getAgents(pageNumber, _filter, scrollToCurrentDivPosition);

//   }

//   const clearFilter = () => {
//     let newData = {
//       searchKey: [],
//       kycLevel: [],
//     }
//     setFilter(newData);
//     setActivePageNumber(0);
//     getAgents(1, newData);
//   }

//   const onSearchSelectOptionChange = (item) => {
//     let isExist = filter.searchKey.find(el => el.name === item.value);
//     if (isExist) { return false; }
//     let _searchKey = [...filter.searchKey];
//     _searchKey.push({ name: item.value, value: [""] })
//     setFilter(prevFilter => ({ ...prevFilter, searchKey: _searchKey }))
//   }

//   const scrollToCurrentDivPosition = (id = "") => {
//     const element = document.getElementById(id);
//     if (element) {
//       element.scrollIntoView({ behavior: 'smooth' });
//     }
//   }

//   const removeMultiItem = (index, fieldName) => {
//     let arr = [...filter[fieldName]];
//     arr = arr.filter((_, idx) => index !== idx)
//     setFilter(prevFilter => ({ ...prevFilter, [fieldName]: arr }))
//   }

//   const onMultiSelectOptionChange = (item, fieldName) => {
//     let currentArr = [...filter[fieldName]];
//     let isExist = currentArr.find(el => el.value === item.value);
//     if (isExist) { return false; }
//     currentArr.push({ label: item.label, value: item.value })
//     setFilter((values) => ({ ...values, [fieldName]: currentArr }))
//   }

//   const setRightSectionSpinner = useCallback(spinner => {
//     dispatch(addRightSectionSpinner(spinner));
//   }, []);

//   const extractRightSectionSpinner = useCallback(spinner => {
//     dispatch(removeRightSectionSpinner(spinner));
//   }, []);

//   const getFail = (error, spinnerId) => {
//     error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
//     spinnerId && extractRightSectionSpinner(spinnerId);
//   }

//   return (
//     <>
//       <HtmlHead title={title} description={description} />
//       <div className='page-content position-relative min-h-100vh'>
//         <RightSectionSpinner spinner={rightSectionSpinners} />

//         <div className="container-fluid">
//           <Breadcrumbs
//             title="Cryllex"
//             breadcrumbItem="Agents"
//           />
//           <Row>
//             <Col lg={12}>
//               <Row className='mb-4'>
//                 {
//                   filterData && isShowFilter ?
//                     <Col xl={3}>
//                       <Card id='filter' className='position-relative'>
//                         <CardBody>
//                           <CardTitle className="mb-4">
//                             <div className='d-flex justify-content-between align-items-center cursor-pointer'>
//                               Filter by
//                               <i
//                                 className='bx bx-x'
//                                 style={{ fontSize: "20px" }}
//                                 onClick={() => setIsShowFilter(false)}
//                               />
//                             </div>
//                             <hr />
//                           </CardTitle>
//                           <div>
//                             <div className='w-100 mb-4'>
//                               <div className='mb-3'>
//                                 {
//                                   searchRequestModel ?
//                                     <div>
//                                       <Label className='text-nowrap'>Search by:</Label>
//                                       <ReactSelectOption
//                                         isSearchable={true}
//                                         selectedValue={(() => {
//                                           const selectedValue = { label: "Select", value: "" };
//                                           return selectedValue;
//                                         })()}
//                                         items={Object.keys(searchRequestModel).map(data => ({ label: MainService.camelCaseToWords(data), value: data }))}
//                                         onChange={(item) => onSearchSelectOptionChange(item)}
//                                       />
//                                     </div>
//                                     : null
//                                 }
//                               </div>
//                               {
//                                 filter.searchKey && filter.searchKey.length ?
//                                   filter.searchKey.map((item, index) => {
//                                     return <div key={index} className='border rounded p-2 mb-2'>
//                                       <div className='d-flex justify-content-between align-items-center '>
//                                         <p className='mb-0'><b>{MainService.camelCaseToWords(item.name)}</b></p>
//                                         <Button
//                                           color='success'
//                                           style={{ padding: "3px" }}
//                                           className='d-flex justify-content-center align-items-center bg-success'
//                                           onClick={() => addSearchInput(index)}
//                                         >
//                                           <i className='bx bx-plus' style={{ fontSize: "18px" }}></i>
//                                         </Button>

//                                       </div>
//                                       <hr />
//                                       {
//                                         item.value && item.value.length ?
//                                           item.value.map((el, idx) => {
//                                             return <div key={idx} className='d-flex justify-content-between align-items-center mb-1'>
//                                               <Input
//                                                 id="search"
//                                                 type="search"
//                                                 placeholder='Search'
//                                                 className={`form-control resize-none`}
//                                                 value={el}
//                                                 onChange={event => onChange(event, index, idx, fielsLengths.length_120)}
//                                               />
//                                               <Button
//                                                 outline
//                                                 color='danger'
//                                                 style={{ padding: "5px" }}
//                                                 className='d-flex justify-content-center align-items-center ms-2'
//                                                 onClick={() => removeSearchInput(index, idx)}
//                                               >
//                                                 <i className='bx bx-trash' style={{ fontSize: "18px" }} />
//                                               </Button>
//                                             </div>
//                                           })
//                                           : null
//                                       }

//                                     </div>
//                                   })
//                                   : null
//                               }
//                             </div>
//                             <hr />
//                           </div>

//                           {
//                             filterData.kyclevels && filterData.kyclevels.length ?
//                               <div className='w-100 mb-4'>
//                                 <Label className='text-nowrap'>KYC Level</Label>
//                                 <ReactSelectOption
//                                   isSearchable={true}
//                                   selectedValue={(() => {
//                                     const selectedValue = { label: "Select", value: "" };
//                                     return selectedValue;
//                                   })()}
//                                   items={filterData.kyclevels.map(data => ({ ...data, label: data.value, value: data.key }))}
//                                   onChange={(item) => onMultiSelectOptionChange(item, "kycLevel")}
//                                 />

//                                 <div>
//                                   {
//                                     filter.kycLevel && filter.kycLevel.map((item, index) => {
//                                       return <div key={index} className='d-flex justify-content-between align-items-center border rounded p-2 mt-1'>
//                                         {MainService.camelCaseToWords(item.label)}
//                                         <Button
//                                           outline
//                                           color='danger'
//                                           style={{ padding: "5px" }}
//                                           className='d-flex justify-content-center align-items-center ms-2'
//                                           onClick={() => removeMultiItem(index, "kycLevel")}
//                                         >
//                                           <i className='bx bx-trash' style={{ fontSize: "18px" }} />
//                                         </Button>
//                                       </div>
//                                     })
//                                   }
//                                 </div>
//                               </div>

//                               : null
//                           }
//                           <div className="mt-4 mb-3 d-flex gap-2 justify-content-end">
//                             <Button outline color='primary' onClick={clearFilter}>
//                               Clear filter
//                             </Button>
//                             <Button color='primary' onClick={() => onFilter()} className='px-4'>
//                               Filter
//                             </Button>
//                           </div>
//                         </CardBody>
//                       </Card>
//                     </Col>
//                     : null
//                 }
//                 <Col xl={filterData && isShowFilter ? 9 : 12}>
//                   <Card id='table' className="position-relative">

//                     <CardBody>
//                       <CardTitle className="mb-4">
//                         <div className='d-flex justify-content-between align-items-center'>
//                           Agents
//                           {
//                             !isShowFilter && filterData ?
//                               <Button
//                                 color='primary'
//                                 size='sm'
//                                 className='px-3 d-flex align-items-end'
//                                 onClick={() => {
//                                   setIsShowFilter(true);
//                                   scrollToCurrentDivPosition("filter")
//                                 }}
//                               >
//                                 <i className='bx bx-filter me-2' style={{ fontSize: "20px" }}></i>
//                                 Show Filter
//                               </Button>
//                               : null
//                           }
//                         </div>
//                         <hr />
//                       </CardTitle>
//                       {
//                         agents && agents.length ? (
//                           <Table striped bordered hover responsive>
//                             <thead>
//                               <tr>
//                                 <th>#</th>
//                                 <th className='text-nowrap'>Client Id</th>
//                                 <th className='text-nowrap'>Classification</th>
//                                 <th className='text-nowrap'>Legal business Name</th>
//                                 <th className='text-nowrap'>KYC Status</th>
//                                 <th className='text-nowrap'>Primary Representative</th>
//                                 <th className='text-nowrap'>Email</th>
//                                 <th className='text-nowrap'>Approved</th>
//                                 <th className='text-nowrap'>Active</th>
//                                 <th className='text-nowrap text-center'>Actions</th>
//                               </tr>
//                             </thead>
//                             <tbody>
//                               {
//                                 agents.map((item, index) => {
//                                   return <tr key={index}>
//                                     <td>{index + 1}</td>
//                                     <td>{item.uniqueId}</td>
//                                     <td>{item.classification}</td>
//                                     <td>{item.legalBusinessName}</td>
//                                     <td>{item.kycLevel ? `Level ${item.kycLevel}` : `Unlevel KYC`}</td>
//                                     <td>{item.primaryUserFullName}</td>
//                                     <td>{item.email}</td>
//                                     <td>
//                                       <span className={`badge badge-soft-${item?.isApproved ? "success" : "danger"} font-size-14 badge badge-pill`}>
//                                         {item?.isApproved ? "Yes" : "No"}
//                                       </span>
//                                     </td>
//                                     <td>
//                                       <span className={`badge badge-soft-${item?.isActive ? "success" : "danger"} font-size-14 badge badge-pill`}>
//                                         {item?.isActive ? "Yes" : "No"}
//                                       </span>
//                                     </td>
//                                     <td>
//                                       <div className='d-flex flex-nowrap'>
//                                         <Button
//                                           type="button"
//                                           color="primary"
//                                           className='shadow-sm text-nowrap me-2'
//                                           onClick={() => props.history.push(`/${language}/agent/${item.id}`)}
//                                         >
//                                           View KYC
//                                         </Button>
//                                         <Button
//                                           type="button"
//                                           color="info"
//                                           className='shadow-sm me-2 text-nowrap'
//                                           onClick={() => props.history.push(`/${language}/agent/organizations/${item.id}`)}
//                                         >
//                                           View Related Parties
//                                         </Button>
//                                       </div>
//                                     </td>
//                                   </tr>
//                                 })
//                               }
//                             </tbody>
//                           </Table>
//                         ) :
//                           <NoData />
//                       }
//                     </CardBody>
//                     {
//                       pagination && pagination.TotalPages && pagination.TotalPages > 1 ?
//                         <ReactPaginate
//                           nextLabel={`Next`}
//                           onPageChange={handlePageClick}
//                           pageRangeDisplayed={3}
//                           marginPagesDisplayed={2}
//                           pageCount={pagination.TotalPages}
//                           previousLabel={`Previous`}
//                           pageClassName="page-item"
//                           pageLinkClassName="page-link"
//                           previousClassName="page-item"
//                           previousLinkClassName="page-link"
//                           nextClassName="page-item"
//                           nextLinkClassName="page-link"
//                           breakLabel="..."
//                           breakClassName="page-item"
//                           breakLinkClassName="page-link"
//                           containerClassName="pagination mb-0"
//                           activeClassName="active"
//                           renderOnZeroPageCount={null}
//                           forcePage={activePageNumber}
//                         />
//                         : null
//                     }
//                   </Card>
//                 </Col>
//               </Row>
//             </Col>
//           </Row>
//         </div>
//       </div>
//     </>
//   )
// }

// export default withTranslation()(Agents)


import React, { useCallback, useEffect, useRef, useState } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import {
  Card,
  CardBody,
  Col,
  Row,
  Label,
  Button,
  Input,
  CardTitle
} from "reactstrap"
import { Table } from "react-bootstrap"
import { ERROR_KEY, fielsLengths } from '../../../Constants/MainKeys'
import { addRightSectionSpinner, removeRightSectionSpinner } from '../../../store/spinner/actions'
import AlertService from '../../../Services/alertService'
import { useDispatch, useSelector } from 'react-redux'
import HtmlHead from '../../../components/HtmlHead/HtmlHead'
import RightSectionSpinner from '../../../components/Spinners/RightSectionSpinner'
import uuid from 'react-uuid'
import ApiService from '../../../Services/ApiService'
import ReactSelectOption from '../../../components/SelectOptions/ReactSelectOption'
import NoData from '../../../components/NoData/NoData'
import MainService from '../../../Services/MainService'
import ReactPaginate from 'react-paginate'

const activeFilterFields = [
  {
    value: "All",
    key: null,
  },
  {
    value: "Active",
    key: true,
  },
  {
    value: "Inactive",
    key: false,
  },
]

const approvedFilterFields = [
  {
    value: "All",
    key: null,
  },
  {
    value: "Approved",
    key: true,
  },
  {
    value: "Not Approved",
    key: false,
  },
]

const Agents = (props) => {

  const dispatch = useDispatch();
  const pageSize = 10;
  const title = "Agents";
  const description = "";
  const { rightSectionSpinners } = useSelector(state => state.Spinners);
  const { language } = useSelector(state => state.Languages);
  const [agents, setAgents] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [activePageNumber, setActivePageNumber] = useState(0);
  const [isShowFilter, setIsShowFilter] = useState(true);

  const [searchRequestModel, setSearchRequestModel] = useState(null);
  const [filterData, setFilterData] = useState(null);
  const [filter, setFilter] = useState({
    searchKey: [],
    clasificationType: [],
    kycLevel: [],
    isActive: null,
    isApproved: null,
  })

  useEffect(() => {
    getAgents(1, filter);
  }, [])

  const getAgents = (currentPage, filter, cb) => {
    const spinnerId = uuid();
    setRightSectionSpinner(spinnerId);
    let _filter = { ...filter };
    if (!_filter.searchKey.length) {
      _filter.searchKey = null;
    }
    if (!_filter.clasificationType.length) {
      _filter.clasificationType = null;
    }
    ApiService.getAgents(currentPage, pageSize, _filter).then(response => {
      if (response && response.pagination) setPagination(JSON.parse(response.pagination));
      if (response && response.data) {
        const data = { ...response.data };
        if (data.filter) {
          if (data.filter.serachRequestModel) {
            setSearchRequestModel(data.filter.serachRequestModel);
          }
          setFilterData(data.filter);
        }
        if (data.agents) {
          setAgents(data.agents);
        } else {
          setAgents([]);
        }
      }
      cb && cb("table")
    }).catch(error => getFail(error)).finally(() => {
      extractRightSectionSpinner(spinnerId)
    })
  }

  const handlePageClick = (event) => {
    // setActivePageNumber(event.selected);
    onFilter(event.selected + 1);
  };

  const onChange = (event, mainIndex, childIndex, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    let _searchKey = [...filter.searchKey];
    _searchKey[mainIndex].value[childIndex] = event.target.value;
    setFilter(prevFilter => ({ ...prevFilter, searchKey: _searchKey }))
  }

  const addSearchInput = (mainIndex) => {
    let _searchKey = [...filter.searchKey];
    let currentObj = _searchKey[mainIndex];
    const emptyString = currentObj.value.find(element => element.trim() === "");
    if (typeof emptyString !== "undefined") { return false; }
    currentObj.value = [...currentObj.value, ""];
    _searchKey[mainIndex] = currentObj;
    setFilter(prevFilter => ({ ...prevFilter, searchKey: _searchKey }))
  }

  const removeSearchInput = (mainIndex, childIndex) => {
    let _searchKey = [...filter.searchKey];
    let currentObj = _searchKey[mainIndex];
    currentObj.value = currentObj.value.filter((_, index) => index !== childIndex);
    if (!currentObj.value.length) {
      _searchKey = _searchKey.filter((_, idx) => idx !== mainIndex)
    } else {
      _searchKey[mainIndex] = currentObj;
    }
    setFilter(prevFilter => ({ ...prevFilter, searchKey: _searchKey }))
  }


  const onSelectOptionChange = (item, fieldName) => {
    setFilter((values) => ({ ...values, [fieldName]: item.value }))
  }

  const removeMultiItem = (index, fieldName) => {
    let arr = [...filter[fieldName]];
    arr = arr.filter((_, idx) => index !== idx)
    setFilter(prevFilter => ({ ...prevFilter, [fieldName]: arr }))
  }

  const onMultiSelectOptionChange = (item, fieldName) => {
    let currentArr = [...filter[fieldName]];
    let isExist = currentArr.find(el => el.value === item.value);
    if (isExist) { return false; }
    currentArr.push({ label: item.label, value: item.value })
    setFilter((values) => ({ ...values, [fieldName]: currentArr }))
  }

  const onSearchSelectOptionChange = (item) => {
    let isExist = filter.searchKey.find(el => el.name === item.value);
    if (isExist) { return false; }
    let _searchKey = [...filter.searchKey];
    _searchKey.push({ name: item.value, value: [""] })
    setFilter(prevFilter => ({ ...prevFilter, searchKey: _searchKey }))
  }

  const clearFilter = () => {
    let _filter = {
      clasificationType: [],
      kycLevel: [],
      isActive: null,
      isApproved: null,
      searchKey: [],
    };
    setFilter(_filter);
    getAgents(1, _filter);
  }

  const onFilter = (pageNumber = 1) => {
    let _filter = { ...filter };
    let newSearchKey = {};
    _filter.searchKey.forEach(item => {
      if (item.value.filter(value => value.trim().length > 0).length) {
        newSearchKey[item.name] = item.value.filter(value => value.trim().length > 0);
      }
    });
    if (Object.keys(newSearchKey).length) {
      _filter.searchKey = newSearchKey;
    } else {
      _filter.searchKey = [];
    }
    _filter.kycLevel = _filter.kycLevel.map(item => item.value);
    _filter.clasificationType = _filter.clasificationType.map(item => item.value);
    setActivePageNumber(pageNumber - 1 || 0);
    setFilter(prevFilter => ({ ...prevFilter, kycLevel: filter.kycLevel, searchKey: filter.searchKey }));
    getAgents(pageNumber, _filter, scrollToCurrentDivPosition);
  }

  const scrollToCurrentDivPosition = (id = "") => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const setRightSectionSpinner = useCallback(spinner => {
    dispatch(addRightSectionSpinner(spinner));
  }, []);

  const extractRightSectionSpinner = useCallback(spinner => {
    dispatch(removeRightSectionSpinner(spinner));
  }, []);

  const getFail = (error) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
  }

  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className='page-content position-relative min-h-100vh'>
        <RightSectionSpinner spinner={rightSectionSpinners} />
        <div className='container-fluid'>
          <Breadcrumbs
            title="Cryllex"
            breadcrumbItem="Agents"
          />
          <Row>
            <Col lg={12}>
              <Row className='mb-4'>
                {
                  filterData && isShowFilter ?
                    <Col xl={3}>
                      <Card id="filter">
                        <CardBody className='pb-0'>
                          <CardTitle className="mb-4">
                            <div className='d-flex justify-content-between align-items-center cursor-pointer'>
                              Filter by
                              <i
                                className='bx bx-x'
                                style={{ fontSize: "20px" }}
                                onClick={() => setIsShowFilter(false)}
                              />
                            </div>
                            <hr />
                          </CardTitle>
                          <div>
                            <div className='w-100 mb-4'>
                              <div className='mb-3'>
                                {
                                  searchRequestModel ?
                                    <div>
                                      <Label className='text-nowrap'>Search by:</Label>
                                      <ReactSelectOption
                                        isSearchable={true}
                                        selectedValue={(() => {
                                          const selectedValue = { label: "Select", value: "" };
                                          return selectedValue;
                                        })()}
                                        items={Object.keys(searchRequestModel).map(data => ({ label: MainService.camelCaseToWords(data), value: data }))}
                                        onChange={(item) => onSearchSelectOptionChange(item)}
                                      />
                                    </div>
                                    : null
                                }
                              </div>
                              {
                                filter.searchKey && filter.searchKey.length ?
                                  filter.searchKey.map((item, index) => {
                                    return <div key={index} className='border rounded p-2 mb-2'>
                                      <div className='d-flex justify-content-between align-items-center '>
                                        <p className='mb-0'><b>{MainService.camelCaseToWords(item.name)}</b></p>
                                        <Button
                                          color='success'
                                          style={{ padding: "3px" }}
                                          className='d-flex justify-content-center align-items-center bg-success'
                                          onClick={() => addSearchInput(index)}
                                        >
                                          <i className='bx bx-plus' style={{ fontSize: "18px" }}></i>
                                        </Button>

                                      </div>
                                      <hr />
                                      {
                                        item.value && item.value.length ?
                                          item.value.map((el, idx) => {
                                            return <div key={idx} className='d-flex justify-content-between align-items-center mb-1'>
                                              <Input
                                                id="search"
                                                type="search"
                                                placeholder='Search'
                                                className={`form-control resize-none`}
                                                value={el}
                                                onChange={event => onChange(event, index, idx, fielsLengths.length_120)}
                                              />
                                              <Button
                                                outline
                                                color='danger'
                                                style={{ padding: "5px" }}
                                                className='d-flex justify-content-center align-items-center ms-2'
                                                onClick={() => removeSearchInput(index, idx)}
                                              >
                                                <i className='bx bx-trash' style={{ fontSize: "18px" }} />
                                              </Button>
                                            </div>
                                          })
                                          : null
                                      }

                                    </div>
                                  })
                                  : null
                              }
                            </div>
                            <hr />
                          </div>
                          {
                            filterData.classifications && filterData.classifications.length ?
                              <div className='w-100 mb-4'>
                                <Label className='text-nowrap'>Clasification Type</Label>
                                <ReactSelectOption
                                  isSearchable={true}
                                  selectedValue={(() => {
                                    const selectedValue = { label: "Select", value: "" };
                                    return selectedValue;
                                  })()}
                                  items={filterData.classifications.map(data => ({ ...data, label: data.value, value: data.key }))}
                                  onChange={(item) => onMultiSelectOptionChange(item, "clasificationType")}
                                />

                                <div>
                                  {
                                    filter.clasificationType && filter.clasificationType.map((item, index) => {
                                      return <div key={index} className='d-flex justify-content-between align-items-center border rounded p-2 mt-1'>
                                        {MainService.camelCaseToWords(item.label)}
                                        <Button
                                          outline
                                          color='danger'
                                          style={{ padding: "5px" }}
                                          className='d-flex justify-content-center align-items-center ms-2'
                                          onClick={() => removeMultiItem(index, "clasificationType")}
                                        >
                                          <i className='bx bx-trash' style={{ fontSize: "18px" }} />
                                        </Button>
                                      </div>
                                    })
                                  }
                                </div>
                              </div>

                              : null
                          }
                          {
                            filterData.kyclevels && filterData.kyclevels.length ?
                              <div className='w-100 mb-4'>
                                <Label className='text-nowrap'>KYC Level</Label>
                                <ReactSelectOption
                                  isSearchable={true}
                                  selectedValue={(() => {
                                    const selectedValue = { label: "Select", value: "" };
                                    return selectedValue;
                                  })()}
                                  items={filterData.kyclevels.map(data => ({ ...data, label: data.value, value: data.key }))}
                                  onChange={(item) => onMultiSelectOptionChange(item, "kycLevel")}
                                />

                                <div>
                                  {
                                    filter.kycLevel && filter.kycLevel.map((item, index) => {
                                      return <div key={index} className='d-flex justify-content-between align-items-center border rounded p-2 mt-1'>
                                        {MainService.camelCaseToWords(item.label)}
                                        <Button
                                          outline
                                          color='danger'
                                          style={{ padding: "5px" }}
                                          className='d-flex justify-content-center align-items-center ms-2'
                                          onClick={() => removeMultiItem(index, "kycLevel")}
                                        >
                                          <i className='bx bx-trash' style={{ fontSize: "18px" }} />
                                        </Button>
                                      </div>
                                    })
                                  }
                                </div>
                              </div>

                              : null
                          }
                          <div className='w-100 mb-4'>
                            <Label className='text-nowrap'>Active</Label>
                            <ReactSelectOption
                              value={filter.isActive}
                              isSearchable={true}
                              selectedValue={(() => {
                                const selectedItem = { ...activeFilterFields.find(data => data.key === filter.isActive) };
                                if (Object.keys(selectedItem).length) {
                                  selectedItem.label = selectedItem.value;
                                  selectedItem.value = selectedItem.key;
                                  return selectedItem;
                                } else {
                                  return { label: "Choose...", value: null }
                                }
                              })()}
                              items={activeFilterFields.map(data => ({ ...data, label: data.value, value: data.key }))}
                              onChange={(item) => onSelectOptionChange(item, "isActive")}
                            />
                          </div>
                          <div className='w-100 mb-4'>
                            <Label className='text-nowrap'>Approved</Label>
                            <ReactSelectOption
                              value={filter.isApproved}
                              isSearchable={true}
                              selectedValue={(() => {
                                const selectedItem = { ...approvedFilterFields.find(data => data.key === filter.isApproved) };
                                if (Object.keys(selectedItem).length) {
                                  selectedItem.label = selectedItem.value;
                                  selectedItem.value = selectedItem.key;
                                  return selectedItem;
                                } else {
                                  return { label: "Choose...", value: null }
                                }
                              })()}
                              items={approvedFilterFields.map(data => ({ ...data, label: data.value, value: data.key }))}
                              onChange={(item) => onSelectOptionChange(item, "isApproved")}
                            />
                          </div>

                          <div className="mt-2 mb-3 d-flex gap-2 justify-content-end">
                            <Button outline color='primary' onClick={clearFilter} className='text-nowrap'>
                              Clear filter
                            </Button>
                            <Button color='primary' onClick={() => onFilter()} className='px-4'>
                              Filter
                            </Button>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    : null
                }
                <Col xl={filterData && isShowFilter ? 9 : 12}>
                  <Card id='table'>
                    <CardBody className='pb-0'>
                      <CardTitle className="mb-4">
                        <div className='d-flex justify-content-between align-items-center'>
                          Agents
                          {
                            !isShowFilter && filterData ?
                              <Button
                                color='primary'
                                size='sm'
                                className='px-3 d-flex align-items-end'
                                onClick={() => {
                                  setIsShowFilter(true);
                                  scrollToCurrentDivPosition("filter")
                                }}
                              >
                                <i className='bx bx-filter me-2' style={{ fontSize: "20px" }}></i>
                                Show Filter
                              </Button>
                              : null
                          }
                        </div>
                        <hr />
                      </CardTitle>
                      {
                        agents && agents.length ?
                          <div>

                            <Table striped bordered hover responsive>
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th className='text-nowrap'>Client Id</th>
                                  <th className='text-nowrap'>Classification</th>
                                  <th className='text-nowrap'>Legal business Name</th>
                                  <th className='text-nowrap'>KYC Status</th>
                                  <th className='text-nowrap'>Primary Representative</th>
                                  <th className='text-nowrap'>Email</th>
                                  <th className='text-nowrap'>Approved</th>
                                  <th className='text-nowrap'>Active</th>
                                  <th className='text-nowrap text-center'>Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  agents.map((item, index) => {
                                    return <tr key={index}>
                                      <td>{index + 1}</td>
                                      <td>{item.uniqueId}</td>
                                      <td>{item.classification}</td>
                                      <td>{item.legalBusinessName}</td>
                                      <td>{item.kycLevel ? `Level ${item.kycLevel}` : `Unlevel KYC`}</td>
                                      <td>{item.primaryUserFullName}</td>
                                      <td>{item.email}</td>
                                      <td>
                                        <span className={`badge badge-soft-${item?.isApproved ? "success" : "danger"} font-size-14 badge badge-pill`}>
                                          {item?.isApproved ? "Yes" : "No"}
                                        </span>
                                      </td>
                                      <td>
                                        <span className={`badge badge-soft-${item?.isActive ? "success" : "danger"} font-size-14 badge badge-pill`}>
                                          {item?.isActive ? "Yes" : "No"}
                                        </span>
                                      </td>
                                      <td>
                                        <div className='d-flex flex-nowrap'>
                                          <Button
                                            type="button"
                                            color="primary"
                                            className='shadow-sm text-nowrap me-2'
                                            onClick={() => props.history.push(`/${language}/agent/${item.id}`)}
                                          >
                                            View KYC
                                          </Button>
                                          <Button
                                            type="button"
                                            color="info"
                                            className='shadow-sm me-2 text-nowrap'
                                            onClick={() => props.history.push(`/${language}/agent/organizations/${item.id}`)}
                                          >
                                            View Related Parties
                                          </Button>
                                        </div>
                                      </td>
                                    </tr>
                                  })
                                }
                              </tbody>
                            </Table>
                            <div className='py-3 d-flex justify-content-end'>
                              {
                                pagination && pagination.TotalPages && pagination.TotalPages > 1 ?
                                  <ReactPaginate
                                    nextLabel={`Next`}
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={2}
                                    pageCount={pagination.TotalPages}
                                    previousLabel={`Previous`}
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    breakLabel="..."
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    containerClassName="pagination mb-0"
                                    activeClassName="active"
                                    renderOnZeroPageCount={null}
                                    forcePage={activePageNumber}
                                  />
                                  : null
                              }
                            </div>
                          </div>
                          :
                          <NoData />
                      }

                    </CardBody>
                  </Card>
                </Col>


              </Row>
            </Col >

          </Row >
        </div >
      </div >
    </>
  )
}

export default Agents