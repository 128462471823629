import React, { useState } from 'react'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Container,
} from "reactstrap"

export default function CryptoHedging() {

  const [modal, setmodal] = useState(true)

  return (
    <Container>
      <div className='page-content'>Crypto Hedging page</div>
      <Modal
        isOpen={modal}
        size="sm"
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setmodal(!modal)
        }}
      >
        <div className="modal-content">
          <ModalHeader
            toggle={() => {
              setmodal(!modal)
            }}
          >
            Crypto Hedging
          </ModalHeader>
          <ModalBody>
            <form>
              <div className='row mt-1'>
                <div className='col-3 pe-0'>
                  <span className='badge badge-soft-secondary font-size-14 d-flex align-items-center justify-content-center h-100 d-block px-2'><b>ETH</b></span>
                </div>
                <div className='col-9'>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Enter value"
                  />
                </div>
              </div>
              <div className='row mt-1'>
                <div className='col-3 pe-0'>
                  <span className='badge badge-soft-secondary font-size-14 d-flex align-items-center justify-content-center h-100 d-block px-2'><b>BNB</b></span>
                </div>
                <div className='col-9'>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Enter value"
                  />
                </div>
              </div>
              <div className='row mt-1'>
                <div className='col-3 pe-0'>
                  <span className='badge badge-soft-secondary font-size-14 d-flex align-items-center justify-content-center h-100 d-block px-2'><b>DAI</b></span>
                </div>
                <div className='col-9'>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Enter value"
                  />
                </div>
              </div>
              <div className='row mt-1'>
                <div className='col-3 pe-0'>
                  <span className='badge badge-soft-secondary font-size-14 d-flex align-items-center justify-content-center h-100 d-block px-2'><b>UNI</b></span>
                </div>
                <div className='col-9'>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Enter value"
                  />
                </div>
              </div>
            </form>
          </ModalBody>
          <ModalFooter className='d-flex justify-content-center'>
            <Button type="button" color="success" className='shadow-sm'>
              Add entry (+)
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </Container>
  )
}
