import React from 'react'
import { Button, Label, UncontrolledAlert } from 'reactstrap';
import MainService from '../../../../Services/MainService';

export default function FilesComponent(props) {

  const {
    data,
    fieldName,
    labelValue,
    deleteDocument,
    setZoomImagePath,
    uploadFile,
    alertMessage = null,
    alertIcon = null,
    alertType = null,
    isShowDeleteIcon = true,
    multiple = true,
  } = props;

  return (
    <div className='border px-3 py-3 rounded h-100 d-flex flex-column justify-content-between align-items-center'>
      {
        labelValue ?
          <Label className='mb-2 text-center d-block'>{labelValue}</Label>
          : null
      }
      <div className='w-100'>
        <div className='w-100 overflowY-scroll' style={{ maxHeight: "calc(200px + 1rem)", overflowX: "hidden" }}>
          <div className='d-flex flex-wrap'>
            {
              data[fieldName] && data[fieldName].length ?
                data[fieldName].map((item, index) => {
                  return <div key={index} className='position-relative border rounded' style={{ maxWidth: "150px", maxHeight: "150px", minHeight: "100px", minWidth: "100px", margin: "0.25rem auto" }} >
                    {
                      (() => {
                        switch (MainService.getFxtensions(item.filePath)) {
                          case "image":
                            return <div
                              style={{ width: "100%", height: "100%", }}
                              className='d-flex justify-content-center align-items-center flex-column cursor-pointer'
                              onClick={() => setZoomImagePath(item.filePath)}
                            >
                              <img
                                src={item.filePath}
                                className='rounded'
                                style={{ maxWidth: "200px", width: "100%", height: "100%", maxHeight: "200px" }}
                                alt='/'
                              />
                            </div>
                          case "file":
                            return <div
                              style={{ width: "100%", height: "100%", }}
                              className='p-2  d-flex justify-content-center align-items-center flex-column cursor-pointer'
                              onClick={() => window.open(item.filePath, "_blank")}
                            >
                              <i className='bx bx-file-blank' style={{ fontSize: "50px" }} ></i>
                            </div>
                          default:
                            break;
                        }
                      })()
                    }
                    {
                      isShowDeleteIcon ?
                        <i
                          className='bx bx-x position-absolute font-size-20 text-danger bg-white rounded cursor-pointer border'
                          style={{ top: "1px", right: "1px" }}
                          onClick={() => {
                            deleteDocument({ fieldName, fileLibraryId: item.fileLibraryId });
                          }}
                        />
                        : null
                    }

                  </div>

                }) : null
            }
          </div>
        </div>
        <div className='d-flex flex-column align-items-center'>
          <Button
            onClick={MainService.triggerUploadClick}
            outline
            className='mt-1'
            color="primary"
            type='button'
          >
            <input type="file" id='avatarImage' className="d-none" hidden multiple={multiple} onChange={(event) => uploadFile({ event, fieldName })} />
            Upload file
          </Button>
        </div>
      </div>
      {
        alertMessage && alertIcon && alertType ?
          <UncontrolledAlert color={alertType} role="alert" className='mt-3 w-100 mb-0'>
            <i className={`${alertIcon} mdi me-2`}></i>
            {alertMessage}
          </UncontrolledAlert> : null
      }
    </div>
  )
}
