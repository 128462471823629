import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, Modal, ModalFooter,
  ModalHeader, ModalBody
} from "reactstrap"
import { updateUserData } from '../../../store/actions';

const ChooseActiveAgentModal = ({ agents }) => {

  const dispatch = useDispatch()
  const { user } = useSelector(state => state.Login);
  const [agentId, setAgentId] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const setActiveAgentId = () => {
    if (!agentId) { return false; }
    setIsLoading(true);
    const userCopy = { ...user }
    userCopy.activeAgentId = agentId;
    dispatch(updateUserData(userCopy));
  }

  return (
    <Modal isOpen={true}
      size="lg"
    >
      <ModalHeader>Choose agent</ModalHeader>
      <ModalBody>
        <div className='d-flex flex-wrap'>
          {
            agents && agents.map((item, index) => {
              return <div
                key={index}
                className={`p-3 border rounded m-1 border-2 cursor-pointer ${agentId === item.id ? "border-success" : ""}`}
                onClick={() => setAgentId(item.id)}
              >
                {item.legalBusinessName}
              </div>
            })
          }
        </div>
      </ModalBody>
      <ModalFooter>
        {
          isLoading ?
            <Button
              color="light"
              disabled={true}
            >
              <i className='bx bx-hourglass bx-spin font-size-16 align-middle me-2' />
              Loading
            </Button>
            : <Button
              color="primary"
              onClick={() => setActiveAgentId()}
            >
              Save
            </Button>
        }




      </ModalFooter>
    </Modal>
  );
}

export default ChooseActiveAgentModal;
