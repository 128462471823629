import React from 'react'
import NoData from '../../../../components/NoData/NoData'
import { Card, Table, CardBody, Row, Col } from 'reactstrap'
import BankAccountInformation from '../../BankAccount/BankAccountInformation'
import { useSelector } from 'react-redux';
import Auxiliary from '../../../../hoc/auxiliary/auxiliary';
import MainService from '../../../../Services/MainService';
import moment from 'moment';
import { ADMIN_USER_TYPE_ID } from '../../../../Constants/MainKeys';

export default function dataTable({ data, currencies, user }) {

  const getCurrency = (currencyId) => {
    if (!currencyId || !currencies?.length) return "";
    const currentCurrency = currencies.find(item => item.id === currencyId);
    return currentCurrency ? currentCurrency.name : "";
  };

  return data ?
    <Auxiliary>
      {
        user.userTypeId === ADMIN_USER_TYPE_ID ?
          <Card className="mini-stats-wid my-1">
            <CardBody>
              <Row>
                <Col md={6}>
                  <Table bordered striped responsive className='mb-0'>
                    <tbody>
                      <tr>
                        <th
                          scope="row"
                          style={{ width: "400px" }}
                          className={"text-capitalize"}
                        >
                          Local Transaction Id
                        </th>
                        <td className='w-50'><span className='max-line-1' title={data.localTransactionId}>{data.localTransactionId}</span></td>
                      </tr>
                      <tr>
                        <th
                          scope="row"
                          style={{ width: "400px" }}
                          className={"text-capitalize"}
                        >
                          Amount
                        </th>
                        <td className='w-50'>{data.amount} {getCurrency(data.currencyId)}</td>
                      </tr>

                      <tr>
                        <th
                          scope="row"
                          style={{ width: "400px" }}
                          className={"text-capitalize"}
                        >
                          Commission
                        </th>
                        <td className='w-50'>{data.commission} {getCurrency(data.currencyId)}</td>
                      </tr>
                      <tr>
                        <th
                          scope="row"
                          style={{ width: "400px" }}
                          className={"text-capitalize"}
                        >
                          Local Commission
                        </th>
                        <td className='w-50'>{data.localCommission} {getCurrency(data.currencyId)}</td>
                      </tr>

                      <tr>
                        <th
                          scope="row"
                          style={{ width: "400px" }}
                          className={"text-capitalize"}
                        >
                          Notified
                        </th>
                        <td className='w-50'>
                          <span className={`badge badge-soft-${data?.notified ? "success" : "danger"} font-size-14 badge badge-pill`}>
                            {data?.notified ? "Yes" : "No"}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th
                          scope="row"
                          style={{ width: "400px" }}
                          className={"text-capitalize"}
                        >
                          Notification Time
                        </th>
                        <td className='w-50'>{
                          data.notificationTime ? moment(MainService.convertUTCDateToLocalDate(new Date(data.notificationTime))).format("LL H:mm") : null
                        }</td>
                      </tr>
                      <tr>
                        <th
                          scope="row"
                          style={{ width: "400px" }}
                          className={"text-capitalize"}
                        >
                          Processed
                        </th>
                        <td className='w-50'>
                          <span className={`badge badge-soft-${data?.processed ? "success" : "danger"} font-size-14 badge badge-pill`}>
                            {data?.processed ? "Yes" : "No"}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th
                          scope="row"
                          style={{ width: "400px" }}
                          className={"text-capitalize"}
                        >
                          Operation Time
                        </th>
                        <td className='w-50'>{
                          data.operationTime ? moment(MainService.convertUTCDateToLocalDate(new Date(data.operationTime))).format("LL H:mm") : "Not operated yet"
                        }</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
                <Col md={6}>
                  <Table bordered striped responsive className='mb-0'>
                    <tbody>
                      <tr>
                        <th
                          scope="row"
                          style={{ width: "400px" }}
                          className={"text-capitalize"}
                        >
                          Status
                        </th>
                        <td className='w-50'>
                          <span className={`badge badge-soft-${MainService.getStatusColor(data.statusName)} font-size-14 badge badge-pill`}>{data.statusName}</span>
                        </td>
                      </tr>
                      <tr>
                        <th
                          scope="row"
                          style={{ width: "400px" }}
                          className={"text-capitalize"}
                        >
                          Status Update Time
                        </th>
                        <td className='w-50'>{
                          data.statusUpdateTime ? moment(MainService.convertUTCDateToLocalDate(new Date(data.statusUpdateTime))).format("LL H:mm") : "Not updated yet"
                        }</td>
                      </tr>
                      <tr>
                        <th
                          scope="row"
                          style={{ width: "400px" }}
                          className={"text-capitalize"}
                        >
                          Calculated
                        </th>
                        <td className='w-50'>
                          <span className={`badge badge-soft-${data?.calculated ? "success" : "danger"} font-size-14 badge badge-pill`}>
                            {data?.calculated ? "Yes" : "No"}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th
                          scope="row"
                          style={{ width: "400px" }}
                          className={"text-capitalize"}
                        >
                          Calculated Amount
                        </th>
                        <td className='w-50'>{data.calculatedAmount} {getCurrency(data.currencyId)}</td>
                      </tr>
                      <tr>
                        <th
                          scope="row"
                          style={{ width: "400px" }}
                          className={"text-capitalize"}
                        >
                          Calculated Cleared Amount
                        </th>
                        <td className='w-50'>{data.calculatedClearedAmount} {getCurrency(data.currencyId)}</td>
                      </tr>
                      <tr>
                        <th
                          scope="row"
                          style={{ width: "400px" }}
                          className={"text-capitalize"}
                        >
                          Calculation Time
                        </th>
                        <td className='w-50'>
                          {
                            data.calculationTime ? moment(MainService.convertUTCDateToLocalDate(new Date(data.calculationTime))).format("LL H:mm") : "Not calculated yet"
                          }
                        </td>
                      </tr>
                      <tr>
                        <th
                          scope="row"
                          style={{ width: "400px" }}
                          className={"text-capitalize"}
                        >
                          Create Time
                        </th>
                        <td className='w-50'>{
                          data.createTime ? moment(MainService.convertUTCDateToLocalDate(new Date(data.createTime))).format("LL H:mm") : null
                        }</td>
                      </tr>
                      <tr>
                        <th
                          scope="row"
                          style={{ width: "400px" }}
                          className={"text-capitalize"}
                        >
                          Decline Message
                        </th>
                        <td className='w-50'>{data.declineMessage}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </CardBody>
          </Card>
          : <Card className="mini-stats-wid my-1">
            <CardBody>
              <Row>
                <Col md={6}>
                  <Table bordered striped responsive className='mb-0'>
                    <tbody>
                      <tr>
                        <th
                          scope="row"
                          style={{ width: "400px" }}
                          className={"text-capitalize"}
                        >
                          Local Transaction Id
                        </th>
                        {/* <td className='w-50'>{data.localTransactionId}</td> */}
                        <td className='w-50'><span className='max-line-1' title={data.localTransactionId}>{data.localTransactionId}</span></td>
                      </tr>
                      <tr>
                        <th
                          scope="row"
                          style={{ width: "400px" }}
                          className={"text-capitalize"}
                        >
                          Amount
                        </th>
                        <td className='w-50'>{data.amount} {getCurrency(data.currencyId)}</td>
                      </tr>

                      <tr>
                        <th
                          scope="row"
                          style={{ width: "400px" }}
                          className={"text-capitalize"}
                        >
                          Commission
                        </th>
                        <td className='w-50'>{data.commission} {getCurrency(data.currencyId)}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
                <Col md={6}>
                  <Table bordered striped responsive className='mb-0'>
                    <tbody>
                      <tr>
                        <th
                          scope="row"
                          style={{ width: "400px" }}
                          className={"text-capitalize"}
                        >
                          Status
                        </th>
                        <td className='w-50'>
                          <span className={`badge badge-soft-${MainService.getStatusColor(data.statusName)} font-size-14 badge badge-pill`}>{data.statusName}</span>
                        </td>
                      </tr>



                      <tr>
                        <th
                          scope="row"
                          style={{ width: "400px" }}
                          className={"text-capitalize"}
                        >
                          Create Time
                        </th>
                        <td className='w-50'>{
                          data.createTime ? moment(MainService.convertUTCDateToLocalDate(new Date(data.createTime))).format("LL H:mm") : null
                        }</td>
                      </tr>
                      <tr>
                        <th
                          scope="row"
                          style={{ width: "400px" }}
                          className={"text-capitalize"}
                        >
                          Decline Message
                        </th>
                        <td className='w-50'>{data.declineMessage}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </CardBody>
          </Card>
      }
      {
        data.organizationBankAccount ?
          <div>
            <p className='h4 mt-3 mb-3'>Bank account information</p>
            <Card>
              <CardBody>
                <BankAccountInformation
                  bankAccount={data.organizationBankAccount}
                  isShowStatus={false}
                  currencies={currencies}
                />
              </CardBody>
            </Card>
          </div>

          : null
      }
    </Auxiliary>
    : <Card>
      <CardBody>
        <NoData />
        <p className='text-center'>
          No Data
        </p>
      </CardBody>
    </Card>
}
