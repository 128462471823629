import React, { useState, useEffect } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import ApiService from '../../../Services/ApiService'
import { useCallback } from 'react'
import { addButtonSpinner, removeButtonSpinner, removeRightSectionSpinner, addRightSectionSpinner } from '../../../store/actions'
import { ERROR_KEY, INACTIVE_ACCOUNT_INFO_MSG, MERCHANT_USER_TYPE_ID, SUCCESS_KEY } from '../../../Constants/MainKeys'
import AlertService from '../../../Services/alertService'
import uuid from 'react-uuid'
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  UncontrolledAlert,
} from "reactstrap"
import { Table } from "react-bootstrap"
import HtmlHead from '../../../components/HtmlHead/HtmlHead'
import RightSectionSpinner from '../../../components/Spinners/RightSectionSpinner'
import ActionButton from '../../../components/Buttons/ActionButton'
import NoData from '../../../components/NoData/NoData'
import ReactPaginate from 'react-paginate'
import ReactSelectOption from '../../../components/SelectOptions/ReactSelectOption'
import MainService from '../../../Services/MainService'

const buttonSpinnerId = uuid();

const Users = (props) => {

  const title = "Users"
  const description = "";
  const pageSize = 10;
  const dispatch = useDispatch();
  const { rightSectionSpinners } = useSelector(state => state.Spinners);
  const { user } = useSelector(state => state.Login);
  const { language } = useSelector(state => state.Languages);
  const [users, setUsers] = useState([]);
  const [activePageNumber, setActivePageNumber] = useState(0);
  const [pagination, setPagination] = useState(null);
  const [type, setType] = useState(0);
  const [types, setTypes] = useState([]);
  const [isShowAlert, setIsShowAlert] = useState(false);

  useEffect(() => {
    getUsers(1, type, setIsShowAlert);
  }, [])

  const handlePageClick = (event) => {
    getUsers(event.selected + 1, type)
    setActivePageNumber(event.selected);
  };

  const getUsers = (pageNumber, type, cb) => {
    const spinnerId = uuid();
    setRightSectionSpinner(spinnerId);
    (user.userTypeId === MERCHANT_USER_TYPE_ID ?
      ApiService.getUsersAsMerchant(user.organizationId, type, pageNumber, pageSize) :
      ApiService.getUsersAsAgent(user.activeAgentId, type, pageNumber, pageSize))
      .then(response => {
        if (response && response.pagination) setPagination(JSON.parse(response.pagination));
        if (response && response.data) {
          if (response.data.filter && response.data.filter.types) {
            let _types = [...response.data.filter.types];
            _types.unshift({ key: 0, value: "All" });
            setTypes(_types);
          };
          if (response.data.significantControllers && response.data.significantControllers.length) {
            setUsers(response.data.significantControllers);
            cb && cb(true);
          } else {
            setUsers();
          }
        }
      }).catch(error => getFail(error)).finally(() => {
        extractRightSectionSpinner(spinnerId)
      })
  }

  const onSelectOptionsChange = (item, cb) => {
    if (!item) { return false; }
    cb(item.value);
    getUsers(1, item.value)
  }

  const deleteSignificantController = (itemId, spinnerId) => {
    AlertService.alertConfirm(
      `Are you sure ?`,
      "",
      "Yes",
      "No"
    ).then(() => {
      setButtonSpinner(spinnerId);
      (user.userTypeId === MERCHANT_USER_TYPE_ID ?
        ApiService.deleteSignificantControllerAsMerchant(itemId) :
        ApiService.deleteSignificantControllerAsAgent(itemId)).then(() => {
          AlertService.alert(SUCCESS_KEY, "User deleted successfully");
          setUsers(users.filter(item => item.id !== itemId))
        }).catch(error => getFail(error)).finally(() => {
          extractButtonSpinner(spinnerId)
        })
    })
  }

  const setRightSectionSpinner = useCallback(spinner => {
    dispatch(addRightSectionSpinner(spinner));
  }, []);

  const extractRightSectionSpinner = useCallback(spinner => {
    dispatch(removeRightSectionSpinner(spinner));
  }, []);

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const getFail = (error) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
  }

  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className='page-content position-relative min-h-100vh'>
        <RightSectionSpinner spinner={rightSectionSpinners} />
        <div className="container-fluid">
          {
            isShowAlert ?
              <UncontrolledAlert color="info" role="alert">
                All Directors and Ultimate Beneficial Owners i.e. equity holding greater than 20%
              </UncontrolledAlert>
              : null
          }
          <Breadcrumbs
            title="Cryllex"
            breadcrumbItem="Significant controllers"
          />
          <div>
            <Card>
              <CardBody>
                <div className='mb-3 d-sm-flex align-items-end justify-content-between'>
                  {
                    user.isActive ?
                      <div style={{ minWidth: "200px" }}>
                        <Label>Choose type</Label>
                        <ReactSelectOption
                          value={type}
                          selectedValue={(() => {
                            const selectedValue = { ...types.find(data => data.key === type) };
                            if (Object.keys(selectedValue).length) {
                              selectedValue.label = selectedValue.value;
                              selectedValue.value = selectedValue.key;
                              return selectedValue;
                            } else {
                              return { label: "Choose type", value: null }
                            }
                          })()}
                          items={types.map(data => ({ label: data.value, value: data.key }))}
                          onChange={(item) => onSelectOptionsChange(item, setType)}
                        />
                      </div>
                      : null
                  }
                  {
                    users && users.length ?
                      <div className='d-flex justify-content-end mt-2 mt-md-0'>
                        <Button
                          type="button"
                          color="primary"
                          outline
                          className='shadow-sm'
                          onClick={() => props.history.push(`/${language}/significant-controllers-form`)}
                        >
                          + &nbsp; Create significant controller
                        </Button>
                      </div>
                      : null
                  }
                </div>
                <div>
                  {
                    users && users.length ?
                      <div>
                        <Table striped bordered hover responsive>
                          <thead>
                            <tr className='cursor-default'>
                              <th className="text-nowrap">Full name</th>
                              <th className="text-nowrap">Email</th>
                              <th className="text-nowrap">Phone Number</th>
                              <th className="text-nowrap">Type</th>
                              <th className="text-nowrap">Percentage</th>
                              <th className="text-nowrap text-center">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              users.map((item, index) => {
                                return <tr key={index} className='cursor-default'>
                                  <td className='fw-500'>{item.fullName}</td>
                                  <td className='fw-500'>{item.email}</td>
                                  <td className='fw-500 text-nowrap'>{MainService.formatPhoneNumber(item.phoneNumber)}</td>
                                  <td className='fw-500'>{item.typeName}</td>
                                  <td className='fw-500'>{item.percentage}%</td>
                                  <td>
                                    <div className='d-flex justify-content-center'>
                                      <Button
                                        type="button"
                                        color="primary"
                                        className='shadow-sm me-1'
                                        onClick={() => { props.history.push(`/${language}/significant-controllers-form/${item.id}`) }}
                                      >
                                        Update
                                      </Button>
                                      <ActionButton
                                        type="button"
                                        name="Delete"
                                        spinnerStyle={{ maxWidth: "35px" }}
                                        className="shadow-sm btn btn-danger"
                                        spinnerId={`${buttonSpinnerId}_user_${index}`}
                                        onClick={() => deleteSignificantController(item.id, `${buttonSpinnerId}_user_${index}`)}
                                      />
                                    </div>
                                  </td>
                                </tr>
                              })
                            }
                          </tbody>
                        </Table>
                        {
                          pagination ?
                            <div className='py-3 d-flex justify-content-end'>
                              <ReactPaginate
                                nextLabel="Next"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={3}
                                marginPagesDisplayed={2}
                                pageCount={pagination.TotalPages}
                                previousLabel="Previous"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                containerClassName="pagination"
                                activeClassName="active"
                                renderOnZeroPageCount={null}
                                forcePage={activePageNumber}
                              />
                            </div>
                            : null
                        }

                      </div>
                      :
                      <div>
                        <NoData />
                        {
                          user.isActive ?
                            <div>
                              {/* <p className="my-3 text-center">You don't have created significant controllers yet.</p> */}
                              <div className='d-flex justify-content-center'>
                                <Button
                                  type="button"
                                  color="primary"
                                  className='shadow-sm mb-3'
                                  onClick={() => props.history.push(`/${language}/significant-controllers-form`)}
                                >
                                  Create significant controller
                                </Button>
                              </div>
                            </div>
                            : <div>
                              <p className="my-3 text-center">{INACTIVE_ACCOUNT_INFO_MSG}</p>
                            </div>
                        }
                      </div>
                  }
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </>
  )
}

export default withRouter(Users)