import { useState, useEffect } from 'react';

function useElementSize(className) {
  const [size, setSize] = useState({ width: 0, height: 0 });
  
  useEffect(() => {
    const element = document.querySelector(`.${className}`);

    const updateSize = () => {
      if (element) {
        const rect = element.getBoundingClientRect();
        setSize({ width: rect.width, height: rect.height });
      }
    };

    // Initial size update
    updateSize();

    // Update size whenever the window is resized
    window.addEventListener('resize', updateSize);
    window.addEventListener('click', updateSize);

    // Clean up event listener when component unmounts
    return () => {
      window.removeEventListener('resize', updateSize);
      window.removeEventListener('click', updateSize);
    };
  }, [className]);

  return size;
}

export default useElementSize;
