import React from 'react'
import PhoneInput from 'react-phone-input-2';
import {
  Col,
  Row,
  Label,
  Input,
} from "reactstrap"
import MainService from '../../../../Services/MainService';
import { allValidFileTypes } from '../../../../Constants/MainKeys';
import FileComponent from '../../Merchant/Components/FileComponent';
import Switch from "react-switch"
import OnIcon from '../../../../components/OnIcon/OnIcon';
import OffIcon from '../../../../components/OffIcon/OffIcon';
import ApiService from '../../../../Services/ApiService';

export default function LevelOneRequirementsForm(props) {

  const { data, onChange, onSwitcherChange, uploadFile, onPhoneNumberChange, setZoomImagePath, deleteDocument, isDisabledFields, confirmProof, confirmLoaderId } = props;
  return (
    <Col sm={12}>
      <Row>
        <Col md={6}>
          <label htmlFor='phoneNumber' className='mt-3'>Telephone Number:</label>
          <PhoneInput
            country={""}
            specialLabel=""
            value={data.levelOne.phoneNumber || ""}
            className={`custom-phone-number-input-block`}
            onChange={event => onPhoneNumberChange(event, "levelOne", "phoneNumber")}
          />
        </Col>
        <Col md={6}>
          <label htmlFor='addressRegistration' className='mt-3'>Primary contact Address:</label>
          <Input
            id='addressRegistration'
            type="text"
            value={data.levelOne.addressRegistration || ""}
            name="addressRegistration"
            placeholder='Ex. 1 Smith Street, Springfield, IL, 12345'
            className={`form-control`}
            autoComplete='new-password'
            onChange={(event) => onChange(event, "levelOne", "addressRegistration")}
          />
        </Col>
        <Col md={6}>
          <Label htmlFor='countryId' className='mt-3'>
            Liveness Test Status
          </Label>
          <div className='d-flex align-items-center mt-1'>
            <Switch
              className="react-switch mt-1"
              size={1}
              checkedIcon={<OnIcon />}
              uncheckedIcon={<OffIcon />}
              checked={data.levelOne.livenessTestStatus || false}
              aria-labelledby="neat-label"
              disabled={isDisabledFields}
              onChange={() => onSwitcherChange(!data.levelOne.livenessTestStatus, "levelOne", "livenessTestStatus")}
            />
          </div>
        </Col>
        <Col sm={12}>

          <Row className='mt-3'>
            <Col md={3} sm={6} className='mb-3'>
              <FileComponent
                data={data?.levelOne}
                fieldName="userPassportProofFile"
                labelValue="Primary contact passport/national ID proof"
                confirmLoaderId={confirmLoaderId}
                setZoomImagePath={() => setZoomImagePath(data.levelOne.userPassportProofFile.filePath)}
                uploadFile={(item) =>
                  uploadFile({
                    ...item,
                    obj: "levelOne",
                    checkFiltType: MainService.getUserFileType,
                    method: ApiService.uploadLevelOneDocumentAsAdmin,
                    id: data.levelOne.primaryUserId
                  })}
                deleteDocument={(item) =>
                  deleteDocument({
                    ...item,
                    getFileType: MainService.getUserFileType,
                    method: ApiService.deleteLevelOneDocumentAsAdmin,
                    fileObj: "levelOne",
                    id: data.levelOne.primaryUserId
                  })}
                confirmProof={(item) =>
                  confirmProof({
                    ...item,
                    fileObj: "levelOne",
                    fieldName: "userAddressProofFile",
                  })}

              />
            </Col>
            <Col md={3} sm={6} className='mb-3'>
              <FileComponent
                data={data?.levelOne}
                fieldName="userAddressProofFile"
                labelValue="Primary contact address proof"
                confirmLoaderId={confirmLoaderId}
                setZoomImagePath={() => setZoomImagePath(data.levelOne.userAddressProofFile.filePath)}
                uploadFile={(item) =>
                  uploadFile({
                    ...item,
                    obj: "levelOne",
                    checkFiltType: MainService.getUserFileType,
                    method: ApiService.uploadLevelOneDocumentAsAdmin,
                    id: data.levelOne.primaryUserId
                  })}
                deleteDocument={(item) =>
                  deleteDocument({
                    ...item,
                    getFileType: MainService.getUserFileType,
                    method: ApiService.deleteLevelOneDocumentAsAdmin,
                    fileObj: "levelOne",
                    id: data.levelOne.primaryUserId
                  })
                }
                confirmProof={(item) =>
                  confirmProof({
                    ...item,
                    fileObj: "levelOne",
                    fieldName: "userAddressProofFile",
                  })}
              />
            </Col>
            <Col sm={12}><small className='fw-500'>Allowed file types {allValidFileTypes.map((item, index) => <i key={index}>{item}. </i>)}</small></Col>
          </Row>
        </Col>
      </Row>
    </Col>
  )
}
