import React, { useCallback, useRef, useState } from 'react'
import HtmlHead from '../../../components/HtmlHead/HtmlHead'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import {
  Card,
  CardBody,
  Row,
  Label,
  Input,
  Col,
  UncontrolledAlert
} from "reactstrap"
import { Button } from "react-bootstrap"
import AlertService from '../../../Services/alertService'
import MainService from '../../../Services/MainService'
import { useSelector } from 'react-redux'
import uuid from 'react-uuid'
import RightSectionSpinner from '../../../components/Spinners/RightSectionSpinner'
import { useDispatch } from 'react-redux'
import { ALL_VALID_FILE_TYPES, ERROR_KEY, SPINNER_COLOR, SUCCESS_KEY, VALID_FILE_TYPES_KEY, VALID_IMAGE_TYPES_KEY, allValidFileTypes } from '../../../Constants/MainKeys'
import { fielsLengths } from '../../../Constants/MainKeys';
import ActionButton from '../../../components/Buttons/ActionButton';
import ApiService from '../../../Services/ApiService'
import { addButtonSpinner, addRightSectionSpinner, removeButtonSpinner, removeRightSectionSpinner } from '../../../store/spinner/actions'
import { useEffect } from 'react'
import PhoneInput from 'react-phone-input-2'
import ReactSelectOption from '../../../components/SelectOptions/ReactSelectOption'
import NoData from '../../../components/NoData/NoData'
import { PuffLoader } from 'react-spinners'
import Auxiliary from '../../../hoc/auxiliary/auxiliary'

const spinnerId = uuid();
const buttonSpinnerId = uuid();

function useOutsideAlerter(ref, cb) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        cb(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

export default function BusinessKYC(props) {

  let entityFormNumber = 1;
  const title = "Business Representative";
  const description = "";
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);
  const { user } = useSelector(state => state.Login);
  const { rightSectionSpinners } = useSelector(state => state.Spinners);
  const mainService = new MainService();

  const [id, setId] = useState(null);
  const [isChanged, setIsChanged] = useState(false);
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
  const [values, setValues] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [zoomImagePath, setZoomImagePath] = useState(null)

  useOutsideAlerter(wrapperRef, setZoomImagePath);

  useEffect(() => {
    getOrganizationKYC();
  }, []);

  useEffect(() => {
    if (values && values.length) {
      values.forEach(item => {
        if ((!item.isNullable && !item.toString().trim().length) || item.isValid === false) {
          setIsInvalidSubmit(true)
        }
      })
    }
  }, [values]);


  const getOrganizationKYC = () => {
    setRightSectionSpinner(spinnerId);
    const data = {
      organizationId: user.organizationId,
      entityId: user.agentId,
      entityFormNumber,
    };
    ApiService.getOrganizationKYC(data)
      .then(response => {
        if (response && response.data) {
          setId(response.data.id)
          if (response.data.responseData) {
            setValues(response.data.responseData);
          }
        }
      })
      .catch(error => getFail(error))
      .finally(() => {
        extractRightSectionSpinner(spinnerId);
      });
  };

  const onChange = (index, key, value, maxLength = Infinity) => {
    if (maxLength && maxLength < value.length) { return; }
    const updatedFormFields = [...values];
    updatedFormFields[index] = { ...updatedFormFields[index], [key]: value };
    if (key === 'value' && !updatedFormFields[index].isNullable) {
      const regEx = updatedFormFields[index].regEx;
      const isValid = new RegExp(regEx).test(value);
      updatedFormFields[index] = { ...updatedFormFields[index], isValid };
    }
    setValues(updatedFormFields);
    setIsInvalidSubmit(false);
  };

  const onNumberChange = (index, key, value, minLength = 0, maxLength = Infinity) => {
    const updatedFormFields = [...values];
    if (value.includes("e") || value.includes(".") || value.includes("-") || value === "0") {
      updatedFormFields[index] = { ...updatedFormFields[index], [key]: "" };
      setValues(updatedFormFields);
    };
    if (key === 'value' && !updatedFormFields[index].isNullable) {
      const regEx = updatedFormFields[index].regEx;
      const isValid = new RegExp(regEx).test(value);
      updatedFormFields[index] = { ...updatedFormFields[index], isValid };
    }
    if (value === '' || (typeof +value === "number" && Number(value) >= minLength && Number(value) <= maxLength)) {
      updatedFormFields[index] = { ...updatedFormFields[index], [key]: +value };
      setValues(updatedFormFields);
      return false;
    }
  };

  const onPhoneNumberChange = (index, key, value) => {
    const updatedFormFields = [...values];
    updatedFormFields[index] = { ...updatedFormFields[index], [key]: value };
    setValues(updatedFormFields);
    setIsChanged(true);
    setIsInvalidSubmit(false);
  }

  const onSelectOptionChange = (index, key, value) => {
    const updatedFormFields = [...values];
    updatedFormFields[index] = { ...updatedFormFields[index], [key]: value };
    setValues(updatedFormFields);
    setIsChanged(true);
    setIsInvalidSubmit(false)
  }

  const uploadFile = (event, item, index) => {
    if (!event.target.files[0] || !item) { return; }
    const file = event.target.files[0];
    if (file) {
      mainService.readFile(file, allValidFileTypes).then(() => {
        const formData = new FormData();
        formData.append("id", id);
        formData.append("jsonPropertyName", item.jsonPropertyName);
        formData.append("document", file);
        formData.append("organizationId", user.organizationId);
        formData.append("entityId", user.agentId);
        formData.append("entityFormNumber", entityFormNumber);
        setIsLoading(true);
        ApiService.uploadOrganizationKYCFile(formData).then(response => {
          if (response && response.data) {
            const data = { ...response.data };
            let fileResponse = {
              fileLibraryId: data.fileLibraryId,
              filePath: data.filePath,
            }
            const updatedFormFields = [...values];
            updatedFormFields[index].value = data.fileLibraryId.toString();
            updatedFormFields[index] = { ...updatedFormFields[index], file: fileResponse };
            setValues(updatedFormFields);
          }
        }).catch(error => getFail(error)).finally(() => {
          setIsLoading(false);
        })

      }).catch(error => error && AlertService.alert("error", "Invalid file type"));
    };
  }

  const deleteDocument = (item, index) => {
    if (!id || !item) { return false; }
    AlertService.alertConfirm(
      `Are you sure you want to delete current file ?`,
      "",
      "Yes",
      "No"
    ).then(() => {
      setIsLoading(true);
      let data = {
        id,
        organizationId: user.organizationId,
        entityId: user.agentId,
        entityFormNumber,
        fileLibraryId: item.file?.fileLibraryId,
        jsonPropertyName: item.jsonPropertyName
      }
      ApiService.deleteOrganizationKYCFile(data).then(() => {
        const updatedFormFields = [...values];
        updatedFormFields[index].value = null;
        updatedFormFields[index] = { ...updatedFormFields[index], file: null };
        setValues(updatedFormFields);
        AlertService.alert(SUCCESS_KEY, "File deleted successfully");
      }).catch(error => getFail(error)).finally(() => {
        setIsLoading(false);
      })
    })
  }

  const onSubmit = event => {
    event.preventDefault();
    if (isInvalidSubmit) { return false };
    let formFields = {};
    let data = {};
    formFields.organizationId = user.organizationId;
    formFields.entityId = user.agentId;
    formFields.entityFormNumber = entityFormNumber;
    values.forEach(item => {
      data[item.fieldName] = item.value;
    })
    formFields.data = data;
    setButtonSpinner(buttonSpinnerId);
    ApiService.createOrganizationKYC(formFields).then(response => {
      if (response && response.data) {
        setId(response.data.id)
        if (response.data.responseData) {
          setValues(response.data.responseData);
        }
      }
      AlertService.alert(SUCCESS_KEY, "Data saved");
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }).catch(error => getFail(error)).finally(() => {
      extractButtonSpinner(buttonSpinnerId);
      setIsChanged(false)
    })
  };

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const setRightSectionSpinner = useCallback(spinner => {
    dispatch(addRightSectionSpinner(spinner));
  }, []);

  const extractRightSectionSpinner = useCallback(spinner => {
    dispatch(removeRightSectionSpinner(spinner));
  }, []);

  const getFail = error => {
    error && AlertService.alert(AlertService.checkMessageType(error.respcode) || ERROR_KEY, error);
  };

  return (
    <Auxiliary>
      <div className={`zoom-image-modal ${!zoomImagePath ? "d-none" : ""}`}>
        <img
          src={zoomImagePath ? zoomImagePath : ""}
          ref={wrapperRef}
        />
        <i className='bx bx-x close-icon'></i>
      </div>
      <section className="page-content position-relative min-h-100">
        <HtmlHead title={title} description={description} />
        <RightSectionSpinner spinner={rightSectionSpinners} />
        <div className="container-fluid">
          <form onSubmit={onSubmit} onChange={() => setIsChanged(true)}>
            <div>
              <div className="container-fluid">
                <Breadcrumbs title="Cryllex" breadcrumbItem="Business KYC" />
                <Row className="justify-content-center">
                  <Card>
                    <CardBody>
                      {
                        values && values.length ?
                          <Col sm={12}>
                            <Row>
                              {values.map((item, index) => {
                                if (item.inputType === "number" || item.inputType === "text") {
                                  return <Col key={index} lg={4} md={6} className="mb-4">
                                    <Label htmlFor={item.fieldDisplayName} className="text-muted text-truncate mb-0">
                                      {item.fieldDisplayName}{!item.isNullable ? "*" : ""}
                                    </Label>
                                    <Input
                                      id={item.fieldDisplayName}
                                      type={item.inputType}
                                      className={`form-control ${item.isValid === false || (!item.isNullable && !item.value.trim().length) ? "error-border" : ""}`}
                                      value={item.value || ""}
                                      onChange={event => {
                                        if (item.type === "number") {
                                          onNumberChange(index, "value", event.target.value, item.min, item.max)
                                        } else {
                                          onChange(index, "value", event.target.value, item.max)
                                        }
                                      }}
                                    />
                                  </Col>
                                } else if (item.inputType === "tel") {
                                  return <Col key={index} lg={4} md={6} className="mb-4">
                                    <Label htmlFor={item.fieldDisplayName} className="text-muted text-truncate mb-0">
                                      {item.fieldDisplayName}{!item.isNullable ? "*" : ""}
                                    </Label>
                                    <PhoneInput
                                      country={""}
                                      specialLabel=""
                                      value={item.phoneNumber || ""}
                                      className={`custom-phone-number-input-block ${(!item.isNullable && !item.value.trim().length) ? "error-border" : ""}`}
                                      onChange={event => onPhoneNumberChange(index, "value", event)}
                                    />
                                  </Col>
                                } else if (item.inputType === "dropdown" && item.libraryName) {
                                  return <Col key={index} lg={4} md={6} className="mb-4">
                                    <Label htmlFor={item.fieldDisplayName} className="text-muted text-truncate mb-0">
                                      {item.fieldDisplayName}{!item.isNullable ? "*" : ""}
                                    </Label>
                                    {
                                      item.dropdownLibrary && item.dropdownLibrary.libraryData && item.dropdownLibrary.libraryData.length ?
                                        <ReactSelectOption
                                          value={item.value}
                                          isSearchable={true}
                                          className={`${(!item.isNullable && !item.value.trim().length) ? "error-border" : ""}`}
                                          selectedValue={(() => {
                                            const selectedValue = { ...item.dropdownLibrary.libraryData.find(data => data.displayName == item.value) };
                                            if (Object.keys(selectedValue).length) {
                                              selectedValue.label = selectedValue.displayName;
                                              selectedValue.value = selectedValue.id;
                                              return selectedValue;
                                            } else {
                                              return { label: "Choose...", value: "" }
                                            }
                                          })()}
                                          items={item.dropdownLibrary.libraryData.map(data => ({ label: data.displayName, value: data.id }))}
                                          onChange={(item) => onSelectOptionChange(index, "value", item.label)}
                                        />
                                        : null
                                    }
                                  </Col>
                                } else if (item.inputType === "dropdown" && !item.libraryName) {
                                  return <Col key={index} lg={4} md={6} className="mb-4">
                                    <Label htmlFor={item.fieldDisplayName} className="text-muted text-truncate mb-0">
                                      {item.fieldDisplayName}{!item.isNullable ? "*" : ""}
                                    </Label>
                                    {
                                      item.countries && item.countries.length ?
                                        <ReactSelectOption
                                          value={item.value}
                                          isSearchable={true}
                                          className={`${(!item.isNullable && !item.value.trim().length) ? "error-border" : ""}`}
                                          selectedValue={(() => {
                                            const selectedValue = { ...item.countries.find(data => data.isocode2 == item.value) };
                                            if (Object.keys(selectedValue).length) {
                                              selectedValue.label = selectedValue.name;
                                              selectedValue.value = selectedValue.isocode2;
                                              return selectedValue;
                                            } else {
                                              return { label: "Choose...", value: "" }
                                            }
                                          })()}
                                          items={item.countries.map(data => ({ label: data.name, value: data.isocode2 }))}
                                          onChange={(item) => onSelectOptionChange(index, "value", item.value)}
                                        />
                                        : null
                                    }
                                  </Col>
                                }
                              })}
                            </Row>
                            <Row>
                              {
                                isLoading ?
                                  <Auxiliary>
                                    <div className='d-flex justify-content-center align-items-center' style={{ minHeight: "150px", }}>
                                      <PuffLoader size={50} color={SPINNER_COLOR} />
                                    </div>
                                    <hr />
                                  </Auxiliary>
                                  : values.map((item, index) => {
                                    if (item.inputType === "file" && id) {
                                      return <Col key={index} md={3} sm={6} className='mb-3'>
                                        <div className='border px-3 py-3 rounded h-100 d-flex flex-column justify-content-between align-items-center'>
                                          <Label className='mb-2 text-center d-block word-break-break-word'>{item.fieldDisplayName}</Label>
                                          {
                                            item.file ?
                                              <div className='position-relative border rounded' style={{ maxWidth: "150px", maxHeight: "150px", minHeight: "100px", minWidth: "100px" }}>
                                                {
                                                  (() => {
                                                    switch (MainService.getFxtensions(item.file.filePath)) {
                                                      case "image":
                                                        return <div
                                                          style={{ width: "100%", height: "100%", }}
                                                          className='d-flex justify-content-center align-items-center flex-column cursor-pointer'
                                                          onClick={() => setZoomImagePath(item.file.filePath)}
                                                        >
                                                          <img
                                                            src={item.file.filePath}
                                                            className='rounded'
                                                            style={{ width: "100%", height: "100%" }}
                                                            alt='/'
                                                          />
                                                        </div>
                                                      case "file":
                                                        return <div
                                                          style={{ width: "100%", height: "100%", }}
                                                          className='p-2  d-flex justify-content-center align-items-center flex-column cursor-pointer'
                                                          onClick={() => window.open(item.file.filePath, "_blank")}
                                                        >
                                                          <i className='bx bx-file-blank' style={{ fontSize: "50px" }} ></i>
                                                        </div>
                                                      default:
                                                        break;
                                                    }
                                                  })()
                                                }
                                                <i
                                                  className='bx bx-x position-absolute font-size-20 text-danger bg-white rounded cursor-pointer border'
                                                  style={{ top: "1px", right: "1px" }}
                                                  onClick={() => {
                                                    deleteDocument(item, index);
                                                  }}
                                                />
                                              </div>
                                              : <div className='d-flex flex-column align-items-center'>
                                                <Button
                                                  onClick={MainService.triggerUploadClick}
                                                  className='mt-1'
                                                  color="primary"
                                                  type='button'
                                                >
                                                  <input type="file" id='avatarImage' className="d-none" hidden onChange={(event) => uploadFile(event, item, index)} />
                                                  Upload file
                                                </Button>
                                              </div>
                                          }
                                        </div>
                                      </Col>
                                    }
                                  })
                              }
                              {
                                values.find(item => item.inputType === "file") ?
                                  <Col sm={12}>
                                    <small className='fw-500 d-block mb-2'>Allowed file types {allValidFileTypes.map((item, index) => <i key={index}>{item}. </i>)}</small>
                                    <UncontrolledAlert color="warning" role="alert">
                                      <i className="mdi mdi-alert-outline me-2"></i>
                                      All documents require Certified true copies, alternatively, the documents can be visually presented through Cryllex customer support
                                    </UncontrolledAlert>
                                  </Col>
                                  : null
                              }
                            </Row>
                          </Col>
                          : <NoData />
                      }

                      <div className="d-flex justify-content-end">
                        <ActionButton
                          type="submit"
                          name="Save"
                          className="btn btn-primary btn-block px-4"
                          spinnerId={buttonSpinnerId}
                          disabled={!isChanged || isInvalidSubmit || isLoading ? true : false}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Row>
              </div>
            </div>
          </form>
        </div>
      </section>
    </Auxiliary>
  );
}

