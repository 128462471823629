import React from "react";
import PuffLoader from "react-spinners/PuffLoader";
import { SPINNER_COLOR } from "../../Constants/MainKeys";

function PageSpinner(props) {

  return (
    props.spinner && props.spinner.length ?
      <div className="spinner-layer" >
        <div className="sweet-loading">
          <PuffLoader size={80} color={SPINNER_COLOR}/>
        </div>
      </div>
      : null
  );
}

export default PageSpinner;