import React from 'react'
import { Button, Label, UncontrolledAlert } from 'reactstrap';
import MainService from '../../../../Services/MainService';

export default function FileComponent(props) {

  const {
    data,
    fieldName,
    labelValue,
    deleteDocument,
    setZoomImagePath,
    uploadFile,
    alertMessage = null,
    alertIcon = null,
    alertType = null,
    isShowDeleteIcon = true,
    multiple = false,
    confirmProof = () => { },
    confirmLoaderId,
  } = props;

  return (
    <div className='border px-3 py-3 rounded h-100 d-flex flex-column justify-content-between align-items-center'>
      <Label className='mb-2 text-center d-block'>{labelValue}</Label>
      {
        data[fieldName] ?
          <div className='position-relative border rounded' style={{ maxWidth: "150px", maxHeight: "150px", minHeight: "100px", minWidth: "100px" }}>
            {
              (() => {
                switch (MainService.getFxtensions(data[fieldName].filePath)) {
                  case "image":
                    return <div
                      style={{ width: "100%", height: "100%", }}
                      className='d-flex justify-content-center align-items-center flex-column cursor-pointer'
                      onClick={() => setZoomImagePath(data[fieldName].filePath)}
                    >
                      <img
                        src={data[fieldName].filePath}
                        className='rounded'
                        style={{ width: "100%", height: "100%" }}
                        alt='/'
                      />
                    </div>
                  case "file":
                    return <div
                      style={{ width: "100%", height: "100%", }}
                      className='p-2  d-flex justify-content-center align-items-center flex-column cursor-pointer'
                      onClick={() => window.open(data[fieldName].filePath, "_blank")}
                    >
                      <i className='bx bx-file-blank' style={{ fontSize: "50px" }} ></i>
                    </div>
                  default:
                    break;
                }
              })()
            }
            {
              isShowDeleteIcon ?
                confirmLoaderId === data[fieldName]?.fileLibraryId ? null :
                  <i
                    className='bx bx-x position-absolute font-size-20 text-danger bg-white rounded cursor-pointer border'
                    style={{ top: "1px", right: "1px" }}
                    onClick={() => {
                      deleteDocument({ fieldName, fileLibraryId: data[fieldName].fileLibraryId });
                    }}
                  />
                : null
            }
            {
              typeof data[fieldName].isActive === "boolean" ?
                data[fieldName]?.isActive ?
                  <Button
                    onClick={() => { }}
                    style={{
                      position: "absolute",
                      bottom: "5px",
                      width: "90%",
                      left: "50%",
                      transform: "translateX(-50%)",
                      cursor: "default",
                      pointerEvents: "none",
                    }}
                    size='sm'
                    color="success"
                    type='button'
                  >
                    Confirmed
                  </Button>
                  : <Button
                    onClick={() => {
                      if (confirmLoaderId === data[fieldName]?.fileLibraryId) {
                        return false;
                      }
                      confirmProof();
                    }}
                    style={{
                      position: "absolute",
                      bottom: "5px",
                      width: "90%",
                      left: "50%",
                      transform: "translateX(-50%)"
                    }}
                    size='sm'
                    color="primary"
                    type='button'
                  >
                    {
                      confirmLoaderId === data[fieldName]?.fileLibraryId ?
                        <i className="bx bx-loader bx-spin font-size-16 align-middle ms-2"></i>
                        : "Confirm"
                    }
                  </Button>
                : null
            }
          </div>
          : <div className='d-flex flex-column align-items-center'>
            <Button
              onClick={MainService.triggerUploadClick}
              outline
              className='mt-1'
              color="primary"
              type='button'
            >
              <input type="file" id='avatarImage' className="d-none" hidden multiple={multiple} onChange={(event) => uploadFile({ event, fieldName })} />
              Upload file
            </Button>
          </div>
      }

      {
        alertMessage && alertIcon && alertType ?
          <UncontrolledAlert color={alertType} role="alert" className='mt-3 w-100 mb-0'>
            <i class={`${alertIcon} mdi me-2`}></i>
            {alertMessage}
          </UncontrolledAlert> : null
      }
    </div>
  )
}
