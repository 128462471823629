import React from 'react'
import {
  Button,
  Col,
  Row,
  Table
} from "reactstrap"
import MainService from '../../../../Services/MainService';
import FilesComponent from '../../Merchant/Components/FilesComponent';

export default function LevelTwoRequirements(props) {

  const { data, setZoomImagePath } = props;

  const viewProof = (path) => {
    if (!path) { return false; }
    if (MainService.getFxtensions(path) === "image") {
      setZoomImagePath(path)
    } else {
      window.open(path, "_blank")
    }
  }

  return (
    <Col sm={12}>
      <Row>
        <Col sm={6}>
          <Table bordered striped responsive className='mb-0'>
            <tbody>
              <tr>
                <th
                  scope="row"
                  style={{ width: "400px" }}
                  className={"text-capitalize"}
                >
                  Business Registration Number
                </th>
                <td className='w-50'>{data.levelTwo.businessRegistrationNumber}</td>
              </tr>
              <tr>
                <th
                  scope="row"
                  style={{ width: "400px" }}
                  className={"text-capitalize"}
                >
                  Business Registration Address
                </th>
                <td className='w-50'>{data.levelTwo.businessRegisteredAddress}</td>
              </tr>
              <tr>
                <th
                  scope="row"
                  style={{ width: "400px" }}
                  className={"text-capitalize"}
                >
                  Business registration certificate proof
                </th>
                <td className='w-50'>
                  {
                    !data.levelTwo.businessRegistrationCertificateFile ? "No attached file yet" :
                      <Button
                        color="primary"
                        size="sm"
                        onClick={() => {
                          viewProof(data.levelTwo.businessRegistrationCertificateFile.filePath)
                        }}
                      >
                        View Proof
                      </Button>
                  }
                </td>
              </tr>
              <tr>
                <th
                  scope="row"
                  style={{ width: "400px" }}
                  className={"text-capitalize"}
                >
                  Business Registration Address Proof
                </th>
                <td className='w-50'>
                  {
                    !data.levelTwo.businessRegisteredAddressFile ? "No attached file yet"
                      : <Button
                        color="primary"
                        size="sm"
                        onClick={() => {
                          viewProof(data.levelTwo.businessRegisteredAddressFile.filePath)
                        }}
                      >
                        View Proof
                      </Button>
                  }
                </td>
              </tr>
              <tr>
                <th
                  scope="row"
                  style={{ width: "400px" }}
                  className={"text-capitalize"}
                >
                  Other Proof Of Business
                </th>
                <td className='w-50'>
                  {
                    !data.levelTwo.otherProofFile ? "No attached file yet" :
                      <Button
                        color="primary"
                        size="sm"
                        onClick={() => {
                          viewProof(data.levelTwo.otherProofFile.filePath)
                        }}
                      >
                        View Proof
                      </Button>
                  }
                </td>
              </tr>

              {/* <tr>
                <th
                  scope="row"
                  style={{ width: "400px" }}
                  className={"text-capitalize"}
                >
                  Tax Id
                </th>
                <td className='w-50'>{data.levelTwo.taxId}</td>
              </tr> */}
              <tr>
                <th
                  scope="row"
                  style={{ width: "400px" }}
                  className={"text-capitalize"}
                >
                  {data.levelTwo.typeOfEntityLibrary?.displayName}
                </th>
                <td className='w-50'>
                  {
                    data.levelTwo.typeOfEntityLibrary &&
                      data.levelTwo.typeOfEntityLibrary.libraryData &&
                      data.levelTwo.typeOfEntityLibrary.libraryData.length ?
                      data.levelTwo.typeOfEntityLibrary.libraryData.map((item, index) => {
                        if (item.id === data.levelTwo.typeOfEntityLibraryDataId) {
                          return <span key={index}>{item.displayName}</span>
                        }
                      })
                      : null
                  }
                </td>
              </tr>
              <tr>
                <th
                  scope="row"
                  style={{ width: "400px" }}
                  className={"text-capitalize"}
                >
                  {data.levelTwo.customerTypeLibrary?.displayName}
                </th>
                <td className='w-50'>
                  {
                    data.levelTwo.customerTypeLibrary &&
                      data.levelTwo.customerTypeLibrary.libraryData &&
                      data.levelTwo.customerTypeLibrary.libraryData.length ?
                      data.levelTwo.customerTypeLibrary.libraryData.map((item, index) => {
                        if (item.id === data.levelTwo.customerTypeLibraryDataId) {
                          return <span key={index}>{item.displayName}</span>
                        }
                      })
                      : null
                  }
                </td>
              </tr>
              <tr>
                <th
                  scope="row"
                  style={{ width: "400px" }}
                  className={"text-capitalize"}
                >
                  {data.levelTwo.industryLibrary?.displayName}
                </th>
                <td className='w-50'>
                  {
                    data.levelTwo.industryLibrary &&
                      data.levelTwo.industryLibrary.libraryData &&
                      data.levelTwo.industryLibrary.libraryData.length ?
                      data.levelTwo.industryLibrary.libraryData.map((item, index) => {
                        if (item.id === data.levelTwo.industryLibraryDataId) {
                          return <span key={index}>{item.displayName}</span>
                        }
                      })
                      : null
                  }
                </td>
              </tr>
              <tr>
                <th
                  scope="row"
                  style={{ width: "400px" }}
                  className={"text-capitalize"}
                >
                  Regulatory Licensing Number:
                </th>
                <td className='w-50'>{data.levelTwo.primaryRegulatoryLicencingNumber}</td>
              </tr>
            </tbody>
          </Table>
        </Col>
        <Col sm={6}>
          <Table bordered striped responsive className='mb-0'>
            <tbody>
              <tr>
                <th
                  scope="row"
                  style={{ width: "400px" }}
                  className={"text-capitalize"}
                >
                  Incorporation Number
                </th>
                <td className='w-50'>{data.levelTwo.incorporationNumber}</td>
              </tr>

              <tr>
                <th
                  scope="row"
                  style={{ width: "400px" }}
                  className={"text-capitalize"}
                >
                  Country of Incorporation/Registration
                </th>
                <td className='w-50'>{data.levelTwo.businessCountryOfRegistration}</td>
              </tr>

              <tr>
                <th
                  scope="row"
                  style={{ width: "400px" }}
                  className={"text-capitalize"}
                >
                  Province:
                </th>
                <td className='w-50'>{data.levelTwo.province}</td>
              </tr>
              <tr>
                <th
                  scope="row"
                  style={{ width: "400px" }}
                  className={"text-capitalize"}
                >
                  City:
                </th>
                <td className='w-50'>{data.levelTwo.city}</td>
              </tr>
              <tr>
                <th
                  scope="row"
                  style={{ width: "400px" }}
                  className={"text-capitalize"}
                >
                  Post Code:
                </th>
                <td className='w-50'>{data.levelTwo.postCode}</td>
              </tr>
              <tr>
                <th
                  scope="row"
                  style={{ width: "400px" }}
                  className={"text-capitalize"}
                >
                  {data.levelTwo.paymentMethodLibrary?.displayName}
                </th>
                <td className='w-50'>
                  {
                    data.levelTwo.paymentMethodLibrary &&
                      data.levelTwo.paymentMethodLibrary.libraryData &&
                      data.levelTwo.paymentMethodLibrary.libraryData.length ?
                      data.levelTwo.paymentMethodLibrary.libraryData.map((item, index) => {
                        if (item.id === data.levelTwo.paymentMethodLibraryDataId) {
                          return <span key={index}>{item.displayName}</span>
                        }
                      })
                      : null
                  }
                </td>
              </tr>

              <tr>
                <th
                  scope="row"
                  style={{ width: "400px" }}
                  className={"text-capitalize"}
                >
                  Certificate Of Incumbency Proof
                </th>
                <td className='w-50'>
                  {
                    !data.levelTwo.certificateOfIncumbencyFile ? "No attached file yet" :
                      <Button
                        color="primary"
                        size="sm"
                        onClick={() => {
                          viewProof(data.levelTwo.certificateOfIncumbencyFile.filePath)
                        }}
                      >
                        View Proof
                      </Button>
                  }
                </td>
              </tr>
              <tr>
                <th
                  scope="row"
                  style={{ width: "400px" }}
                  className={"text-capitalize"}
                >
                  Certificate Of Incorporation Proof
                </th>
                <td className='w-50'>
                  {
                    !data.levelTwo.certificateOfIncorporationFile ? "No attached file yet" :
                      <Button
                        color="primary"
                        size="sm"
                        onClick={() => {
                          viewProof(data.levelTwo.certificateOfIncorporationFile.filePath)
                        }}
                      >
                        View Proof
                      </Button>
                  }
                </td>
              </tr>

              <tr>
                <th
                  scope="row"
                  style={{ width: "400px" }}
                  className={"text-capitalize"}
                >
                  {data.levelTwo.payeeTypeLibrary?.displayName}
                </th>
                <td className='w-50'>
                  {
                    data.levelTwo.payeeTypeLibrary &&
                      data.levelTwo.payeeTypeLibrary.libraryData &&
                      data.levelTwo.payeeTypeLibrary.libraryData.length ?
                      data.levelTwo.payeeTypeLibrary.libraryData.map((item, index) => {
                        if (item.id === data.levelTwo.payeeTypeLibraryDataId) {
                          return <span key={index}>{item.displayName}</span>
                        }
                      })
                      : null
                  }
                </td>
              </tr>
              <tr>
                <th
                  scope="row"
                  style={{ width: "400px" }}
                  className={"text-capitalize"}
                >
                  {data.levelTwo.holderTypeLibrary?.displayName}
                </th>
                <td className='w-50'>
                  {
                    data.levelTwo.holderTypeLibrary &&
                      data.levelTwo.holderTypeLibrary.libraryData &&
                      data.levelTwo.holderTypeLibrary.libraryData.length ?
                      data.levelTwo.holderTypeLibrary.libraryData.map((item, index) => {
                        if (item.id === data.levelTwo.holderTypeLibraryDataId) {
                          return <span key={index}>{item.displayName}</span>
                        }
                      })
                      : null
                  }
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
        <Col sm={12} className='mt-3'>
          <Table bordered responsive className='mb-0'>
            <tbody>
              <tr>
                <th
                  scope="row"
                  style={{ width: "400px" }}
                  className={"text-capitalize"}
                >
                  Primary Regulatory Licenses (if applicable)
                </th>
                <td className='w-50'>

                  {
                    data.levelTwo.businessRegulatoryLicencingNumberFile && data.levelTwo.businessRegulatoryLicencingNumberFile.length ?
                      <div className='w-100'>
                        <div className='w-100 overflowY-scroll' style={{ maxHeight: "calc(200px + 1rem)", overflowX: "hidden" }}>
                          <div className='d-flex flex-wrap'>
                            {
                              data.levelTwo.businessRegulatoryLicencingNumberFile.map((item, index) => {
                                return <div key={index} className='position-relative border rounded' style={{ maxWidth: "150px", maxHeight: "150px", minHeight: "100px", minWidth: "100px", margin: "5px" }}>
                                  {
                                    (() => {
                                      switch (MainService.getFxtensions(item.filePath)) {
                                        case "image":
                                          return <div
                                            style={{ width: "100%", height: "100%", }}
                                            className='d-flex justify-content-center align-items-center flex-column cursor-pointer'
                                            onClick={() => setZoomImagePath(item.filePath)}
                                          >
                                            <img
                                              src={item.filePath}
                                              className='rounded'
                                              style={{ maxWidth: "200px", width: "100%", height: "100%", maxHeight: "200px" }}
                                              alt='/'
                                            />
                                          </div>
                                        case "file":
                                          return <div
                                            style={{ width: "100%", height: "100%", }}
                                            className='p-2  d-flex justify-content-center align-items-center flex-column cursor-pointer'
                                            onClick={() => window.open(item.filePath, "_blank")}
                                          >
                                            <i className='bx bx-file-blank' style={{ fontSize: "50px" }} ></i>
                                          </div>
                                        default:
                                          break;
                                      }
                                    })()
                                  }

                                </div>
                              })
                            }
                          </div>
                        </div>

                      </div>
                      : "No attached file(s) yet"
                  }
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </Col>
  )
}
