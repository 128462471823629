import React from 'react'
import {
  Col,
  Row,
  Input,
} from "reactstrap"
import ReactSelectOption from '../../../../components/SelectOptions/ReactSelectOption';
import { allValidFileTypes } from '../../../../Constants/MainKeys';
import MainService from '../../../../Services/MainService';
import FileComponent from '../../Merchant/Components/FileComponent';
import FilesComponent from '../../Merchant/Components/FilesComponent';
import ApiService from '../../../../Services/ApiService';

export default function LevelTwoRequirementsForm(props) {

  const { data, onChange, uploadFile, setZoomImagePath, onSelectOptionChange, bulkUploadFile, deleteDocument, countries, confirmProof, confirmLoaderId } = props;

  return (
    <Col sm={12}>
      <Row  >

        <Col lg={4} md={6}>
          <label htmlFor='businessRegistrationNumber' className='mt-3'>Business Registration Number:</label>
          <Input
            id='businessRegistrationNumber'
            type="text"
            placeholder='Ex. ABC123456'
            value={data.levelTwo.businessRegistrationNumber || ""}
            name="businessRegistrationNumber"
            className={`form-control`}
            autoComplete='new-password'
            onChange={(event) => onChange(event, "levelTwo", "businessRegistrationNumber")}
          />
        </Col>

        <Col lg={4} md={6}>
          <label htmlFor='businessRegisteredAddress' className='mt-3'>Business Registration Address:</label>
          <Input
            id='businessRegisteredAddress'
            type="text"
            placeholder='Ex. ABC123456'
            value={data.levelTwo.businessRegisteredAddress || ""}
            name="businessRegisteredAddress"
            className={`form-control`}
            autoComplete='new-password'
            onChange={(event) => onChange(event, "levelTwo", "businessRegisteredAddress")}
          />
        </Col>
        <Col lg={4} md={6}>
          <label htmlFor='primaryRegulatoryLicencingNumber' className='mt-3'>Primary Licensing Number:</label>
          <Input
            id='primaryRegulatoryLicencingNumber'
            type="text"
            placeholder='Ex. 1234567890'
            value={data.levelTwo.primaryRegulatoryLicencingNumber || ""}
            name="primaryRegulatoryLicencingNumber"
            className={`form-control`}
            autoComplete='new-password'
            onChange={(event) => onChange(event, "levelTwo", "primaryRegulatoryLicencingNumber")}
          />
        </Col>
        <Col lg={4} md={6}>
          <label htmlFor='incorporationNumber' className='mt-3'>Incorporation Number:</label>
          <Input
            id='incorporationNumber'
            type="text"
            placeholder='Ex. 1234567890'
            value={data.levelTwo.incorporationNumber || ""}
            name="incorporationNumber"
            className={`form-control`}
            autoComplete='new-password'
            onChange={(event) => onChange(event, "levelTwo", "incorporationNumber")}
          />
        </Col>

        <Col lg={4} md={6}>
          <label htmlFor='' className='mt-3'>{data.levelTwo.customerTypeLibrary?.displayName}</label>
          {
            data.levelTwo.customerTypeLibrary &&
              data.levelTwo.customerTypeLibrary.libraryData &&
              data.levelTwo.customerTypeLibrary.libraryData.length ?
              <ReactSelectOption
                value={data.levelTwo.customerTypeLibrary}
                isSearchable={true}
                selectedValue={(() => {
                  const selectedValue = { ...data.levelTwo.customerTypeLibrary.libraryData.find(item => item.id === data.levelTwo.customerTypeLibraryDataId) };
                  if (Object.keys(selectedValue).length) {
                    selectedValue.label = selectedValue.displayName;
                    selectedValue.value = selectedValue.id;
                    return selectedValue;
                  } else {
                    return { label: "Choose...", value: "" }
                  }
                })()}
                items={data.levelTwo.customerTypeLibrary.libraryData.map(item => ({ label: item.displayName, value: item.id }))}
                onChange={(item) => onSelectOptionChange(item.value, "levelTwo", "customerTypeLibraryDataId")}
              />
              : null
          }
        </Col>
        <Col lg={4} md={6}>
          <label htmlFor='' className='mt-3'>{data.levelTwo.typeOfEntityLibrary?.displayName}</label>
          {
            data.levelTwo.typeOfEntityLibrary &&
              data.levelTwo.typeOfEntityLibrary.libraryData &&
              data.levelTwo.typeOfEntityLibrary.libraryData.length ?
              <ReactSelectOption
                value={data.levelTwo.typeOfEntityLibrary}
                isSearchable={true}
                selectedValue={(() => {
                  const selectedValue = { ...data.levelTwo.typeOfEntityLibrary.libraryData.find(item => item.id === data.levelTwo.typeOfEntityLibraryDataId) };
                  if (Object.keys(selectedValue).length) {
                    selectedValue.label = selectedValue.displayName;
                    selectedValue.value = selectedValue.id;
                    return selectedValue;
                  } else {
                    return { label: "Choose...", value: "" }
                  }
                })()}
                items={data.levelTwo.typeOfEntityLibrary.libraryData.map(item => ({ label: item.displayName, value: item.id }))}
                onChange={(item) => onSelectOptionChange(item.value, "levelTwo", "typeOfEntityLibraryDataId")}
              />
              : null
          }
        </Col>
        <Col lg={4} md={6}>
          <label htmlFor='' className='mt-3'>{data.levelTwo.industryLibrary?.displayName}</label>
          {
            data.levelTwo.industryLibrary &&
              data.levelTwo.industryLibrary.libraryData &&
              data.levelTwo.industryLibrary.libraryData.length ?
              <ReactSelectOption
                value={data.levelTwo.industryLibrary}
                isSearchable={true}
                selectedValue={(() => {
                  const selectedValue = { ...data.levelTwo.industryLibrary.libraryData.find(item => item.id === data.levelTwo.industryLibraryDataId) };
                  if (Object.keys(selectedValue).length) {
                    selectedValue.label = selectedValue.displayName;
                    selectedValue.value = selectedValue.id;
                    return selectedValue;
                  } else {
                    return { label: "Choose...", value: "" }
                  }
                })()}
                items={data.levelTwo.industryLibrary.libraryData.map(item => ({ label: item.displayName, value: item.id }))}
                onChange={(item) => onSelectOptionChange(item.value, "levelTwo", "industryLibraryDataId")}
              />
              : null
          }
        </Col>

        <Col lg={4} md={6}>
          <label htmlFor='businessCountryOfIncorporation' className='mt-3'>Country of registration/incorporation</label>
          {
            countries && countries.length ?
              <ReactSelectOption
                value={data.levelTwo.businessCountryOfRegistrationId}
                isSearchable={true}
                selectedValue={(() => {
                  const selectedValue = { ...countries.find(item => item.id === data.levelTwo.businessCountryOfRegistrationId) };
                  if (Object.keys(selectedValue).length) {
                    selectedValue.label = selectedValue.name;
                    selectedValue.value = selectedValue.id;
                    return selectedValue;
                  } else {
                    return { label: "Choose...", value: "" }
                  }
                })()}
                items={countries.map(item => ({ label: item.name, value: item.id }))}
                onChange={(item) => onSelectOptionChange(item.value, "levelTwo", "businessCountryOfRegistrationId")}
              />
              : null
          }
        </Col>
        <Col lg={4} md={6}>
          <label htmlFor='province' className='mt-3'>Province</label>
          <Input
            id='province'
            type="text"
            placeholder='Ex. Texas'
            value={data.levelTwo.province || ""}
            name="province"
            className={`form-control`}
            autoComplete='new-password'
            onChange={(event) => onChange(event, "levelTwo", "province")}
          />
        </Col>
        <Col lg={4} md={6}>
          <label htmlFor='city' className='mt-3'>City</label>
          <Input
            id='city'
            type="text"
            placeholder='Ex. Dallas'
            value={data.levelTwo.city || ""}
            name="city"
            className={`form-control`}
            autoComplete='new-password'
            onChange={(event) => onChange(event, "levelTwo", "city")}
          />
        </Col>

        <Col lg={4} md={6}>
          <label htmlFor='postCode' className='mt-3'>Post code</label>
          <Input
            id='postCode'
            type="text"
            placeholder='Ex. 0001'
            value={data.levelTwo.postCode || ""}
            name="postCode"
            className={`form-control`}
            autoComplete='new-password'
            onChange={(event) => onChange(event, "levelTwo", "postCode")}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={12}><hr /></Col>
        <Col lg={4} md={6}>
          <label htmlFor='' className=''>{data.levelTwo.paymentMethodLibrary?.displayName}</label>
          {
            data.levelTwo.paymentMethodLibrary &&
              data.levelTwo.paymentMethodLibrary.libraryData &&
              data.levelTwo.paymentMethodLibrary.libraryData.length ?
              <ReactSelectOption
                value={data.levelTwo.paymentMethodLibrary}
                isSearchable={true}
                selectedValue={(() => {
                  const selectedValue = { ...data.levelTwo.paymentMethodLibrary.libraryData.find(item => item.id === data.levelTwo.paymentMethodLibraryDataId) };
                  if (Object.keys(selectedValue).length) {
                    selectedValue.label = selectedValue.displayName;
                    selectedValue.value = selectedValue.id;
                    return selectedValue;
                  } else {
                    return { label: "Choose...", value: "" }
                  }
                })()}
                items={data.levelTwo.paymentMethodLibrary.libraryData.map(item => ({ label: item.displayName, value: item.id }))}
                onChange={(item) => onSelectOptionChange(item.value, "levelTwo", "paymentMethodLibraryDataId")}
              />
              : null
          }
        </Col>
        <Col lg={4} md={6}>
          <label htmlFor='' className='mt-md-0 mt-3'>{data.levelTwo.payeeTypeLibrary?.displayName}</label>
          {
            data.levelTwo.payeeTypeLibrary &&
              data.levelTwo.payeeTypeLibrary.libraryData &&
              data.levelTwo.payeeTypeLibrary.libraryData.length ?
              <ReactSelectOption
                value={data.levelTwo.payeeTypeLibrary}
                isSearchable={true}
                selectedValue={(() => {
                  const selectedValue = { ...data.levelTwo.payeeTypeLibrary.libraryData.find(item => item.id === data.levelTwo.payeeTypeLibraryDataId) };
                  if (Object.keys(selectedValue).length) {
                    selectedValue.label = selectedValue.displayName;
                    selectedValue.value = selectedValue.id;
                    return selectedValue;
                  } else {
                    return { label: "Choose...", value: "" }
                  }
                })()}
                items={data.levelTwo.payeeTypeLibrary.libraryData.map(item => ({ label: item.displayName, value: item.id }))}
                onChange={(item) => onSelectOptionChange(item.value, "levelTwo", "payeeTypeLibraryDataId")}
              />
              : null
          }
        </Col>
        <Col lg={4} md={6}>
          <label htmlFor='' className='mt-lg-0 mt-3'>{data.levelTwo.holderTypeLibrary?.displayName}</label>
          {
            data.levelTwo.holderTypeLibrary &&
              data.levelTwo.holderTypeLibrary.libraryData &&
              data.levelTwo.holderTypeLibrary.libraryData.length ?
              <ReactSelectOption
                value={data.levelTwo.holderTypeLibrary}
                isSearchable={true}
                selectedValue={(() => {
                  const selectedValue = { ...data.levelTwo.holderTypeLibrary.libraryData.find(item => item.id === data.levelTwo.holderTypeLibraryDataId) };
                  if (Object.keys(selectedValue).length) {
                    selectedValue.label = selectedValue.displayName;
                    selectedValue.value = selectedValue.id;
                    return selectedValue;
                  } else {
                    return { label: "Choose...", value: "" }
                  }
                })()}
                items={data.levelTwo.holderTypeLibrary.libraryData.map(item => ({ label: item.displayName, value: item.id }))}
                onChange={(item) => onSelectOptionChange(item.value, "levelTwo", "holderTypeLibraryDataId")}
              />
              : null
          }
        </Col>
      </Row>
      <Row>
        <Col sm={12} className='mt-3'>
          <Row>
            <Col md={6} className='mb-3'>
              <FilesComponent
                data={data?.levelTwo}
                fieldName="businessRegulatoryLicencingNumberFile"
                labelValue="Primary regulatory licenses (if applicable)"
                setZoomImagePath={setZoomImagePath}
                alertMessage="Allow multiple"
                alertIcon="mdi-alert-circle-outline"
                alertType="info"
                multiple={true}

                deleteDocument={(item) =>
                  deleteDocument({
                    ...item,
                    getFileType: MainService.getOrganizationDetailsFileType,
                    fileObj: "levelTwo",
                    id: data.levelTwo.id
                  })}
                uploadFile={(item) =>
                  bulkUploadFile({
                    ...item,
                    obj: "levelTwo",
                    checkFiltType: MainService.getOrganizationDetailsFileType,
                    method: ApiService.bulkUploadDocumentAsAdmin,
                    id: data.levelTwo.id
                  })}
              />
            </Col>
            <Col md={3} className='mb-3'>
              <FileComponent
                data={data?.levelTwo}
                fieldName="businessRegisteredAddressFile"
                labelValue="Business Registration Address Proof"
                setZoomImagePath={setZoomImagePath}
                confirmLoaderId={confirmLoaderId}
                confirmProof={(item) =>
                  confirmProof({
                    ...item,
                    fileObj: "levelTwo",
                    fieldName: "businessRegisteredAddressFile",
                  })}
                deleteDocument={(item) =>
                  deleteDocument({
                    ...item,
                    getFileType: MainService.getOrganizationDetailsFileType,
                    fileObj: "levelTwo",
                    id: data.levelTwo.id
                  })}
                uploadFile={(item) =>
                  uploadFile({
                    ...item, obj: "levelTwo",
                    checkFiltType: MainService.getOrganizationDetailsFileType,
                    method: ApiService.uploadDocumentAsAdmin,
                    id: data.levelTwo.id
                  })}
              />
            </Col>
            <Col md={3} className='mb-3'>
              <FileComponent
                data={data?.levelTwo}
                fieldName="businessRegistrationCertificateFile"
                labelValue="Business Registration Certificate Proof"
                setZoomImagePath={setZoomImagePath}
                confirmLoaderId={confirmLoaderId}
                confirmProof={(item) =>
                  confirmProof({
                    ...item,
                    fileObj: "levelTwo",
                    fieldName: "businessRegistrationCertificateFile",
                  })}
                deleteDocument={(item) =>
                  deleteDocument({
                    ...item,
                    getFileType: MainService.getOrganizationDetailsFileType,
                    fileObj: "levelTwo",
                    id: data.levelTwo.id
                  })}
                uploadFile={(item) =>
                  uploadFile({
                    ...item,
                    obj: "levelTwo",
                    checkFiltType: MainService.getOrganizationDetailsFileType,
                    method: ApiService.uploadDocumentAsAdmin,
                    id: data.levelTwo.id
                  })}
              />
            </Col>
            <Col md={3} className='mb-3'>
              <FileComponent
                data={data?.levelTwo}
                fieldName="certificateOfIncumbencyFile"
                labelValue="Certificate Of Incumbency Proof"
                setZoomImagePath={setZoomImagePath}
                confirmLoaderId={confirmLoaderId}
                confirmProof={(item) =>
                  confirmProof({
                    ...item,
                    fileObj: "levelTwo",
                    fieldName: "certificateOfIncumbencyFile",
                  })}
                deleteDocument={(item) =>
                  deleteDocument({
                    ...item,
                    getFileType: MainService.getOrganizationDetailsFileType,
                    fileObj: "levelTwo",
                    id: data.levelTwo.id,
                  })}
                uploadFile={(item) =>
                  uploadFile({
                    ...item,
                    obj: "levelTwo",
                    checkFiltType: MainService.getOrganizationDetailsFileType,
                    method: ApiService.uploadDocumentAsAdmin,
                    id: data.levelTwo.id
                  })}
              />
            </Col>
            <Col md={3} className='mb-3'>
              <FileComponent
                data={data?.levelTwo}
                fieldName="certificateOfIncorporationFile"
                labelValue="Certificate Of Incorporation Proof"
                setZoomImagePath={setZoomImagePath}
                confirmLoaderId={confirmLoaderId}
                confirmProof={(item) =>
                  confirmProof({
                    ...item,
                    fileObj: "levelTwo",
                    fieldName: "certificateOfIncorporationFile",
                  })}
                deleteDocument={(item) =>
                  deleteDocument({
                    ...item,
                    getFileType: MainService.getOrganizationDetailsFileType,
                    fileObj: "levelTwo",
                    id: data.levelTwo.id,
                  })}
                uploadFile={(item) =>
                  uploadFile({
                    ...item,
                    obj: "levelTwo",
                    checkFiltType: MainService.getOrganizationDetailsFileType,
                    method: ApiService.uploadDocumentAsAdmin,
                    id: data.levelTwo.id
                  })}
              />
            </Col>
            <Col md={3} className='mb-3'>
              <FileComponent
                data={data?.levelTwo}
                fieldName="otherProofFile"
                labelValue="Other Proof Of Business"
                setZoomImagePath={setZoomImagePath}
                confirmLoaderId={confirmLoaderId}
                confirmProof={(item) =>
                  confirmProof({
                    ...item,
                    fileObj: "levelTwo",
                    fieldName: "otherProofFile",
                  })}
                deleteDocument={(item) =>
                  deleteDocument({
                    ...item,
                    getFileType: MainService.getOrganizationDetailsFileType,
                    fileObj: "levelTwo",
                    id: data.levelTwo.id,
                  })}
                uploadFile={(item) =>
                  uploadFile({
                    ...item,
                    obj: "levelTwo",
                    checkFiltType: MainService.getOrganizationDetailsFileType,
                    method: ApiService.uploadDocumentAsAdmin,
                    id: data.levelTwo.id
                  })}
              />
            </Col>
            <Col sm={12}><small className='fw-500'>Allowed file types {allValidFileTypes.map((item, index) => <i key={index}>{item}. </i>)}</small></Col>
          </Row>
        </Col>
      </Row>
    </Col>
  )
}
