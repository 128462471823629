import React from 'react'
import PhoneInput from 'react-phone-input-2';
import {
  Col,
  Row,
  Label,
  Button,
  Input,
  UncontrolledAlert,
} from "reactstrap"
import MainService from '../../../../Services/MainService';
import FileComponent from '../../Merchant/Components/FileComponent';
import Switch from "react-switch"
import OnIcon from '../../../../components/OnIcon/OnIcon';
import OffIcon from '../../../../components/OffIcon/OffIcon';
import ApiService from '../../../../Services/ApiService';
import ReactSelectOption from '../../../../components/SelectOptions/ReactSelectOption';

export default function LevelThreeRequirementsForm(props) {

  const { data, onChange, uploadFile, setZoomImagePath, deleteDocument, onNumberChange, onSelectOptionChange, countries, isDisabledFields, onSwitcherChange, confirmProof, confirmLoaderId } = props;

  return (
    <Col sm={12}>
      <Row className='mb-4'>
        <Col lg={12}>
          <Row>
            <Col lg={4} md={6} className='order-md-first'>
              <Label htmlFor='accountName' className='text-muted text-truncate mb-0'>Account Name*</Label>
              <Input
                id="accountName"
                type="text"
                placeholder='Ex. ABC Corporation'
                className={`form-control`}
                value={data.levelThree.accountName || ""}
                onChange={event => onChange(event, "levelThree", "accountName")}
              />
            </Col>
            <Col lg={12}>
              <UncontrolledAlert color="warning" role="alert" className='mt-3 mb-0'>
                <i className="mdi mdi-alert-outline me-2"></i>
                Attention: Your legal business name is linked to your bank account, and any changes made to
                the bank account name will automatically update your business name as well.
              </UncontrolledAlert>
            </Col>
            <Col lg={8} md={6} className='order-first'>
              <div className='d-flex flex-column align-items-end'>
                <Label htmlFor='countryId' className='mt-3 mb-0'>
                  Bank account status
                </Label>
                <div className='mt-1'>
                  <Switch
                    className="react-switch mt-1"
                    size={1}
                    checkedIcon={<OnIcon />}
                    uncheckedIcon={<OffIcon />}
                    checked={data.levelThree.isApproved || false}
                    aria-labelledby="neat-label"
                    disabled={isDisabledFields}
                    onChange={() => onSwitcherChange(!data.levelThree.isApproved, "levelThree", "isApproved")}
                  />
                </div>
              </div>
              <hr className='d-md-none d-block' />
            </Col>
          </Row>
        </Col>
        <Col sm={12}><hr /></Col>

        <Col lg={4} md={6} className="mb-4">
          <Label htmlFor='accountNumber' className={`text-muted text-truncate mb-0`}>Account Number*</Label>
          <Input
            id="accountNumber"
            type="text"
            placeholder='Ex. 1234567890'
            className={`form-control`}
            value={data.levelThree.accountNumber || ""}
            onChange={(event) => onChange(event, "levelThree", "accountNumber")}
          />
        </Col>
        <Col lg={4} md={6}>
          <Label htmlFor='swiftCode' className='text-muted text-truncate mb-0'>Swift Code*</Label>
          <Input
            id="swiftCode"
            type="text"
            className={`form-control`}
            placeholder='Ex. INJSAM22'
            value={data.levelThree.swiftCode || ""}
            onChange={(event) => onChange(event, "levelThree", "swiftCode")}
          />
        </Col>
        <Col lg={4} md={6} className="mb-4">
          <Label htmlFor='routingType' className='text-muted text-truncate mb-0'>Routing Type*</Label>
          {
            data.levelThree.routingTypeLibrary &&
              data.levelThree.routingTypeLibrary.libraryData &&
              data.levelThree.routingTypeLibrary.libraryData.length ?
              <ReactSelectOption
                value={data.levelThree?.routingTypeLibraryDataId}
                isSearchable={true}
                selectedValue={(() => {
                  const selectedValue = { ...data.levelThree.routingTypeLibrary.libraryData.find(item => item.id === data.levelThree?.routingTypeLibraryDataId) };
                  console.log(selectedValue);
                  if (Object.keys(selectedValue).length) {
                    selectedValue.label = selectedValue.displayName;
                    selectedValue.value = selectedValue.id;
                    return selectedValue;
                  } else {
                    return { label: "Choose...", value: "" }
                  }
                })()}
                items={data.levelThree.routingTypeLibrary.libraryData.map(data => ({ label: data.displayName, value: data.id }))}
                onChange={(item) => onSelectOptionChange(item.value, "levelThree", "routingTypeLibraryDataId")}
              />
              : null
          }
        </Col>
      </Row>
      <hr />
      <Row className="mb-4">
        <Col lg={4} md={6} className="mb-4">
          <Label htmlFor='bankName' className='text-muted text-truncate mb-0'>Bank Name*</Label>
          <Input
            id="bankName"
            type="text"
            placeholder='Ex. Citibank'
            className={`form-control`}
            value={data.levelThree.bankName || ""}
            onChange={(event) => onChange(event, "levelThree", "bankName")}
          />
        </Col>
        <Col lg={4} md={6} className="mb-4">
          <Label htmlFor='bankAddress' className='text-muted text-truncate mb-0'>Bank Address*</Label>
          <Input
            id="bankAddress"
            type="text"
            placeholder='Ex. 789 Oak Road, Villageton, CA, 98765'
            className={`form-control`}
            value={data.levelThree.bankAddress || ""}
            onChange={(event) => onChange(event, "levelThree", "bankAddress")}
          />
        </Col>
        <Col lg={4} md={6} className="mb-4">
          <Label htmlFor='bankCode' className='text-muted text-truncate mb-0'>Bank Code*</Label>
          <Input
            id="bankCode"
            type="text"
            placeholder='Ex. 123456'
            className={`form-control`}
            value={data.levelThree.bankCode || ""}
            onChange={(event) => onChange(event, "levelThree", "bankCode")}
          />
        </Col>

        <Col lg={4} md={6} className="mb-4">
          <Label htmlFor='branchAddress' className='text-muted text-truncate mb-0'>Branch Address*</Label>
          <Input
            id="branchAddress"
            type="text"
            placeholder='Ex. 543 Birch Boulevard, Countryside, OR, 98765'
            className={`form-control`}
            value={data.levelThree.branchAddress || ""}
            onChange={(event) => onChange(event, "levelThree", "branchAddress")}
          />
        </Col>
        <Col lg={4} md={6} className="mb-4">
          <Label htmlFor='branchCode' className='text-muted text-truncate mb-0'>Branch Code*</Label>
          <Input
            id="branchCode"
            type="text"
            className={`form-control`}
            placeholder='Ex. 002'
            value={data.levelThree.branchCode || ""}
            onChange={(event) => onChange(event, "levelThree", "branchCode")}
          />
        </Col>
        <Col lg={4} md={6}>
          <Label htmlFor='branchName' className='text-muted text-truncate mb-0'>Branch Name*</Label>
          <Input
            id="branchName"
            type="text"
            placeholder='Ex. Oakwood Avenue Branch'
            className={`form-control`}
            value={data.levelThree.branchName || ""}
            onChange={(event) => onChange(event, "levelThree", "branchName")}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mb-4">

        {/* <Col lg={4} md={6} className="mb-4">
          <Label htmlFor='routingValue' className='text-muted text-truncate mb-0'>Routing Value*</Label>
          <Input
            id="routingValue"
            type="text"
            placeholder='Ex. 123456789'
            className={`form-control`}
            value={data.levelThree.routingValue || ""}
            onChange={(event) => onChange(event, "levelThree", "routingValue")}
          />
        </Col> */}
        <Col lg={4} md={6} className="mb-4">
          <Label htmlFor='countryId' className='text-muted text-truncate mb-0'>Country*</Label>
          {
            countries && countries.length ?
              <ReactSelectOption
                value={data.levelThree.countryId}
                isSearchable={true}
                className={``}
                selectedValue={(() => {
                  const selectedValue = { ...countries.find(item => item.id === data.levelThree.countryId) };
                  if (Object.keys(selectedValue).length) {
                    selectedValue.label = selectedValue.name;
                    selectedValue.value = selectedValue.id;
                    return selectedValue;
                  } else {
                    return { label: "Choose...", value: "" }
                  }
                })()}
                items={countries.map(item => ({ label: item.name, value: item.id }))}
                onChange={(item) => onSelectOptionChange(item.value, "levelThree", "countryId")}
              />
              : null
          }
        </Col>
        <Col lg={4} md={6} className="mb-4">
          <Label htmlFor='province' className='text-muted text-truncate mb-0'>Province*</Label>
          <Input
            id="province"
            type="text"
            placeholder='Ex. California'
            className={`form-control`}
            value={data.levelThree.province || ""}
            onChange={(event) => onChange(event, "levelThree", "province")}
          />
        </Col>
        <Col lg={4} md={6} className="mb-4">
          <Label htmlFor='city' className='text-muted text-truncate mb-0'>City*</Label>
          <Input
            id="city"
            type="text"
            placeholder='Ex. Los Angeles'
            className={`form-control`}
            value={data.levelThree.city || ""}
            onChange={(event) => onChange(event, "levelThree", "city")}
          />
        </Col>

        <Col sm={12}>
          <hr />
          <Row>
            <Col md={3} sm={6} className='mb-3'>
              <FileComponent
                data={data.levelThree}
                fieldName="bankAddressFile"
                labelValue="Bank Address Proof"
                setZoomImagePath={setZoomImagePath}
                confirmLoaderId={confirmLoaderId}
                deleteDocument={(item) =>
                  deleteDocument({
                    ...item,
                    getFileType: MainService.getBankAddressFileType,
                    fileObj: "levelThree",
                    method: ApiService.deleteBankAccountDocumentAsAdmin,
                    id: data.levelThree.id
                  })}
                uploadFile={(item) =>
                  uploadFile({
                    ...item,
                    obj: "levelThree",
                    checkFiltType: MainService.getBankAddressFileType,
                    method: ApiService.uploadBankAccountDocumentAsAdmin,
                    id: data.levelThree.id
                  })}
                confirmProof={(item) =>
                  confirmProof({
                    ...item,
                    fileObj: "levelTwo",
                    fieldName: "otherProofFile",
                  })}
              />
            </Col>

          </Row>
        </Col>
      </Row>
    </Col>
  )
}
