import React, { useEffect, useState } from 'react'
import Breadcrumbs from './../../../components/Common/Breadcrumb'
import { withRouter } from 'react-router-dom'
import { withTranslation } from "react-i18next"
import { useCallback } from 'react'
import { addPageSpinner, addRightSectionSpinner, removePageSpinner, removeRightSectionSpinner } from './../../../store/spinner/actions'
import AlertService from '../../../Services/alertService'
import { ERROR_KEY } from './../../../Constants/MainKeys'
import uuid from 'react-uuid'
import { useDispatch, useSelector } from 'react-redux';
import * as moment from 'moment';
import {
  Card,
  CardBody,
  Col,
  Row,
} from "reactstrap"
import { Table } from "react-bootstrap"
import MainService from '../../../Services/MainService'
import ApiService from '../../../Services/ApiService'
import RightSectionSpinner from '../../../components/Spinners/RightSectionSpinner'
import HtmlHead from '../../../components/HtmlHead/HtmlHead'
import NoData from '../../../components/NoData/NoData'


const TransactionHistoryView = (props) => {

  const title = "Transaction View";
  const description = "";
  const dispatch = useDispatch();
  const { rightSectionSpinners } = useSelector(state => state.Spinners);
  const { localTransactionId } = props.match.params;
  const [currentTransaction, setCurrentTransaction] = useState(null);

  const statuses = [
    {
      "key": "NEW",
      "value": 0
    },
    {
      "key": "HASH_SET",
      "value": 1
    },
    {
      "key": "AWAITING",
      "value": 2
    },
    {
      "key": "ERROR",
      "value": 3
    },
    {
      "key": "REJECTED",
      "value": 4
    },
    {
      "key": "SUCCESS",
      "value": 5
    },
    {
      "key": "CANCELLED",
      "value": 6
    },
    {
      "key": "TIMEOUT",
      "value": 7
    }
  ]

  useEffect(() => {
    if (localTransactionId) {
      getTransactionByLocalTransactionId();
    }
  }, []);

  const getTransactionByLocalTransactionId = () => {
    const spinnerId = uuid();
    setRightSectionSpinner(spinnerId);
    ApiService.getTransactionByLocalTransactionId(localTransactionId).then(respose => {
      if (respose && respose.data) {
        setCurrentTransaction({ ...respose.data });
      }
      extractRightSectionSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId))
  }

  const setRightSectionSpinner = useCallback(spinner => {
    dispatch(addRightSectionSpinner(spinner));
  }, []);

  const extractRightSectionSpinner = useCallback(spinner => {
    dispatch(removeRightSectionSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractRightSectionSpinner(spinnerId);
  }

  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className='page-content position-relative min-h-100vh'>
        <RightSectionSpinner spinner={rightSectionSpinners} />
        <Breadcrumbs
          title="Transactions History"
          breadcrumbItem="Transaction View"
          isShowGoBackButton={true}
          goBack={() => window.history.back()}
        />
        <Row>
          <Col>
            {
              currentTransaction ?
                <>
                  <Card>
                    <CardBody>
                      <div className="table-responsive">
                        <Table responsive className="table mb-0 table-bordered">
                          <tbody>
                            <tr>
                              <th style={{ width: "400px" }} className="text-capitalize"><b>Crypto amount</b></th>
                              <td>
                                <b>
                                  {currentTransaction.cryptoAmount}
                                  &nbsp;
                                  {currentTransaction.cryptoCurrency && currentTransaction.cryptoCurrency.name ?
                                    currentTransaction.cryptoCurrency.name : ""}
                                </b>
                              </td>
                            </tr>
                            <tr>
                              <th style={{ width: "400px" }} className="text-capitalize"><b>Fiat amount</b></th>
                              <td>
                                <b>
                                  {currentTransaction.fiatAmount}
                                  &nbsp;
                                  {currentTransaction.fiatCurrency && currentTransaction.fiatCurrency.name ?
                                    currentTransaction.fiatCurrency.name : ""}
                                </b>
                              </td>
                            </tr>

                            <tr>
                              <th style={{ width: "400px" }} className="text-capitalize"><b>Status</b></th>
                              <td><span className={`badge badge-soft-${MainService.getStatusColor(currentTransaction.status)} font-size-14 badge badge-pill`}>{MainService.getStatusName(currentTransaction.status, statuses)?.toLowerCase()}</span></td>
                            </tr>
                            {
                              currentTransaction.createDate ?
                                <tr>
                                  <th className="text-capitalize"><b>Date</b></th>
                                  <td>
                                    <b>
                                      {moment(new Date(currentTransaction.createDate)).format("LL HH:mm")}
                                    </b>
                                  </td>
                                </tr>
                                : null
                            }
                            <tr>
                              <th className="text-capitalize"><b>Processed</b></th>
                              <td>
                                <b>
                                  {currentTransaction.processed ? "YES" : "NO"}
                                </b>
                              </td>
                            </tr>
                            {
                              currentTransaction.localTransactionId ?
                                <tr>
                                  <th className="text-capitalize"><b>Transaction Id</b></th>
                                  <td>
                                    <b>
                                      <span className='max-line-1' title={currentTransaction.localTransactionId}>{currentTransaction.localTransactionId}</span>
                                    </b>
                                  </td>
                                </tr>
                                : null
                            }
                            {
                              currentTransaction.remoteTransactionId ?
                                <tr>
                                  <th className="text-capitalize"><b>Remote Transaction Id</b></th>
                                  <td>
                                    <b>
                                      {currentTransaction.remoteTransactionId}
                                    </b>
                                  </td>
                                </tr>
                                : null
                            }
                            {
                              currentTransaction.cryptoWallet ?
                                <tr>
                                  <th className="text-capitalize"><b>Wallet</b></th>
                                  <td>
                                    <b>
                                      {currentTransaction.cryptoWallet.name}
                                    </b>
                                  </td>
                                </tr>
                                : null
                            }
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody>
                      <div>
                        <h5 className="mb-3">Details</h5>
                        <hr />
                        <div className="table-responsive">
                          <Table className="table mb-0 table-bordered">
                            <tbody>
                              {
                                currentTransaction.finishDate ?
                                  <tr><th style={{ width: "400px" }} className="text-capitalize">Finish date</th>
                                    <td>{moment(MainService.convertUTCDateToLocalDate(new Date(currentTransaction.finishDate))).format("LL HH:mm")}</td>
                                  </tr>
                                  : null
                              }
                              {
                                currentTransaction.cryptoPayerAddress ?
                                  <tr>
                                    <th style={{ width: "400px" }} className="text-capitalize">Crypto payer address</th>
                                    <td>
                                      <a
                                        href={`https://etherscan.io/address/${currentTransaction.cryptoPayerAddress}`}
                                        target='_blank'
                                        className='nowrap'
                                      >
                                        {`https://etherscan.io/address/${currentTransaction.cryptoPayerAddress}`}
                                        <i className='bx bx-link-external ms-1'></i>
                                      </a>
                                    </td>
                                  </tr>
                                  : null
                              }
                              {
                                currentTransaction.cryptoTransactionHash ?
                                  <tr>
                                    <th style={{ width: "400px" }} className="text-capitalize">Crypto transaction hash</th>
                                    <td>
                                      <a
                                        href={`https://etherscan.io/tx/${currentTransaction.cryptoTransactionHash}`}
                                        target='_blank'
                                        className='nowrap'
                                      >
                                        {`https://etherscan.io/tx/${currentTransaction.cryptoTransactionHash}`}
                                        <i className='bx bx-link-external ms-1'></i>
                                      </a>
                                    </td>
                                  </tr>
                                  : null
                              }
                              {
                                currentTransaction.cancelUrl ?
                                  <tr>
                                    <th style={{ width: "400px" }} className="text-capitalize">Cancel URL</th>
                                    <td>{currentTransaction.cancelUrl}</td>
                                  </tr>
                                  : null
                              }
                              {
                                currentTransaction.cryptoCurrency ?
                                  <tr>
                                    <th style={{ width: "400px" }} className="text-capitalize">Crypto Currency Id: {currentTransaction.cryptoCurrency?.id}</th>
                                    <td>Name: {currentTransaction.cryptoCurrency?.name}</td>
                                  </tr>
                                  : null
                              }
                              {
                                currentTransaction.errorCode ?
                                  <tr>
                                    <th style={{ width: "400px" }} className="text-capitalize">Error code</th>
                                    <td>{currentTransaction.errorCode}</td>
                                  </tr>
                                  : null
                              }
                              {
                                currentTransaction.errorMessage ?
                                  <tr>
                                    <th style={{ width: "400px" }} className="text-capitalize">Error message</th>
                                    <td>{currentTransaction.errorMessage}</td>
                                  </tr>
                                  : null
                              }
                              {
                                currentTransaction.errorTime ?
                                  <tr><th style={{ width: "400px" }} className="text-capitalize">Error time</th>
                                    <td>{moment(MainService.convertUTCDateToLocalDate(new Date(currentTransaction.errorTime))).format("LL HH:mm")}</td>
                                  </tr>
                                  : null
                              }
                              <tr>
                                <th style={{ width: "400px" }} className="text-capitalize">Crypto Currency Id</th>
                                <td>{currentTransaction.cryptoCurrencyId}</td>
                              </tr>
                              {
                                currentTransaction.fiatCurrency && currentTransaction.fiatCurrency.id && currentTransaction.fiatCurrency.name ?
                                  <tr>
                                    <th style={{ width: "400px" }} className="text-capitalize">Fiat Currency</th>
                                    <td>Id: {currentTransaction.fiatCurrency.id}, Name: {currentTransaction.fiatCurrency.name}</td>
                                  </tr>
                                  : null
                              }
                              <tr>
                                <th style={{ width: "400px" }} className="text-capitalize">Fiat Currency Id</th>
                                <td>{currentTransaction.fiatCurrencyId}</td>
                              </tr>
                              {
                                currentTransaction.hiddenFields ?
                                  <tr>
                                    <th style={{ width: "400px" }} className="text-capitalize">Hidden Fields</th>
                                    <td>{currentTransaction.hiddenFields}</td>
                                  </tr>
                                  : null
                              }
                              {
                                currentTransaction.paymentCalculatedCryptoCost ?
                                  <tr>
                                    <th style={{ width: "400px" }} className="text-capitalize">Payment Calculated Crypto Cost</th>
                                    <td>{currentTransaction.paymentCalculatedCryptoCost}</td>
                                  </tr>
                                  : null
                              }


                              <tr>
                                <th style={{ width: "400px" }} className="text-capitalize">Payment Page Opened</th>
                                <td>{currentTransaction.paymentPageOpened + ""}</td>
                              </tr>

                              {
                                currentTransaction.paymentPageOpenedDateTime ?
                                  <tr><th style={{ width: "400px" }} className="text-capitalize">Payment Page Opened Date Time</th>
                                    <td>{moment(MainService.convertUTCDateToLocalDate(new Date(currentTransaction.paymentPageOpenedDateTime))).format("LL HH:mm")}</td>
                                  </tr>
                                  : null
                              }
                              {
                                currentTransaction.paymentPageOpenedDeadline ?
                                  <tr><th style={{ width: "400px" }} className="text-capitalize">Payment Page Opened Deadline</th>
                                    <td>{moment(MainService.convertUTCDateToLocalDate(new Date(currentTransaction.paymentPageOpenedDeadline))).format("LL HH:mm")}</td>
                                  </tr>
                                  : null
                              }
                              <tr>
                                <th style={{ width: "400px" }} className="text-capitalize">Payment Requested</th>
                                <td>{currentTransaction.paymentRequested + ""}</td>
                              </tr>
                              {
                                currentTransaction.paymentRequestedDatetime ?
                                  <tr><th style={{ width: "400px" }} className="text-capitalize">Payment Requested Datetime</th>
                                    <td>{moment(MainService.convertUTCDateToLocalDate(new Date(currentTransaction.paymentRequestedDatetime))).format("LL HH:mm")}</td>
                                  </tr>
                                  : null
                              }
                              {
                                currentTransaction.successUrl ?
                                  <tr>
                                    <th style={{ width: "400px" }} className="text-capitalize">Success Url</th>
                                    <td>{currentTransaction.successUrl}</td>
                                  </tr>
                                  : null
                              }
                              {
                                currentTransaction.transactionText ?
                                  <tr>
                                    <th style={{ width: "400px" }} className="text-capitalize">Transaction Text</th>
                                    <td>{currentTransaction.transactionText}</td>
                                  </tr>
                                  : null
                              }
                              {
                                currentTransaction.paymentCustomerDetails ?
                                  <tr>
                                    <th style={{ width: "400px" }} className="text-capitalize">Payment Customer Details</th>
                                    <td>
                                      {
                                        currentTransaction.paymentCustomerDetails.address ?
                                          <div className='mt-1'>
                                            <b>Address:</b>&nbsp;<span>{currentTransaction.paymentCustomerDetails.address}</span>
                                          </div>
                                          : null
                                      }
                                      {
                                        currentTransaction.paymentCustomerDetails.address2 ?
                                          <div className='mt-1'>
                                            <b>Address 2:</b>&nbsp;<span>{currentTransaction.paymentCustomerDetails.address2}</span>
                                          </div>
                                          : null
                                      }
                                      {
                                        currentTransaction.paymentCustomerDetails.city ?
                                          <div className='mt-1'>
                                            <b>City:</b>&nbsp;<span>{currentTransaction.paymentCustomerDetails.city}</span>
                                          </div>
                                          : null
                                      }
                                      {
                                        currentTransaction.paymentCustomerDetails.country ?
                                          <div className='mt-1'>
                                            <b>Country:</b>&nbsp;<span>{currentTransaction.paymentCustomerDetails.country}</span>
                                          </div>
                                          : null
                                      }
                                      {
                                        currentTransaction.paymentCustomerDetails.dateOfBirth ?
                                          <div className='mt-1'>
                                            <b>Date Of Birth:</b>&nbsp;<span>{moment(MainService.convertUTCDateToLocalDate(new Date(currentTransaction.paymentCustomerDetails.dateOfBirth))).format("LL HH:mm")}</span>
                                          </div>
                                          : null
                                      }
                                      {
                                        currentTransaction.paymentCustomerDetails.email ?
                                          <div className='mt-1'>
                                            <b>Email:</b>&nbsp;<span>{currentTransaction.paymentCustomerDetails.email}</span>
                                          </div>
                                          : null
                                      }
                                      {
                                        currentTransaction.paymentCustomerDetails.firstname ?
                                          <div className='mt-1'>
                                            <b>Firstname:</b>&nbsp;<span>{currentTransaction.paymentCustomerDetails.firstname}</span>
                                          </div>
                                          : null
                                      }
                                      {
                                        currentTransaction.paymentCustomerDetails.lastname ?
                                          <div className='mt-1'>
                                            <b>Lastname:</b>&nbsp;<span>{currentTransaction.paymentCustomerDetails.lastname}</span>
                                          </div>
                                          : null
                                      }
                                      {
                                        currentTransaction.paymentCustomerDetails.phoneNumber ?
                                          <div className='mt-1 text-nowrap'>
                                            <b>Phone Number:</b>&nbsp;<span>{MainService.formatPhoneNumber(currentTransaction.paymentCustomerDetails.phoneNumber)}</span>
                                          </div>
                                          : null
                                      }
                                      {
                                        currentTransaction.paymentCustomerDetails.postalCode ?
                                          <div className='mt-1'>
                                            <b>Postal Code:</b>&nbsp;<span>{currentTransaction.paymentCustomerDetails.postalCode}</span>
                                          </div>
                                          : null
                                      }
                                      {
                                        currentTransaction.paymentCustomerDetails.state ?
                                          <div className='mt-1'>
                                            <b>State:</b>&nbsp;<span>{currentTransaction.paymentCustomerDetails.state}</span>
                                          </div>
                                          : null
                                      }
                                      {
                                        currentTransaction.paymentCustomerDetails.title ?
                                          <div className='mt-1'>
                                            <b>Title:</b>&nbsp;<span>{currentTransaction.paymentCustomerDetails.title}</span>
                                          </div>
                                          : null
                                      }
                                    </td>
                                  </tr>
                                  : null
                              }
                              {
                                currentTransaction.paymentMerchantDetails ?
                                  <tr>
                                    <th style={{ width: "400px" }} className="text-capitalize">Payment Merchant Details</th>
                                    <td>
                                      {
                                        currentTransaction.paymentMerchantDetails.cancelButtonText ?
                                          <div className='mt-1'>
                                            <b>Cancel Button Text:</b>&nbsp;<span>{currentTransaction.paymentMerchantDetails.cancelButtonText}</span>
                                          </div>
                                          : null
                                      }
                                      {
                                        currentTransaction.paymentMerchantDetails.language ?
                                          <div className='mt-1'>
                                            <b>Language:</b>&nbsp;<span>{currentTransaction.paymentMerchantDetails.language}</span>
                                          </div>
                                          : null
                                      }
                                      {
                                        currentTransaction.paymentMerchantDetails.logoUrl ?
                                          <div className='mt-1'>
                                            <b>LogoUrl:</b>&nbsp;<span>{currentTransaction.paymentMerchantDetails.logoUrl}</span>
                                          </div>
                                          : null
                                      }
                                      {
                                        currentTransaction.paymentMerchantDetails.successButtonText ?
                                          <div className='mt-1'>
                                            <b>Success Button Text:</b>&nbsp;<span>{currentTransaction.paymentMerchantDetails.successButtonText}</span>
                                          </div>
                                          : null
                                      }
                                    </td>
                                  </tr>
                                  : null
                              }
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                  {
                    currentTransaction.paymentProductInfo && currentTransaction.paymentProductInfo.length ?
                      <Card>
                        <CardBody>
                          <div>
                            <h5>Payment Product Info</h5>
                            <hr />
                            <div className="table-responsive">
                              <Table className="table table-striped mb-0">
                                <thead>
                                  <tr>
                                    <th scope="col">Image</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Code</th>
                                    <th scope="col">Cost</th>
                                    <th scope="col">Discount</th>
                                    <th scope="col">ProductId</th>
                                    <th scope="col">Description</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {currentTransaction.paymentProductInfo.map((item, key) => (
                                    <tr key={key}>
                                      <td style={{ width: "100px" }}>
                                        <img
                                          src={item.imageUrl}
                                          alt="/"
                                          className="avatar-md h-auto d-block rounded"
                                        />
                                      </td>
                                      <td>
                                        <span>{item.name}</span>
                                      </td>
                                      <td>
                                        <span>{item.code}</span>
                                      </td>
                                      <td>
                                        <span>{item.cost}</span>
                                      </td>
                                      <td>
                                        <span>{item.discount}</span>
                                      </td>
                                      <td>
                                        <span>{item.productId}</span>
                                      </td>
                                      <td>
                                        <span>{item.description}</span>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                      : null
                  }
                </>
                : <Card>
                  <CardBody>
                    <NoData />
                  </CardBody>
                </Card>
            }
          </Col>
        </Row>
      </div>
    </>
  )
}

export default withRouter(withTranslation()(TransactionHistoryView))