import React from 'react'
import Breadcrumbs from './../../../components/Common/Breadcrumb'
import { Card, CardBody, Col, Row, } from "reactstrap"
import ReactApexChart from "react-apexcharts"

export default function CryptoBalance() {

  const series = [
    {
      name: "Previous",
      data: [30, 11, 22, 32, 32, 23, 58, 45, 30, 36, 15, 34],
    },
    {
      name: "Current",
      data: [18, 21, 77, 36, 65, 47, 51, 32, 45, 28, 31, 12],
    },
    {
      name: "Next",
      data: [24, 36, 12, 14, 16, 18, 60, 65, 70, 80, 90, 65],
    },
  ]

  const options = {
    chart: {
      height: 350,
      type: "area",
      toolbar: {
        show: false,
      },
    },
    colors: ["#f1b44c", "#556ee6", "#FF0000",],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.45,
        opacityTo: 0.05,
        stops: [20, 100, 100, 100],
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },

    markers: {
      size: 3,
      strokeWidth: 3,

      hover: {
        size: 4,
        sizeOffset: 2,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
    },
  }

  return (
    <div className='page-content'>
      <div className="container-fluid">
        <Breadcrumbs
          title="Account Balances"
          breadcrumbItem="Crypto Balance"
        />
        <Row>
          <Col xl={12}>
            <Card className="mini-stats-wid my-1">
              <CardBody className='p-3'>
                <div className="me-1 d-flex justify-content-between align-items-center">
                  <div className='d-flex justify-content-between w-100 px-5'>
                    <div>
                      <h5 className="mb-1">Crypto balance</h5>
                      <span className="text-truncate">Acc ending in ...0485</span>
                    </div>

                    <div className='d-flex flex-column'>
                      <b className=' font-size-14'>
                        $101.321.123
                      </b>
                      <span className='badge badge-soft-success  font-size-14'>
                        +$3.237.457 USD
                      </span>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <Row>
                  <Col xl={12}>
                    <div className="d-flex flex-wrap">
                      <h5 className="card-title me-2">Today</h5>
                    </div>
                  </Col>
                  <hr className="mb-4" />
                  <Col xl={8}>
                    <div id="area-chart" dir="ltr" className='border'>
                      <ReactApexChart
                        options={options}
                        series={series}
                        type="area"
                        height={350}
                        className="apex-charts"
                      />
                    </div>
                  </Col>
                  <Col xl={4}>
                    <div className='border'>
                      <div className='m-3'>
                        <div className="text-muted">
                          <h5> Crypto balance</h5>
                          <div className="d-flex">
                            <span className='badge badge-soft-success  font-size-14'>
                              $3.237.457 USD
                            </span>
                          </div>
                          <span className="d-block text-truncate mt-1">March 3</span>
                        </div>
                      </div>
                      <hr />
                      <div className='m-3'>
                        <div className="text-muted">
                          <h5>Cash Balance</h5>
                          <div className="d-flex">
                            <span className='badge badge-soft-info  font-size-14'>
                              $5.2373457 USD
                            </span>
                          </div>
                          <span className="d-block text-truncate mt-1">March 3</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div >
  )
}
