import React, { useCallback, useEffect } from 'react'
import Breadcrumbs from './../../../components/Common/Breadcrumb'
import ReactApexChart from "react-apexcharts"
import {
  Card,
  CardBody,
  Col,
  Row,
  Label
} from "reactstrap"
import { Button } from "react-bootstrap"
import { withTranslation } from "react-i18next"
import { ERROR_KEY } from './../../../Constants/MainKeys'
import { addPageSpinner, addRightSectionSpinner, removePageSpinner, removeRightSectionSpinner } from './../../../store/spinner/actions'
import AlertService from '../../../Services/alertService'
import { useDispatch, useSelector } from 'react-redux'
import HtmlHead from '../../../components/HtmlHead/HtmlHead'
import RightSectionSpinner from '../../../components/Spinners/RightSectionSpinner'
import uuid from 'react-uuid'
import ApiService from '../../../Services/ApiService'
import { useState } from 'react'
import moment from 'moment'
import ReactSelectOption from '../../../components/SelectOptions/ReactSelectOption'

const Dashboard = (props) => {

  const { t } = props;
  const dispatch = useDispatch();
  const title = "Dashboard";
  const description = "";
  const { rightSectionSpinners } = useSelector(state => state.Spinners);
  const [data, setData] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(5);
  const { language } = useSelector(state => state.Languages);

  const [apexFields, setApexFields] = useState(null);

  const items = [
    {
      id: 1,
      displayName: "Hourly"
    },
    {
      id: 2,
      displayName: "Daily"
    },
    {
      id: 3,
      displayName: "Weekly"
    },
    {
      id: 4,
      displayName: "Monthly"
    },
    {
      id: 5,
      displayName: "Tri-monthly"
    },
    {
      id: 6,
      displayName: "YTD"
    },
  ]

  useEffect(() => {
    setApexFields({
      options: {
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: ["Jan", "Mar", "Jun", "Sep", "Dec"],
        }
      },
      series: [{
        name: '',
        data: generateRandomNumbers(5)
      }],
    })
  }, []);

  const getMonths = (currentYear) => {
    if (!currentYear) { return false; }
    let givenDateTime = JSON.stringify(currentYear);

    let createdDate = new Date(givenDateTime);
    createdDate.setDate(1);
    let currentDate = new Date();
    let dateAndYearList = [createdDate.toLocaleString('en', { month: 'long' })];

    while (createdDate.setMonth(createdDate.getMonth() + 1) < currentDate) {
      dateAndYearList.unshift(createdDate.toLocaleString('en', {
        month: 'long',
      }));
    }
    return dateAndYearList;
  }

  const generateRandomNumbers = (length) => {
    const numbers = [];

    for (let i = 0; i < length; i++) {
      const randomNumber = Math.floor(Math.random() * (100 - 10 + 1)) + 10;
      numbers.push(randomNumber);
    }

    return numbers;
  }

  const onSelectiOptionChange = (item) => {
    setSelectedItemId(item.value);

    switch (item.value) {
      case 1:
        setApexFields({
          options: {
            dataLabels: {
              enabled: false,
            },
            xaxis: {
              categories: ["01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00", "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00", "00:00"],
            }
          },
          series: [{
            name: '',
            data: generateRandomNumbers(24)
          }],
        })
        break;
      case 2:
        setApexFields({
          options: {
            dataLabels: {
              enabled: false,
            },
            xaxis: {
              categories: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
            }
          },
          series: [{
            name: '',
            data: generateRandomNumbers(7)
          }],
        })
        break;
      case 3:
        setApexFields({
          options: {
            dataLabels: {
              enabled: false,
            },
            xaxis: {
              categories: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
            }
          },
          series: [{
            name: '',
            data: generateRandomNumbers(10)
          }],
        })
        break;
      case 4:
        setApexFields({
          options: {
            dataLabels: {
              enabled: false,
            },
            xaxis: {
              categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            }
          },
          series: [{
            name: '',
            data: generateRandomNumbers(12)
          }],
        })
        break;
      case 5:
        setApexFields({
          options: {
            dataLabels: {
              enabled: false,
            },
            xaxis: {
              categories: ["Jan", "Mar", "Jun", "Sep", "Dec"],
            }
          },
          series: [{
            name: '',
            data: generateRandomNumbers(5)
          }],
        })
        break;
      case 6:
        setApexFields({
          options: {
            dataLabels: {
              enabled: false,
            },
            xaxis: {
              categories: getMonths(new Date().getFullYear()).reverse(),
            }
          },
          series: [{
            name: '',
            data: generateRandomNumbers(getMonths(new Date().getFullYear()).length)
          }],
        })
        break;

      default:
        break;
    }

  }

  useEffect(() => {
    getDashbordData();
  }, [])

  const getDashbordData = () => {
    const spinnerId = uuid();
    setRightSectionSpinner(spinnerId);
    ApiService.getDashboardData().then(response => {
      if (response) {
        setData({ ...response });
      }
      extractRightSectionSpinner(spinnerId)
    }).catch(error => getFail(error, spinnerId))
  }

  const setRightSectionSpinner = useCallback(spinner => {
    dispatch(addRightSectionSpinner(spinner));
  }, []);

  const extractRightSectionSpinner = useCallback(spinner => {
    dispatch(removeRightSectionSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractRightSectionSpinner(spinnerId);
  }

  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className='page-content position-relative min-h-100vh'>
        <RightSectionSpinner spinner={rightSectionSpinners} />
        <Breadcrumbs
          title="Cryllex"
          breadcrumbItem="Dashboard"
        />
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <Row>
                  <Col lg={12}>
                    <div className="d-flex flex-wrap justify-content-between mb-3">
                      <h4 className=" me-2">{moment(new Date()).format("LL")}</h4>
                      {
                        items && items.length ?
                          <div style={{ minWidth: "200px" }}>
                            <ReactSelectOption
                              value={selectedItemId}
                              selectedValue={(() => {
                                const selectedValue = { ...items.find(data => data.id === selectedItemId) };
                                if (selectedValue) {
                                  selectedValue.label = selectedValue.displayName;
                                  selectedValue.value = selectedValue.id;
                                }
                                return selectedValue;
                              })()}
                              items={items.map(data => ({ label: data.displayName, value: data.id }))}
                              onChange={(item) => onSelectiOptionChange(item)}
                            />
                          </div>
                          : null
                      }
                    </div>
                  </Col>
                  <hr className="mb-4" />
                  <Col xl={8}>
                    {
                      apexFields ?
                        <div id="area-chart" dir="ltr" className='border'>
                          <ReactApexChart
                            options={apexFields?.options}
                            series={apexFields?.series}
                            type="area"
                            height={350}
                            className="apex-charts"
                          />
                        </div>
                        : null
                    }
                  </Col>
                  {
                    data ?
                      <Col xl={4}>
                        <div>
                          <div className='mx-3 border px-3 pt-3 pb-2 rounded mb-2'>
                            <div className="text-muted">
                              <div className="d-flex justify-content-between flex-wrap align-items-center">
                                <h5 className='m-0'>Cash Balance</h5>
                                <span className='badge badge-soft-success  font-size-14'>
                                  $ {data.fiat ? data.fiat : 0} USD
                                </span>
                              </div>
                              <Button type='button' size='sm' className='w-100 mt-3 mb-0'>
                                Withdrawal
                              </Button>
                            </div>
                          </div>


                          <div className='mx-3 border px-3 pt-3 pb-2 rounded mb-2'>
                            <div className="text-muted">
                              <div className="d-flex justify-content-between flex-wrap align-items-center">
                                <h5 className='m-0'>Crypto Balance</h5>
                                <span className='badge badge-soft-info  font-size-14'>
                                  $ {data.payouts ? data.payouts : 0} USD
                                </span>
                              </div>
                              <Button type='button' size='sm' className='w-100 mt-3 mb-0'>
                                Withdrawal
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Col>
                      : null
                  }
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col xs={12}>
            <Card>
              <CardBody>
                <Row>
                  <Col xs={12} className='d-flex justify-content-end mb-3'>
                    <Button
                      variant='link'
                      className='underline font-size-18'
                      onClick={() => {
                        props.history.push(`/${language}/analytics`)
                      }}
                    >
                      More Details
                    </Button>
                  </Col>
                  <Col xs={12} md={6}>
                    <div>
                      <p className='font-weight-500 font-size-20'>Total Gross Volume</p>
                      <p className='font-weight-500 font-size-24'><>$103,502</></p>
                    </div>
                    <div>
                      <p className='font-weight-500 font-size-20'>New Customers</p>
                      <p className='font-weight-500 font-size-24 d-flex'>
                        {data && data.newCustomers ? data.newCustomers : 0}
                        <span className='d-block'>
                          <span className='font-weight-500 text-success ms-2'><i className='bx bx-up-arrow-alt font-size-24'></i></span>
                          <span className='font-weight-500 text-success'>18%</span>
                        </span>
                      </p>
                    </div>
                  </Col>
                  <Col xs={12} md={6}>

                    <div className='border rounded p-3 mb-2'>
                      <div className='d-flex justify-content-between align-items-center'>
                        <span className='font-weight-500 font-size-18'>Successful Payments</span>
                        {
                          data && data.failedPayments ?
                            <div className='d-flex justify-content-center font-size-18'>
                              <span className='font-weight-500'>{data.failedPayments}</span>
                              <span className='font-weight-500 text-success ms-2'><i className='bx bx-up-arrow-alt font-size-24'></i></span>
                              <span className='font-weight-500 text-success'>5%</span>
                            </div>
                            : <div className='d-flex justify-content-center font-size-18'>
                              <span className='font-weight-500'>0</span>
                            </div>
                        }
                      </div>
                    </div>
                    <div className='border rounded p-3 mb-2'>
                      <div className='d-flex justify-content-between align-items-center'>
                        <span className='font-weight-500 font-size-18'>Failed payments</span>
                        {
                          data && data.failedPayments ?
                            <div className='d-flex justify-content-center font-size-18'>
                              <span className='font-weight-500'>{data.failedPayments}</span>
                              <span className='font-weight-500 text-success ms-2'><i className='bx bx-up-arrow-alt font-size-24'></i></span>
                              <span className='font-weight-500 text-success'>5%</span>
                            </div>
                            : <div className='d-flex justify-content-center font-size-18'>
                              <span className='font-weight-500'>0</span>
                            </div>
                        }
                      </div>
                    </div>
                    <div className='border rounded p-3 mb-2'>
                      <div className='d-flex justify-content-between align-items-center'>
                        <span className='font-weight-500 font-size-18'>Canceled payments</span>
                        {
                          data && data.cancelledPayments ?
                            <div className='d-flex justify-content-center font-size-18'>
                              <span className='font-weight-500'>{data.cancelledPayments}</span>
                              <span className='font-weight-500 text-danger ms-2'><i className='bx bx-down-arrow-alt font-size-24'></i></span>
                              <span className='font-weight-500 text-danger'>53%</span>
                            </div>
                            : <div className='d-flex justify-content-center font-size-18'>
                              <span className='font-weight-500'>0</span>
                            </div>
                        }
                      </div>
                    </div>

                  </Col>


                </Row>
              </CardBody>
            </Card>
          </Col>


        </Row>
      </div>
    </>
  )
}

export default withTranslation()(Dashboard)