
import React, { useCallback, useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
} from "reactstrap"
import { useDispatch, useSelector } from 'react-redux'
import HtmlHead from '../../../components/HtmlHead/HtmlHead'
import RightSectionSpinner from '../../../components/Spinners/RightSectionSpinner'
import moment from 'moment'
import Table from 'react-bootstrap/Table';
import ApiService from '../../../Services/ApiService';
import uuid from 'react-uuid'
import { addButtonSpinner, addRightSectionSpinner, removeButtonSpinner, removeRightSectionSpinner } from '../../../store/spinner/actions';
import AlertService from '../../../Services/alertService'
import NoData from '../../../components/NoData/NoData'
import ActionButton from '../../../components/Buttons/ActionButton'
import Breadcrumbs from './../../../components/Common/Breadcrumb'

const buttonSpinnerId = uuid();
const ClientInvitations = (props) => {

  const title = "Client Invitations";
  const description = "";
  const pageSize = 20;

  const dispatch = useDispatch();
  const { rightSectionSpinners } = useSelector(state => state.Spinners);
  const { user } = useSelector(state => state.Login);
  const { language, languages } = useSelector(state => state.Languages);

  const [clientInvitations, setClientInvitations] = useState([])
  const [copiedInvitationId, setCopiedInvitationId] = useState(null)

  useEffect(() => {
    getClientInvitations(1);
    return () => {
      setClientInvitations([])
    }
  }, []);

  const getClientInvitations = () => {
    const spinnerId = uuid();
    setRightSectionSpinner(spinnerId);
    ApiService.getClientInvitations().then(response => {
      if (response && response.data) {
        setClientInvitations(response.data);
      }
    }).catch(error => AlertService.alert("error", error)).finally(() => {
      extractRightSectionSpinner(spinnerId)
    })
  };

  const deleteInvitation = (id) => {
    AlertService.alertConfirm(
      `Are you sure ?`,
      "",
      "Yes",
      "No"
    ).then(() => {
      setButtonSpinner(`${buttonSpinnerId}_${id}`);
      ApiService.deleteInvitation(id).then(() => {
        setClientInvitations(clientInvitations.filter(item => item.id !== id));
        AlertService.alert("success", "Data saved");
      }).catch(error => AlertService.alert("error", error)).finally(() => {
        extractButtonSpinner(`${buttonSpinnerId}_${id}`);
      })
    })
  }

  const setRightSectionSpinner = useCallback(spinner => {
    dispatch(addRightSectionSpinner(spinner));
  }, []);

  const extractRightSectionSpinner = useCallback(spinner => {
    dispatch(removeRightSectionSpinner(spinner));
  }, []);

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);


  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className='page-content position-relative min-h-100vh'>
        <RightSectionSpinner spinner={rightSectionSpinners} />
        <div className='container-fluid'>
          <Breadcrumbs
            title="Cryllex"
            breadcrumbItem="Invitations"
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col sm={12} className='mb-3'>
                      <div className='d-flex justify-content-end'>
                        <Button
                          type="button"
                          color="primary"
                          outline
                          className='shadow-sm mt-2 mt-md-0'
                          onClick={() => props.history.push(`/${language}/client-invitation-form`)}
                        >
                          + &nbsp; Add new client
                        </Button>

                      </div>
                      <hr />
                    </Col>
                    <Col sm={12}>


                      {
                        clientInvitations && clientInvitations.length ?
                          <Table striped bordered hover responsive>
                            <thead>
                              <tr>
                                <th className='text-nowrap'>Business Name</th>
                                <th className='text-nowrap'>Email</th>
                                <th className='text-nowrap'>Invitation Sent</th>
                                <th className='text-nowrap'>Client Registered</th>
                                <th className='text-nowrap text-center'>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                clientInvitations.map((item, index) => {
                                  return <tr key={index}>
                                    <td><span className='word-break-break-word max-line-3'>{item.legalBusinessName}</span></td>
                                    <td><span className='word-break-break-word max-line-3'>{item.email}</span></td>
                                    <td><span className='word-break-break-word'>{item.invitationSent ? "Yes" : "No"}</span></td>
                                    <td><span className='word-break-break-word'>{item.clientRegistered ? "Yes" : "No"}</span></td>
                                    <td className='text-center'>
                                      <div className='d-flex gap-2 justify-content-center'>
                                        <Button
                                          type='button'
                                          className='px-3 text-nowrap'
                                          color={copiedInvitationId === item.id ? "success" : "primary"}
                                          onClick={() => {
                                            setCopiedInvitationId(item.id);
                                            navigator.clipboard.writeText(item.agentInviteURL);
                                            setTimeout(() => {
                                              setCopiedInvitationId(null);
                                            }, 1000);
                                          }}
                                        >
                                          {copiedInvitationId === item.id ? "Copied !" : "Copy Link"}
                                        </Button>
                                        <Button
                                          type='button'
                                          className='px-3 text-nowrap'
                                          color="success"
                                          onClick={() => { }}
                                        >
                                          Send Link
                                        </Button>

                                        <ActionButton
                                          type="button"
                                          name="Delete"
                                          color="danger"
                                          className='px-3'
                                          spinnerId={`${buttonSpinnerId}_${item.id}`}
                                          onClick={() => deleteInvitation(item.id)}
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                })
                              }
                            </tbody>
                          </Table>
                          : <NoData />
                      }
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>

          </Row>
        </div>
      </div>
    </>
  );
}

export default ClientInvitations;
