import React, { useCallback, useState, useEffect } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { ERROR_KEY, SUCCESS_KEY } from '../../../Constants/MainKeys'
import { addButtonSpinner, addPageSpinner, removeButtonSpinner, removePageSpinner } from '../../../store/spinner/actions'
import AlertService from '../../../Services/alertService'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ApiService from '../../../Services/ApiService'
import uuid from 'react-uuid'
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  Label,
} from "reactstrap"
import ReactSelectOption from '../../../components/SelectOptions/ReactSelectOption'
import NoData from '../../../components/NoData/NoData'


const PageContents = (props) => {

  const dispatch = useDispatch();
  const { pageId } = props.match.params;
  const [pageContents, setPageContents] = useState([]);
  const { language, languages } = useSelector(state => state.Languages);
  const [languageId, setLanguageId] = useState(1);
  const [dragContentId, setDragContentId] = useState(null);


  useEffect(() => {
    // if (languages && languages.length) {
    //   getPageContents(languages[0].id);
    // }
    getPageContents();
  }, [languages])

  const getPageContents = () => {
    if (!pageId) { return false; }
    const spinnerId = uuid();
    setPageSpinner(spinnerId);
    ApiService.getPageContents(pageId).then(response => {
      if (response && response.data) {
        setPageContents(response.data);
      } else {
        setPageContents([]);
      }
      extractPageSpinner(spinnerId)
    }).catch(error => getFail(error, spinnerId))
  }

  const deleteContent = (currentContent) => {
    const spinnerId = uuid();
    AlertService.alertConfirm(
      `Are you sure you want to delete "${currentContent.name}" page ?`,
      "",
      "Yes",
      "No"
    ).then(() => {
      setPageSpinner(spinnerId);
      ApiService.deleteContent(currentContent.id).then(() => {
        setPageContents(pageContents.filter(content => content.id !== currentContent.id));
        AlertService.alert(SUCCESS_KEY, "Page content successfully deleted")
        extractPageSpinner(spinnerId)
      }).catch(error => getFail(error, spinnerId))
    })
  }

  const drop = (dropContentId) => {
    const spinnerId = uuid();
    if (dropContentId && dragContentId && dropContentId !== dragContentId) {
      setPageSpinner(spinnerId);
      ApiService.onChangePageContentOrder(dropContentId, dragContentId, languageId).then(response => {
        const data = [...response.data];
        setPageContents(data);
        extractPageSpinner(spinnerId)
      }).catch(error => getFail(error, spinnerId));
    }
  }

  const drag = (contentId) => {
    contentId && setDragContentId(contentId);
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const setPageSpinner = useCallback(spinner => {
    dispatch(addPageSpinner(spinner));
  }, []);

  const extractPageSpinner = useCallback(spinner => {
    dispatch(removePageSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractPageSpinner(spinnerId);
    spinnerId && extractButtonSpinner(spinnerId);
  }

  return (
    <div className='page-content position-relative'>
      <div className="container-fluid">
        <Breadcrumbs
          title="Page content"
          breadcrumbItem="Page content"
          isShowGoBackButton={true}
          goBack={() => window.history.back()}
        />

        <div className='my-2'>
          <div className='d-flex justify-content-end'>
            <button className="btn btn-outline-primary" type="button" onClick={() => props.history.push(`/${language}/pages/content-form/${pageId}`)}>
              Create content
            </button>
          </div>
        </div>
        {/* <Card>
          <CardBody>
            <Row>
              <Col md={6}>
                <div className="my-2">
                  <Label>Choose language</Label>
                  <ReactSelectOption
                    value={languageId}
                    isSearchable={false}
                    selectedValue={(() => {
                      const selectedItem = { ...languages.find(data => +data.id === languageId) };
                      if (selectedItem) {
                        selectedItem.label = selectedItem.name;
                        selectedItem.value = selectedItem.id;
                      }
                      return selectedItem;
                    })()}
                    items={languages.map(data => ({ ...data, label: data.name, value: data.id }))}
                    onChange={(item) =>
                      getPageContents(item.id)
                    }
                  />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card> */}
        <Row>
          <Col>
            <CardBody>
              <Card>
                {
                  pageContents && pageContents.length ?
                    <div className="table-responsive mt-4">
                      <Table className="table table-striped mb-0">
                        <thead>
                          <tr>
                            <th>Title</th>
                            <th>Short Content</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            pageContents.map(content => {
                              return <tr
                                key={content.id}
                                draggable={true}
                                className="cursor-pointer"
                                onDrop={() => drop(content.id)}
                                onDragOver={event => event.preventDefault()}
                                onDragStart={() => drag(content.id)}
                              >
                                <td>{content.title}</td>
                                <td>{content.shortContent}</td>
                                <td>
                                  <div className='d-flex'>
                                    <button
                                      className='btn btn-outline-primary px-2 py-1 me-1'
                                      // onClick={() => props.history.push(`/${language}/pages/content-form/${pageId}/${content.id}/${content.languageId}`)}
                                      onClick={() => props.history.push(`/${language}/pages/content-form/${pageId}/${content.id}/${1}`)}
                                      title="Page content"
                                    >
                                      <i className='bx bx-edit-alt' style={{ fontSize: "20px" }} />
                                    </button>
                                    <button
                                      className='btn btn-outline-danger px-2 py-1'
                                      onClick={() => deleteContent(content)}
                                      title="Delet"
                                    >
                                      <i className='bx bx-trash' style={{ fontSize: "20px" }} />
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            })
                          }
                        </tbody>
                      </Table>
                    </div>
                    : <NoData />
                }
              </Card>
            </CardBody>
          </Col>
        </Row>


      </div>
    </div>
  )
}

export default withRouter(PageContents)