import React, { useCallback, useState, useEffect } from 'react'
import Breadcrumbs from './../../../components/Common/Breadcrumb'
import {
  Card,
  CardBody,
  Col,
  Row,
  CardHeader,
  Table,
  Input,
  Label,
  ModalHeader,
  ModalBody,
  Modal
} from "reactstrap"
import * as moment from "moment";
import AlertService from '../../../Services/alertService';
import { addButtonSpinner, addPageSpinner, addRightSectionSpinner, removeButtonSpinner, removePageSpinner, removeRightSectionSpinner } from './../../../store/spinner/actions';
import uuid from 'react-uuid';
import { INACTIVE_ACCOUNT_INFO_MSG, SUCCESS_KEY } from '../../../Constants/MainKeys';
import ApiService from '../../../Services/ApiService';
import { useDispatch, useSelector } from 'react-redux';
import ActionButton from '../../../components/Buttons/ActionButton';
import MainService from '../../../Services/MainService';
import HtmlHead from './../../../components/HtmlHead/HtmlHead';
import RightSectionSpinner from '../../../components/Spinners/RightSectionSpinner';
import NoData from '../../../components/NoData/NoData';


const buttonSpinnerId = uuid();
export default function ApiKeys() {

  const title = "API Keys"
  const description = ""
  const dispatch = useDispatch();
  const { rightSectionSpinners } = useSelector(state => state.Spinners);
  const { user } = useSelector(state => state.Login);
  const passwordMaxLength = 120;
  const [selectedApiKey, setSelectedApiKey] = useState(null);
  const [apiKeys, setApiKeys] = useState(null);
  const [password, setPassword] = useState("");
  const [privateKey, setPrivateKey] = useState("");

  const [apiKeyName, setApiKeyName] = useState("");
  const [isShowApiKeyModal, setIsShowApiKeyModal] = useState(false);
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);

  useEffect(() => {
    getKeys();
  }, [])

  const getKeys = () => {
    const spinnerId = uuid();
    setRightSectionSpinner(spinnerId);
    ApiService.getAllKeys().then((response) => {
      if (response && response.data) {
        const data = [...response.data];
        data.forEach(item => item._token = "");
        setApiKeys(response.data);
      }
      extractRightSectionSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId));
  }

  const generateKeys = () => {
    AlertService.alertConfirm(
      `Are you sure you want to generate new public and privete keys ?`,
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
      "Yes",
      "No"
    ).then(() => {
      const spinnerId = uuid();
      setRightSectionSpinner(spinnerId);
      ApiService.generateKeys().then(() => {
        getKeys();
        AlertService.alert(SUCCESS_KEY, "Public and Private keys generated successfully");
        extractRightSectionSpinner(spinnerId);
        setPrivateKey("");
      }).catch(error => getFail(error, spinnerId));
    })
  }

  const checkPassword = (event) => {
    event && event.preventDefault();
    if (!password.trim().length || !selectedApiKey) { return false; }
    setButtonSpinner(buttonSpinnerId);
    ApiService.checkPassword(btoa(password.trim()), selectedApiKey.publicKey).then(response => {
      if (response && response.data) {
        let item = { ...selectedApiKey };
        item._token = response.data;
        var foundIndex = apiKeys.findIndex(x => x.id == selectedApiKey.id);
        apiKeys[foundIndex] = item;
        setApiKeys(apiKeys);
      }
      setSelectedApiKey(null);
      setPassword("");
      extractButtonSpinner(buttonSpinnerId)
    }).catch(error => getFail(error, buttonSpinnerId));
  }

  const onChange = (event, cb, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    cb(event.target.value);
    setIsInvalidSubmit(false)
  }

  const copyLink = (link) => {
    if (!link) { return; }
    navigator.clipboard.writeText(link);
    AlertService.alert(SUCCESS_KEY, "Copied");
  }

  const cancel = () => {
    setApiKeyName("");
    setIsShowApiKeyModal(false);
  }

  const onSubmit = (event) => {
    event && event.preventDefault();
    if (!apiKeyName.trim().length) {
      setIsInvalidSubmit(true);
      return false;
    }
    setButtonSpinner(buttonSpinnerId);
    ApiService.generateKeys({ name: apiKeyName }).then(() => {
      setIsShowApiKeyModal(false);
      setApiKeyName("");
      getKeys();
      extractButtonSpinner(buttonSpinnerId);
    }).catch(error => getFail(error, buttonSpinnerId))


  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const setRightSectionSpinner = useCallback(spinner => {
    dispatch(addRightSectionSpinner(spinner));
  }, []);

  const extractRightSectionSpinner = useCallback(spinner => {
    dispatch(removeRightSectionSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || "error"), error);
    spinnerId && extractRightSectionSpinner(spinnerId);
    spinnerId && extractButtonSpinner(spinnerId);
    setSelectedApiKey(null);
    setPassword("");
  }

  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className='page-content position-relative min-h-100vh'>
        <RightSectionSpinner spinner={rightSectionSpinners} />
        <div className="container-fluid">
          <Breadcrumbs
            title="Cryllex"
            breadcrumbItem="Api keys"
          />
          <Row>
            <Col xl={12}>
              <Card className='position-relative'>
                {
                  user.isActive ?
                    <CardHeader className="bg-transparent">
                      <div className='d-flex justify-content-between'>
                        <div>
                          <h5 className="mt-2"> Standart Keys</h5>
                          <small>Lorem Ipsum is simply dummy text of the printing and typesetting industry</small>
                        </div>
                        <div className='d-flex align-items-center'>
                          <button
                            className="btn btn-outline-primary d-flex align-items-center"
                            type="button"
                            // onClick={generateKeys}
                            onClick={() => setIsShowApiKeyModal(true)}
                          >
                            <i className='bx bx-plus' ></i> <span className='ms-1'>Create secret key</span>
                          </button>
                        </div>
                      </div>
                      <hr />
                    </CardHeader>
                  :null
                }
                {
                  apiKeys && apiKeys.length ?
                    <CardBody className='pt-0'>
                      {
                        <div className="table-responsive">
                          <Table className="table table-striped mb-0">
                            <thead>
                              <tr className='cursor-default'>
                                <th>Name</th>
                                <th>Public key</th>
                                <th>Token</th>
                                <th>Created</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                apiKeys.map((key, index) => {
                                  return <tr className='cursor-default'>
                                    <td th scope="row" >{key.name}</td>
                                    <td
                                      style={{ maxWidth: "300px" }}
                                      className={`cursor-pointer`}
                                      title="Copy"
                                      onClick={() => copyLink(key.publicKey)}
                                    >
                                      {key.publicKey}
                                    </td>

                                  
                                    <td
                                      style={{ maxWidth: "300px" }}
                                    >
                                      {
                                        key._token ?
                                          <span className='cursor-pointer' onClick={() => key._token && copyLink(key.publicKey)}>{key._token}</span>
                                          : <>
                                            <span > {key.privateKey}</span>
                                            <button
                                              type="button"
                                              className="btn btn-primary btn-sm ms-4"
                                              style={{ padding: "2px 8px" }}
                                              onClick={() => setSelectedApiKey(key)}
                                            >
                                              <span className='font-size-11'>Show</span>
                                            </button>
                                          </>
                                      }
                                    </td>
                                    {
                                      key.createTime ?
                                        <td>{moment(MainService.convertUTCDateToLocalDate(new Date(key.createTime))).format("lll")}</td>
                                        : null
                                    }
                                  </tr>
                                })
                              }

                              {
                                apiKeys.createTime ?
                                  <td>{moment(MainService.convertUTCDateToLocalDate(new Date(apiKeys.createTime))).format("lll")}</td>
                                  : null
                              }

                              {
                                // <tr className='cursor-default'>
                                //   <th scope="row">Private key</th>
                                //   {
                                //     privateKey ?
                                //       <th
                                //         style={{ maxWidth: "300px" }}
                                //         className={`cursor-pointer`}
                                //         title="Copy"
                                //         onClick={() => copyLink(privateKey)}
                                //       >
                                //         {privateKey}
                                //       </th>
                                //       : null
                                //   }
                                //   {
                                //     !privateKey ?
                                //       <th
                                //         style={{ maxWidth: "300px" }}
                                //       >
                                //         <div className='d-flex align-items-center'>
                                //           <span className='text-blur d-block'>
                                //             {apiKeys.privateKey}
                                //           </span>
                                //           <button
                                //             type="button"
                                //             className="btn btn-primary btn-sm ms-4"
                                //             style={{ padding: "2px 8px" }}
                                //             onClick={() => setIsShowCreateSecretKeyModal(true)}
                                //           >
                                //             <span className='font-size-11'>Show</span>
                                //           </button>
                                //         </div>
                                //       </th>
                                //       : null
                                //   }
                                //   <td>
                                //     {
                                //       privateKey ?
                                //         <i
                                //           className='bx bx-copy font-size-18 cursor-pointer'
                                //           onClick={() => copyLink(privateKey)}
                                //         />
                                //         : null
                                //     }
                                //   </td>

                                // </tr>
                              }
                            </tbody>
                          </Table>
                        </div>
                      }
                    </CardBody>
                    : <>
                      <NoData />
                      {
                        !user.isActive ?
                          <p className="my-3 text-center mb-5">{INACTIVE_ACCOUNT_INFO_MSG}</p>
                          : null
                      }
                    </>
                }

              </Card>
            </Col>
          </Row>
          {
            selectedApiKey ?
              <Modal
                isOpen={selectedApiKey}
                toggle={() => { setSelectedApiKey(null) }}
                centered={true}
              >
                <form onSubmit={checkPassword}>
                  <div className="modal-header">
                    <h5 className="modal-title mt-0">Check password</h5>
                    <button
                      type="button"
                      onClick={() => { setSelectedApiKey(null) }}
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="row mb-4 px-3">
                      <label className='ps-0 cursor-pointer' htmlFor="password">Password</label>
                      <input
                        id="password"
                        type="password"
                        className="form-control"
                        placeholder="Please enter your password"
                        value={password}
                        onChange={(event) => onChange(event, setPassword, passwordMaxLength)}
                      />
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => setSelectedApiKey(null)}
                    >
                      Cancel
                    </button>
                    <ActionButton
                      type="submit"
                      name="Check password"
                      className="btn btn-primary"
                      spinnerId={buttonSpinnerId}
                      onClick={checkPassword}
                    />
                  </div>
                </form>
              </Modal>
              : null
          }
        </div>
      </div >
      {
        isShowApiKeyModal ?
          <Modal
            isOpen={true}
            size="lg"
            role="dialog"
            autoFocus={true}
            centered={true}
            tabIndex="-1"
            toggle={cancel}
          >
            <div div className="modal-content" >
              <ModalHeader
                toggle={cancel}
              >
                <span className='font-size-16'>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                </span>
              </ModalHeader>
              <ModalBody>
                <form onSubmit={onSubmit}>

                  <div>
                    <Label
                      htmlFor="apiKeyName"
                      className="form-check-label cursor-pointer mb-2"
                    >
                      Company name*
                    </Label>
                    <Input
                      type="text"
                      className={`form-control ${(isInvalidSubmit && !apiKeyName.trim().length) ? "error-border" : ""}`}
                      value={apiKeyName}
                      onChange={(event) => onChange(event, setApiKeyName, 250)}
                    />
                  </div>
                  <p className='mt-3'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                  <div className='mt-2 d-flex justify-content-end'>
                    <ActionButton
                      type="submit"
                      name="Save"
                      className="btn btn-primary btn-block px-3"
                      spinnerId={buttonSpinnerId}
                    />
                  </div>

                </form>
              </ModalBody>

            </div>
          </Modal >
          : null
      }
    </>
  )
}
