import { OBJECT_KEY, REFRESH_TOKEN_KEY, TOKEN_KEY, USER_KEY } from "./../../../Constants/MainKeys"
import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  AUTH_SUCCESS,
  AUTH_FAIL,
  REMOVE_LOGIN_ERROR_MESSAGE,
  CURRENT_USER_SUCCESS,
  UPDATE_USER_DATA
} from "./actionTypes"

const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  if (typeof JSON.parse(str) !== OBJECT_KEY) {
    return false;
  }
  return true;
}

const initialState = {
  token: localStorage.getItem(TOKEN_KEY),
  error: "",
  loading: true,
  user: localStorage.getItem(USER_KEY) && isJson(localStorage.getItem(USER_KEY)) ? JSON.parse(localStorage.getItem(USER_KEY)) : null,
  refreshToken: localStorage.getItem(REFRESH_TOKEN_KEY),
  loginErrorMessage: ""
}

const login = (state = initialState, action) => {
  switch (action.type) {

    case AUTH_SUCCESS:
      localStorage.setItem(TOKEN_KEY, action.payload.token.data.accessToken);
      action.payload.token.data.refreshToken && localStorage.setItem(REFRESH_TOKEN_KEY, action.payload.token.data.refreshToken);
      return {
        ...state,
        token: action.payload.token,
        refreshToken: action.payload.token.data.refreshToken
      };

    case CURRENT_USER_SUCCESS:
      localStorage.setItem(USER_KEY, JSON.stringify(action.payload));
      return {
        ...state,
        user: action.payload,
      };

    case AUTH_FAIL:
      return {
        ...state,
        loginErrorMessage: action.payload,
      };

    case REMOVE_LOGIN_ERROR_MESSAGE:
      return {
        ...state,
        loginErrorMessage: ''
      };

    case LOGIN_USER:
      return {
        ...state,
        loading: true,
      }

    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case LOGOUT_USER_SUCCESS:
      localStorage.removeItem(USER_KEY);
      localStorage.removeItem(TOKEN_KEY);
      localStorage.removeItem(REFRESH_TOKEN_KEY);

      return {
        ...state,
        user: null,
        token: null,
        refreshToken: null,
      }

    case API_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      }

    case UPDATE_USER_DATA:
      localStorage.setItem("user", JSON.stringify(action.payload));
      return {
        ...state,
        user: action.payload
      };

    default:
      break;
  }
  return state;
}

export default login
