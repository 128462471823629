// -------------------------------------------------------------------------------------
export const IDENTITY_API_URL_KEY = 'https://identity.cryllex.biz/';
export const API_URL_KEY = ['https://api.cryllex.biz/api','https://dashboard-api.cryllex.biz/api'];

// -------------------------------------------------------------------------------------


// -------------------------------------------------------------------------------------
// export const API_URL_KEY = 'http://localhost:17424';
// export const IDENTITY_API_URL_KEY = 'http://localhost:63002/';
// -------------------------------------------------------------------------------------
