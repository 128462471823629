import React from 'react'
import {
  Button,
  Col,
  Row,
  Table
} from "reactstrap"
import MainService from '../../../../Services/MainService';
import moment from 'moment';

export default function LevelThreeRequirements(props) {
  const { data, setZoomImagePath, countries } = props;

  const viewProof = (path) => {
    if (MainService.getFxtensions(path) === "image") {
      setZoomImagePath(path)
    } else {
      window.open(path, "_blank")
    }
  }

  return (data.levelThree ?

    <Col sm={12}>

      <Row>
        <Col sm={6}>
          <Table bordered striped responsive className='mb-0'>
            <tbody>
              <tr>
                <th
                  scope="row"
                  style={{ width: "400px" }}
                  className={"text-capitalize"}
                >
                  Account Name
                </th>
                <td className='w-50'>{data.levelThree?.accountName}</td>
              </tr>
              <tr>
                <th
                  scope="row"
                  style={{ width: "400px" }}
                  className={"text-capitalize"}
                >
                  Account Number
                </th>
                <td className='w-50'>{data.levelThree?.accountNumber}</td>
              </tr>
              <tr>
                <th
                  scope="row"
                  style={{ width: "400px" }}
                  className={"text-capitalize"}
                >
                  Swift Code
                </th>
                <td className='w-50'>{data.levelThree?.swiftCode}</td>
              </tr>
              <tr>
                <th
                  scope="row"
                  style={{ width: "400px" }}
                  className={"text-capitalize"}
                >
                  Country
                </th>
                <td className='w-50'>
                  {
                    countries && countries.length ?
                      countries.map((item, index) => {
                        if (item.id === data.levelThree?.countryId) {
                          return <span key={index}>{item.name}</span>
                        }
                      })
                      : null
                  }
                </td>
              </tr>
              <tr>
                <th
                  scope="row"
                  style={{ width: "400px" }}
                  className={"text-capitalize"}
                >
                  Province
                </th>
                <td className='w-50'>{data.levelThree?.province}</td>
              </tr>
              <tr>
                <th
                  scope="row"
                  style={{ width: "400px" }}
                  className={"text-capitalize"}
                >
                  City
                </th>
                <td className='w-50'>{data.levelThree?.city}</td>
              </tr>
              <tr>
                <th
                  scope="row"
                  style={{ width: "400px" }}
                  className={"text-capitalize"}
                >
                  Routing Type
                </th>
                {
                  data.levelThree?.routingTypeLibraryDataId &&
                    data.levelThree?.routingTypeLibrary &&
                    data.levelThree?.routingTypeLibrary.libraryData &&
                    data.levelThree?.routingTypeLibrary.libraryData.length ?
                    data.levelThree?.routingTypeLibrary.libraryData.map((item, index) => {
                      if (item.id === data.levelThree?.routingTypeLibraryDataId) {
                        return <td key={index} className='w-50'>{item.displayName}</td>
                      }
                    })
                    : <td className='w-50'>Your routing type not set yet.</td>
                }
              </tr>
              <tr>
                <th
                  scope="row"
                  style={{ width: "400px" }}
                  className={"text-capitalize"}
                >
                  Create Date
                </th>
                <td className='w-50'>
                  {moment(MainService.convertUTCDateToLocalDate(new Date(data.levelThree?.createTime))).format("LL HH:mm")}
                </td>
              </tr>



            </tbody>
          </Table>
        </Col>
        <Col sm={6}>
          <Table bordered striped responsive className='mb-0'>
            <tbody>
              <tr>
                <th
                  scope="row"
                  style={{ width: "400px" }}
                  className={"text-capitalize"}
                >
                  Bank Address
                </th>
                <td className='w-50'>{data.levelThree?.bankAddress}</td>
              </tr>
              <tr>
                <th
                  scope="row"
                  style={{ width: "400px" }}
                  className={"text-capitalize"}
                >
                  Bank Address Proof
                </th>
                <td className='w-50'>
                  {
                    !data.levelThree?.bankAddressFile ? "No attached file yet" :
                      <Button
                        color="primary"
                        size="sm"
                        onClick={() => {
                          viewProof(data.levelThree?.bankAddressFile.filePath)
                        }}
                      >
                        View Proof
                      </Button>
                  }
                </td>
              </tr>
              <tr>
                <th
                  scope="row"
                  style={{ width: "400px" }}
                  className={"text-capitalize"}
                >
                  Bank Code
                </th>
                <td className='w-50'>{data.levelThree?.bankCode}</td>
              </tr>
              <tr>
                <th
                  scope="row"
                  style={{ width: "400px" }}
                  className={"text-capitalize"}
                >
                  Bank Name
                </th>
                <td className='w-50'>{data.levelThree?.bankName}</td>
              </tr>
              <tr>
                <th
                  scope="row"
                  style={{ width: "400px" }}
                  className={"text-capitalize"}
                >
                  Branch Address
                </th>
                <td className='w-50'>{data.levelThree?.branchAddress}</td>
              </tr>
              <tr>
                <th
                  scope="row"
                  style={{ width: "400px" }}
                  className={"text-capitalize"}
                >
                  Branch Code
                </th>
                <td className='w-50'>{data.levelThree?.branchCode}</td>
              </tr>
              <tr>
                <th
                  scope="row"
                  style={{ width: "400px" }}
                  className={"text-capitalize"}
                >
                  Branch Name
                </th>
                <td className='w-50'>{data.levelThree?.branchName}</td>
              </tr>
              <tr>
                <th
                  scope="row"
                  style={{ width: "400px" }}
                  className={"text-capitalize"}
                >
                  Active
                </th>
                <td className='w-50'>
                  <span className={`badge badge-soft-${data.levelThree?.isApproved ? "success" : "danger"} font-size-14 badge badge-pill`}>
                    {data.levelThree?.isApproved ? "Yes" : "No"}
                  </span>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </Col>
    : null
  )
}
