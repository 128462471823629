import React, { useEffect, useState } from 'react'
// import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import HtmlHead from '../../../components/HtmlHead/HtmlHead'
import RightSectionSpinner from '../../../components/Spinners/RightSectionSpinner'
import { useSelector } from 'react-redux'
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  CardHeader,
} from "reactstrap"
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import ProgressBar from 'react-bootstrap/ProgressBar';
import NoData from '../../../components/NoData/NoData'


const KeyPerformanceIndicators = () => {

  const title = "Key Performance Indicators";
  const description = "";
  const { rightSectionSpinners } = useSelector(state => state.Spinners);


  return (

    <>
      <HtmlHead title={title} description={description} />
      <div className='page-content position-relative min-h-100vh'>
        <RightSectionSpinner spinner={rightSectionSpinners} />
        <div className="container-fluid">
          <Breadcrumbs
            title="Cryllex"
            breadcrumbItem="Key Performance Indicators"
          />
          <Row>
            <Col lg={12}>

              <Card className="position-relative">
                  <NoData />
              </Card>

            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default KeyPerformanceIndicators