// @flow
import {
  CHANGE_LAYOUT,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_SIDEBAR_THEME,
  CHANGE_SIDEBAR_TYPE,
  CHANGE_TOPBAR_THEME,
  SHOW_RIGHT_SIDEBAR,
  CHANGE_SIDEBAR_THEME_IMAGE,
  CHANGE_PRELOADER,
  TOGGLE_LEFTMENU,
  SHOW_SIDEBAR,
} from "./actionTypes"

const INIT_STATE = {
  layoutType: localStorage.getItem("layoutType") ? localStorage.getItem("layoutType") : "vertical",
  layoutWidth: localStorage.getItem("layoutWidth") ? localStorage.getItem("layoutWidth") : "fluid",
  leftSideBarTheme: localStorage.getItem("leftSideBarTheme") ? localStorage.getItem("leftSideBarTheme") : "dark",
  leftSideBarThemeImage: localStorage.getItem("leftSideBarThemeImage") ? localStorage.getItem("leftSideBarThemeImage") : "none",
  leftSideBarType: localStorage.getItem("leftSideBarType") ? localStorage.getItem("leftSideBarType") : "default",
  topbarTheme: localStorage.getItem("topbarTheme") ? localStorage.getItem("topbarTheme") : "light",
  isPreloader: false,
  showRightSidebar: false,
  isMobile: false,
  showSidebar: true,
  leftMenu: false,
}

const Layout = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_LAYOUT:
      localStorage.setItem("layoutType", action.payload)
      return {
        ...state,
        layoutType: action.payload,
      }
    case CHANGE_PRELOADER:
      return {
        ...state,
        isPreloader: action.payload,
      }

    case CHANGE_LAYOUT_WIDTH:
      localStorage.setItem("layoutWidth", action.payload)
      return {
        ...state,
        layoutWidth: action.payload,
      }
    case CHANGE_SIDEBAR_THEME:
      localStorage.setItem("leftSideBarTheme", action.payload);
      return {
        ...state,
        leftSideBarTheme: action.payload,
      }
    case CHANGE_SIDEBAR_THEME_IMAGE:
      localStorage.setItem("leftSideBarThemeImage", action.payload)
      return {
        ...state,
        leftSideBarThemeImage: action.payload,
      }
    case CHANGE_SIDEBAR_TYPE:
      localStorage.setItem("leftSideBarType", action.payload.sidebarType)
      return {
        ...state,
        leftSideBarType: action.payload.sidebarType,
      }
    case CHANGE_TOPBAR_THEME:
      localStorage.setItem("topbarTheme", action.payload)
      return {
        ...state,
        topbarTheme: action.payload,
      }
    case SHOW_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: action.payload,
      }
    case SHOW_SIDEBAR:
      return {
        ...state,
        showSidebar: action.payload,
      }
    case TOGGLE_LEFTMENU:
      return {
        ...state,
        leftMenu: action.payload,
      }

    default:
      return state
  }
}

export default Layout
