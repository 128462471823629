import React, { useState } from "react"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap"
import { useSelector } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { AGENT_USER_TYPE_ID, MERCHANT_USER_TYPE_ID } from "../../../Constants/MainKeys"
import AlertService from "../../../Services/alertService"

const ProfileMenu = props => {

  const [menu, setMenu] = useState(false)
  const { language } = useSelector(state => state.Languages);
  const { user } = useSelector(state => state.Login);

  const logout = () => {
    AlertService.alertConfirm(
      "Log out",
      "This will log you out of your account. Proceed?",
      "Yes",
      "Close"
    ).then(() => {
      props.history.push(`/${language}/logout`)
    })
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item"
          id="page-header-user-dropdown"
          tag="button"
        >
          {
            user && user.avatarImage ?
              <div
                style={{
                  backgroundImage: `url(${user.avatarImage?.filePath})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center"
                }}
                className="rounded-circle header-profile-user"
              />
              : <i className='bx bx-user' style={{ fontSize: "22px" }} ></i>
          }
        </DropdownToggle>
        {
          user.userTypeId === AGENT_USER_TYPE_ID ?
            <DropdownMenu className="dropdown-menu-end">
              <Link to={`#`} className="dropdown-item" style={{ cursor: "default" }}>
                {
                  user.firstname && user.lastname ?
                    <b>{user.firstname} {user.lastname}</b>
                    : <b>{user.email}</b>
                }
              </Link>
              <hr className="my-1" />
              <Link className="dropdown-item py-2" to={`/${language}/profile`}
                onClick={() => setMenu(false)}
              >
                Profile
              </Link>
              <Link className="dropdown-item py-2" to={`/${language}/kyc`}
                onClick={() => setMenu(false)}
              >
                KYC
              </Link>
              <Link className="dropdown-item py-2" to={`/${language}/profile-settings`}
                onClick={() => setMenu(false)}
              >
                Account Information
              </Link>
              <div className="dropdown-divider" />
              <Link to="#" className="dropdown-item" onClick={logout}>
                <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
                <span>Logout</span>
              </Link>
            </DropdownMenu>
            : user.userTypeId === MERCHANT_USER_TYPE_ID ?
              <DropdownMenu className="dropdown-menu-end">
                <Link to={`#`} className="dropdown-item" style={{ cursor: "default" }}>
                  {
                    user.firstname && user.lastname ?
                      <b>{user.firstname} {user.lastname}</b>
                      : <b>{user.email}</b>
                  }
                </Link>
                <hr className="my-1" />

                <Link className="dropdown-item py-2" to={`/${language}/profile`}
                  onClick={() => setMenu(false)}
                >
                  Profile
                </Link>
                <Link className="dropdown-item py-2" to={`/${language}/merchant/company-information`}
                  onClick={() => setMenu(false)}
                >
                  Company Information
                </Link>
                <Link className="dropdown-item py-2" to={`/${language}/merchant/business-details`}
                  onClick={() => setMenu(false)}
                >
                  Business KYC
                </Link>
                {/* Tigona asel vor comment anem 14.06.2024 */}
                {/* {
                  user.isActive ?
                    <Link className="dropdown-item py-2" to={`/${language}/merchant/business-kyc`}
                      onClick={() => setMenu(false)}
                    >
                      Business KYC
                    </Link>
                    : null
                } */}

                <div className="dropdown-divider" />
                <Link to="#" className="dropdown-item" onClick={logout}>
                  <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
                  <span>Logout</span>
                </Link>
              </DropdownMenu> :
              <DropdownMenu className="dropdown-menu-end">
                <Link to={`#`} className="dropdown-item" style={{ cursor: "default" }}>
                  {
                    user.firstname && user.lastname ?
                      <b>{user.firstname} {user.lastname}</b>
                      : <b>{user.email}</b>
                  }
                </Link>
                <hr className="my-1" />

                <Link className="dropdown-item py-2" to={`/${language}/profile`}
                  onClick={() => setMenu(false)}
                >
                  Profile
                </Link>

                <div className="dropdown-divider" />
                <Link to="#" className="dropdown-item" onClick={logout}>
                  <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
                  <span>Logout</span>
                </Link>
              </DropdownMenu>
        }

      </Dropdown>
    </React.Fragment>
  )
}

export default withRouter(ProfileMenu) 
