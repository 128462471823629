import React, { useState, useEffect } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import {
  Card,
  CardBody,
  Col,
  Row,
  CardHeader,
  Table,
  Button
} from "reactstrap"
import { Link, withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import ReactSelectOption from '../../../components/SelectOptions/ReactSelectOption'
import ApiService from '../../../Services/ApiService'
import { useCallback } from 'react'
import { addRightSectionSpinner, removeRightSectionSpinner } from '../../../store/actions'
import { ADMIN_USER_TYPE_ID, ERROR_KEY, INACTIVE_ACCOUNT_INFO_MSG, MERCHANT_USER_TYPE_ID, SPINNER_COLOR } from '../../../Constants/MainKeys'
import AlertService from '../../../Services/alertService'
import uuid from 'react-uuid'
import * as moment from 'moment'
import ReactPaginate from 'react-paginate'
import MainService from '../../../Services/MainService'
import PuffLoader from "react-spinners/PuffLoader"
import HtmlHead from '../../../components/HtmlHead/HtmlHead'
import RightSectionSpinner from '../../../components/Spinners/RightSectionSpinner';
import NoData from '../../../components/NoData/NoData'
import Auxiliary from '../../../hoc/auxiliary/auxiliary'
import BankAccountInformation from '../BankAccount/BankAccountInformation'
import PayOutDataTable from './Components/PayOutDataTable'

const Payout = (props) => {

  const { itemId } = props.match.params;
  const title = "Payout Information"
  const description = ""
  const pageSize = 10;
  const dispatch = useDispatch();
  const { rightSectionSpinners } = useSelector(state => state.Spinners);
  const { language } = useSelector(state => state.Languages);
  const { user } = useSelector(state => state.Login);
  const [currencies, setCurrencies] = useState([]);

  const [payOutData, setPayOutData] = useState(null);

  useEffect(() => {
    if (itemId) {
      getPayoutById();
      getCurrencies();
    }
  }, [])

  const getPayoutById = () => {
    const spinnerId = uuid();
    setRightSectionSpinner(spinnerId);
    (user.userTypeId === MERCHANT_USER_TYPE_ID ?
      ApiService.getPayoutByIdAsMerchant(itemId) : ApiService.getPayoutByIdAsAdmin(itemId)
    ).then(response => {
      if (response && response.data) {
        setPayOutData(response.data)
      }
      extractRightSectionSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId));
  }

  const getCurrencies = () => {
    const spinnerId = uuid();
    ApiService.getFiatCurrencies().then(response => {
      if (response && response.data) {
        const data = [...response.data];
        setCurrencies(data);
      }
    }).catch(error => getFail(error, spinnerId))
  }

  const setRightSectionSpinner = useCallback(spinner => {
    dispatch(addRightSectionSpinner(spinner));
  }, []);

  const extractRightSectionSpinner = useCallback(spinner => {
    dispatch(removeRightSectionSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractRightSectionSpinner(spinnerId);
  }

  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className='page-content position-relative min-h-100vh'>
        <RightSectionSpinner spinner={rightSectionSpinners} />
        <div className="container-fluid">
          <Breadcrumbs
            title="Cryllex"
            breadcrumbItem="Payout Information"
            isShowGoBackButton={true}
            goBack={() => window.history.back()}
          />
          <Row>
            <Col xl={12} className="mb-2">
              <PayOutDataTable data={payOutData} currencies={currencies} user={user} />
            </Col>
          </Row>
        </div>
      </div >
    </>
  )
}

export default withRouter(Payout)