
import React, { useCallback, useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Row,
  UncontrolledAlert,
} from "reactstrap"
import { useDispatch, useSelector } from 'react-redux'
import HtmlHead from '../../../components/HtmlHead/HtmlHead'
import RightSectionSpinner from '../../../components/Spinners/RightSectionSpinner'
import moment from 'moment'
import Table from 'react-bootstrap/Table';
import ReactPaginate from 'react-paginate'
import ApiService from './../../../Services/ApiService';
import uuid from 'react-uuid'
import { addButtonSpinner, addRightSectionSpinner, removeButtonSpinner, removeRightSectionSpinner } from './../../../store/spinner/actions';
import AlertService from '../../../Services/alertService'
import { ERROR_KEY } from '../../../Constants/MainKeys'
import { useDebounce } from 'use-debounce'
import NoData from '../../../components/NoData/NoData'
import Breadcrumbs from './../../../components/Common/Breadcrumb'

const ClientDatabase = (props) => {

  const title = "Client Database";
  const description = "";

  const dispatch = useDispatch();
  const { rightSectionSpinners } = useSelector(state => state.Spinners);
  const { user } = useSelector(state => state.Login);
  const { language, languages } = useSelector(state => state.Languages);

  const [search, setSearch] = useState("")
  const [organizations, setOrganizations] = useState('')
  const [activePageNumber, setActivePageNumber] = useState(0)
  const [pagination, setPagination] = useState(null)
  const [debouncedSearch] = useDebounce(search, 1000)

  const onChange = (event, cb, maxLength = Infinity) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    cb(event.target.value);
  }

  useEffect(() => {
    getOrganizations(1);
    return () => {
      setOrganizations([])
    }
  }, [debouncedSearch]);

  const handlePageClick = (event) => {
    setActivePageNumber(event.selected);
    getOrganizations(event.selected + 1);
  };

  const getOrganizations = (pageNumber) => {
    const spinnerId = uuid();
    setRightSectionSpinner(spinnerId);
    const data = {
      pageNumber: pageNumber,
      pageSize: 10,
      search: debouncedSearch,
    }
    ApiService.getOrganizationsAsAgent(user.activeAgentId, data).then(response => {
      if (response && response.pagination) setPagination(JSON.parse(response.pagination));
      if (response && response.data) {
        setOrganizations(response.data)
      }
      extractRightSectionSpinner(spinnerId)
    }).catch(error => getFail(error, spinnerId))
  };

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const setRightSectionSpinner = useCallback(spinner => {
    dispatch(addRightSectionSpinner(spinner));
  }, []);

  const extractRightSectionSpinner = useCallback(spinner => {
    dispatch(removeRightSectionSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractRightSectionSpinner(spinnerId);
    spinnerId && extractButtonSpinner(spinnerId);
  }


  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className='page-content position-relative min-h-100vh'>
        <RightSectionSpinner spinner={rightSectionSpinners} />
        <UncontrolledAlert color="info" role="alert">
          View, change and customize your offerings to your clients.
        </UncontrolledAlert>

        <div className='container-fluid'>
          <Breadcrumbs
            title="Cryllex"
            breadcrumbItem="Organizations"
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row className='justify-content-end'>
                    {/* TODO: We need to do this search input in the same way as on other pages, or create a new search type by name, email address or other field. */}
                    {/* <Col md={4}>
                      <div className='app-search'>
                        <div className='position-relative'>
                          <Input
                            id="search"
                            type="search"
                            placeholder='Search'
                            className={`form-control resize-none`}
                            value={search}
                            onChange={event => onChange(event, setSearch, 100)}
                          />
                          <span className="bx bx-search-alt"></span>
                        </div>
                      </div>
                    </Col> */}
                    <Col sm={12}>
                      {
                        organizations && organizations.length ?
                          <Table striped bordered hover responsive>
                            <thead>
                              <tr>
                                <th>Logo</th>
                                <th className='text-norap'>Client ID</th>
                                <th className='text-norap'>Classification</th>
                                <th className='text-norap'>Legal Business Name</th>
                                <th className='text-norap'>Industry</th>
                                <th className='text-norap'>Email</th>
                                <th className='text-norap'>Active</th>
                                <th className='text-norap text-center'>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                organizations.map((item, index) => {
                                  return <tr key={index}>
                                    <td>
                                      {
                                        item.logoFilePath ?
                                          <div className='d-flex justify-content-center align-items-center'>
                                            <img
                                              src={item.logoFilePath}
                                              alt="/"
                                              className="rounded-circle"
                                              height="30"
                                            />
                                          </div>
                                          : <div className='d-flex justify-content-center align-items-center'>
                                            <i className='bx bx-image font-size-30' ></i>
                                          </div>
                                      }
                                    </td>
                                    <td>{item?.uniqueId}</td>
                                    <td>{item?.classification}</td>
                                    <td>{item.organizationName}</td>
                                    <td>{item.industryLibraryData?.displayName}</td>
                                    <td>{item.organizationEmail}</td>
                                    <td>
                                      <span className={`badge badge-soft-${item?.isActive ? "success" : "danger"} font-size-14 badge badge-pill`}>
                                        {item?.isActive ? "Active" : "Inactive"}
                                      </span>
                                    </td>
                                    <td className='text-center'>
                                      <Button
                                        type='button'
                                        color="primary"
                                        className='text-nowrap'
                                        onClick={() => props.history.push(`/${language}/client-database/${item.id}`)}
                                      >
                                        View Organization
                                      </Button>
                                    </td>
                                  </tr>
                                })
                              }



                            </tbody>
                          </Table>
                          : <NoData />
                      }


                      <div className='py-3 d-flex justify-content-end'>
                        {
                          pagination && pagination.TotalPages && pagination.TotalPages > 1 ?
                            <ReactPaginate
                              nextLabel={`Next`}
                              onPageChange={handlePageClick}
                              pageRangeDisplayed={3}
                              marginPagesDisplayed={2}
                              pageCount={pagination.TotalPages}
                              previousLabel={`Previous`}
                              pageClassName="page-item"
                              pageLinkClassName="page-link"
                              previousClassName="page-item"
                              previousLinkClassName="page-link"
                              nextClassName="page-item"
                              nextLinkClassName="page-link"
                              breakLabel="..."
                              breakClassName="page-item"
                              breakLinkClassName="page-link"
                              containerClassName="pagination mb-0"
                              activeClassName="active"
                              renderOnZeroPageCount={null}
                              forcePage={activePageNumber}
                            />
                            : null
                        }
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>

          </Row>
        </div>
      </div>
    </>
  );
}

export default ClientDatabase;
