import React, { useCallback, useEffect, useRef } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  Table,
  CardTitle,
  Label,
  Input
} from "reactstrap"
import { ERROR_KEY, SUCCESS_KEY } from '../../../Constants/MainKeys'
import { addButtonSpinner, addRightSectionSpinner, removeButtonSpinner, removeRightSectionSpinner } from '../../../store/spinner/actions'
import AlertService from '../../../Services/alertService'
import { useDispatch, useSelector } from 'react-redux'
import HtmlHead from '../../../components/HtmlHead/HtmlHead'
import RightSectionSpinner from '../../../components/Spinners/RightSectionSpinner'
import uuid from 'react-uuid'
import ApiService from '../../../Services/ApiService'
import { useState } from 'react'
import MainService from '../../../Services/MainService'
import NoData from '../../../components/NoData/NoData'

const AttachAgent = (props) => {

  const { organizationId } = props.match.params;

  const dispatch = useDispatch();
  const title = "Organizaton Transactions";
  const description = "";
  const { buttonSpinners } = useSelector(state => state.Spinners);
  const { rightSectionSpinners } = useSelector(state => state.Spinners);
  const { language } = useSelector(state => state.Languages);
  const { user } = useSelector(state => state.Login);
  const mainService = new MainService();


  const [organizationData, setOrganizationData] = useState(null);
  const [agentId, setAgentId] = useState(null);
  const [selectedAgentId, setSelectedAgentId] = useState(null);
  const [agents, setAgents] = useState([]);
  const [isDisabledFields, setIsDisabledFields] = useState(false);

  useEffect(() => {
    getAgentsListAsAdmin();
    getOrganizationAttachedAgentId();
  }, [])

  const getAgentsListAsAdmin = () => {
    const spinnerId = uuid();
    setRightSectionSpinner(spinnerId);

    ApiService.getAgentsListAsAdmin().then(response => {
      if (response && response.data) {
        setAgents(response.data)
      }
    }).catch(error => getFail(error)).finally(() => {
      extractRightSectionSpinner(spinnerId);
    })
  }

  const getOrganizationAttachedAgentId = () => {
    if (!organizationId) { return false; }
    const spinnerId = uuid();
    setRightSectionSpinner(spinnerId);
    ApiService.getOrganizationAttachedAgentId(organizationId).then(response => {
      if (response && response.data) {
        setAgentId(response.data)
      }
    }).catch(error => getFail(error)).finally(() => {
      extractRightSectionSpinner(spinnerId);
    })
  }

  const attachOrganizationToAgent = (agent) => {
    if (!agent || !organizationId || isDisabledFields) { return false; }
    AlertService.alertConfirm(
      `Are you sure?`,
      `You want to attach the current organization to the "${agent.legalBusinessName}" agent.`,
      "Yes",
      "No"
    ).then(() => {
      setIsDisabledFields(true);
      let data = {
        agentId: +agent.id,
        organizationId: +organizationId,
      }
      ApiService.attachOrganizationToAgentAsAdmin(data).then(response => {
        setIsDisabledFields(true)
        setAgentId(agent.id);
        setSelectedAgentId(agent.id);
        AlertService.alert(SUCCESS_KEY, `The current organization attached to the "${agent.legalBusinessName}" agent successfully.`)
      }).catch(error => getFail(error)).finally(() => {
        setIsDisabledFields(false);
      })
    })
  }

  const detachAgentFromOrganization = (agent) => {
    if (!agent || !organizationId || isDisabledFields) { return false; }
    AlertService.alertConfirm(
      `Are you sure?`,
      `You want to detach the current organization to the "${agent.legalBusinessName}" agent.`,
      "Yes",
      "No"
    ).then(() => {
      setIsDisabledFields(true);
      ApiService.detachAgentFromOrganizationAsAdmin(organizationId, agent.id).then(() => {
        setIsDisabledFields(true)
        setAgentId(null);
        AlertService.alert(SUCCESS_KEY, `The current organization attached to the "${agent.legalBusinessName}" agent successfully.`)
      }).catch(error => getFail(error)).finally(() => {
        setIsDisabledFields(false);
      })
    })
  }

  const setRightSectionSpinner = useCallback(spinner => {
    dispatch(addRightSectionSpinner(spinner));
  }, []);

  const extractRightSectionSpinner = useCallback(spinner => {
    dispatch(removeRightSectionSpinner(spinner));
  }, []);

  const getFail = (error) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
  }

  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className='page-content position-relative min-h-100vh'>
        <RightSectionSpinner spinner={rightSectionSpinners} />
        <div className="container-fluid">
          <Breadcrumbs
            title="Cryllex"
            breadcrumbItem="Attach Agent"
            isShowGoBackButton={true}
            goBack={() => {
              window.history.back();
            }}
          />



          {
            agents && agents.length ?
              <Row className='align-items-stretch'>
                {
                  agents.map((item, index) => {
                    return <Col key={index} md={6} xl={4} lg={6}>
                      <Card>
                        <CardBody>

                          <div className='d-flex flex-column justify-content-between h-100'>

                            <div className='avatar-md rounded-circle img-thumbnail d-flex align-items-center justify-content-center mb-4'>
                              <i className='bx bx-briefcase-alt' style={{ fontSize: "35px" }} />
                            </div>

                            <div>
                              <h5 className="fs-17 mb-2">
                                <a className="text-dark" href="/job-details">{item.legalBusinessName}</a>
                              </h5>
                              <ul className="list-inline mb-0">
                                <li className="list-inline-item">
                                  <p className="text-muted fs-14 mb-1">Country of regitsration: </p>
                                </li>
                                <li className="list-inline-item">
                                  <p className="text-muted fs-14 mb-0">
                                    <i className="mdi mdi-map-marker"></i>
                                    {item.country}
                                  </p>
                                </li>
                              </ul>
                            </div>


                            <div className="mt-4 hstack gap-2">
                              <Button
                                type="button"
                                color="info"
                                className='w-100'
                                onClick={() => { props.history.push(`/${language}/profile-settings/${item.id}`) }}
                              >
                                View profile
                              </Button>
                              <Button
                                type="button"
                                color={agentId === item.id ? "danger" : "success"}
                                disabled={isDisabledFields}
                                className='w-100'
                                onClick={() => {
                                  if (agentId === item.id) {
                                    detachAgentFromOrganization(item);
                                  } else {
                                    attachOrganizationToAgent(item);
                                  }
                                }}
                              >
                                {
                                  agentId === item.id ? "Detach " : "Attach"
                                }

                              </Button>

                            </div>
                          </div>
                        </CardBody>

                      </Card>
                    </Col>
                  })
                }
              </Row>


              :


              <Card>
                <CardBody>
                  <NoData />
                  <p className="my-3 text-center"><b>No Data</b></p>
                </CardBody>
              </Card>
          }
        </div>
      </div>
    </>
  )
}

export default AttachAgent

