import React from 'react';
import lottieJson from "./../../assets/videos/nodata.json"
import Lottie from 'react-lottie-player'

export default function NoData(props) {

  const { width, height } = props;
  return <div>
    <Lottie
      play
      loop
      animationData={lottieJson}
      style={{ width: width ? width : 250, height: height ? height : 250, margin: "0 auto" }}
    />
  </div>
}
