import React, { useState } from 'react'
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from "reactstrap"
import Breadcrumbs from './../../../components/Common/Breadcrumb'
import { withRouter } from "react-router-dom"
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { ERROR_KEY } from './../../../Constants/MainKeys'
import AlertService from '../../../Services/alertService'
import { addPageSpinner, removePageSpinner } from '../../../store/spinner/actions'


const CryptoRefunds = (props) => {

  const data = [
    {
      reference: "REF: HT4578KI",
      name: "Dob Dick",
      amount: "$128.36USD",
      date: "08/11/2021",
    },
    {
      reference: "REF: GHJ4578KI",
      name: "Paul Christian",
      amount: "$45.17USD",
      date: "08/11/2021",
    },
    {
      reference: "REF: DUL78552F",
      name: "Silas Hudson",
      amount: "$1000.00USD",
      date: "08/11/2021",
    },
    {
      reference: "REF: DUL78552F",
      name: "Bella Quinn",
      amount: "$553.99USD",
      date: "08/11/2021",
    },
  ]

  const dispatch = useDispatch();

  const [currentItem, setCurrentItem] = useState(null);

  const setPageSpinner = useCallback(spinner => {
    dispatch(addPageSpinner(spinner));
  }, []);

  const extractPageSpinner = useCallback(spinner => {
    dispatch(removePageSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractPageSpinner(spinnerId);
  };

  return (
    <div className='page-content'>
      <div className="container-fluid">
        <Breadcrumbs title="Cryllex" breadcrumbItem="Transactions History" />
        <Row className="mb-3">
          <div className="col-md-5">
            <input
              className="form-control"
              type="search"
              placeholder='Search'
            />
          </div>
        </Row>
        <Row>
          <Col xl={12}>
            <Card>
              <CardBody>
                <div className="table-responsive">
                  <Table className="table table-striped mb-0">
                    <thead>
                      <tr className='cursor-default'>
                        <th>Reference No.</th>
                        <th>Name</th>
                        <th>Amount</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        data.map((item, index) => {
                          return <tr key={index} className='cursor-default'>
                            <th scope="row">{item.reference}</th>
                            <td>{item.name}</td>
                            <td className='text-success'>
                              <b>{item.amount}</b>
                            </td>
                            <td>{item.date}</td>
                            <td>
                              <button
                                className="btn btn-info btn-sm cursor-pointer"
                                onClick={() => setCurrentItem(item)}
                              >
                                Refund
                              </button>
                            </td>
                          </tr>
                        })
                      }
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {
          currentItem ?
            <Modal
              isOpen={true}
              size="sm"
              role="dialog"
              autoFocus={true}
              centered={true}
              className="exampleModal"
              tabIndex="-1"
              toggle={() => {
                setCurrentItem(null)
              }}
            >
              <div className="modal-content">
                <ModalHeader
                  toggle={() => {
                    setCurrentItem(null)
                  }}
                >
                  {currentItem.reference} <br /> {currentItem.name}
                </ModalHeader>
                <ModalBody>
                  <div className='row d-flex justify-content-center'>
                    <div className='col-12'>
                      <h5 className='text-center'>
                        Refund amount
                      </h5>
                    </div>
                    <div className='col-8'>
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="Enter value"
                      />
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter className='d-flex justify-content-around'>
                  <Button type="button" color="danger" className='shadow-sm' onClick={() => setCurrentItem(null)}>
                    Cancel
                  </Button>
                  <Button type="button" color="success" className='shadow-sm'>
                    Submit
                  </Button>
                </ModalFooter>
              </div>
            </Modal>
            : null
        }
      </div>
    </div>
  )
}

export default withRouter(CryptoRefunds)
