import React, { useCallback, useState, useEffect } from 'react'
import Breadcrumbs from './../../../components/Common/Breadcrumb'
import { ERROR_KEY, SUCCESS_KEY } from './../../../Constants/MainKeys'
import { addButtonSpinner, addPageSpinner, removeButtonSpinner, removePageSpinner } from './../../../store/spinner/actions'
import AlertService from '../../../Services/alertService'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ApiService from '../../../Services/ApiService'
import uuid from 'react-uuid'
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap"
import NoData from '../../../components/NoData/NoData'

const Pages = (props) => {

  const dispatch = useDispatch();
  const { language } = useSelector(state => state.Languages);
  const [pages, setPages] = useState([]);
  const [dragPageId, setPageId] = useState(null);

  useEffect(() => {
    getPages();
  }, [])

  const getPages = () => {
    const spinnerId = uuid();
    setPageSpinner(spinnerId);
    ApiService.getPagesAsAdmin().then(response => {
      if (response && response.data) {
        setPages(response.data);
      } else {
        setPages([]);
      }
      extractPageSpinner(spinnerId)
    }).catch(error => getFail(error, spinnerId))
  }

  const deletePage = (currentPage) => {
    const spinnerId = uuid();
    AlertService.alertConfirm(
      `Are you sure you want to delete "${currentPage.name}" page ?`,
      "",
      "Yes",
      "No"
    ).then(() => {
      setPageSpinner(spinnerId);
      ApiService.deletePage(currentPage.id).then(() => {
        setPages(pages.filter(page => page.id !== currentPage.id));
        AlertService.alert(SUCCESS_KEY, "Page successfully deleted")
        extractPageSpinner(spinnerId)
      }).catch(error => getFail(error, spinnerId))
    })
  }

  const drop = (dropPageId) => {
    const spinnerId = uuid();
    if (dropPageId && dragPageId && dropPageId !== dragPageId) {
      setPageSpinner(spinnerId);
      ApiService.onChangePageOrder(dropPageId, dragPageId).then(response => {
        if (response && response.data) {
          const data = [...response.data];
          setPages(data);
        }
        extractPageSpinner(spinnerId)
      }).catch(error => getFail(error, spinnerId));
    }
  }

  const drag = (contentId) => {
    contentId && setPageId(contentId);
  };

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const setPageSpinner = useCallback(spinner => {
    dispatch(addPageSpinner(spinner));
  }, []);

  const extractPageSpinner = useCallback(spinner => {
    dispatch(removePageSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractPageSpinner(spinnerId);
    spinnerId && extractButtonSpinner(spinnerId);
  }

  return (
    <div className='page-content position-relative'>
      <div className="container-fluid">
        <Breadcrumbs
          title="Pages"
          breadcrumbItem="Pages"
        />

        <div className='my-2'>
          <div className='d-flex justify-content-end'>
            <button className="btn btn-outline-primary" type="button" onClick={() => props.history.push(`/${language}/pages/form`)}>
              Create page
            </button>
          </div>
        </div>

        <Row>
          <Col>
            {
              pages && pages.length ?
                <CardBody>
                  <Card>
                    <div className="table-responsive mt-4">
                      <Table className="table mb-0">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Uri</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            pages.map(page => {
                              return <tr
                                key={page.id}
                                draggable={true}
                                className="cursor-pointer"
                                onDrop={() => drop(page.id)}
                                onDragOver={event => event.preventDefault()}
                                onDragStart={() => drag(page.id)}
                              >
                                <td>{page.name}</td>
                                <td>{page.uri}</td>
                                <td>
                                  <div className='d-flex'>
                                    <button
                                      className='btn btn-outline-primary px-2 py-1 me-1'
                                      onClick={() => props.history.push(`/${language}/pages/content/${page.id}`)}
                                      title="Page content"
                                    >
                                      <i className='bx bx-book-content' style={{ fontSize: "20px" }} />
                                    </button>
                                    <button
                                      className='btn btn-outline-primary px-2 py-1 me-1'
                                      onClick={() => props.history.push(`/${language}/pages/form/${page.id}`)}
                                      title="Edit"
                                    >
                                      <i className='bx bx-edit-alt' style={{ fontSize: "20px" }} />
                                    </button>
                                    <button
                                      className='btn btn-outline-danger px-2 py-1'
                                      onClick={() => deletePage(page)}
                                      title="Delet"
                                    >
                                      <i className='bx bx-trash' style={{ fontSize: "20px" }} />
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            })
                          }
                        </tbody>
                      </Table>
                    </div>
                  </Card>
                </CardBody>
                : <>
                  <hr />
                  <NoData />
                </>
            }
          </Col>
        </Row>


      </div>
    </div>
  )
}

export default withRouter(Pages)