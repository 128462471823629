import React from 'react'

export default function OffIcon() {
  return (
    <div className='d-flex justify-content-center align-items-center h-100'>
      <i
        className='bx bx-x'
        style={{
          color: "#ffffff",
          fontSize: "20px",
          height: "100%",
          lineHeight: "27px"
        }}
      />
    </div>
  )
}