import React, { useCallback, useState, useEffect } from 'react'
import Breadcrumbs from './../../../components/Common/Breadcrumb'
import { ERROR_KEY, SUCCESS_KEY } from './../../../Constants/MainKeys'
import { addButtonSpinner, addPageSpinner, removeButtonSpinner, removePageSpinner } from './../../../store/spinner/actions'
import AlertService from '../../../Services/alertService'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  Input,
  Label,
  Col
} from "reactstrap"
import uuid from 'react-uuid'
import ReactSelectOption from './../../../components/SelectOptions/ReactSelectOption';
import ActionButton from '../../../components/Buttons/ActionButton'
import ApiService from '../../../Services/ApiService'

const buttonSpinnerId = uuid();
const menuGroups = [
  {
    id: 1,
    name: "Header"
  },
  {
    id: 2,
    name: "Right menu"
  },
  {
    id: 3,
    name: "Footer"
  },
  {
    id: 4,
    name: "Left menu"
  },
];

const PageForm = (props) => {

  const dispatch = useDispatch();
  const { pageId } = props.match.params;
  const [pages, setPages] = useState([]);
  const [pageData, setPageData] = useState(null);
  const { language } = useSelector(state => state.Languages);
  const [values, setValues] = useState({
    name: "",
    parentId: null,
    menuGroupId: null,
    uri: "",
    enabled: false,
    pageTitle: "",
    metaContent: ""
  })

  useEffect(() => {
    getPages();
    if (pageId) { getPageById() }
  }, [])

  const onChange = (event, name, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    setValues((values) => ({
      ...values,
      [name]: event.target.value,
    }));
  }

  const getPages = () => {
    const spinnerId = uuid();
    setPageSpinner(spinnerId);
    ApiService.getPagesAsAdmin().then(response => {
      if (response && response.data) {
        setPages(response.data);
      } else {
        setPages([]);
      }
      extractPageSpinner(spinnerId)
    }).catch(error => getFail(error, spinnerId))
  }

  const getPageById = () => {
    if (!pageId) { return false; }
    const spinnerId = uuid();
    setPageSpinner(spinnerId);
    ApiService.getPageById(pageId).then(response => {
      if (response && response.data) {
        const data = { ...response.data };
        setPageData(response.data);
        setValues((values) => ({
          ...values,
          name: data.name,
          parentId: data.parentId,
          menuGroupId: data.menuGroupId,
          uri: data.uri,
          enabled: data.enabled,
          pageTitle: data.pageTitle,
          metaContent: data.metaContent
        }));
      }
      extractPageSpinner(spinnerId)
    }).catch(error => getFail(error, spinnerId))
  }

  const onCheckboxChange = (event, name) => {
    setValues((values) => ({
      ...values,
      [name]: event.target.checked,
    }));
  }

  const onSelectOptionChange = (item, name) => {
    if (!item) { return false; }
    setValues((values) => ({
      ...values,
      [name]: item.value ? item.value : item,
    }));
  }

  const onSubmit = (event) => {
    event && event.preventDefault();
    setButtonSpinner(buttonSpinnerId);
    const form = { ...values }
    if (pageId) { form.id = +pageId }
    (pageId ? ApiService.updatePage(form) : ApiService.createPage(form)).then(response => {
      extractButtonSpinner(buttonSpinnerId);
      if (pageId) {
        AlertService.alert(SUCCESS_KEY, "Page updated successfully");
      } else {
        AlertService.alert(SUCCESS_KEY, "Page created successfully");
      }
      props.history.push(`/${language}/pages`)
    }).catch(error => getFail(error, buttonSpinnerId));
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);


  const setPageSpinner = useCallback(spinner => {
    dispatch(addPageSpinner(spinner));
  }, []);

  const extractPageSpinner = useCallback(spinner => {
    dispatch(removePageSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractPageSpinner(spinnerId);
    spinnerId && extractButtonSpinner(spinnerId);
  }

  return (
    <div className='page-content position-relative'>
      <div className="container-fluid">
        <Breadcrumbs
          title="Page form"
          breadcrumbItem="Page form"
          isShowGoBackButton={true}
          goBack={() => window.history.back()}
        />
        <>
          <form className='row' onSubmit={onSubmit}>
            <Col md={6}>
              <div className='mb-2'>
                <Label className='text-muted text-truncate mb-0'>Name *</Label>
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  value={values.name}
                  required={true}
                  onChange={(event) => onChange(event, "name", 200)}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className='mb-2'>
                <Label className='text-muted text-truncate mb-0'>Uri</Label>
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Uri"
                  value={values.uri}
                  onChange={(event) => onChange(event, "uri", 200)}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className='mb-2 mt-2 mt-md-0 h-100 d-md-flex align-items-center'>
                <div className="form-check mb-3">
                  <Input
                    id="enabled"
                    name="enabled"
                    className="form-check-input"
                    type="checkbox"
                    checked={values.enabled}
                    onChange={(event) => onCheckboxChange(event, "enabled")}
                  />
                  <Label
                    className="form-check-label cursor-pointer"
                    htmlFor="enabled"
                  >
                    Enabled
                  </Label>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className='mb-2'>
                <Label className='text-muted text-truncate mb-0'>Page Title</Label>
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Page Title"
                  value={values.pageTitle}
                  onChange={(event) => onChange(event, "pageTitle", 200)}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mt-2">
                <Label className='text-muted text-truncate mb-0'>Parent page</Label>
                <ReactSelectOption
                  value={values.parentId}
                  isSearchable={false}
                  selectedValue={(() => {
                    const selectedItem = { ...pages.find(data => +data.id === +values.parentId) };
                    if (selectedItem) {
                      selectedItem.label = selectedItem.name;
                      selectedItem.value = selectedItem.id;
                    }
                    return selectedItem;
                  })()}
                  items={pages.map(data => ({ ...data, label: data.name, value: data.id }))}
                  onChange={(item) => onSelectOptionChange(item, "parentId")}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mt-2">
                <Label className='text-muted text-truncate mb-0'>Menu groups</Label>
                <ReactSelectOption
                  value={values.menuGroupId}
                  isSearchable={false}
                  selectedValue={(() => {
                    const selectedItem = { ...menuGroups.find(data => +data.id === +values.menuGroupId) };
                    if (selectedItem) {
                      selectedItem.label = selectedItem.name;
                      selectedItem.value = selectedItem.id;
                    }
                    return selectedItem;
                  })()}
                  items={menuGroups.map(data => ({ ...data, label: data.name, value: data.id }))}
                  onChange={(item) => onSelectOptionChange(item, "menuGroupId")}
                />
              </div>
            </Col>
            <div className='my-3'>
              <Label className='text-muted text-truncate mb-0'>Meta content</Label>
              <Input
                type="textarea"
                className="form-control"
                placeholder="Meta content"
                value={values.metaContent}
                rows="8"
                onChange={(event) => onChange(event, "metaContent")}
              />
            </div>
            <Col>
              <ActionButton
                type="submit"
                name={`${pageId ? "Update" : "Create"}`}
                className="shadow-sm ms-auto px-4 btn btn-primary"
                spinnerId={buttonSpinnerId}
              />
            </Col>
          </form>
        </>
      </div>
    </div>
  )
}

export default withRouter(PageForm)