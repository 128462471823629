import React, { useCallback, useEffect } from 'react'
import Breadcrumbs from './../../../components/Common/Breadcrumb'
import ReactApexChart from "react-apexcharts"
import {
  Card,
  CardBody,
  Col,
  Row,
  Label,
  Button,
  Table
} from "reactstrap"
import { ALL_VALID_FILE_TYPES, ERROR_KEY, SUCCESS_KEY, VALID_FILE_TYPES_KEY, VALID_IMAGE_TYPES_KEY } from './../../../Constants/MainKeys'
import { addButtonSpinner, addPageSpinner, addRightSectionSpinner, removeButtonSpinner, removePageSpinner, removeRightSectionSpinner } from './../../../store/spinner/actions'
import { useDispatch, useSelector } from 'react-redux'
import HtmlHead from './../../../components/HtmlHead/HtmlHead'
import RightSectionSpinner from './../../../components/Spinners/RightSectionSpinner'
import uuid from 'react-uuid'
import ApiService from './../../../Services/ApiService'
import { useState } from 'react'
import ReactSelectOption from './../../../components/SelectOptions/ReactSelectOption'
import MainService from './../../../Services/MainService'
import ActionButton from './../../../components/Buttons/ActionButton'
import AlertService from './../../../Services/alertService';
import Auxiliary from './../../../hoc/auxiliary/auxiliary';
import OnIcon from './../../../components/OnIcon/OnIcon';
import OffIcon from './../../../components/OffIcon/OffIcon';
import Switch from "react-switch"

const buttonSpinnerId = uuid();

const ProfileSettings = (props) => {

  const { agentId } = props.match.params;
  const dispatch = useDispatch();
  const mainService = new MainService();
  const title = "View KYC";
  const description = "";
  const { rightSectionSpinners } = useSelector(state => state.Spinners);
  const { user } = useSelector(state => state.Login);
  const [isForm, setIsForm] = useState(false);
  const [isDisabledFields, setIsDisabledFields] = useState(false);


  const [values, setValues] = useState({
    agentBusinessName: "Lorem",
    rampCounterpartyId: 1,
    rampCounterpartyAPIKeyId: 2,
    OTCOnRampCounterpartyBank: [{
      bankAccountNumber: "Lorem 1",
      address: "Lorem 1",
      addressDocumentation: null,
      bankAccountRegion: "Lorem 1",
      SWIFTCode: "Lorem 1",
    }],
    defaultOnRampCounterpartyId: 1,
    defaultOffRampCounterpartyId: 1,
    agentBankProviderInformation: [{
      bankName: "Lorem 1",
      bankCode: "Lorem 1",
      bankAddress: null,
      domesticSystemPaymentId: "Lorem 1",
      SWIFTCode: "Lorem 1"
    }],
    agentBankAccountInformation: [{
      agentBankAccountName: "Lorem 1",
      bankAccountNumber: "Lorem 1",
      bankAccountAddress: null,
      domesticSystemPaymentId: "Lorem 1",
      SMSVerificationCode: "Lorem 1"
    }],
    agentBankAccountAPIKeys: ["Lorem 1"],
    defaultBankAccountForOutwardRemittanceToClientId: 1,
    defaultBankAccountForInwardRemittanceFromClientId: 1,
  })

  const [_defaultValues, _setDefaultValues] = useState({
    id: null,
    agentId: "",
    bankAccountRegion: null,
    legalBusinessName: "",
    defaultOffRampCounterpartyLibId: null,
    defaultOnRampCounterpartyLibId: null,
    rampCounterpartyIDLibId: null,
    swift: null,
    agentBanks: [],
    defaultOnRampCounterparty: null,
    defaultOffRampCounterparty: null,
    rampConterparty: null,
  })

  const [_values, _setValues] = useState({
    id: null,
    agentId: "",
    bankAccountRegion: null,
    legalBusinessName: "",
    defaultOffRampCounterpartyLibId: null,
    defaultOnRampCounterpartyLibId: null,
    rampCounterpartyIDLibId: null,
    swift: null,
    agentBanks: [],
    defaultOnRampCounterparty: null,
    defaultOffRampCounterparty: null,
    rampConterparty: null,
  })

  useEffect(() => {
    getAgentProfile();
  }, []);

  const getAgentProfile = () => {
    const spinnerId = uuid();
    setRightSectionSpinner(spinnerId);
    (agentId ? ApiService.getAgentProfileAsAdmin(agentId) : ApiService.getAgentProfileAsAgent(user.id)).then(response => {
      if (response && response.data) {
        const data = { ...response.data };
        _setValues(data);
        _setDefaultValues(data)
      }
      extractRightSectionSpinner(spinnerId)
    }).catch(error => getFail(error, spinnerId))
  };

  const _onChangeFieldsInObj = (event, field, index, fieldName) => {
    _setValues(prevState => {
      const updatedField = [...prevState[field]];
      updatedField[index] = {
        ...updatedField[index],
        [fieldName]: event.target.value,
      };
      return {
        ...prevState,
        [field]: updatedField,
      };
    });
  };

  const _onChange = (event, field) => {
    _setValues((values) => ({
      ...values,
      [field]: event.target.value
    }))
  }

  const _uploadFile = async (event, field, index, fieldName) => {
    if (!event.target.files.length) { return; }
    let files = [...event.target.files];
    for (let i in files) {
      const file = files[i];
      const fileName = file.name;
      const lastDotIndex = fileName.lastIndexOf('.');
      const fileExtention = lastDotIndex !== -1 ? fileName.substring(lastDotIndex + 1).toLowerCase() : ' ';
      if (ALL_VALID_FILE_TYPES.includes(fileExtention)) {
        let fileType = null;
        if (VALID_IMAGE_TYPES_KEY.includes(fileExtention)) {
          fileType = "image";
        }
        else if (VALID_FILE_TYPES_KEY.includes(fileExtention)) {
          fileType = "file";
        }
        await mainService.readFile(file, ALL_VALID_FILE_TYPES).then(uploadedFile => {

          _setValues(prevState => {
            const updatedField = [...prevState[field]];
            updatedField[index] = {
              ...updatedField[index],
              [fieldName]: {
                fileName,
                fileType,
                file,
                uploadedFile,
                id: uuid(),
                size: file.size
              },
            };

            return {
              ...prevState,
              [field]: updatedField,
            };
          });
        }).catch(error => error && AlertService.alert("error", "Invalid file format"));
      } else {
        AlertService.alert("error", "Invalid file format")
        return false;
      }
    }
  };

  const onChangeIsActive = () => {

    AlertService.alertConfirm(
      `Are you sure?`,
      "You want to update status.",
      "Yes",
      "No"
    ).then(() => {
      setIsDisabledFields(true)
      const data = {
        id: _values.id,
        updaterUserId: null,
        status: !values.isActive,
      }
      ApiService.organizationUpdateStatusAsAgent(data).then(() => {
        _setValues((value) => ({
          ...value,
          isActive: !_values.isActive
        }))
        AlertService.alert(SUCCESS_KEY, "Data saved")
      }).catch(error => getFail(error)).finally(() => {
        setIsDisabledFields(false)
      })
    })
  }

  const _onSelectOptionsChange = (item, field) => {
    if (!item) { return false };
    _setValues((values) => ({
      ...values,
      [field]: item.value
    }))
  }

  const onSubmit = () => {
    setButtonSpinner(buttonSpinnerId);
    ApiService.updateAgentProfile(_values).then(response => {
      AlertService.alert(SUCCESS_KEY, "Data saved");
    }).catch(error => getFail(error)).finally(() => {
      setIsForm(false);
      extractButtonSpinner(buttonSpinnerId);
      scrollTop();
    })
  }

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const setRightSectionSpinner = useCallback(spinner => {
    dispatch(addRightSectionSpinner(spinner));
  }, []);

  const extractRightSectionSpinner = useCallback(spinner => {
    dispatch(removeRightSectionSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractRightSectionSpinner(spinnerId);
    spinnerId && extractButtonSpinner(spinnerId);
  }

  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className='page-content position-relative min-h-100vh'>
        <RightSectionSpinner spinner={rightSectionSpinners} />
        <div className='container-fluid'>
          {
            agentId ?
              <Breadcrumbs
                title="Cryllex"
                breadcrumbItem="Account Information"
                isShowGoBackButton={true}
                goBack={() => window.history.back()}
              />
              : <Breadcrumbs
                title="Cryllex"
                breadcrumbItem="Account Information"
              />
          }

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div>
                    <div className='d-flex justify-content-between mb-2'>
                      <h4 className='card-title'>Agent Profile</h4>
                      <h4 className='card-title'>ID: {_values?.uniqueId}</h4>
                    </div>
                    <hr />
                    {
                      !isForm ?
                        <Col sm={12}>
                          <Row>
                            <Col sm={6}>
                              <Table bordered striped responsive className='mb-0'>
                                <tbody>

                                  <tr>
                                    <th
                                      scope="row"
                                      style={{ width: "400px" }}
                                      className={"text-capitalize"}
                                    >
                                      Agent Business Name:

                                    </th>
                                    <td className='w-50'>{_values.legalBusinessName}</td>
                                  </tr>
                                  <tr>
                                    <th
                                      scope="row"
                                      style={{ width: "400px" }}
                                      className={"text-capitalize"}
                                    >
                                      Default On-Ramp Counterparty:
                                    </th>
                                    <td className='w-50'>
                                      {_values.defaultOnRampCounterparty?.libraryData.map(item => {
                                        if (item.id === _values.defaultOnRampCounterpartyLibId) {
                                          return item.displayName;
                                        }
                                      })}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th
                                      scope="row"
                                      style={{ width: "400px" }}
                                      className={"text-capitalize"}
                                    >
                                      Status:
                                    </th>
                                    <td className='w-50'>
                                      <span className={`badge badge-soft-${_values?.isActive ? "success" : "danger"} font-size-14 badge badge-pill`}>
                                        {_values?.isActive ? "Active" : "Inactive"}
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </Col>
                            <Col sm={6}>
                              <Table bordered striped responsive className='mb-0'>
                                <tbody>


                                  <tr>
                                    <th
                                      scope="row"
                                      style={{ width: "400px" }}
                                      className={"text-capitalize"}
                                    >
                                      Ramp Counterparty Name/ID:
                                    </th>
                                    <td className='w-50'>
                                      {_values.rampConterparty?.libraryData.map(item => {
                                        if (item.id === _values.rampCounterpartyIDLibId) {
                                          return item.displayName;
                                        }
                                      })}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th
                                      scope="row"
                                      style={{ width: "400px" }}
                                      className={"text-capitalize"}
                                    >
                                      Default Off-Ramp Counterparty:
                                    </th>
                                    <td className='w-50'>
                                      {_values.defaultOffRampCounterparty?.libraryData.map(item => {
                                        if (item.id === _values.defaultOffRampCounterpartyLibId) {
                                          return item.displayName;
                                        }
                                      })}
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </Col>
                          </Row>
                        </Col>
                        : <Col>
                          <Row>
                            <Col md={6} className='mt-3'>
                              <Auxiliary>
                                <Label htmlFor='certificateOfInsurance'>Agent Business Name:</Label>
                                <input
                                  id='certificateOfInsurance'
                                  type="text"
                                  value={_values.legalBusinessName}
                                  name="certificateOfInsurance"
                                  className={`form-control`}
                                  autoComplete='new-password'
                                  onChange={(event) => _onChange(event, "legalBusinessName")}
                                />
                              </Auxiliary>
                            </Col>
                            <Col md={6} className='mt-3'>
                              {
                                _values.rampConterparty && _values.rampConterparty.libraryData && _values.rampConterparty.libraryData.length ?
                                  <div>
                                    <Label>Ramp Counterparty Name/ID:</Label>
                                    <div className='w-100' >
                                      <ReactSelectOption
                                        value={_values.rampCounterpartyIDLibId}
                                        selectedValue={(() => {
                                          const selectedValue = { ..._values.rampConterparty.libraryData.find(data => data.id === _values.rampCounterpartyIDLibId) };
                                          if (selectedValue) {
                                            selectedValue.label = selectedValue.displayName;
                                            selectedValue.value = selectedValue.id;
                                          }
                                          return selectedValue;
                                        })()}
                                        items={_values.rampConterparty.libraryData.map(data => ({ label: data.displayName, value: data.id }))}
                                        onChange={(item) => _onSelectOptionsChange(item, "rampCounterpartyIDLibId")}
                                      />
                                    </div>
                                  </div>
                                  : null
                              }
                            </Col>
                            <Col md={6} className='mt-3'>
                              {
                                _values.defaultOnRampCounterparty && _values.defaultOnRampCounterparty.libraryData && _values.defaultOnRampCounterparty.libraryData.length ?
                                  <div>
                                    <Label>Default On-Ramp Counterparty: </Label>
                                    <div className='w-100' >
                                      <ReactSelectOption
                                        value={_values.defaultOnRampCounterpartyLibId}
                                        selectedValue={(() => {
                                          const selectedValue = { ..._values.defaultOnRampCounterparty.libraryData.find(data => data.id === _values.defaultOnRampCounterpartyLibId) };
                                          if (selectedValue) {
                                            selectedValue.label = selectedValue.displayName;
                                            selectedValue.value = selectedValue.id;
                                          }
                                          return selectedValue;
                                        })()}
                                        items={_values.defaultOnRampCounterparty.libraryData.map(data => ({ label: data.displayName, value: data.id }))}
                                        onChange={(item) => _onSelectOptionsChange(item, "defaultOnRampCounterpartyLibId")}
                                      />
                                    </div>
                                  </div>
                                  : null
                              }
                            </Col>
                            <Col md={6} className='mt-3'>
                              {
                                _values.defaultOffRampCounterparty && _values.defaultOffRampCounterparty.libraryData && _values.defaultOffRampCounterparty.libraryData.length ?
                                  <div>
                                    <Auxiliary>
                                      <Label>Default Off-Ramp Counterparty:</Label>
                                      <div className='w-100' >
                                        <ReactSelectOption
                                          value={_values.defaultOffRampCounterpartyLibId}
                                          selectedValue={(() => {
                                            const selectedValue = { ..._values.defaultOffRampCounterparty.libraryData.find(data => data.id === _values.defaultOffRampCounterpartyLibId) };
                                            if (selectedValue) {
                                              selectedValue.label = selectedValue.displayName;
                                              selectedValue.value = selectedValue.id;
                                            }
                                            return selectedValue;
                                          })()}
                                          items={_values.defaultOffRampCounterparty.libraryData.map(data => ({ label: data.displayName, value: data.id }))}
                                          onChange={(item) => _onSelectOptionsChange(item, "defaultOffRampCounterpartyLibId")}
                                        />
                                      </div>
                                    </Auxiliary>
                                  </div>
                                  : null
                              }
                            </Col>
                            <Col md={6} className='mt-3'>
                              <Label htmlFor='isActive' className='d-flex align-items-center'>
                                Status:
                                <p className={`ms-2 mb-0 text-${_values.isActive ? "success" : "danger"}`}>({_values.isActive ? "Active" : "Inactive"})</p>
                              </Label>
                              <div className='d-flex align-items-center mt-1'>
                                <Switch
                                  id="isActive"
                                  className="react-switch mt-1"
                                  size={1}
                                  checkedIcon={<OnIcon />}
                                  uncheckedIcon={<OffIcon />}
                                  checked={_values.isActive}
                                  disabled={isDisabledFields}
                                  aria-labelledby="neat-label"
                                  onChange={onChangeIsActive}
                                />
                              </div>
                            </Col>
                          </Row>
                        </Col>
                    }

                    {/* Hly parz chi inch piti lini */}
                    {/* <Col sm={12}>
                      <div className={`mt-3`}>
                        <label className='me-3 mb-0'>OTC On-Ramp Counterparty Bank A/C:</label>
                        <Button
                          type='button'
                          color="primary"
                          style={{ padding: "3px 8px" }}
                          onClick={() => { }}
                        >
                          +
                        </Button>
                      </div>
                      {
                        _values.agentBanks && _values.agentBanks.length ?
                          _values.agentBanks.map((item, index) => {
                            return <Row key={index} className='mt-2 border px-2 py-3 position-relative'>
                              <Col md={6}>
                                <div>
                                  {
                                    isForm ?
                                      <Auxiliary>
                                        <label className='me-5'>Bank Account Number:</label>
                                        <input
                                          id='certificateOfInsurance'
                                          type="text"
                                          value={item.accountNumber}
                                          name="certificateOfInsurance"
                                          className={`form-control`}
                                          autoComplete='new-password'
                                          onChange={(event) => _onChangeFieldsInObj(event, "agentBanks", index, "accountNumber")}
                                        />
                                      </Auxiliary>
                                      : <div className='d-flex justify-content-between align-items-center mt-1'>
                                        <p>Bank Account Number:</p>
                                        <p>
                                          <b>
                                            {item.accountNumber}
                                          </b>
                                        </p>
                                      </div>
                                  }
                                </div>
                              </Col>
                              <Col md={6}>
                                <div>
                                  {
                                    isForm ?
                                      <Auxiliary>
                                        <label htmlFor='certificateOfInsurance' className='me-5'>Address:</label>
                                        <input
                                          id='certificateOfInsurance'
                                          type="text"
                                          value={item.address}
                                          name="certificateOfInsurance"
                                          className={`form-control`}
                                          autoComplete='new-password'
                                          onChange={(event) => _onChangeFieldsInObj(event, "agentBanks", index, "address")}
                                        />
                                      </Auxiliary>
                                      : <div className='d-flex justify-content-between align-items-center mt-1'>
                                        <p>Address:</p>
                                        <p>
                                          <b>
                                            {item.address}
                                          </b>
                                        </p>
                                      </div>
                                  }
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className={`d-flex justify-content-between align-items-center ${isForm ? "mt-3" : "mt-2"}`}>
                                  <label htmlFor='certificateOfInsurance' className='me-5'>Address Documentation*:</label>
                                  {
                                    item.addressFile?.uploadedFile || item.addressFilePath ?
                                      <div className='position-relative border rounded' style={{ width: "100px", height: "100px" }}>

                                        {
                                          (() => {
                                            switch (item.addressFile.fileType) {
                                              case "image":
                                                return <div style={{ width: "100%", height: "100%", }} className='d-flex justify-content-center align-items-center flex-column'>
                                                  <img src={item.addressFile?.uploadedFile || item.addressFilePath} className='rounded' style={{ width: "100%", height: "100%" }} />
                                                </div>
                                              case "file":
                                                return <div style={{ width: "100%", height: "100%", }} className='p-2  d-flex justify-content-center align-items-center flex-column'>
                                                  <i className='bx bx-file-blank' style={{ fontSize: "50px" }} ></i>
                                                  <small className='max-line-2 mt-1 word-break-break-word'>{item.addressFile?.fileName}</small>
                                                </div>
                                              default:
                                                break;
                                            }
                                          })()
                                        }
                                        {
                                          isForm ?
                                            <i
                                              className='bx bx-x position-absolute font-size-20 text-danger bg-white rounded cursor-pointer border'
                                              style={{ top: "1px", right: "1px" }}

                                              onClick={() => {
                                                _setValues(prevState => {
                                                  const agentBanksCopy = [...prevState.agentBanks];
                                                  agentBanksCopy[index].addressFile = null;
                                                  agentBanksCopy[index].addressFilePath = null;
                                                  return {
                                                    ...prevState,
                                                    agentBanks: agentBanksCopy,
                                                  };
                                                });
                                              }}
                                            />
                                            : null
                                        }
                                      </div>
                                      : isForm ? <Button
                                        onClick={MainService.triggerUploadClick}
                                        outline
                                        color="primary"
                                        type='button'
                                      >
                                        <input type="file" id='avatarImage' className="d-none" hidden onChange={(event) => _uploadFile(event, "agentBanks", index, "addressFile")} />
                                        Attach file
                                      </Button> : <span className=''>No attached file yet. </span>
                                  }
                                </div>

                              </Col>
                              <Col md={6}>
                                <div>
                                  {
                                    isForm ?
                                      <Auxiliary>
                                        <label htmlFor='certificateOfInsurance' className='me-5'>Bank Account Region:</label>
                                        <input
                                          id='certificateOfInsurance'
                                          type="text"
                                          value={item.bankAccountRegion}
                                          name="certificateOfInsurance"
                                          className={`form-control`}
                                          autoComplete='new-password'
                                          onChange={(event) => _onChangeFieldsInObj(event, "agentBanks", index, "bankAccountRegion")}
                                        />
                                      </Auxiliary>
                                      : <div className='d-flex justify-content-between align-items-center mt-1'>
                                        <p>Bank Account Number:</p>
                                        <p>
                                          <b>
                                            {item.bankAccountRegion}
                                          </b>
                                        </p>
                                      </div>
                                  }
                                </div>
                              </Col>
                              <Col md={6}>
                                <div>
                                  {
                                    isForm ?
                                      <Auxiliary>
                                        <label htmlFor='certificateOfInsurance' className='me-5'>SWIFT Code:</label>
                                        <input
                                          id='certificateOfInsurance'
                                          type="text"
                                          value={item.swiftCode}
                                          name="certificateOfInsurance"
                                          className={`form-control`}
                                          autoComplete='new-password'
                                          onChange={(event) => _onChangeFieldsInObj(event, "agentBanks", index, "swiftCode")}
                                        />
                                      </Auxiliary>
                                      : <div className='d-flex justify-content-between align-items-center mt-1'>
                                        <p>Bank Account Number:</p>
                                        <p>
                                          <b>
                                            {item.swiftCode}
                                          </b>
                                        </p>
                                      </div>
                                  }
                                </div>
                              </Col>
                              {
                                values.OTCOnRampCounterpartyBank.length > 1 && isForm ?
                                  <i
                                    className='bx bx-x position-absolute font-size-20 text-danger bg-white rounded cursor-pointer border w-auto p-0'
                                    style={{ top: "1px", right: "1px" }}
                                    // onClick={() => setShareholderPercentage_1(null)}
                                    onClick={() => {
                                      setValues(prevState => ({
                                        ...prevState,
                                        OTCOnRampCounterpartyBank: prevState.OTCOnRampCounterpartyBank.filter((_, idx) => idx !== index),
                                      }));
                                    }}
                                  />
                                  : null
                              }
                              {
                                index ?
                                  <div className='px-4'>
                                    <hr />
                                  </div>
                                  : null
                              }
                            </Row>
                          })
                          : null
                      }
                    </Col> */}
                  </div>

                  {
                    agentId ? null :
                      <div className='mt-4 d-flex justify-content-end flex-wrap'>
                        <div className='d-flex mt-3'>
                          {
                            isForm ?
                              <Auxiliary>
                                <Button color="secondary" outline className='px-4'
                                  onClick={() => {
                                    setIsForm(false);
                                    _setValues(_defaultValues)
                                    scrollTop();
                                  }}
                                >
                                  Cancel
                                </Button>

                                <ActionButton
                                  type="button"
                                  name="Save"
                                  className="btn btn-primary btn-block ms-2 px-4"
                                  spinnerId={buttonSpinnerId}
                                  disabled={isDisabledFields}
                                  onClick={onSubmit}
                                />

                              </Auxiliary>
                              :
                              <Button color="primary" className='px-4'
                                onClick={() => {
                                  setIsForm(true);
                                  scrollTop();
                                }}
                              >
                                Edit
                              </Button>
                          }
                        </div>
                      </div>
                  }

                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default ProfileSettings