import React from 'react';
import { useSelector } from 'react-redux';
import DefaultButtonSpinner from '../Spinners/DefaultButtonSpinner';
import Auxiliary from './../../hoc/auxiliary/auxiliary';
import { Button, Spinner } from 'reactstrap';

const ActionButton = (props) => {
  const { buttonSpinners } = useSelector(state => state.Spinners);
  const currentSpinner = buttonSpinners.find(spinner => spinner === props.spinnerId);

  return (
    currentSpinner ? <Button
      color={props.color || "primary"}
      style={{ pointerEvents: "none" }}
      outline={props.outline || false}
      className={props.buttonClassName || props.className}
    >
      Loading
      <i className="bx bx-loader bx-spin font-size-16 align-middle ms-2"></i>{" "}
    </Button>
      :
      <Button
        type={props.type}
        color={props.color || "primary"}
        outline={props.outline || false}
        disabled={currentSpinner || props.disabled}
        className={`d-flex justify-content-center align-items-center ${props.className}`}
        onClick={props.onClick}
      >
        {props.name ? props.name : props.children}

      </Button>
  )
  // return <Button
  //   type={props.type}
  //   color={props.color || "primary"}
  //   outline={props.outline || false}
  //   disabled={currentSpinner || props.disabled}
  //   className={`d-flex justify-content-center align-items-center ${props.className}`}
  //   onClick={props.onClick}
  // >
  //   {
  //     currentSpinner ?
  //       <Auxiliary>
  //         <span className='me-2'>Loading...</span>
  //         <Spinner size="sm" />
  //       </Auxiliary>
  //       : props.name ? props.name : props.children
  //   }
  //   {props.children}

  // </Button>
};

export default ActionButton;  