import React, { useState, useEffect, useRef } from 'react'
import Breadcrumbs from './../../../components/Common/Breadcrumb'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import ApiService from '../../../Services/ApiService'
import { useCallback } from 'react'
import { addButtonSpinner, removeButtonSpinner, removeRightSectionSpinner, addRightSectionSpinner } from '../../../store/actions'
import { AGENT_USER_TYPE_ID, ERROR_KEY, MERCHANT_USER_TYPE_ID, SPINNER_COLOR, SUCCESS_KEY, VALID_IMAGE_TYPES_KEY, allValidFileTypes, fielsLengths } from '../../../Constants/MainKeys'
import AlertService from '../../../Services/alertService'
import uuid from 'react-uuid'
import {
  Row,
  Col,
  Card,
  CardBody,
  Label, Input, Button, Alert, UncontrolledAlert,
} from "reactstrap"
import HtmlHead from '../../../components/HtmlHead/HtmlHead'
import RightSectionSpinner from '../../../components/Spinners/RightSectionSpinner'
import ActionButton from '../../../components/Buttons/ActionButton'
import ReactSelectOption from '../../../components/SelectOptions/ReactSelectOption'
import MainService from '../../../Services/MainService'
import { PuffLoader } from 'react-spinners'
import FileComponent from '../Merchant/Components/FileComponent'

const buttonSpinnerId = uuid();
const modalSpinnerId = uuid();

function useOutsideAlerter(ref, cb) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        cb(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const BankAccountForm = (props) => {
  const query = new URLSearchParams(props.location.search);
  const redirectUrl = query.get('redirectUrl');

  const mainService = new MainService()
  const { accountId } = props.match.params;
  const wrapperRef = useRef(null);
  const title = "Bank Account form"
  const description = "";
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.Login);
  const { rightSectionSpinners } = useSelector(state => state.Spinners);
  const { language } = useSelector(state => state.Languages);
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [isShowImageLoader, setIsShowImageLoader] = useState(false);
  const [zoomImagePath, setZoomImagePath] = useState(null);
  const [countries, setCountries] = useState([]);
  const [currencies, setCurrencies] = useState([]);


  useOutsideAlerter(wrapperRef, setZoomImagePath);

  const [values, setValues] = useState({
    accountNumber: "",
    accountName: "",
    bankName: "",
    bankAddress: "",
    bankCode: "",
    swiftCode: "",
    branchAddress: "",
    branchCode: "",
    branchName: "",
    // routingNumber: "",
    routingType: "",
    // routingValue: "",
    countryId: "",
    province: "",
    city: "",
    currencyId: null
  })

  useEffect(() => {
    getAccount();
    getCountries();
    getFiatCurrencies();
  }, [])

  const getFiatCurrencies = () => {
    const spinnerId = uuid();
    setRightSectionSpinner(spinnerId)
    ApiService.getFiatCurrencies().then(response => {
      if (response && response.data) {
        setCurrencies(response.data)
      }
    }).catch(error => getFail(error)).finally(() => {
      extractRightSectionSpinner(spinnerId)
    })
  }

  const getCountries = () => {
    const spinnerId = uuid();
    setRightSectionSpinner(spinnerId)
    ApiService.getCountries().then(response => {
      if (response && response.data) {
        setCountries(response.data)
      }
    }).catch(error => getFail(error)).finally(() => {
      extractRightSectionSpinner(spinnerId)
    })
  }

  const getAccount = () => {
    const spinnerId = uuid();
    setRightSectionSpinner(spinnerId);
    (user.userTypeId === MERCHANT_USER_TYPE_ID ?
      ApiService.getAccountAsMerchant(accountId || null) :
      ApiService.getAccountAsAgent(accountId || null))
      .then(response => {
        if (response && response.data) {
          const data = { ...response.data }
          setValues(data);
        }
        extractRightSectionSpinner(spinnerId);
      }).catch(error => getFail(error, spinnerId))
  }

  const getInvalidField = () => {
    if (
      !values.accountNumber ||
      !values.accountName ||
      !values.bankName ||
      !values.bankAddress ||
      !values.bankCode ||
      !values.swiftCode ||
      !values.branchAddress ||
      !values.branchCode ||
      !values.branchName ||
      // !values.routingNumber ||
      // !values.routingType ||
      // !values.routingValue ||
      !values.countryId ||
      !values.province ||
      !values.city
    ) {
      return true;
    } else {
      return false;
    }
  }

  const onChange = (event, field, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    setValues((values) => ({
      ...values,
      [field]: event.target.value,
    }));
    setIsInvalidSubmit(false)
  }

  const onSelectOptionChange = (value, field) => {
    setValues((values) => ({
      ...values,
      [field]: value,
    }));
    setIsChanged(true);
    setIsInvalidSubmit(false)
  }

  const onNumberChange = (event, field, maxLength = Infinity) => {
    if (event.target.value.includes("e") || event.target.value.includes(".") || event.target.value.includes("-") || event.target.value === "0") {
      setValues((values) => ({
        ...values,
        [field]: "",
      }));
      return false;
    };
    if (event.target.value === '' || (typeof +event.target.value === "number" && Number(event.target.value) >= 0 && Number(event.target.value) < maxLength)) {
      setValues((values) => ({
        ...values,
        [field]: event.target.value ? +event.target.value : "",
      }));
      return false;
    }
    setIsInvalidSubmit(false)
  };

  const getFileType = (fileType) => {
    if (!fileType) { return 0; }
    switch (fileType) {
      case "bankAddressFile":
        return 1;
      default:
        break;
    }
  }

  const uploadFile = async (data) => {
    const { event, fieldName } = data;
    if (!event.target.files.length) { return false; }
    const formData = new FormData();

    let files = [...event.target.files];
    for (let i in files) {
      const file = files[i];
      const fileName = file.name;
      const lastDotIndex = fileName.lastIndexOf('.');
      const fileExtention = lastDotIndex !== -1 ? fileName.substring(lastDotIndex + 1).toLowerCase() : ' ';

      if (allValidFileTypes.includes(fileExtention.toLowerCase())) {
        mainService.readFile(file, allValidFileTypes).then(uploadedFile => {
          setIsShowImageLoader(true);
          formData.append("id", values.id);
          formData.append("document", file);
          formData.append("type", getFileType(fieldName));
          (user.userTypeId === MERCHANT_USER_TYPE_ID ?
            ApiService.uploadBankAccountDocumentAsMerchant(formData) :
            ApiService.uploadBankAccountDocumentAsAgent(formData)).then(response => {
              setValues((values) => ({
                ...values,
                [fieldName]: response.data,
              }))
              AlertService.alert(SUCCESS_KEY, "Data saved")
            }).catch(error => getFail(error)).finally(() => {
              setIsShowImageLoader(false);
            })

        }).catch(error => {
          error && AlertService.alert("error", "Invalid file format")
        });
      } else {
        AlertService.alert("error", "Invalid file format")
        return false;
      }
    }
  };

  const deleteDocument = (data) => {
    const { fieldName, fileLibraryId } = data;
    if (!fileLibraryId) { return false; }
    AlertService.alertConfirm(
      `Are you sure you want to delete current file ?`,
      "",
      "Yes",
      "No"
    ).then(() => {
      setIsShowImageLoader(true);
      (user.userTypeId === MERCHANT_USER_TYPE_ID ?
        ApiService.deleteBankAccountDocumentAsMerchant(fileLibraryId, values.id, getFileType(fieldName)) :
        ApiService.deleteBankAccountDocumentAsAgent(fileLibraryId, values.id, getFileType(fieldName))).then(() => {
          setValues((values) => ({
            ...values,
            [fieldName]: null,
          }))
          AlertService.alert(SUCCESS_KEY, "File deleted successfully")
        }).catch(error => getFail(error)).finally(() => {
          setIsShowImageLoader(false);
        })
    })
  }

  const onSubmit = (event) => {
    event && event.preventDefault();
    setButtonSpinner(modalSpinnerId);
    const valuesCopy = { ...values };
    // delete valuesCopy.countries;
    if (getInvalidField()) {
      setIsInvalidSubmit(true);
      return false;
    }
    setButtonSpinner(buttonSpinnerId);
    // valuesCopy.routingNumber = valuesCopy.routingNumber.toString();
    (user.userTypeId === MERCHANT_USER_TYPE_ID ?
      accountId ? ApiService.updateOrganizationBankAccountAsMerchant(valuesCopy) : ApiService.createAccountAsMerchant(valuesCopy) :
      user.userTypeId === AGENT_USER_TYPE_ID ?
        accountId ? ApiService.updateOrganizationBankAccountAsAgent(valuesCopy) : ApiService.createAccountAsAgent(valuesCopy) : null
    ).then((response) => {
      if (response && response.data) {
        if (redirectUrl) {
          props.history.push(`/${language}/${redirectUrl}`)
        } else {
          props.history.push(`/${language}/bank-account-form/${response.data.id}`)
        }
        // setValues(response.data);
      }
      AlertService.alert(SUCCESS_KEY, `Your account has been successfully ${accountId ? "updated" : "created"}`)
      props.history.push(`/${language}/bank-account`);
      extractButtonSpinner(buttonSpinnerId)
    }).catch(error => getFail(error, buttonSpinnerId))
  }

  const setRightSectionSpinner = useCallback(spinner => {
    dispatch(addRightSectionSpinner(spinner));
  }, []);

  const extractRightSectionSpinner = useCallback(spinner => {
    dispatch(removeRightSectionSpinner(spinner));
  }, []);

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractRightSectionSpinner(spinnerId);
    spinnerId && extractButtonSpinner(spinnerId);
  }

  return (
    <>
      <div className={`zoom-image-modal ${!zoomImagePath ? "d-none" : ""}`}>
        <img
          src={zoomImagePath ? zoomImagePath : ""}
          ref={wrapperRef}
        />
        <i className='bx bx-x close-icon'></i>
      </div>
      <section className='page-content position-relative min-h-100'>
        <HtmlHead title={title} description={description} />
        <RightSectionSpinner spinner={rightSectionSpinners} />
        <div className="container-fluid">
          {
            values && !values.isApproved ?
              <UncontrolledAlert color="danger" role="alert">
                <i className="mdi mdi-block-helper me-2"></i>
                Attention: You can't perform any payout process with an inactive bank account. Please wait for approval from Cryllex administration.
              </UncontrolledAlert>
              : null
          }
          {
            accountId ?
              <UncontrolledAlert color="warning" role="alert">
                <i className="mdi mdi-alert-outline me-2"></i>
                After updating your bank account, its status has become inactive until approved by a Cryllex administrator. Please note that you will be unable to perform any actions with an inactive bank account.
              </UncontrolledAlert>
              : null
          }
          <Breadcrumbs
            title="Cryllex"
            breadcrumbItem="Bank account form"
            isShowGoBackButton={true}
            goBack={() => window.history.back()}
          />
          <form onSubmit={onSubmit} onChange={() => setIsChanged(true)}>

            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <Row>
                      <Col sm={12}>
                        <Row className='mb-4'>

                          <Col lg={12}>
                            <Row>
                              <Col lg={4} md={6}>
                                <Label htmlFor='accountName' className='text-muted text-truncate mb-0'>Account Name*</Label>
                                <Input
                                  id="accountName"
                                  type="text"
                                  className={`form-control ${(isInvalidSubmit && !values.accountName?.trim().length) ? "error-border" : ""}`}
                                  value={values.accountName || ""}
                                  onChange={event => onChange(event, "accountName", fielsLengths.length_120)}
                                />
                              </Col>
                              <Col lg={12}>
                                <UncontrolledAlert color="warning" role="alert" className='mt-3 mb-0'>
                                  <i className="mdi mdi-alert-outline me-2"></i>
                                  Attention: Your legal business name is linked to your bank account, and any changes made to
                                  the bank account name will automatically update your business name as well.
                                </UncontrolledAlert>
                              </Col>

                            </Row>
                          </Col>
                          <Col sm={12}><hr /></Col>
                          <Col lg={4} md={6}>
                            <Label htmlFor='accountNumber' className={`text-muted text-truncate mb-0`}>Account Number*</Label>
                            <Input
                              id="accountNumber"
                              type="text"
                              className={`form-control ${(isInvalidSubmit && !values.accountNumber?.trim().length) ? "error-border" : ""}`}
                              value={values.accountNumber || ""}
                              onChange={event => onChange(event, "accountNumber", fielsLengths.length_120)}
                            />
                          </Col>
                          <Col lg={4} md={6}>
                            <Label htmlFor='swiftCode' className='text-muted text-truncate mb-0'>Swift Code*</Label>
                            <Input
                              id="swiftCode"
                              type="text"
                              className={`form-control ${(isInvalidSubmit && !values.swiftCode?.trim().length) ? "error-border" : ""}`}
                              placeholder='Ex. INJSAM22'
                              value={values.swiftCode || ""}
                              onChange={event => onChange(event, "swiftCode", fielsLengths.length_120)}
                            />
                          </Col>
                          <Col lg={4} md={6}>
                            <Label htmlFor='currencyId' className='text-muted text-truncate mb-0'>Currencies*</Label>
                            {
                              currencies && currencies.length ?
                                <ReactSelectOption
                                  value={values.currencyId}
                                  isSearchable={true}
                                  // menuPlacement="auto"
                                  className={`${(isInvalidSubmit && !values.currencyId) ? "error-border" : ""}`}
                                  selectedValue={(() => {
                                    const selectedValue = { ...currencies.find(data => data.id === values.currencyId) };
                                    if (Object.keys(selectedValue).length) {
                                      selectedValue.label = selectedValue.name;
                                      selectedValue.value = selectedValue.id;
                                      return selectedValue;
                                    } else {
                                      return { label: "Choose...", value: "" }
                                    }
                                  })()}
                                  items={currencies.map(data => ({ label: data.name, value: data.id }))}
                                  onChange={(item) => onSelectOptionChange(item.value, "currencyId")}
                                />
                                : null
                            }
                          </Col>
                        </Row>
                        <hr />
                        <Row className="mb-4">
                          <Col lg={4} md={6} className="mb-4">
                            <Label htmlFor='bankName' className='text-muted text-truncate mb-0'>Bank Name*</Label>
                            <Input
                              id="bankName"
                              type="text"
                              className={`form-control ${(isInvalidSubmit && !values.bankName?.trim().length) ? "error-border" : ""}`}
                              value={values.bankName || ""}
                              onChange={event => onChange(event, "bankName", fielsLengths.length_120)}
                            />
                          </Col>
                          <Col lg={4} md={6} className="mb-4">
                            <Label htmlFor='bankAddress' className='text-muted text-truncate mb-0'>Bank Address*</Label>
                            <Input
                              id="bankAddress"
                              type="text"
                              className={`form-control ${(isInvalidSubmit && !values.bankAddress?.trim().length) ? "error-border" : ""}`}
                              value={values.bankAddress || ""}
                              onChange={event => onChange(event, "bankAddress", fielsLengths.length_120)}
                            />
                          </Col>
                          <Col lg={4} md={6} className="mb-4">
                            <Label htmlFor='bankCode' className='text-muted text-truncate mb-0'>Bank Code*</Label>
                            <Input
                              id="bankCode"
                              type="text"
                              className={`form-control ${(isInvalidSubmit && !values.bankCode?.trim().length) ? "error-border" : ""}`}
                              value={values.bankCode || ""}
                              onChange={event => onChange(event, "bankCode", fielsLengths.length_120)}
                            />
                          </Col>

                          <Col lg={4} md={6}>
                            <Label htmlFor='branchAddress' className='text-muted text-truncate mb-0'>Branch Address*</Label>
                            <Input
                              id="branchAddress"
                              type="text"
                              className={`form-control ${(isInvalidSubmit && !values.branchAddress?.trim().length) ? "error-border" : ""}`}
                              value={values.branchAddress || ""}
                              onChange={event => onChange(event, "branchAddress", fielsLengths.length_120)}
                            />
                          </Col>
                          <Col lg={4} md={6}>
                            <Label htmlFor='branchCode' className='text-muted text-truncate mb-0'>Branch Code*</Label>
                            <Input
                              id="branchCode"
                              type="text"
                              className={`form-control ${(isInvalidSubmit && !values.branchCode?.trim().length) ? "error-border" : ""}`}
                              value={values.branchCode || ""}
                              onChange={event => onChange(event, "branchCode", fielsLengths.length_120)}
                            />
                          </Col>
                          <Col lg={4} md={6}>
                            <Label htmlFor='branchName' className='text-muted text-truncate mb-0'>Branch Name*</Label>
                            <Input
                              id="branchName"
                              type="text"
                              className={`form-control ${(isInvalidSubmit && !values.branchName?.trim().length) ? "error-border" : ""}`}
                              value={values.branchName || ""}
                              onChange={event => onChange(event, "branchName", fielsLengths.length_120)}
                            />
                          </Col>
                        </Row>
                        <Row className="mb-4">
                          <Col lg={4} md={6}>
                            <Label htmlFor='countryId' className='text-muted text-truncate mb-0'>Country*</Label>
                            {
                              countries && countries.length ?
                                <ReactSelectOption
                                  value={values.countryId}
                                  isSearchable={true}
                                  // menuPlacement="auto"
                                  className={`${(isInvalidSubmit && !values.countryId) ? "error-border" : ""}`}
                                  selectedValue={(() => {
                                    const selectedValue = { ...countries.find(data => data.id === values.countryId) };
                                    if (Object.keys(selectedValue).length) {
                                      selectedValue.label = selectedValue.name;
                                      selectedValue.value = selectedValue.id;
                                      return selectedValue;
                                    } else {
                                      return { label: "Choose...", value: "" }
                                    }
                                  })()}
                                  items={countries.map(data => ({ label: data.name, value: data.id }))}
                                  onChange={(item) => onSelectOptionChange(item.value, "countryId")}
                                />
                                : null
                            }
                          </Col>
                          <Col lg={4} md={6}>
                            <Label htmlFor='province' className='text-muted text-truncate mb-0'>Province*</Label>
                            <Input
                              id="province"
                              type="text"
                              className={`form-control ${(isInvalidSubmit && !values.province?.trim().length) ? "error-border" : ""}`}
                              value={values.province || ""}
                              onChange={event => onChange(event, "province", fielsLengths.length_120)}
                            />
                          </Col>
                          <Col lg={4} md={6}>
                            <Label htmlFor='city' className='text-muted text-truncate mb-0'>City*</Label>
                            <Input
                              id="city"
                              type="text"
                              className={`form-control ${(isInvalidSubmit && !values.city?.trim().length) ? "error-border" : ""}`}
                              value={values.city || ""}
                              onChange={event => onChange(event, "city", fielsLengths.length_120)}
                            />
                          </Col>
                        </Row>
                        {/* <hr /> */}
                        <Row>
                          {/* <Col lg={4} md={6} className="mb-4">
                            <Label htmlFor='routingNumber' className='text-muted text-truncate mb-0'>Routing Number*</Label>
                            <Input
                              id="routingNumber"
                              type="text"
                              className={`form-control ${(isInvalidSubmit && (!values.routingNumber || (values && values.routingNumber.toString().length < 9))) ? "error-border" : ""}`}
                              value={values.routingNumber || ""}
                              placeholder='Ex. 123456789'
                              onChange={event => onNumberChange(event, "routingNumber", fielsLengths.length_1000000000)}
                            />
                          </Col> */}
                          {/* <Col lg={4} md={6} className="mb-4">
                            <Label htmlFor='routingType' className='text-muted text-truncate mb-0'>Routing Type*</Label>
                            <Input
                              id="routingType"
                              type="text"
                              className={`form-control ${(isInvalidSubmit && !values.routingType?.trim().length) ? "error-border" : ""}`}
                              value={values.routingType || ""}
                              onChange={event => onChange(event, "routingType", fielsLengths.length_120)}
                            />
                          </Col> */}
                          {/* <Col lg={4} md={6} className="mb-4">
                            <Label htmlFor='routingValue' className='text-muted text-truncate mb-0'>Routing Value*</Label>
                            <Input
                              id="routingValue"
                              type="text"
                              className={`form-control ${(isInvalidSubmit && !values.routingValue?.trim().length) ? "error-border" : ""}`}
                              value={values.routingValue || ""}
                              onChange={event => onChange(event, "routingValue", fielsLengths.length_120)}
                            />
                          </Col> */}
                        </Row>
                        <Row>
                          {
                            values.id ?
                              isShowImageLoader ?
                                <Col sm={12}>
                                  <div className='d-flex justify-content-center align-items-center' style={{ minHeight: "150px", }}>
                                    <PuffLoader size={60} color={SPINNER_COLOR} />
                                  </div>
                                </Col>
                                :
                                <Col sm={12}>
                                  <hr />
                                  <Row>
                                    <Col md={3} sm={6} className='mb-3'>
                                      <FileComponent
                                        data={values}
                                        fieldName="bankAddressFile"
                                        labelValue="Bank Address File*"
                                        setZoomImagePath={setZoomImagePath}
                                        uploadFile={uploadFile}
                                        deleteDocument={deleteDocument}
                                      />
                                    </Col>
                                    <Col sm={12}>
                                      <small className='fw-500 d-block mb-2'>Allowed file types {allValidFileTypes.map((item, index) => <i key={index}>{item}. </i>)}</small>
                                      <UncontrolledAlert color="warning" role="alert">
                                        <i className="mdi mdi-alert-outline me-2"></i>
                                        All documents require Certified true copies, alternatively, the documents can be visually presented through Cryllex customer support
                                      </UncontrolledAlert>
                                    </Col>
                                  </Row>
                                </Col>
                              : null
                          }
                        </Row>
                      </Col>
                    </Row>
                    <div className='d-flex justify-content-end'>
                      <ActionButton
                        type="submit"
                        name="Save"
                        className="btn btn-primary btn-block px-4"
                        spinnerId={buttonSpinnerId}
                        disabled={!isChanged}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </form>
        </div>
      </section>
    </>
  )
}

export default withRouter(BankAccountForm)