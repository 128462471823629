import React from 'react'
import { Switch, Route, Redirect } from "react-router-dom"
import Dashboard from '../../pages/Admin-Pages/Dashboard/Dashboard'
import { ADMIN_USER_TYPE_ID, AGENT_USER_TYPE_ID, DEFAULT_LANGUAGE_KEY } from '../../Constants/MainKeys'
import { useSelector } from 'react-redux'
import TransactionHistory from "../../pages/Admin-Pages/TransactionHistory/TransactionHistory"
import CryptoHedging from "../../pages/Admin-Pages/CryptoHedging/CryptoHedging"
import Notifications from "../../pages/Admin-Pages/Notifications/Notifications"
import CryptoDiscounts from "../../pages/Admin-Pages/CryptoDiscounts/CryptoDiscounts"
import ReportSummary from "../../pages/Admin-Pages/ReportSummary/ReportSummary"
import CryptoRefunds from "../../pages/Admin-Pages/CryptoRefunds/CryptoRefunds"
import PaymentsAnalysis from "../../pages/Admin-Pages/PaymentsAnalysis/PaymentsAnalysis"
import AnnualTaxForms from "../../pages/Admin-Pages/AnnualTaxForms/AnnualTaxForms"
import ContactUs from "../../pages/Admin-Pages/ContactUs"
import ChangePassword from "../../pages/Admin-Pages/ChangePassword"
import TransactionHistoryView from "../../pages/Admin-Pages/TransactionHistory/TransactionHistoryView"
import AccountBalances from "../../pages/Admin-Pages/AccountBalances/AccountBalances"
import CashBalance from "../../pages/Admin-Pages/CashBalance/CashBalance"
import CryptoBalance from "../../pages/Admin-Pages/CryptoBalance/CryptoBalance"
import CryptoStaking from "../../pages/Admin-Pages/CryptoStaking/CryptoStaking"
import Payouts from "../../pages/Admin-Pages/Payouts/Payouts"
import ApiKeys from "../../pages/Admin-Pages/ApiKeys/ApiKeys"
import PayoutForm from "../../pages/Admin-Pages/Payouts/PayoutForm"
import BankAccount from "../../pages/Admin-Pages/BankAccount/BankAccount"
import Profile from "../../pages/Authentication/Profile"
import Logout from '../../pages/Authentication/Logout'
import FAQ from "../../pages/Admin-Pages/FAQ"
import Pages from "../../pages/Admin-Pages/Page/Pages"
import PageForm from "../../pages/Admin-Pages/Page/PageForm"
import PageContents from "../../pages/Admin-Pages/Page/PageContents"
import PageContentForm from "../../pages/Admin-Pages/Page/PageContentForm"
import InstantPayment from "../../pages/Admin-Pages/InstantPayment/InstantPayment"
import BusinessDetails from './../../pages/Admin-Pages/Merchant/BusinessDetails';
import Customers from '../../pages/Admin-Pages/Customers/Customers'
import Products from '../../pages/Admin-Pages/Products/Products'
import Logs from '../../pages/Admin-Pages/Logs/Logs'
import Reports from '../../pages/Admin-Pages/Reports/Reports'
import CompanyInformation from '../../pages/Admin-Pages/Merchant/CompanyInformation.js'
import Analytics from '../../pages/Admin-Pages/Analytics/Analytics'
import Agents from '../../pages/Admin-Pages/Agents/Agents'
import AllTransactions from '../../pages/Admin-Pages/AllTransactions/AllTransactions'
import RelatedMerchants from '../../pages/Admin-Pages/RelatedMerchants/RelatedMerchants'
import Organizations from '../../pages/Admin-Pages/Organizations/Organizations.js'
import PayingCustomers from '../../pages/Admin-Pages/PayingCustomers/PayingCustomers'
import ViewRelatedParties from '../../pages/Admin-Pages/Agents/components/ViewRelatedParties'
import ViewKycAgents from '../../pages/Admin-Pages/Agents/components/ViewKyc'
import RelatedMerchantsViewInfo from '../../pages/Admin-Pages/RelatedMerchants/components/ViewInfo'
import OrganizationKYC from '../../pages/Admin-Pages/Organizations/OrganizationKYC.js'
import OrganizationTransactions from '../../pages/Admin-Pages/Organizations/OrganizationTransactions.js'
import PayingCustomersViewTransactions from '../../pages/Admin-Pages/PayingCustomers/components/ViewTransactions'
import PayingCustomersViewKYC from '../../pages/Admin-Pages/PayingCustomers/components/ViewKYC'
import TransactionView from '../../pages/Admin-Pages/AllTransactions/components/TransactionView'
import KeyPerformanceIndicators from '../../pages/Admin-Pages/KeyPerformanceIndicators/KeyPerformanceIndicators'
import Home from '../../pages/Agent-Pages/Home/Home'
import ProfileSettings from '../../pages/Agent-Pages/ProfileSettings/ProfileSettings'
import TransactionsOverview from '../../pages/Agent-Pages/TransactionsOverview/TransactionsOverview'
import ClientDatabase from '../../pages/Agent-Pages/ClientDatabase/ClientDatabase'
import CurrentClientDatabase from '../../pages/Agent-Pages/ClientDatabase/CurrentClientDatabase'
import ClientKycInformation from '../../pages/Agent-Pages/ClientDatabase/ClientKycInformation'
import ClientBankingInformation from '../../pages/Agent-Pages/ClientDatabase/ClientBankingInformation.js'
import ClientTransactions from '../../pages/Agent-Pages/ClientDatabase/ClientTransactions'
import ClientInvitationForm from '../../pages/Agent-Pages/ClientDatabase/ClientInvitationForm.js'
import CurrentTransactionsOverview from '../../pages/Agent-Pages/TransactionsOverview/CurrentTransactionsOverview.js'
import ClientInvitations from '../../pages/Agent-Pages/ClientDatabase/ClientInvitations.js'
import Users from '../../pages/Admin-Pages/Users/Users.js'
import UserForm from '../../pages/Admin-Pages/Users/UserForm.js'
import BusinessKYC from '../../pages/Admin-Pages/Merchant/BusinessKYC.js'
import BankAccountForm from '../../pages/Admin-Pages/BankAccount/BankAccountForm.js'
import Payout from '../../pages/Admin-Pages/Payouts/Payout.js'
import AdminDashboard from '../../pages/Admin-Pages/Dashboard/AdminDashboard.js'
import AgentKYC from '../../pages/Admin-Pages/Agents/AgentKYC.js'
import Kyc from '../../pages/Agent-Pages/Kyc/Kyc'
import AgentOrganizations from '../../pages/Admin-Pages/Agents/AgentOrganizations.js'
import AttachAgent from '../../pages/Admin-Pages/Organizations/AttachAgent.js'
import PayoutsForAdmin from '../../pages/Admin-Pages/Payouts/PayoutsForAdmin.js'
import OrganizationWallets from '../../pages/Admin-Pages/Organizations/OrganizationWallets.js'

export default function Authmiddleware() {

  const { language } = useSelector(state => state.Languages);
  const { user } = useSelector(state => state.Login);

  if (user.userTypeId === ADMIN_USER_TYPE_ID) {
    return <Switch>
      <Route path="/:language/dashboard" exact component={AdminDashboard} />
      <Route path="/:language/profile" exact component={Profile} />
      <Route path="/:language/agents" exact component={Agents} />
      <Route path="/:language/agent/:agentId" exact component={AgentKYC} />
      <Route path="/:language/agent/organizations/:agentId" exact component={AgentOrganizations} />
      <Route path="/:language/agents/view-related-parties" exact component={ViewRelatedParties} />
      <Route path="/:language/agents/view-kyc" exact component={ViewKycAgents} />
      <Route path="/:language/agents/related-merchants" exact component={RelatedMerchants} />
      <Route path="/:language/agents/related-merchants/view-info" exact component={RelatedMerchantsViewInfo} />
      <Route path="/:language/organizations" exact component={Organizations} />
      <Route path="/:language/organization/:organizationId" exact component={OrganizationKYC} />
      <Route path="/:language/organization/transactions/:organizationId" exact component={OrganizationTransactions} />
      <Route path="/:language/organization/attach-agent/:organizationId" exact component={AttachAgent} />
      <Route path="/:language/organization/wallets/:organizationId" exact component={OrganizationWallets} />
      <Route path="/:language/paying-customers" exact component={PayingCustomers} />
      <Route path="/:language/paying-customers/view-transactions" exact component={PayingCustomersViewTransactions} />
      <Route path="/:language/paying-customers/view-kyc" exact component={PayingCustomersViewKYC} />
      <Route path="/:language/all-transactions" exact component={AllTransactions} />
      <Route path="/:language/transaction/view/:transactionId" exact component={TransactionView} />
      <Route path="/:language/reports" exact component={Reports} />
      <Route path="/:language/significant-controllers-form/:userId" exact component={UserForm} />
      <Route path="/:language/payouts" exact component={PayoutsForAdmin} />
      <Route path="/:language/payout/:itemId" exact component={Payout} />

      <Route path="/:language/pages" exact component={Pages} />
      <Route path="/:language/pages/form" exact component={PageForm} />
      <Route path="/:language/pages/form/:pageId" exact component={PageForm} />
      <Route path="/:language/pages/content/:pageId" exact component={PageContents} />
      <Route path="/:language/pages/content-form" exact component={PageContentForm} />
      <Route path="/:language/pages/content-form/:pageId" exact component={PageContentForm} />
      <Route path="/:language/pages/content-form/:pageId" exact component={PageContentForm} />
      <Route path="/:language/pages/content-form/:pageId/:contentId/:languageId" exact component={PageContentForm} />

      <Route path="/:language/profile-settings/:agentId" exact component={ProfileSettings} />

      <Route path="/:language/logout" exact component={Logout} />
      <Redirect to={`/${language ? language : DEFAULT_LANGUAGE_KEY}/dashboard`} />

    </Switch>
  } else if (user.userTypeId === AGENT_USER_TYPE_ID) {
    return <Switch>
      <Route path="/:language/home" exact component={Home} />
      <Route path="/:language/profile" exact component={Profile} />
      <Route path="/:language/kyc" exact component={Kyc} />
      <Route path="/:language/client-database" exact component={ClientDatabase} />
      <Route path="/:language/client-database/:clientId" exact component={CurrentClientDatabase} />
      <Route path="/:language/client-database-kyc/:clientId" exact component={ClientKycInformation} />
      <Route path="/:language/client-database-banking-information/:clientId" exact component={ClientBankingInformation} />
      <Route path="/:language/client-database-transactions" exact component={ClientTransactions} />
      <Route path="/:language/client-database-transactions/:clientId" exact component={ClientTransactions} />
      <Route path="/:language/client-invitations" exact component={ClientInvitations} />
      <Route path="/:language/client-invitation-form" exact component={ClientInvitationForm} />
      {/* <Route path="/:language/client-invitation-form/:invitationId" exact component={ClientInvitationForm} /> */}

      <Route path="/:language/profile-settings" exact component={ProfileSettings} />
      <Route path="/:language/transactions-overview" exact component={TransactionsOverview} />
      <Route path="/:language/transactions-overview/:transactionId" exact component={CurrentTransactionsOverview} />
      <Route path="/:language/balance/payout/:itemId" exact component={Payout} />
      <Route path="/:language/significant-controllers" exact component={Users} />
      <Route path="/:language/significant-controllers-form" exact component={UserForm} />
      <Route path="/:language/significant-controllers-form/:userId" exact component={UserForm} />

      <Route path="/:language/bank-account" exact component={BankAccount} />
      <Route path="/:language/bank-account-form" exact component={BankAccountForm} />
      <Route path="/:language/bank-account-form/:accountId" exact component={BankAccountForm} />

      <Route path="/:language/logout" exact component={Logout} />
      <Redirect to={`/${language ? language : DEFAULT_LANGUAGE_KEY}/home`} />

    </Switch>
  } else {
    return <Switch>
      <Route path="/:language/dashboard" exact component={Dashboard} />
      <Route path="/:language/payments/transaction-history" exact component={TransactionHistory} />
      <Route path="/:language/payments/transaction-history/view/:localTransactionId" exact component={TransactionHistoryView} />
      <Route path="/:language/balance/account-balances" exact component={AccountBalances} />
      <Route path="/:language/instant-payment" exact component={InstantPayment} />
      <Route path="/:language/crypto-hedging" exact component={CryptoHedging} />
      <Route path="/:language/crypto-refunds" exact component={CryptoRefunds} />
      <Route path="/:language/crypto-discounts" exact component={CryptoDiscounts} />
      <Route path="/:language/report-summary" exact component={ReportSummary} />
      <Route path="/:language/analysis/payments-analysis" exact component={PaymentsAnalysis} />
      <Route path="/:language/analysis/key-performance-indicators" exact component={KeyPerformanceIndicators} />
      <Route path="/:language/annual-tax-forms" exact component={AnnualTaxForms} />
      <Route path="/:language/contact-us" exact component={ContactUs} />
      <Route path="/:language/faq" exact component={FAQ} />
      <Route path="/:language/change-password" exact component={ChangePassword} />
      <Route path="/:language/cash-balance" exact component={CashBalance} />
      <Route path="/:language/crypto-balance" exact component={CryptoBalance} />
      <Route path="/:language/crypto-staking" exact component={CryptoStaking} />
      <Route path="/:language/balance/payouts" exact component={Payouts} />
      <Route path="/:language/balance/payout/form" exact component={PayoutForm} />
      <Route path="/:language/balance/payout/:itemId" exact component={Payout} />
      <Route path="/:language/api-keys" exact component={ApiKeys} />
      <Route path="/:language/profile" exact component={Profile} />
      <Route path="/:language/notifications" exact component={Notifications} />
      <Route path="/:language/bank-account" exact component={BankAccount} />
      <Route path="/:language/bank-account-form" exact component={BankAccountForm} />
      <Route path="/:language/bank-account-form/:accountId" exact component={BankAccountForm} />
      <Route path="/:language/significant-controllers" exact component={Users} />
      <Route path="/:language/significant-controllers-form" exact component={UserForm} />
      <Route path="/:language/significant-controllers-form/:userId" exact component={UserForm} />
      <Route path="/:language/payments/customers" exact component={Customers} />
      <Route path="/:language/payments/products" exact component={Products} />
      <Route path="/:language/logs" exact component={Logs} />
      <Route path="/:language/reports" exact component={Reports} />
      <Route path="/:language/analytics" exact component={Analytics} />
      <Route path="/:language/merchant/business-details" exact component={BusinessDetails} />
      {/* <Route path="/:language/merchant/business-kyc" exact component={BusinessKYC} /> Tigona asel vor comment anem 14.06.2024 */}
      <Route path="/:language/merchant/company-information" exact component={CompanyInformation} />
      <Route path="/:language/logout" exact component={Logout} />
      <Redirect to={`/${language ? language : DEFAULT_LANGUAGE_KEY}/dashboard`} />
    </Switch>
  }




}