import axios from 'axios';
import { API_URL_KEY, IDENTITY_API_URL_KEY } from '../Constants/ApiKeys';
import { DEFAULT_LANGUAGE_KEY, ERROR_KEY, LANGUAGE_KEY, OBJECT_KEY, REFRESH_TOKEN_KEY, TOKEN_KEY, USER_KEY } from './../Constants/MainKeys';
import AlertService from './alertService';

var api = []
for (let index = 0; index < API_URL_KEY.length; index++) {
  api.push(axios.create({
    baseURL: API_URL_KEY[index],
    withCredentials: true,
  }));

  api[index].interceptors.request.use(async config => {
    config.headers = {
      'Authorization': localStorage.getItem(TOKEN_KEY) ? localStorage.getItem(TOKEN_KEY) : "",
      'language': localStorage.getItem(LANGUAGE_KEY) ? localStorage.getItem(LANGUAGE_KEY) : DEFAULT_LANGUAGE_KEY,
      'ngrok-skip-browser-warning': 'true',
    };
    return config;
  });

  api[index].interceptors.response.use(response => {
    if (response.data) return response.data;
  }, error => {
    if (error.response) {
      if (error.response.status === 401) {
        //return Promise.reject("401 Unauthorized");
        const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
        const user = localStorage.getItem(USER_KEY);
        const token = localStorage.getItem(TOKEN_KEY);
        if (!refreshToken || !user || !token) {
          logout();
        } else {
          getUserDataByRefreshToken(refreshToken, user);
        }
      } else if (error.response.status === 404) { // Status Code: 404
        return Promise.reject("404 Not Found");
      } else if (error.response.status === 500) { // Status Code: 500
        return Promise.reject("500 Internal Server Error");
      }
      if (typeof error.response.data === OBJECT_KEY) {
        const currentError = { ...error.response.data };
        const errors = currentError.errors?.Error;
        let errorMessages = [];
        if (errors && errors.length > 0) {
          errors.forEach((error) => {
            const errorParams = error.params;
            const paramKeys = Object.keys(errorParams);
            paramKeys.forEach((key) => {
              const value = errorParams[key];
              if (typeof value === 'string' && value.trim().length > 0) {
                errorMessages.push(value);
              }
            });
          });
        } else {
          return Promise.reject("Unrecognized error");
        }
        if (errorMessages.length) {
          showError(errorMessages);
          return Promise.reject();
        } else {
          return Promise.reject("Unrecognized error");
        }
      } else {
        return Promise.reject("Unrecognized error");
      }

    } else {
      return Promise.reject("Unrecognized error");
    }
  });
}

// const api = axios.create({
//   baseURL: API_URL_KEY,
//   withCredentials: true,
// });

const identityApi = axios.create({
  baseURL: IDENTITY_API_URL_KEY
});

// api.interceptors.request.use(async config => {
//   config.headers = {
//     'Authorization': localStorage.getItem(TOKEN_KEY) ? localStorage.getItem(TOKEN_KEY) : "",
//     'language': localStorage.getItem(LANGUAGE_KEY) ? localStorage.getItem(LANGUAGE_KEY) : DEFAULT_LANGUAGE_KEY,
//     'ngrok-skip-browser-warning': 'true',
//   };
//   return config;
// });

// api.interceptors.response.use(response => {
//   if (response.data) return response.data;
// }, error => {
//   if (error.response) {
//     if (error.response.status === 401) {
//       const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
//       const user = localStorage.getItem(USER_KEY);
//       const token = localStorage.getItem(TOKEN_KEY);
//       if (!refreshToken || !user || !token) {
//         logout();
//       } else {
//         getUserDataByRefreshToken(refreshToken, user);
//       }
//     } else if (error.response.status === 404) { // Status Code: 404
//       return Promise.reject("404 Not Found");
//     } else if (error.response.status === 500) { // Status Code: 500
//       return Promise.reject("500 Internal Server Error");
//     }
//     if (typeof error.response.data === OBJECT_KEY) {
//       const currentError = { ...error.response.data };
//       const errors = currentError.errors?.Error;
//       let errorMessages = [];
//       if (errors && errors.length > 0) {
//         errors.forEach((error) => {
//           const errorParams = error.params;
//           const paramKeys = Object.keys(errorParams);
//           paramKeys.forEach((key) => {
//             const value = errorParams[key];
//             if (typeof value === 'string' && value.trim().length > 0) {
//               errorMessages.push(value);
//             }
//           });
//         });
//       } else {
//         return Promise.reject("Unrecognized error");
//       }
//       if (errorMessages.length) {
//         showError(errorMessages);
//         return Promise.reject();
//       } else {
//         return Promise.reject("Unrecognized error");
//       }
//     } else {
//       return Promise.reject("Unrecognized error");
//     }

//   } else {
//     return Promise.reject("Unrecognized error");
//   }
// });

const showError = (errorMessages) => {
  // errorMessages.forEach(error => {
  //   AlertService.alert("error", error)
  // });

  const filteredNumbers = errorMessages.filter(item => !isNaN(item)); //error numbers

  const filteredStrings = errorMessages.filter(item => isNaN(item));
  filteredStrings.forEach(error => {
    AlertService.alert("error", error)
  });
}

const logout = () => {
  window.location.href = `/${localStorage.getItem(LANGUAGE_KEY) ? localStorage.getItem(LANGUAGE_KEY) : DEFAULT_LANGUAGE_KEY}`;
  localStorage.removeItem("user");
  localStorage.removeItem("token");
  localStorage.removeItem("refreshToken");
}

const getUserDataByRefreshToken = (refreshToken, user) => {
  const formData = new FormData();
  formData.append(`refreshToken`, refreshToken);
  ApiService.getUserDataByRefreshToken(formData).then(response => {
    if (response.data && response.data.accessToken) {
      const data = { ...response.data };
      localStorage.setItem(TOKEN_KEY, data?.accessToken);
      localStorage.setItem(REFRESH_TOKEN_KEY, data?.refreshToken);
      localStorage.setItem(USER_KEY, user);
    } else {
      logout();
    }
  }).catch(error => getFail(error));
}

const getFail = (error) => {
  if (error) logout();
}

class ApiService {

  // RefreshToken
  static getUserDataByRefreshToken(data) {
    return identityApi.post(`/identity/renewToken`, data)
  };

  static getAllTranslations() {
    return api[0].get(`/translation/GetByLanguageIsoCode`);
  };

  static getTransaltionByKey(key) {
    return api[0].get(`/translation/getbykey/${key}`);
  };

  static getLanguages() {
    return api[0].get(`/language/getall`);
  };

  static currentUser() {
    return api[1].get(`/user/current`);
  };

  static checkKey(key) {
    return api[1].get(`/user/accessRestorePassword?key=${key}`);
  };

  static forgotPassword(email) {
    return api[1].post(`/user/getRestorePasswordUrl?email=${email}`);
  };

  static restorePassword(data) {
    return api[1].put(`/user/restorePassword`, data);
  };

  static changeOldPassword(data) {
    return api[1].put(`/user/updatePassword`, data);
  };

  static deleteUserDocument(fileLibraryId, userId, type) {
    return api[1].delete(`/user/DeleteDocument?libId=${fileLibraryId}&id=${userId}&type=${type}`);
  };

  static checkUserRegistration(key) {
    return api[1].get(`/user/confirmEmail?emailVerificationKey=${key}`);
  };

  static uploadUserAvatar(data) {
    return api[1].post(`/user/UploadDocument`, data);
  };

  static updateProfile(data) {
    return api[1].put(`/user/update`, data);
  };

  static updatePassword(data) {
    return api[1].put(`/user/updatePassword`, data);
  };

  static generateKeys(data) {
    return api[0].post(`/organizationToken/create`, data);
  };

  static getKeys() {
    return api[0].get(`/organizationToken/get`);
  };

  static getAllKeys() {
    return api[0].get(`/organizationToken/getAllByOrganization`);
  };

  static checkPassword(password, publicKey) {
    return api[0].post(`/organizationToken/getKey?password=${password}&publicKey=${publicKey}`);
  };

  static getPagesAsAdmin() {
    return api[0].get(`/page/getAllAdmin`);
  };

  static getPageById(id) {
    return api[0].get(`/page/getAdmin/${id}`);
  };

  static createPage(data) {
    return api[0].post(`/page/create`, data);
  };

  static updatePage(data) {
    return api[0].put(`/page/update`, data);
  };

  static deletePage(pageId) {
    return api[0].delete(`/page/delete/${pageId}`);
  };

  static getPageContentById(contentId) {
    return api[0].get(`/page/GetContent/${contentId}`);
  };

  static createPageContent(data) {
    return api[0].post(`/page/createcontent`, data);
  };

  static updatePageContent(data) {
    return api[0].put(`/page/updatecontent`, data);
  };

  static getPageContents(pageId) {
    return api[0].get(`/page/GetContentsByPage/${pageId}`);
  };

  static deleteContent(contentId) {
    return api[0].delete(`/page/deletecontent/${contentId}`);
  };

  static onChangePageContentOrder(dropContentId, dragSectionId, languageId) {
    return api[0].put(`/page/ChangeContentOrder/${dropContentId}/${dragSectionId}/${languageId}`);
  };

  static sendError(data) {
    return api[0].post(`/error/create`, data);
  };

  static createInstantPayment(data) {
    return api[1].post(`/merchant/instantPayment/create`, data);
  };

  static organizationUpdate(formData) {
    return api[0].put(`/organization/update`, formData);
  };

  static getBalance() {
    return api[0].get(`/admin/balance/get`);
  };

  static getReports() {
    return api[0].get(`/???`);
  };

  static getTransactionDataById(transactionId) {
    return api[0].get(`/???/${transactionId}`);
  };

  static onFilterTransactionsAsAdmin(values, pageNumber, pageSize) {
    return api[0].post(`/admin/transaction/filter?pageNumber=${pageNumber}&pageSize=${pageSize}`, values);
  };

  static getCryptoWallets() {
    // return api[0].get(`/wallet/list`);
    return api[1].get(`/cryptoWallet/list`);
  };

  static getCurrencies() {
    return api[1].get(`/currency/cryptoList`);
  };

  static getFiatCurrencies() {
    return api[1].get(`/currency/fiatList`);
  };

  static getCountries() {
    return api[0].get(`/country/GetBaseModels`);
  };

  static getKycLevels() {
    return api[1].get(`/admin/Kyc/GetLevels`);
  };

  // Agent

  static getOrganizationTransactions(orgId, pageNumber, pageSize, paymentType) {
    return api[1].get(`/merchant/transaction/getByOrg?orgId=${orgId}&pageNumber=${pageNumber}&pageSize=${pageSize}&paymentType=${paymentType}`);
  };

  static getOrganizationTransactionsAsAgent(pageNumber, pageSize, data, orgId) {
    return api[1].post(`/agent/Payout/GetAll?orgId=${orgId}&pageNumber=${pageNumber}&pageSize=${pageSize}`, data);
  };

  static getOrganizationTransactionsAsAdmin(orgId, pageNumber, pageSize, data) {
    return api[1].post(`/admin/Transaction/GetByOrgId?orgId=${orgId}&pageNumber=${pageNumber}&pageSize=${pageSize}`, data);
  };

  static getAllTransactions(pageNumber, pageSize, filter) {
    return api[1].post(`/admin/transaction/getall?pageNumber=${pageNumber}&pageSize=${pageSize}`, filter);
  };

  // agent dashboard
  static getOrganizationsAsAgent(userId = null, data) {
    return api[1].post(`/agent/OrganizationAgent/GetOrganizations/${userId}`, data);
  };
  static getOrganizationsAsAdmin(userId, data) {
    return api[1].post(`/admin/OrganizationAgent/GetOrganizations/${userId}`, data);
  };

  static getOrgBankAccounts(orgtId) {
    return api[1].get(`/agent/bankAccount/GetOrgBankAccounts/${orgtId}`);
  };

  static getOrgBankAccountAsAgent(orgtId) {
    return api[1].get(`/agent/bankAccount/GetOrgBankAccount/${orgtId}`);
  };

  static getOrgBankAccountAsAdmin(orgtId) {
    return api[1].get(`/admin/bankAccount/GetOrgBankAccount/${orgtId}`);
  };

  static getOrgKycInformation(agentId, orgId) {
    return api[1].get(`/agent/OrganizationAgent/GetByOrganizationId?agentId=${agentId}&orgId=${orgId}`);
  };

  static removeClient(clientId, agentId) {
    return api[1].delete(`/agent/OrganizationAgent/Delete/${clientId}/${agentId}`);
  };

  static getClientTypes() {
    return api[1].get(`/agent/AgentOrganizationInvitation/CreateView`);
  };

  static createClientInvitation(data) {
    return api[1].post(`/agent/AgentOrganizationInvitation/Create`, data);
  };

  static getClientInvitationById(id) {
    return api[1].post(`/agent/AgentOrganizationInvitation/Get/${id}`);
  };

  static deleteInvitation(id) {
    return api[1].post(`/agent/AgentOrganizationInvitation/Delete/${id}`);
  };

  static getClientInvitations() {
    return api[1].get(`/agent/AgentOrganizationInvitation/GetAll`);
  };

  static getAgentProfileAsAgent(userId) {
    return api[1].get(`/agent/Agent/GetProfile/${userId}`);
  };

  static getAgentProfileAsAdmin(userId) {
    return api[1].get(`/admin/Agent/GetProfile/${userId}`);
  };
  // end agent dashboard

  static getTransactionHistoryAsMerchant(pageNumber, pageSize, data, clientId) {
    return api[1].post(`/merchant/transaction/get?pageNumber=${pageNumber}&pageSize=${pageSize}`, data);
  };

  static getInstantPaymentsAsMerchant(pageNumber, pageSize, data) {
    return api[1].post(`/merchant/InstantPayment/GetInstant?pageNumber=${pageNumber}&pageSize=${pageSize}`, data);
  };

  static onFilterTransactionsAsMerchant(pageNumber, pageSize, data) {
    return api[1].post(`/merchant/transaction/filter?pageNumber=${pageNumber}&pageSize=${pageSize}`, data);
  };

  static onFilterTransactionsAsAdmin(pageNumber, pageSize, data) {
    return api[1].post(`/admin/transaction/filter?pageNumber=${pageNumber}&pageSize=${pageSize}`, data);
  };

  static getTransactionByLocalTransactionId(localTransactionId) {
    return api[1].get(`/merchant/transaction/details/${localTransactionId}`);
  };

  static getPaymentSystems() {
    return api[1].get(`/merchant/payoutSystem/getall`);
  };

  // static getPayoutHistory(pageNumber, pageSize) {
  //   return api[1].post(`/merchant/payout/getall?pageNumber=${pageNumber}&pageSize=${pageSize}`, {});
  // };

  static getPayoutHistoryAsAdmin(pageNumber, pageSize, data, orgId) {
    if (orgId) {
      return api[1].post(`/admin/Payout/GetAll?pageNumber=${pageNumber}&pageSize=${pageSize}&orgId=${orgId}`, data);
    } else {
      return api[1].post(`/admin/Payout/GetAll?pageNumber=${pageNumber}&pageSize=${pageSize}`, data);
    }
  };

  static getPayoutHistoryAsMerchant(pageNumber, pageSize, data, orgId) {
    if (orgId) {
      return api[1].post(`/merchant/Payout/GetAll?pageNumber=${pageNumber}&pageSize=${pageSize}&orgId=${orgId}`, data);
    } else {
      return api[1].post(`/merchant/Payout/GetAll?pageNumber=${pageNumber}&pageSize=${pageSize}`, data);
    }
  };

  static getPayoutByIdAsMerchant(itemId) {
    return api[1].get(`/merchant/payout/get/${itemId}`);
  };

  static getPayoutByIdAsAdmin(itemId) {
    return api[1].get(`/admin/payout/get/${itemId}`);
  };

  static onPayOut(data) {
    return api[1].post(`/merchant/payout/create`, data);
  };

  static getNetValue(data) {
    return api[1].post(`/merchant/payout/getNetValue`, data);
  };

  static getAccounts() {
    return api[1].get(`/merchant/bankAccount/getActiveAccounts`);
  };

  static getBankAccountAsMerchant() {
    return api[1].get(`/merchant/bankAccount/getActiveAccount`);
  };

  static getBankAccountAsAgent() {
    return api[1].get(`/agent/bankAccount/getActiveAccount`);
  };

  static getAccountByType(type) {
    return api[1].get(`/merchant/bankAccount/getByType/${type}`);
  };

  static createAccountAsMerchant(values) {
    return api[1].post(`/merchant/bankAccount/create`, values);
  };

  static createAccountAsAgent(values) {
    return api[1].post(`/agent/bankAccount/create`, values);
  };

  static getAccountAsMerchant(id) {
    if (id) return api[1].get(`/merchant/bankAccount/get/${id}`);
    else return api[1].get(`/merchant/bankAccount/createView`);
  };

  static getAccountAsAgent(id) {
    if (id) return api[1].get(`/agent/bankAccount/get/${id}`);
    else return api[1].get(`/agent/bankAccount/createView`);
  };

  static updateOrganizationBankAccountAsMerchant(values) {
    return api[1].put(`/merchant/bankAccount/update`, values);
  };

  static updateOrganizationBankAccountAsAgent(values) {
    return api[1].put(`/agent/bankAccount/update`, values);
  };

  static updateOrganizationBankAccountAsAdmin(values) {
    return api[1].put(`/admin/bankAccount/update`, values);
  };

  static filterPayouts(values, pageNumber, pageSize) {
    return api[1].post(`/merchant/payout/filter?pageNumber=${pageNumber}&pageSize=${pageSize}`, values);
  };

  static getOrganizationData() {
    return api[1].get(`/merchant/OrganizationDetail/get`);
  };
  static getAgentKycData() {
    return api[1].get(`/agent/AgentKyc/Get`);
  };

  static getCustomers() {
    return api[1].get(`/merchant/customer/get`);
  };

  static getProducts() {
    return api[1].get(`/merchant/product/get`);
  };

  static getDashboardData() {
    return api[1].get(`/merchant/dashboard/index`);
  };

  static deleteAccountAsMerchant(bankAccountId) {
    return api[1].delete(`/merchant/bankAccount/delete/${bankAccountId}`);
  };

  static deleteAccountAsAgent(bankAccountId) {
    return api[1].delete(`/agent/bankAccount/delete/${bankAccountId}`);
  };

  static getUsersAsMerchant(orgId, type, pageNumber, pageSize) {
    return api[1].get(`/merchant/SignificantController/GetByOrg?orgId=${orgId}&type=${type}&pageNumber=${pageNumber}&pageSize=${pageSize}`);
  };

  static getUsersAsAgent(agentId, type, pageNumber, pageSize) {
    return api[1].get(`/agent/SignificantController/GetByAgent?agentId=${agentId}&type=${type}&pageNumber=${pageNumber}&pageSize=${pageSize}`);
  };

  static getUserByIdAsMerchant(id) {
    return api[1].get(`/merchant/SignificantController/Get/${id}`);
  };

  static getUserByIdAsAgent(id) {
    return api[1].get(`/agent/SignificantController/Get/${id}`);
  };

  static getUserByIdAsAdmin(id) {
    return api[1].get(`/admin/SignificantController/Get/${id}`);
  };

  static createUserAsMerchant(data) {
    return api[1].post(`/merchant/SignificantController/Create`, data);
  };
  static createUserAsAgent(data) {
    return api[1].post(`/agent/SignificantController/Create`, data);
  };

  static updateUserAsMerchant(data) {
    return api[1].put(`/merchant/SignificantController/Update`, data);
  };

  static updateUserAsAgent(data) {
    return api[1].put(`/agent/SignificantController/Update`, data);
  };

  static updateUserAsAdmin(data) {
    return api[1].put(`/admin/SignificantController/Update`, data);
  };

  static getUserTypesAsMerchant() {
    return api[1].get(`/merchant/SignificantController/GetTypes`);
  };

  static getUserTypesAsAdmin() {
    return api[1].get(`/admin/SignificantController/GetTypes`);
  };

  static getUserTypesAsAgent() {
    return api[1].get(`/agent/SignificantController/GetTypes`);
  };

  static deleteSignificantControllerDocumentAsMerchant(fileLibraryId, userId, type) {
    return api[1].delete(`/merchant/SignificantController/DeleteDocument?libId=${fileLibraryId}&id=${userId}&type=${type}`);
  };

  static deleteSignificantControllerDocumentAsAdmin(fileLibraryId, userId, type) {
    return api[1].delete(`/admin/SignificantController/DeleteDocument?libId=${fileLibraryId}&id=${userId}&type=${type}`);
  };

  static deleteSignificantControllerDocumentAsAgent(fileLibraryId, userId, type) {
    return api[1].delete(`/agent/SignificantController/DeleteDocument?libId=${fileLibraryId}&id=${userId}&type=${type}`);
  };

  static deleteBankAccountDocumentAsMerchant(fileLibraryId, userId, type) {
    return api[1].delete(`/merchant/bankAccount/DeleteDocument?libId=${fileLibraryId}&id=${userId}&type=${type}`);
  };

  static deleteBankAccountDocumentAsAgent(fileLibraryId, userId, type) {
    return api[1].delete(`/agent/bankAccount/DeleteDocument?libId=${fileLibraryId}&id=${userId}&type=${type}`);
  };

  static deleteBankAccountDocumentAsAdmin(fileLibraryId, userId, type) {
    return api[1].delete(`/admin/bankAccount/DeleteDocument?libId=${fileLibraryId}&id=${userId}&type=${type}`);
  };

  static uploadSignificantControllerDocumentAsMerchant(data) {
    return api[1].post(`/merchant/SignificantController/UploadDocument`, data);
  };

  static uploadSignificantControllerDocumentAsAdmin(data) {
    return api[1].post(`/admin/SignificantController/UploadDocument`, data);
  };

  static uploadSignificantControllerDocumentAsAgent(data) {
    return api[1].post(`/agent/SignificantController/UploadDocument`, data);
  };

  static uploadBankAccountDocumentAsMerchant(data) {
    return api[1].post(`/merchant/bankAccount/UploadDocument`, data);
  };

  static uploadBankAccountDocumentAsAgent(data) {
    return api[1].post(`/agent/bankAccount/UploadDocument`, data);
  };

  static uploadBankAccountDocumentAsAdmin(data) {
    return api[1].post(`/admin/bankAccount/UploadDocument`, data);
  };

  static deleteSignificantControllerAsMerchant(id) {
    return api[1].delete(`/merchant/SignificantController/Delete/${id}`);
  };

  static deleteSignificantControllerAsAgent(id) {
    return api[1].delete(`/agent/SignificantController/Delete/${id}`);
  };

  static getOrganization() {
    return api[1].get(`/merchant/Organization/Get`);
  };

  static uploadOrganizationLogo(data) {
    return api[1].post(`/merchant/Organization/UploadLogo`, data);
  };

  static deleteOrganizationLogo(libraryId) {
    return api[1].delete(`/merchant/Organization/DeleteLogo/${libraryId}`);
  };

  static updateOrganization(data) {
    return api[1].put(`/merchant/Organization/Update`, data);
  };

  static updateBusinessDetail(data) {
    return api[1].put(`/merchant/OrganizationDetail/Update`, data);
  };

  static getOrganizationKYC(data) {
    return api[1].post(`/merchant/OrganizationKyc/GetByOrgId`, data);
  };

  static getAgentViewOrgKYCAsAgent(orgId) {
    return api[1].get(`/agent/OrganizationKyc/GetOrgKYC/${orgId}`);
  };

  static getOrgBankAccountAsAgent(orgId) {
    return api[1].get(`/agent/OrganizationKyc/GetOrgBankAccount/${orgId}`);
  };

  static getAgentViewOrgKYCAsAdmin(data) {
    return api[1].post(`/admin/OrganizationKyc/GetOrgKYCAgentView`, data);
  };

  static getAgentViewOrgKYCAsMerchant(data) {
    return api[1].post(`/merchant/OrganizationKyc/GetOrgKYCAgentView`, data);
  };

  static createOrganizationKYC(data) {
    return api[1].post(`/merchant/OrganizationKyc/Create`, data);
  };

  static uploadOrganizationKYCFile(data) {
    return api[1].post(`/merchant/OrganizationKyc/UploadDocument`, data);
  };

  static deleteOrganizationKYCFile(data) {
    return api[1].post(`/merchant/OrganizationKyc/DeleteDocument`, data);
  };

  static getOrganizationsByAdmin(pageNumber, pageSize, data) {
    return api[1].post(`/admin/Organization/GetAll?pageNumber=${pageNumber}&pageSize=${pageSize}`, data);
  };

  static getOrganizationById(organizationId) {
    return api[1].get(`/admin/Organization/GetById/${organizationId}`);
  };

  static getAgentKycDataById(agentId) {
    return api[1].get(`/admin/agent/getKyc/${agentId}`);
  };

  static organizationDetailUpdateStatus(data) {
    return api[1].put(`/merchant/OrganizationDetail/UpdateStatus`, data);
  };

  static organizationUpdateStatusAsMerchant(data) {
    return api[1].put(`/merchant/Organization/UpdateStatus`, data);
  };
  static organizationUpdateStatusAsAgent(data) {
    return api[1].put(`/agent/Agent/UpdateStatus`, data);
  };



  //Files 
  static uploadDocumentAsMerchant(data) {
    return api[1].post(`/merchant/OrganizationDetail/UploadDocument`, data);
  };
  static bulkUploadDocumentAsMerchant(data) {
    return api[1].post(`/merchant/OrganizationDetail/BulkUploadDocument`, data);
  };
  static deleteDocumentAsMerchant(fileLibraryId, id, type) {
    return api[1].delete(`/merchant/OrganizationDetail/DeleteDocument?libId=${fileLibraryId}&id=${id}&type=${type}`);
  };

  static uploadDocumentAsAdmin(data) {
    return api[1].post(`/admin/OrganizationDetail/UploadDocument`, data);
  };
  static bulkUploadDocumentAsAdmin(data) {
    return api[1].post(`/admin/OrganizationDetail/BulkUploadDocument`, data);
  };
  static deleteDocumentAsAdmin(fileLibraryId, id, type) {
    return api[1].delete(`/admin/OrganizationDetail/DeleteDocument?libId=${fileLibraryId}&id=${id}&type=${type}`);
  };
  static approveOrganizationKYCDocument(data) {
    return api[1].post(`/admin/OrganizationDetail/ApproveKycProof`, data);
  };
  static approveBankAccountDocument(data) {
    return api[1].post(`/admin/BankAccount/ApproveKycProof`, data);
  };
  static uploadUserDocumentAsAdmin(data) {
    return api[1].post(`/admin/user/UploadDocument`, data);
  };
  static deleteUserDocumentAsAdmin(fileLibraryId, id, type) {
    return api[1].delete(`/admin/user/DeleteDocument?libId=${fileLibraryId}&id=${id}&type=${type}`);
  };
  static uploadLevelOneDocumentAsAdmin(data) {
    return api[1].post(`/admin/User/UploadDocument`, data);
  };
  static deleteLevelOneDocumentAsAdmin(fileLibraryId, id, type) {
    return api[1].delete(`/admin/User/DeleteDocument?libId=${fileLibraryId}&id=${id}&type=${type}`);
  };

  static updateOrganizationDetailOneLevel(data) {
    return api[1].put(`/admin/OrganizationDetail/UpdateLevelOne`, data);
  };

  static updateOrganizationDetailTwoLevel(data) {
    return api[1].put(`/admin/OrganizationDetail/UpdateLevelTwo`, data);
  };
  static updateOrganizationDetailLivenessTestStatus(data) {
    return api[1].put(`/admin/OrganizationDetail/UpdateLivenessTestStatus`, data);
  };
  static updateOrganizationStatus(data) {
    return api[1].put(`/admin/Organization/UpdateStatus`, data);
  };

  static updateAgentDataOneLevel(data) {
    return api[1].put(`/admin/AgentKyc/UpdateLevelOne`, data);
  };
  static updateAgentDataTwoLevel(data) {
    return api[1].put(`/admin/AgentKyc/UpdateLevelTwo`, data);
  };
  static updateAgentDataThreeLevel(data) {
    return api[1].put(`/admin/bankAccount/update`, data);
  };

  static updateAgentStatus(data) {
    return api[1].put(`/???`, data);
  };

  static updateAgentKyc(data) {
    return api[1].put(`/agent/AgentKyc/Update`, data);
  };

  static disabledOrEnableOrganizationStatus(data) {
    return api[1].put(`/admin/Organization/UpdateDisableStatus`, data);
  };

  static getAgents(pageNumber, pageSize, data) {
    return api[1].post(`/admin/Agent/GetAll?pageNumber=${pageNumber}&pageSize=${pageSize}`, data);
  };

  static getAgentsListAsAdmin() {
    return api[1].get(`/admin/Agent/GetList`);
  };


  static uploadDocumentAsAgent(data) {
    return api[1].post(`/agent/AgentKyc/UploadDocument`, data);
  };
  static updateAgentProfile(data) {
    return api[1].put(`/agent/Agent/Update`, data);
  };
  static bulkUploadDocumentAsAgent(data) {
    return api[1].post(`/agent/AgentKyc/BulkUploadDocument`, data);
  };
  static deleteDocumentAsAgent(fileLibraryId, id, type) {
    return api[1].delete(`/agent/AgentKyc/DeleteDocument?libId=${fileLibraryId}&id=${id}&type=${type}`);
  };
  static updateAgentDataLivenessTestStatus(data) {
    return api[1].put(`/admin/AgentKyc/UpdateLivenessTestStatus`, data);
  };
  static bulkUploadAgentKycDocumentAsAdmin(data) {
    return api[1].post(`/admin/AgentKyc/BulkUploadDocument`, data);
  };
  static uploadAgentKycDocumentAsAdmin(data) {
    return api[1].post(`/admin/AgentKyc/UploadDocument`, data);
  };
  static deleteAgentKycDocumentAsAdmin(fileLibraryId, userId, type) {
    return api[1].delete(`/admin/AgentKyc/DeleteDocument?libId=${fileLibraryId}&id=${userId}&type=${type}`);
  };
  static updateAdminKycDataStatus(data) {
    return api[1].put(`/admin/agent/UpdateStatus`, data);
  };
  static disabledOrEnableAdminKycStatus(data) {
    return api[1].put(`/admin/agent/UpdateDisableStatus`, data);
  };
  static getAgentOrganizations(agentId, data) {
    return api[1].post(`/admin/OrganizationAgent/GetOrganizations/${agentId}`, data);
  };
  static updateAgentKycLevel(data) {
    return api[1].put(`/admin/Agent/UpdateLevel`, data);
  };
  static updateOrganizationKycLevel(data) {
    return api[1].put(`/admin/Organization/UpdateLevel`, data);
  };
  static updateBankAccountStatusAsAdmin(data) {
    return api[1].put(`/admin/bankaccount/updateStatus`, data);
  };
  static getOrganizationAttachedAgentId(organizationId) {
    return api[1].get(`/admin/OrganizationAgent/GetByOrganizationId/${organizationId}`);
  };
  static attachOrganizationToAgentAsAdmin(data) {
    return api[1].put(`/admin/OrganizationAgent/Attache`, data);
  };
  static detachAgentFromOrganizationAsAdmin(organizationId, agentId) {
    return api[1].delete(`/admin/OrganizationAgent/Delete?orgId=${organizationId}&agentId=${agentId}`);
  };
  static getPaymentCustomersAsAdmin(pageNumber, pageSize, data) {
    return api[1].post(`/admin/PaymentCustomer/GetAll?pageNumber=${pageNumber}&pageSize=${pageSize}`, data);
  };
  static getOrganizationWallets() {
    return api[1].get(`/admin/OrganizationWallet/GetAll`);
  };
  static createOrganizationWallet(data) {
    return api[1].post(`/admin/OrganizationWallet/Add`, data);
  };
  static updateOrganizationWallet(data) {
    return api[1].put(`/admin/OrganizationWallet/Update`, data);
  };

}

export default ApiService;
