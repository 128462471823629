import React, { useCallback, useEffect } from 'react'
import Breadcrumbs from '../../../../components/Common/Breadcrumb'
import ReactApexChart from "react-apexcharts"
import {
  Card,
  CardBody,
  Col,
  Row,
  Label,
  Button
} from "reactstrap"
import { Table, } from "react-bootstrap"
import { withTranslation } from "react-i18next"
import { ALL_VALID_FILE_TYPES, ERROR_KEY, VALID_FILE_TYPES_KEY, VALID_IMAGE_TYPES_KEY } from '../../../../Constants/MainKeys'
import { addButtonSpinner, addPageSpinner, addRightSectionSpinner, removeButtonSpinner, removePageSpinner, removeRightSectionSpinner } from '../../../../store/spinner/actions'
import AlertService from '../../../../Services/alertService'
import { useDispatch, useSelector } from 'react-redux'
import HtmlHead from '../../../../components/HtmlHead/HtmlHead'
import RightSectionSpinner from '../../../../components/Spinners/RightSectionSpinner'
import uuid from 'react-uuid'
import ApiService from '../../../../Services/ApiService'
import { useState } from 'react'
import moment from 'moment'
import ReactSelectOption from '../../../../components/SelectOptions/ReactSelectOption'
import NoData from '../../../../components/NoData/NoData'
import ReactPaginate from 'react-paginate'
import GetCurrentDate from '../../../../components/DateTime/GetCurrentDate'
import GetCurrentTime from '../../../../components/DateTime/GetCurrentTime'
import MainService from '../../../../Services/MainService'
import ActionButtonWithOvalSpinner from '../../../../components/Buttons/ActionButtonWithOvalSpinner'


const buttonSpinnerId = uuid();
const ViewKyc = (props) => {

  const { t } = props;
  const dispatch = useDispatch();
  const title = "View KYC";
  const description = "";
  const { rightSectionSpinners } = useSelector(state => state.Spinners);
  const mainService = new MainService();

  const [directorPassport_1, setDirectorPassport_1] = useState(null)
  const [directorAddressDocument_1, setDirectorAddressDocument_1] = useState(null)
  const [directorPassport_2, setDirectorPassport_2] = useState(null)
  const [directorAddressDocument_2, setDirectorAddressDocument_2] = useState(null)
  const [shareholderPercentage_1, setShareholderPercentage_1] = useState(null)
  const [shareholderAddressDocument_1, setShareholderAddressDocument_1] = useState(null)
  const [shareholderPercentage_2, setShareholderPercentage_2] = useState(null)
  const [shareholderAddressDocument_2, setShareholderAddressDocument_2] = useState(null)
  const [addressDocumentation, setAddressDocumentation] = useState(null)


  const [certificateOfInsurance, setCertificateOfInsurance] = useState("ABC123");
  const [numberOfDirectors, setNumberOfDirectors] = useState("2");
  const [directorName, setDirectorName] = useState("John Smith");
  const [directorAddress, setDirectorAddress] = useState("123 Main Street");
  const [directorName_2, setDirectorName_2] = useState("Jane Doe");
  const [numberOfShareholders, setNumberOfShareholders] = useState("2");
  const [shareholderName, setShareholderName] = useState("Alice Johnson");
  const [certificateOfIncumbency, setCertificateOfIncumbency] = useState("XYZ456");
  const [shareholderName_2, setShareholderName_2] = useState("Bob Anderson");
  const [bankAccountNumber, setBankAccountNumber] = useState("9876543210");
  const [certificateOfIncumbency_2, setCertificateOfIncumbency_2] = useState("DEF789");
  const [address, setAddress] = useState("456 Oak Street");
  const [bankAccountRegion, setBankAccountRegion] = useState("New York");
  const [SWIFTCode, setSWIFTCode] = useState("SWFT123");


  const [isForm, setIsForm] = useState(false);

  const onChange = (event, cb) => {
    cb(event.target.value)
  }

  const uploadFile = async (event, cb) => {
    if (!event.target.files.length) { return; }
    let files = [...event.target.files];
    for (let i in files) {
      const file = files[i];
      const fileName = file.name;
      const lastDotIndex = fileName.lastIndexOf('.');
      const fileExtention = lastDotIndex !== -1 ? fileName.substring(lastDotIndex + 1).toLowerCase() : ' ';
      if (ALL_VALID_FILE_TYPES.includes(fileExtention)) {
        let fileType = null;
        if (VALID_IMAGE_TYPES_KEY.includes(fileExtention)) {
          fileType = "image";
        }
        else if (VALID_FILE_TYPES_KEY.includes(fileExtention)) {
          fileType = "file";
        }
        await mainService.readFile(file, ALL_VALID_FILE_TYPES).then(uploadedFile => {
          cb({
            fileName,
            fileType,
            file,
            uploadedFile,
            id: uuid(),
            size: file.size
          })
        }).catch(error => error && AlertService.alert("error", "Invalid file format"));
      } else {
        AlertService.alert("error", "Invalid file format")
        return false;
      }
    }
  };

  const onSubmit = () => {
    setButtonSpinner(buttonSpinnerId);
    setTimeout(() => {
      AlertService.alert("success", "Data saved");
      setIsForm(false);
      extractButtonSpinner(buttonSpinnerId);
      scrollTop();
    }, 2000);
  }

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  const acceptOrReject = () => {
    AlertService.alertConfirm(
      `Are you sure ?`,
      "",
      "Yes",
      "No"
    ).then(() => {
      AlertService.alert("success", "Data saved");
      window.history.back();
    })
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const setRightSectionSpinner = useCallback(spinner => {
    dispatch(addRightSectionSpinner(spinner));
  }, []);

  const extractRightSectionSpinner = useCallback(spinner => {
    dispatch(removeRightSectionSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractRightSectionSpinner(spinnerId);
    spinnerId && extractButtonSpinner(spinnerId);
  }

  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className='page-content position-relative min-h-100vh'>
        <RightSectionSpinner spinner={rightSectionSpinners} />
        <div className='container-fluid'>
        
          <Breadcrumbs
            title="Cryllex"
            breadcrumbItem="Lorem ipsum"
            isShowGoBackButton={true}
            goBack={() => window.history.back()}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className='d-flex justify-content-between flex-wrap'>
                    <h4>Agent: GameStop Co. (Client ID #00001)</h4>
                    <span>*Certified True Document</span>
                  </div>
                  <div className='ms-2 mt-4'>
                    <h5 className='bold mb-4'>Level Three Requirements</h5>
                    <Row>
                      <Col md={6}>
                        <Row className='align-items-center my-2'>
                          <Col sm={5}>
                            <label htmlFor='certificateOfInsurance' className='me-5'>Certificate of Insurance:</label>
                          </Col>
                          <Col sm={7}>
                            {
                              isForm ?
                                <input
                                  id='certificateOfInsurance'
                                  type="text"
                                  value={certificateOfInsurance}
                                  name="certificateOfInsurance"
                                  className={`form-control`}
                                  autoComplete='new-password'
                                  onChange={(event) => onChange(event, setCertificateOfInsurance)}
                                />
                                : <span className='fw-500 font-size-15'> {certificateOfInsurance} </span>
                            }

                          </Col>
                        </Row>
                        <Row className='align-items-center my-2'>
                          <Col sm={5}>
                            <label htmlFor='numberOfDirectors' className='me-5'>Number of Directors:</label>
                          </Col>
                          <Col sm={7}>
                            {
                              isForm ?
                                <input
                                  id='numberOfDirectors'
                                  type="text"
                                  value={numberOfDirectors}
                                  name="numberOfDirectors"
                                  className={`form-control`}
                                  autoComplete='new-password'
                                  onChange={(event) => onChange(event, setNumberOfDirectors)}
                                />
                                : <span className='fw-500 font-size-15'> {numberOfDirectors} </span>
                            }

                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <p className='bold font-size-16 ps-4'>Director #1</p>
                        <Row className='align-items-center my-2'>
                          <Col sm={5}>
                            <label htmlFor='directorName' className='me-5 ps-0 ps-sm-4'>Director Name:</label>
                          </Col>
                          <Col sm={7}>
                            {
                              isForm ?
                                <input
                                  id='directorName'
                                  type="text"
                                  value={directorName}
                                  name="directorName"
                                  className={`form-control`}
                                  autoComplete='new-password'
                                  onChange={(event) => onchange(event, setDirectorName)}
                                />
                                : <span className='fw-500 font-size-15'> {directorName} </span>
                            }

                          </Col>
                        </Row>

                        <Row className='align-items-center my-2'>
                          <Col sm={5}>
                            <label htmlFor='directorPassport' className='me-5 ps-0 ps-sm-4'>Director Passport/ID*:</label>
                          </Col>
                          <Col sm={7}>
                            {
                              directorPassport_1 ?
                                <div className='position-relative border rounded' style={{ width: "100px", height: "100px" }}>

                                  {
                                    (() => {
                                      switch (directorPassport_1.fileType) {
                                        case "image":
                                          return <div style={{ width: "100%", height: "100%", }} className='d-flex justify-content-center align-items-center flex-column'>
                                            <img src={directorPassport_1.uploadedFile} className='rounded' style={{ width: "100%", height: "100%" }} />
                                          </div>
                                        case "file":
                                          return <div style={{ width: "100%", height: "100%", }} className='p-2  d-flex justify-content-center align-items-center flex-column'>
                                            <i className='bx bx-file-blank' style={{ fontSize: "50px" }} ></i>
                                            <small className='max-line-2 mt-1 word-break-break-word'>{directorPassport_1.fileName}</small>
                                          </div>
                                        default:
                                          break;
                                      }
                                    })()
                                  }
                                  {
                                    isForm ?
                                      <i
                                        className='bx bx-x position-absolute font-size-20 text-danger bg-white rounded cursor-pointer border'
                                        style={{ top: "1px", right: "1px" }}
                                        onClick={() => setDirectorPassport_1(null)}
                                      />
                                      : null
                                  }
                                </div>
                                : isForm ? <Button
                                  onClick={MainService.triggerUploadClick}
                                  outline
                                  color="primary"
                                  type='button'
                                >
                                  <input type="file" id='avatarImage' className="d-none" hidden onChange={(event) => uploadFile(event, setDirectorPassport_1)} />
                                  Attach file
                                </Button> : <span className='fw-500 font-size-15'>No attached file yet. </span>
                            }
                          </Col>
                        </Row>

                        <Row className='align-items-center my-2'>
                          <Col sm={5}>
                            <label htmlFor='directorPassport' className='me-5 ps-0 ps-sm-4'>Director Address:</label>
                          </Col>
                          <Col sm={7}>
                            {
                              isForm ?
                                <input
                                  id='directorAddress'
                                  type="text"
                                  value={directorAddress}
                                  name="directorAddress"
                                  className={`form-control`}
                                  autoComplete='new-password'
                                  onChange={(event) => onchange(event, setDirectorAddress)}
                                />
                                : <span className='fw-500 font-size-15'> {directorAddress} </span>
                            }

                          </Col>
                        </Row>

                        <Row className='align-items-center my-2'>
                          <Col sm={5}>
                            <label htmlFor='directorAddressDocument' className='me-5 ps-0 ps-sm-4'>Director Address Document*:</label>
                          </Col>
                          <Col sm={7}>
                            {
                              directorAddressDocument_1 ?
                                <div className='position-relative border rounded' style={{ width: "100px", height: "100px" }}>

                                  {
                                    (() => {
                                      switch (directorAddressDocument_1.fileType) {
                                        case "image":
                                          return <div style={{ width: "100%", height: "100%", }} className='d-flex justify-content-center align-items-center flex-column'>
                                            <img src={directorAddressDocument_1.uploadedFile} className='rounded' style={{ width: "100%", height: "100%" }} />
                                          </div>
                                        case "file":
                                          return <div style={{ width: "100%", height: "100%", }} className='p-2  d-flex justify-content-center align-items-center flex-column'>
                                            <i className='bx bx-file-blank' style={{ fontSize: "50px" }} ></i>
                                            <small className='max-line-2 mt-1 word-break-break-word'>{directorAddressDocument_1.fileName}</small>
                                          </div>
                                        default:
                                          break;
                                      }
                                    })()
                                  }
                                  {
                                    isForm ?
                                      <i
                                        className='bx bx-x position-absolute font-size-20 text-danger bg-white rounded cursor-pointer border'
                                        style={{ top: "1px", right: "1px" }}
                                        onClick={() => setDirectorAddressDocument_1(null)}
                                      />
                                      : null
                                  }
                                </div>
                                : isForm ? <Button
                                  onClick={MainService.triggerUploadClick}
                                  outline
                                  color="primary"
                                  type='button'
                                >
                                  <input type="file" id='avatarImage' className="d-none" hidden onChange={(event) => uploadFile(event, setDirectorAddressDocument_1)} />
                                  Attach file
                                </Button> : <span className='fw-500 font-size-15'>No attached file yet. </span>
                            }
                          </Col>
                        </Row>
                      </Col>

                      <Col md={6}>
                        <p className='bold font-size-16 ps-4'>Director #2</p>
                        <Row className='align-items-center my-2'>
                          <Col sm={5}>
                            <label htmlFor='directorName_2' className='me-5 ps-0 ps-sm-4'>Director Name:</label>
                          </Col>
                          <Col sm={7}>
                            {
                              isForm ?
                                <input
                                  id='directorName_2'
                                  type="text"
                                  value={directorName_2}
                                  name="directorName_2"
                                  className={`form-control`}
                                  autoComplete='new-password'
                                  onChange={(event) => onChange(event, setDirectorName_2)}
                                />
                                : <span className='fw-500 font-size-15'> {directorName_2} </span>
                            }

                          </Col>
                        </Row>

                        <Row className='align-items-center my-2'>
                          <Col sm={5}>
                            <label htmlFor='directorPassport_2' className='me-5 ps-0 ps-sm-4'>Director Passport/ID*:</label>
                          </Col>
                          <Col sm={7}>

                            {
                              directorPassport_2 ?
                                <div className='position-relative border rounded' style={{ width: "100px", height: "100px" }}>

                                  {
                                    (() => {
                                      switch (directorPassport_2.fileType) {
                                        case "image":
                                          return <div style={{ width: "100%", height: "100%", }} className='d-flex justify-content-center align-items-center flex-column'>
                                            <img src={directorPassport_2.uploadedFile} className='rounded' style={{ width: "100%", height: "100%" }} />
                                          </div>
                                        case "file":
                                          return <div style={{ width: "100%", height: "100%", }} className='p-2  d-flex justify-content-center align-items-center flex-column'>
                                            <i className='bx bx-file-blank' style={{ fontSize: "50px" }} ></i>
                                            <small className='max-line-2 mt-1 word-break-break-word'>{directorPassport_2.fileName}</small>
                                          </div>
                                        default:
                                          break;
                                      }
                                    })()
                                  }
                                  {
                                    isForm ?
                                      <i
                                        className='bx bx-x position-absolute font-size-20 text-danger bg-white rounded cursor-pointer border'
                                        style={{ top: "1px", right: "1px" }}
                                        onClick={() => setDirectorPassport_2(null)}
                                      />
                                      : null
                                  }
                                </div>
                                : isForm ? <Button
                                  onClick={MainService.triggerUploadClick}
                                  outline
                                  color="primary"
                                  type='button'
                                >
                                  <input type="file" id='avatarImage' className="d-none" hidden onChange={(event) => uploadFile(event, setDirectorPassport_2)} />
                                  Attach file
                                </Button> : <span className='fw-500 font-size-15'>No attached file yet. </span>
                            }
                          </Col>
                        </Row>

                        <Row className='align-items-center my-2'>
                          <Col sm={5}>
                            <label htmlFor='directorPassport_2' className='me-5 ps-0 ps-sm-4'>Director Address:</label>
                          </Col>
                          <Col sm={7}>
                            {
                              isForm ?
                                <input
                                  id='directorPassport_2'
                                  type="text"
                                  value={directorPassport_2}
                                  name="directorPassport_2"
                                  className={`form-control`}
                                  autoComplete='new-password'
                                  onChange={(event) => onChange(event, setDirectorPassport_2)}
                                />
                                : <span className='fw-500 font-size-15'> {directorPassport_2} </span>
                            }

                          </Col>
                        </Row>

                        <Row className='align-items-center my-2'>
                          <Col sm={5}>
                            <label htmlFor='directorAddressDocument_2' className='me-5 ps-0 ps-sm-4'>Director Address Document*:</label>
                          </Col>
                          <Col sm={7}>
                            {
                              directorAddressDocument_2 ?
                                <div className='position-relative border rounded' style={{ width: "100px", height: "100px" }}>

                                  {
                                    (() => {
                                      switch (directorAddressDocument_2.fileType) {
                                        case "image":
                                          return <div style={{ width: "100%", height: "100%", }} className='d-flex justify-content-center align-items-center flex-column'>
                                            <img src={directorAddressDocument_2.uploadedFile} className='rounded' style={{ width: "100%", height: "100%" }} />
                                          </div>
                                        case "file":
                                          return <div style={{ width: "100%", height: "100%", }} className='p-2  d-flex justify-content-center align-items-center flex-column'>
                                            <i className='bx bx-file-blank' style={{ fontSize: "50px" }} ></i>
                                            <small className='max-line-2 mt-1 word-break-break-word'>{directorAddressDocument_2.fileName}</small>
                                          </div>
                                        default:
                                          break;
                                      }
                                    })()
                                  }
                                  {
                                    isForm ?
                                      <i
                                        className='bx bx-x position-absolute font-size-20 text-danger bg-white rounded cursor-pointer border'
                                        style={{ top: "1px", right: "1px" }}
                                        onClick={() => setDirectorAddressDocument_2(null)}
                                      />
                                      : null
                                  }
                                </div>
                                : isForm ? <Button
                                  onClick={MainService.triggerUploadClick}
                                  outline
                                  color="primary"
                                  type='button'
                                >
                                  <input type="file" id='avatarImage' className="d-none" hidden onChange={(event) => uploadFile(event, setDirectorAddressDocument_2)} />
                                  Attach file
                                </Button> : <span className='fw-500 font-size-15'>No attached file yet. </span>
                            }
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <Row className='align-items-center my-2'>
                          <Col sm={5}>
                            <label htmlFor='numberOfShareholders' className='me-5'>Number of Shareholders:</label>
                          </Col>
                          <Col sm={7}>

                            {
                              isForm ?
                                <input
                                  id='numberOfShareholders'
                                  type="text"
                                  value={numberOfShareholders}
                                  name="numberOfShareholders"
                                  className={`form-control`}
                                  autoComplete='new-password'
                                  onChange={(event) => onChange(event, setNumberOfShareholders)}
                                />
                                : <span className='fw-500 font-size-15'> {numberOfShareholders} </span>
                            }
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <p className='bold font-size-16 ps-4'>Shareholder #1</p>

                        <Row className='align-items-center my-2'>
                          <Col sm={5}>
                            <label htmlFor='shareholderName' className='me-5 ps-0 ps-sm-4'>Shareholder Name:</label>
                          </Col>
                          <Col sm={7}>

                            {
                              isForm ?
                                <input
                                  id='shareholderName'
                                  type="text"
                                  value={shareholderName}
                                  name="shareholderName"
                                  className={`form-control`}
                                  autoComplete='new-password'
                                  onChange={(event) => setShareholderName(event, setShareholderName)}
                                />
                                : <span className='fw-500 font-size-15'> {shareholderName} </span>
                            }
                          </Col>
                        </Row>

                        <Row className='align-items-center my-2'>
                          <Col sm={5}>
                            <label htmlFor='shareholderPercentage' className='me-5 ps-0 ps-sm-4'>Shareholder Percentage (%):</label>
                          </Col>
                          <Col sm={7}>
                            {
                              shareholderPercentage_1 ?
                                <div className='position-relative border rounded' style={{ width: "100px", height: "100px" }}>

                                  {
                                    (() => {
                                      switch (shareholderPercentage_1.fileType) {
                                        case "image":
                                          return <div style={{ width: "100%", height: "100%", }} className='d-flex justify-content-center align-items-center flex-column'>
                                            <img src={shareholderPercentage_1.uploadedFile} className='rounded' style={{ width: "100%", height: "100%" }} />
                                          </div>
                                        case "file":
                                          return <div style={{ width: "100%", height: "100%", }} className='p-2  d-flex justify-content-center align-items-center flex-column'>
                                            <i className='bx bx-file-blank' style={{ fontSize: "50px" }} ></i>
                                            <small className='max-line-2 mt-1 word-break-break-word'>{shareholderPercentage_1.fileName}</small>
                                          </div>
                                        default:
                                          break;
                                      }
                                    })()
                                  }
                                  {
                                    isForm ?
                                      <i
                                        className='bx bx-x position-absolute font-size-20 text-danger bg-white rounded cursor-pointer border'
                                        style={{ top: "1px", right: "1px" }}
                                        onClick={() => setShareholderPercentage_1(null)}
                                      />
                                      : null
                                  }
                                </div>
                                : isForm ? <Button
                                  onClick={MainService.triggerUploadClick}
                                  outline
                                  color="primary"
                                  type='button'
                                >
                                  <input type="file" id='avatarImage' className="d-none" hidden onChange={(event) => uploadFile(event, setShareholderPercentage_1)} />
                                  Attach file
                                </Button> : <span className='fw-500 font-size-15'>No attached file yet. </span>
                            }
                          </Col>
                        </Row>

                        <Row className='align-items-center my-2'>
                          <Col sm={5}>
                            <label htmlFor='certificateOfIncumbency' className='me-5 ps-0 ps-sm-4'>Certificate of Incumbency*:</label>
                          </Col>
                          <Col sm={7}>
                            {
                              isForm ?
                                <input
                                  id='certificateOfIncumbency'
                                  type="text"
                                  value={certificateOfIncumbency}
                                  name="certificateOfIncumbency"
                                  className={`form-control`}
                                  autoComplete='new-password'
                                  onChange={(event) => onChange(event, setCertificateOfIncumbency)}
                                />
                                : <span className='fw-500 font-size-15'> {certificateOfIncumbency} </span>
                            }
                          </Col>
                        </Row>

                        <Row className='align-items-center my-2'>
                          <Col sm={5}>
                            <label htmlFor='shareholderAddressDocument' className='me-5 ps-0 ps-sm-4'>Shareholder Address Document*:</label>
                          </Col>
                          <Col sm={7}>
                            {
                              shareholderAddressDocument_1 ?
                                <div className='position-relative border rounded' style={{ width: "100px", height: "100px" }}>

                                  {
                                    (() => {
                                      switch (shareholderAddressDocument_1.fileType) {
                                        case "image":
                                          return <div style={{ width: "100%", height: "100%", }} className='d-flex justify-content-center align-items-center flex-column'>
                                            <img src={shareholderAddressDocument_1.uploadedFile} className='rounded' style={{ width: "100%", height: "100%" }} />
                                          </div>
                                        case "file":
                                          return <div style={{ width: "100%", height: "100%", }} className='p-2  d-flex justify-content-center align-items-center flex-column'>
                                            <i className='bx bx-file-blank' style={{ fontSize: "50px" }} ></i>
                                            <small className='max-line-2 mt-1 word-break-break-word'>{shareholderAddressDocument_1.fileName}</small>
                                          </div>
                                        default:
                                          break;
                                      }
                                    })()
                                  }
                                  {
                                    isForm ?
                                      <i
                                        className='bx bx-x position-absolute font-size-20 text-danger bg-white rounded cursor-pointer border'
                                        style={{ top: "1px", right: "1px" }}
                                        onClick={() => setShareholderAddressDocument_1(null)}
                                      />
                                      : null
                                  }
                                </div>
                                : isForm ? <Button
                                  onClick={MainService.triggerUploadClick}
                                  outline
                                  color="primary"
                                  type='button'
                                >
                                  <input type="file" id='avatarImage' className="d-none" hidden onChange={(event) => uploadFile(event, setShareholderAddressDocument_1)} />
                                  Attach file
                                </Button> : <span className='fw-500 font-size-15'>No attached file yet. </span>
                            }
                          </Col>
                        </Row>

                      </Col>

                      <Col md={6}>
                        <p className='bold font-size-16 ps-4'>Shareholder #2</p>

                        <Row className='align-items-center my-2'>
                          <Col sm={5}>
                            <label htmlFor='shareholderName_2' className='me-5 ps-0 ps-sm-4'>Shareholder Name:</label>
                          </Col>
                          <Col sm={7}>
                            {
                              isForm ?
                                <input
                                  id='shareholderName_2'
                                  type="text"
                                  value={shareholderName_2}
                                  name="shareholderName_2"
                                  className={`form-control`}
                                  autoComplete='new-password'
                                  onChange={(event) => onChange(event, setShareholderName_2)}
                                />
                                : <span className='fw-500 font-size-15'> {shareholderName_2} </span>
                            }
                          </Col>
                        </Row>

                        <Row className='align-items-center my-2'>
                          <Col sm={5}>
                            <label htmlFor='shareholderPercentage_2' className='me-5 ps-0 ps-sm-4'>Shareholder Percentage (%):</label>
                          </Col>
                          <Col sm={7}>
                            {
                              shareholderPercentage_2 ?
                                <div className='position-relative border rounded' style={{ width: "100px", height: "100px" }}>

                                  {
                                    (() => {
                                      switch (shareholderPercentage_2.fileType) {
                                        case "image":
                                          return <div style={{ width: "100%", height: "100%", }} className='d-flex justify-content-center align-items-center flex-column'>
                                            <img src={shareholderPercentage_2.uploadedFile} className='rounded' style={{ width: "100%", height: "100%" }} />
                                          </div>
                                        case "file":
                                          return <div style={{ width: "100%", height: "100%", }} className='p-2  d-flex justify-content-center align-items-center flex-column'>
                                            <i className='bx bx-file-blank' style={{ fontSize: "50px" }} ></i>
                                            <small className='max-line-2 mt-1 word-break-break-word'>{shareholderPercentage_2.fileName}</small>
                                          </div>
                                        default:
                                          break;
                                      }
                                    })()
                                  }
                                  {
                                    isForm ?
                                      <i
                                        className='bx bx-x position-absolute font-size-20 text-danger bg-white rounded cursor-pointer border'
                                        style={{ top: "1px", right: "1px" }}
                                        onClick={() => setShareholderPercentage_2(null)}
                                      />
                                      : null
                                  }
                                </div>
                                : isForm ? <Button
                                  onClick={MainService.triggerUploadClick}
                                  outline
                                  color="primary"
                                  type='button'
                                >
                                  <input type="file" id='avatarImage' className="d-none" hidden onChange={(event) => uploadFile(event, setShareholderPercentage_2)} />
                                  Attach file
                                </Button> : <span className='fw-500 font-size-15'>No attached file yet. </span>
                            }
                          </Col>
                        </Row>

                        <Row className='align-items-center my-2'>
                          <Col sm={5}>
                            <label htmlFor='certificateOfIncumbency_2' className='me-5 ps-0 ps-sm-4'>Certificate of Incumbency*:</label>
                          </Col>
                          <Col sm={7}>
                            {
                              isForm ?
                                <input
                                  id='certificateOfIncumbency_2'
                                  type="text"
                                  value={certificateOfIncumbency_2}
                                  name="certificateOfIncumbency_2"
                                  className={`form-control`}
                                  autoComplete='new-password'
                                  onChange={(event) => onChange(event, setCertificateOfIncumbency_2)}
                                />
                                : <span className='fw-500 font-size-15'> {certificateOfIncumbency_2} </span>
                            }

                          </Col>
                        </Row>

                        <Row className='align-items-center my-2'>
                          <Col sm={5}>
                            <label htmlFor='shareholderAddressDocument_2' className='me-5 ps-0 ps-sm-4'>Shareholder Address Document*:</label>
                          </Col>
                          <Col sm={7}>
                            {
                              shareholderAddressDocument_2 ?
                                <div className='position-relative border rounded' style={{ width: "100px", height: "100px" }}>

                                  {
                                    (() => {
                                      switch (shareholderAddressDocument_2.fileType) {
                                        case "image":
                                          return <div style={{ width: "100%", height: "100%", }} className='d-flex justify-content-center align-items-center flex-column'>
                                            <img src={shareholderAddressDocument_2.uploadedFile} className='rounded' style={{ width: "100%", height: "100%" }} />
                                          </div>
                                        case "file":
                                          return <div style={{ width: "100%", height: "100%", }} className='p-2  d-flex justify-content-center align-items-center flex-column'>
                                            <i className='bx bx-file-blank' style={{ fontSize: "50px" }} ></i>
                                            <small className='max-line-2 mt-1 word-break-break-word'>{shareholderAddressDocument_2.fileName}</small>
                                          </div>
                                        default:
                                          break;
                                      }
                                    })()
                                  }
                                  {
                                    isForm ?
                                      <i
                                        className='bx bx-x position-absolute font-size-20 text-danger bg-white rounded cursor-pointer border'
                                        style={{ top: "1px", right: "1px" }}
                                        onClick={() => setShareholderAddressDocument_2(null)}
                                      />
                                      : null
                                  }
                                </div>
                                : isForm ? <Button
                                  onClick={MainService.triggerUploadClick}
                                  outline
                                  color="primary"
                                  type='button'
                                >
                                  <input type="file" id='avatarImage' className="d-none" hidden onChange={(event) => uploadFile(event, setShareholderAddressDocument_2)} />
                                  Attach file
                                </Button> : <span className='fw-500 font-size-15'>No attached file yet. </span>
                            }
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row className='mt-3'>
                      <Col md={6}>
                        <label>Bank Account Information:</label>
                        <Row className='align-items-center my-2'>
                          <Col sm={5}>
                            <label htmlFor='bankAccountNumber' className='me-5 ps-0 ps-sm-4'>Bank Account Number: </label>
                          </Col>
                          <Col sm={7}>
                            {
                              isForm ?
                                <input
                                  id='bankAccountNumber'
                                  type="text"
                                  value={bankAccountNumber}
                                  name="bankAccountNumber"
                                  className={`form-control`}
                                  autoComplete='new-password'
                                  onChange={(event) => onChange(event, setBankAccountNumber)}
                                />
                                : <span className='fw-500 font-size-15'> {bankAccountNumber} </span>
                            }

                          </Col>
                        </Row>

                        <Row className='align-items-center my-2'>
                          <Col sm={5}>
                            <label htmlFor='address' className='me-5 ps-0 ps-sm-4'>Address:</label>
                          </Col>
                          <Col sm={7}>
                            {
                              isForm ?
                                <input
                                  id='address'
                                  type="text"
                                  value={address}
                                  name="address"
                                  className={`form-control`}
                                  autoComplete='new-password'
                                  onChange={(event) => onChange(event, setAddress)}
                                />
                                : <span className='fw-500 font-size-15'> {address} </span>
                            }

                          </Col>
                        </Row>

                        <Row className='align-items-center my-2'>
                          <Col sm={5}>
                            <label htmlFor='addressDocumentation' className='me-5 ps-0 ps-sm-4'>Address Documentation*:</label>
                          </Col>
                          <Col sm={7}>

                            {
                              addressDocumentation ?
                                <div className='position-relative border rounded' style={{ width: "100px", height: "100px" }}>

                                  {
                                    (() => {
                                      switch (addressDocumentation.fileType) {
                                        case "image":
                                          return <div style={{ width: "100%", height: "100%", }} className='d-flex justify-content-center align-items-center flex-column'>
                                            <img src={addressDocumentation.uploadedFile} className='rounded' style={{ width: "100%", height: "100%" }} />
                                          </div>
                                        case "file":
                                          return <div style={{ width: "100%", height: "100%", }} className='p-2  d-flex justify-content-center align-items-center flex-column'>
                                            <i className='bx bx-file-blank' style={{ fontSize: "50px" }} ></i>
                                            <small className='max-line-2 mt-1 word-break-break-word'>{addressDocumentation.fileName}</small>
                                          </div>
                                        default:
                                          break;
                                      }
                                    })()
                                  }
                                  {
                                    isForm ?
                                      <i
                                        className='bx bx-x position-absolute font-size-20 text-danger bg-white rounded cursor-pointer border'
                                        style={{ top: "1px", right: "1px" }}
                                        onClick={() => setAddressDocumentation(null)}
                                      />
                                      : null
                                  }
                                </div>
                                : isForm ? <Button
                                  onClick={MainService.triggerUploadClick}
                                  outline
                                  color="primary"
                                  type='button'
                                >
                                  <input type="file" id='avatarImage' className="d-none" hidden onChange={(event) => uploadFile(event, setAddressDocumentation)} />
                                  Attach file
                                </Button> : <span className='fw-500 font-size-15'>No attached file yet. </span>
                            }
                          </Col>
                        </Row>
                      </Col>
                      <Col md={6}>
                        <label>&nbsp;</label>
                        <Row className='align-items-center my-2'>
                          <Col sm={5}>
                            <label htmlFor='bankAccountRegion' className='me-5 ps-0 ps-sm-4'>Bank Account Region:</label>
                          </Col>
                          <Col sm={7}>
                            {
                              isForm ?
                                <input
                                  id='bankAccountRegion'
                                  type="text"
                                  value={bankAccountRegion}
                                  name="bankAccountRegion"
                                  className={`form-control`}
                                  autoComplete='new-password'
                                  onChange={(event) => onChange(event, setBankAccountRegion)}
                                />
                                : <span className='fw-500 font-size-15'> {bankAccountRegion} </span>
                            }

                          </Col>
                        </Row>

                        <Row className='align-items-center my-2'>
                          <Col sm={5}>
                            <label htmlFor='SWIFTCode' className='me-5 ps-0 ps-sm-4'>SWIFT Code:</label>
                          </Col>
                          <Col sm={7}>
                            {
                              isForm ?
                                <input
                                  id='SWIFTCode'
                                  type="text"
                                  value={SWIFTCode}
                                  name="SWIFTCode"
                                  className={`form-control`}
                                  autoComplete='new-password'
                                  onChange={(event) => onChange(event, setSWIFTCode)}
                                />
                                : <span className='fw-500 font-size-15'> {SWIFTCode} </span>
                            }

                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>

                  <div className='mt-4 d-flex justify-content-end flex-wrap'>
                    <div className='d-flex mt-3'>
                      <Button color="success" className='ms-3 px-4' onClick={() => acceptOrReject()}>Accept</Button>
                      <Button color="danger" className='ms-3 px-4' onClick={() => acceptOrReject()}>Reject</Button>
                    </div>
                    <div className='d-flex mt-3'>
                      {
                        isForm ?
                          <ActionButtonWithOvalSpinner
                            type="button"
                            name="Save"
                            className="btn btn-primary btn-block ms-3 px-4"
                            spinnerId={buttonSpinnerId}
                            onClick={onSubmit}
                          />
                          :
                          <Button color="primary" className='ms-3 px-4'
                            onClick={() => {
                              setIsForm(true);
                              scrollTop();
                            }}
                          >
                            Edit
                          </Button>
                      }
                      <Button color="primary" className='ms-3 px-4 ' onClick={() => window.history.back()}>Back</Button>
                    </div>
                  </div>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default withTranslation()(ViewKyc)