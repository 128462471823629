// import React, { useCallback, useEffect, useState } from 'react'
// import Breadcrumbs from '../../../components/Common/Breadcrumb'
// import ReactApexChart from "react-apexcharts"
// import {
//   Card,
//   CardBody,
//   Col,
//   Row,
//   Label,
//   Button
// } from "reactstrap"
// import { Table } from "react-bootstrap"
// import { withTranslation } from "react-i18next"
// import { ERROR_KEY } from '../../../Constants/MainKeys'
// import { addPageSpinner, addRightSectionSpinner, removePageSpinner, removeRightSectionSpinner } from '../../../store/spinner/actions'
// import AlertService from '../../../Services/alertService'
// import { useDispatch, useSelector } from 'react-redux'
// import HtmlHead from '../../../components/HtmlHead/HtmlHead'
// import RightSectionSpinner from '../../../components/Spinners/RightSectionSpinner'
// import uuid from 'react-uuid'
// import ApiService from '../../../Services/ApiService'
// import moment from 'moment'
// import ReactSelectOption from '../../../components/SelectOptions/ReactSelectOption'
// import NoData from '../../../components/NoData/NoData'
// import ReactPaginate from 'react-paginate'
// import GetCurrentDate from '../../../components/DateTime/GetCurrentDate'
// import GetCurrentTime from '../../../components/DateTime/GetCurrentTime'

// const PayingCustomers = (props) => {

//   const { t } = props;
//   const pageSize = 10;
//   const dispatch = useDispatch();
//   const title = "Paying Customers";
//   const description = "";
//   const { rightSectionSpinners } = useSelector(state => state.Spinners);
//   const { language, languages } = useSelector(state => state.Languages);
//   const [customers, setCustomers] = useState([]);
//   const [pagination, setPagination] = useState(null);
//   const [activePageNumber, setActivePageNumber] = useState(0);

//   useEffect(() => {
//     getPaymentCustomersAsAdmin();
//   }, [])

//   const getPaymentCustomersAsAdmin = (pageNumber) => {
//     const spinnerId = uuid();
//     setRightSectionSpinner(spinnerId);
//     ApiService.getPaymentCustomersAsAdmin(pageNumber, pageSize).then(response => {
//       if (response && response.pagination) setPagination(JSON.parse(response.pagination));
//       if (response && response.data) {
//         setCustomers(response.data);
//       }
//       extractRightSectionSpinner(spinnerId);
//     }).catch(error => getFail(error, spinnerId))
//   }

//   const handlePageClick = (event) => {
//     setActivePageNumber(event.selected);
//     getPaymentCustomersAsAdmin(event.selected + 1);
//   };

//   const setRightSectionSpinner = useCallback(spinner => {
//     dispatch(addRightSectionSpinner(spinner));
//   }, []);

//   const extractRightSectionSpinner = useCallback(spinner => {
//     dispatch(removeRightSectionSpinner(spinner));
//   }, []);

//   const getFail = (error, spinnerId) => {
//     error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
//     spinnerId && extractRightSectionSpinner(spinnerId);
//   }

//   return (
//     <>
//       <HtmlHead title={title} description={description} />
//       <div className='page-content position-relative min-h-100vh'>
//         <RightSectionSpinner spinner={rightSectionSpinners} />
//         <div className='container-fluid'>
//           <Breadcrumbs
//             title="Cryllex"
//             breadcrumbItem="Paying Customers"
//           />
//           <Row>
//             <Col lg={12}>
//               <Card>
//                 <CardBody>
//                   <Table striped bordered hover responsive>
//                     <thead>
//                       <tr>
//                         <th>Client ID</th>
//                         <th>Classification</th>
//                         <th>Legal Name</th>
//                         <th>KYC Status</th>
//                         <th>Email</th>
//                         <th>Actions</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       <tr>
//                         <td>#00001</td>
//                         <td>Paying Customer</td>
//                         <td>James Jones</td>
//                         <td>Level 2</td>
//                         <td>mail@gmail.com</td>
//                         <td>
//                           <div className='d-flex flex-nowrap'>
//                             <Button
//                               type="button"
//                               color="primary"
//                               className='shadow-sm me-2 nowrap'
//                               onClick={() => {
//                                 props.history.push(`/${language}/paying-customers/view-transactions`)
//                               }}
//                             >
//                               View Transactions
//                             </Button>
//                             <Button
//                               type="button"
//                               color="primary"
//                               className='shadow-sm nowrap'
//                               onClick={() => {
//                                 props.history.push(`/${language}/paying-customers/view-kyc`)
//                               }}
//                             >
//                               View KYC
//                             </Button>
//                           </div>
//                         </td>
//                       </tr>

//                       <tr>
//                         <td>#00002</td>
//                         <td>Paying Customer</td>
//                         <td>William Adams</td>
//                         <td>Level 1</td>
//                         <td>mail@gmail.com</td>
//                         <td>
//                           <div className='d-flex flex-nowrap'>
//                             <Button
//                               type="button"
//                               color="primary"
//                               className='shadow-sm me-2 nowrap'
//                               onClick={() => {
//                                 props.history.push(`/${language}/paying-customers/view-transactions`)
//                               }}
//                             >
//                               View Transactions
//                             </Button>
//                             <Button
//                               type="button"
//                               color="primary"
//                               className='shadow-sm nowrap'
//                               onClick={() => {
//                                 props.history.push(`/${language}/paying-customers/view-kyc`)
//                               }}
//                             >
//                               View KYC
//                             </Button>
//                           </div>
//                         </td>
//                       </tr>

//                       <tr>
//                         <td>#00003</td>
//                         <td>Paying Customer</td>
//                         <td>Jaden Smith</td>
//                         <td>Level 2</td>
//                         <td>mail@gmail.com</td>
//                         <td>
//                           <div className='d-flex flex-nowrap'>
//                             <Button
//                               type="button"
//                               color="primary"
//                               className='shadow-sm me-2 nowrap'
//                               onClick={() => {
//                                 props.history.push(`/${language}/paying-customers/view-transactions`)
//                               }}
//                             >
//                               View Transactions
//                             </Button>
//                             <Button
//                               type="button"
//                               color="primary"
//                               className='shadow-sm nowrap'
//                               onClick={() => {
//                                 props.history.push(`/${language}/paying-customers/view-kyc`)
//                               }}
//                             >
//                               View KYC
//                             </Button>
//                           </div>
//                         </td>
//                       </tr>
//                     </tbody>
//                   </Table>
//                   {
//                     pagination ?
//                       <div className='p-3 d-flex justify-content-end'>
//                         <Row>
//                           <Col>
//                             <ReactPaginate
//                               nextLabel={t("Next")}
//                               onPageChange={handlePageClick}
//                               pageRangeDisplayed={3}
//                               marginPagesDisplayed={2}
//                               pageCount={pagination.TotalPages}
//                               previousLabel={t("Previous")}
//                               pageClassName="page-item"
//                               pageLinkClassName="page-link"
//                               previousClassName="page-item"
//                               previousLinkClassName="page-link"
//                               nextClassName="page-item"
//                               nextLinkClassName="page-link"
//                               breakLabel="..."
//                               breakClassName="page-item"
//                               breakLinkClassName="page-link"
//                               containerClassName="pagination"
//                               activeClassName="active"
//                               renderOnZeroPageCount={null}
//                               forcePage={activePageNumber}
//                             />
//                           </Col>
//                         </Row>
//                       </div>
//                       : null
//                   }
//                 </CardBody>
//               </Card>
//             </Col>

//           </Row>
//         </div>
//       </div>
//     </>
//   )
// }

// export default withTranslation()(PayingCustomers)

import React, { useCallback, useState, useEffect, useRef } from 'react'
import Breadcrumbs from './../../../components/Common/Breadcrumb'
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Button,
  Label,
  Input
} from "reactstrap"
import { Table } from "react-bootstrap"
import ReactPaginate from 'react-paginate'
import ReactSelectOption from './../../../components/SelectOptions/ReactSelectOption'
import { withTranslation } from "react-i18next"
import ApiService from './../../../Services/ApiService';
import AlertService from './../../../Services/alertService'
import { ERROR_KEY, SPINNER_COLOR, fielsLengths } from './../../../Constants/MainKeys'
import uuid from 'react-uuid'
import { addRightSectionSpinner, removeRightSectionSpinner } from './../../../store/spinner/actions'
import { useDispatch, useSelector } from 'react-redux'
import * as moment from 'moment';
import MainService from './../../../Services/MainService'
import PuffLoader from "react-spinners/PuffLoader"
import HtmlHead from './../../../components/HtmlHead/HtmlHead'
import RightSectionSpinner from './../../../components/Spinners/RightSectionSpinner'
import NoData from '../../../components/NoData/NoData'

function useOutsideAlerter(ref, cb) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        cb(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const PayingCustomers = (props) => {

  const { t } = props;
  const title = "Paying Customers";
  const description = "";
  const pageSize = 10;
  const dispatch = useDispatch();
  const { rightSectionSpinners } = useSelector(state => state.Spinners);
  const { language } = useSelector(state => state.Languages);
  const [isShowFilter, setIsShowFilter] = useState(true);
  const [pagination, setPagination] = useState(null);
  const [activePageNumber, setActivePageNumber] = useState(0);
  const [isShowSpinner, setIsShowSpinner] = useState(false);
  const [isShowTableSpinner, setIsShowTableSpinner] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [txnValueType, setTxnValueType] = useState("");

  const wrapperRef = useRef(null);
  const [isShowChangeTxnValuePopup, setIsShowChangeTxnValuePopup] = useState(false);
  useOutsideAlerter(wrapperRef, setIsShowChangeTxnValuePopup);

  const [searchRequestModel, setSearchRequestModel] = useState(null);
  const [filterData, setFilterData] = useState(null);
  const [filter, setFilter] = useState({
    searchKey: [],
    kycLevel: [],
    isActive: true,
    isApproved: true,
    classificationType: 1, //???
  })

  useEffect(() => {
    getPaymentCustomersAsAdmin(1, filter);
    return () => {
      setTransactions([]);
    }
  }, [])

  const getPaymentCustomersAsAdmin = (pageNumber, filter) => {
    const spinnerId = uuid();
    setRightSectionSpinner(spinnerId);
    let _filter = { ...filter };
    Object.entries(_filter).forEach(([key, item]) => {
      if (Array.isArray(item) && !item.length) {
        _filter[key] = null;
      } else if (!item) {
        if (typeof item !== "number") {
          _filter[key] = null;
        }
      }
    });
    ApiService.getPaymentCustomersAsAdmin(pageNumber, pageSize, _filter).then(response => {
      if (response && response.pagination) setPagination(JSON.parse(response.pagination));
      if (response && response.data) {
        const data = { ...response.data };
        if (data.filter) {
          if (data.filter.serachRequestModel) {
            setSearchRequestModel(data.filter.serachRequestModel);
          }
          setFilterData(data.filter);
        }
        if (data && data.paymentHistory) {
          setTransactions(data.paymentHistory);
        } else {
          setTransactions([]);
        }
      }

      extractRightSectionSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId))
  }

  const handlePageClick = (event) => {
    if (filter.kycLevel || filter.currencyId || filter.cryptoWalletId || filter.createDate || filter.finishDate || filter.fiatMinAmount || filter.fiatMaxAmount || filter.cryptoMinAmount || filter.cryptoMaxAmount) {
      onFilter(event.selected + 1, filter, true);
    } else {
      getPaymentCustomersAsAdmin(event.selected + 1)
    }
    setActivePageNumber(event.selected);
  };

  const onCheckboxChange = (event, fieldName) => {
    setFilter((values) => ({
      ...values,
      [fieldName]: event.target.checked,
    }));
  };

  const onChange = (event, mainIndex, childIndex, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    let _searchKey = [...filter.searchKey];
    _searchKey[mainIndex].value[childIndex] = event.target.value;
    setFilter(prevFilter => ({ ...prevFilter, searchKey: _searchKey }))
  }

  const removeSearchInput = (mainIndex, childIndex) => {
    let _searchKey = [...filter.searchKey];
    let currentObj = _searchKey[mainIndex];
    currentObj.value = currentObj.value.filter((_, index) => index !== childIndex);
    if (!currentObj.value.length) {
      _searchKey = _searchKey.filter((_, idx) => idx !== mainIndex)
    } else {
      _searchKey[mainIndex] = currentObj;
    }
    setFilter(prevFilter => ({ ...prevFilter, searchKey: _searchKey }))
  }

  const addSearchInput = (mainIndex) => {
    let _searchKey = [...filter.searchKey];
    let currentObj = _searchKey[mainIndex];
    const emptyString = currentObj.value.find(element => element.trim() === "");
    if (typeof emptyString !== "undefined") { return false; }
    currentObj.value = [...currentObj.value, ""];
    _searchKey[mainIndex] = currentObj;
    setFilter(prevFilter => ({ ...prevFilter, searchKey: _searchKey }))
  }

  const onFilter = (pageNumber = 1) => {
    let _filter = { ...filter };
    let newSearchKey = {};
    _filter.searchKey.forEach(item => {
      if (item.value.filter(value => value.trim().length > 0).length) {
        newSearchKey[item.name] = item.value.filter(value => value.trim().length > 0);
      }
    });
    if (Object.keys(newSearchKey).length) {
      _filter.searchKey = newSearchKey;
    } else {
      _filter.searchKey = [];
    };

    _filter.kycLevel = _filter.kycLevel.map(item => item.value);

    setFilter(prevFilter => ({ ...prevFilter, searchKey: filter.searchKey, kycLevel: filter.kycLevel }));
    getPaymentCustomersAsAdmin(pageNumber, _filter, scrollToCurrentDivPosition);

  }

  const clearFilter = () => {
    let newData = {
      searchKey: [],
      kycLevel: [],
      currencyId: [],
      cryptoWalletId: [],
      createDate: "",
      finishDate: "",
      fiatMinAmount: "",
      fiatMaxAmount: "",
      cryptoMinAmount: "",
      cryptoMaxAmount: "",
    }
    setFilter(newData);
    setActivePageNumber(0);
    getPaymentCustomersAsAdmin(1, newData);
  }

  const onSearchSelectOptionChange = (item) => {
    let isExist = filter.searchKey.find(el => el.name === item.value);
    if (isExist) { return false; }
    let _searchKey = [...filter.searchKey];
    _searchKey.push({ name: item.value, value: [""] })
    setFilter(prevFilter => ({ ...prevFilter, searchKey: _searchKey }))
  }

  const scrollToCurrentDivPosition = (id = "") => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const removeMultiItem = (index, fieldName) => {
    let arr = [...filter[fieldName]];
    arr = arr.filter((_, idx) => index !== idx)
    setFilter(prevFilter => ({ ...prevFilter, [fieldName]: arr }))
  }

  const onMultiSelectOptionChange = (item, fieldName) => {
    let currentArr = [...filter[fieldName]];
    let isExist = currentArr.find(el => el.value === item.value);
    if (isExist) { return false; }
    currentArr.push({ label: item.label, value: item.value })
    setFilter((values) => ({ ...values, [fieldName]: currentArr }))
  }

  const setRightSectionSpinner = useCallback(spinner => {
    dispatch(addRightSectionSpinner(spinner));
  }, []);

  const extractRightSectionSpinner = useCallback(spinner => {
    dispatch(removeRightSectionSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractRightSectionSpinner(spinnerId);
    setIsShowSpinner(false);
    setIsShowTableSpinner(false);
    localStorage.removeItem("transactionHistoryPageNumber");
  }

  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className='page-content position-relative min-h-100vh'>
        <RightSectionSpinner spinner={rightSectionSpinners} />

        <div className="container-fluid">
          <Breadcrumbs
            title="Cryllex"
            breadcrumbItem="Transactions"
          />
          <Row>
            <Col lg={12}>
              <Row className='mb-4'>
                {
                  filterData && isShowFilter ?
                    <Col xl={3}>
                      <Card id='filter' className='position-relative'>
                        <CardBody className="pb-0">
                          <CardTitle className="mb-4">
                            <div className='d-flex justify-content-between align-items-center cursor-pointer'>
                              Filter by
                              <i
                                className='bx bx-x'
                                style={{ fontSize: "20px" }}
                                onClick={() => setIsShowFilter(false)}
                              />
                            </div>
                            <hr />
                          </CardTitle>
                          <div>
                            <div className='w-100 mb-4'>
                              <div className='mb-3'>
                                {
                                  searchRequestModel ?
                                    <div>
                                      <Label className='text-nowrap'>Search by:</Label>
                                      <ReactSelectOption
                                        isSearchable={true}
                                        selectedValue={(() => {
                                          const selectedValue = { label: "Select", value: "" };
                                          return selectedValue;
                                        })()}
                                        items={Object.keys(searchRequestModel).map(data => ({ label: MainService.camelCaseToWords(data), value: data }))}
                                        onChange={(item) => onSearchSelectOptionChange(item)}
                                      />
                                    </div>
                                    : null
                                }
                              </div>
                              {
                                filter.searchKey && filter.searchKey.length ?
                                  filter.searchKey.map((item, index) => {
                                    return <div key={index} className='border rounded p-2 mb-2'>
                                      <div className='d-flex justify-content-between align-items-center '>
                                        <p className='mb-0'><b>{MainService.camelCaseToWords(item.name)}</b></p>
                                        <Button
                                          color='success'
                                          style={{ padding: "3px" }}
                                          className='d-flex justify-content-center align-items-center bg-success'
                                          onClick={() => addSearchInput(index)}
                                        >
                                          <i className='bx bx-plus' style={{ fontSize: "18px" }}></i>
                                        </Button>

                                      </div>
                                      <hr />
                                      {
                                        item.value && item.value.length ?
                                          item.value.map((el, idx) => {
                                            return <div key={idx} className='d-flex justify-content-between align-items-center mb-1'>
                                              <Input
                                                id="search"
                                                type="search"
                                                placeholder='Search'
                                                className={`form-control resize-none`}
                                                value={el}
                                                onChange={event => onChange(event, index, idx, fielsLengths.length_120)}
                                              />
                                              <Button
                                                outline
                                                color='danger'
                                                style={{ padding: "5px" }}
                                                className='d-flex justify-content-center align-items-center ms-2'
                                                onClick={() => removeSearchInput(index, idx)}
                                              >
                                                <i className='bx bx-trash' style={{ fontSize: "18px" }} />
                                              </Button>
                                            </div>
                                          })
                                          : null
                                      }

                                    </div>
                                  })
                                  : null
                              }
                            </div>
                            <hr />
                          </div>

                          {
                            filterData.kycLevels && filterData.kycLevels.length ?
                              <div className='w-100 mb-4'>
                                <Label className='text-nowrap'>KYC Level</Label>
                                <ReactSelectOption
                                  isSearchable={true}
                                  selectedValue={(() => {
                                    const selectedValue = { label: "Select", value: "" };
                                    return selectedValue;
                                  })()}
                                  items={filterData.kycLevels.map(data => ({ ...data, label: data.value, value: data.key }))}
                                  onChange={(item) => onMultiSelectOptionChange(item, "kycLevel")}
                                />

                                <div>
                                  {
                                    filter.kycLevel && filter.kycLevel.map((item, index) => {
                                      return <div key={index} className='d-flex justify-content-between align-items-center border rounded p-2 mt-1'>
                                        {MainService.camelCaseToWords(item.label)}
                                        <Button
                                          outline
                                          color='danger'
                                          style={{ padding: "5px" }}
                                          className='d-flex justify-content-center align-items-center ms-2'
                                          onClick={() => removeMultiItem(index, "kycLevel")}
                                        >
                                          <i className='bx bx-trash' style={{ fontSize: "18px" }} />
                                        </Button>
                                      </div>
                                    })
                                  }
                                </div>
                              </div>
                              : null
                          }
                          <div className='w-100 mb-4'>
                            <div className="form-check mb-3 ">
                              <Label
                                className="form-check-label cursor-pointer font-size-17 ms-2"
                              >
                                Active
                              </Label>
                              <Input
                                id="isActive"
                                name="isActive"
                                className="custom-checkbox"
                                type="checkbox"
                                checked={filter.isActive}
                                onChange={event => onCheckboxChange(event, "isActive")}
                              />
                              <Label
                                className="form-check-label cursor-pointer font-size-17 ms-2"
                                htmlFor="isActive"
                              >
                                Active
                              </Label>
                            </div>
                          </div>
                          <div className='w-100 mb-4'>
                            <div className="form-check mb-3 ">
                              <Label
                                className="form-check-label cursor-pointer font-size-17 ms-2"
                              >
                                Approved
                              </Label>
                              <Input
                                id="isApproved"
                                name="isApproved"
                                className="custom-checkbox"
                                type="checkbox"
                                checked={filter.isApproved}
                                onChange={event => onCheckboxChange(event, "isApproved")}
                              />
                              <Label
                                className="form-check-label cursor-pointer font-size-17 ms-2"
                                htmlFor="isApproved"
                              >
                                Yes
                              </Label>
                            </div>
                          </div>



                          <div className="mt-4 mb-3 d-flex gap-2 justify-content-end">
                            <Button outline color='primary' onClick={clearFilter} className='text-nowrap'>
                              Clear filter
                            </Button>
                            <Button color='primary' onClick={() => onFilter()} className='px-4'>
                              Filter
                            </Button>
                          </div>
                        </CardBody>
                        {
                          isShowSpinner ?
                            <div className="dashboard-spinner-layer" >
                              <div className="sweet-loading">
                                <PuffLoader size={80} color={SPINNER_COLOR} />
                              </div>
                            </div>
                            : null
                        }
                      </Card>
                    </Col>
                    : null
                }
                <Col xl={filterData && isShowFilter ? 9 : 12}>
                  <Card id='table' className="position-relative">

                    <CardBody className="pb-0">
                      <CardTitle className="mb-4">
                        <div className='d-flex justify-content-between align-items-center'>
                          Paying Customers
                          {
                            !isShowFilter && filterData ?
                              <Button
                                color='primary'
                                size='sm'
                                className='px-3 d-flex align-items-end'
                                onClick={() => {
                                  setIsShowFilter(true);
                                  scrollToCurrentDivPosition("filter")
                                }}
                              >
                                <i className='bx bx-filter me-2' style={{ fontSize: "20px" }}></i>
                                Show Filter
                              </Button>
                              : null
                          }
                        </div>
                        <hr />
                      </CardTitle>
                      {
                        transactions && transactions.length ? (
                          // <Table striped bordered hover responsive>
                          //   <thead>
                          //     <tr>
                          //       <th className="text-nowrap">#</th>
                          //       <th className="text-nowrap">On-Chain Txn ID</th>
                          //       <th className="text-nowrap">Txn Date</th>
                          //       <th className="text-nowrap">Paying Customer</th>
                          //       <th className="text-nowrap">Agent</th>
                          //       <th className="text-nowrap">Merchant / Sole Trader</th>
                          //       {transactions[0].transactionValue && Object.keys(transactions[0].transactionValue).length ? (
                          //         <th className="text-nowrap position-relative">
                          //           <span
                          //             className='cursor-pointer'
                          //             onClick={() => setIsShowChangeTxnValuePopup(!isShowChangeTxnValuePopup)}
                          //           >
                          //             {MainService.camelCaseToWords(txnValueType || Object.keys(transactions[0].transactionValue)[0])}
                          //             &nbsp;
                          //             <b>(<i className='bx bxs-down-arrow'></i>)</b>
                          //           </span>
                          //           {isShowChangeTxnValuePopup && (
                          //             <div
                          //               className='position-absolute shadow border rounded bg-white'
                          //               style={{ top: "33px", left: "0" }}
                          //               ref={wrapperRef}
                          //             >
                          //               {Object.keys(transactions[0].transactionValue).map((item, index) => (
                          //                 <div key={index}>
                          //                   <p
                          //                     className='mb-0 px-3 py-2 cursor-pointer'
                          //                     onClick={() => {
                          //                       setTxnValueType(item);
                          //                       setIsShowChangeTxnValuePopup(false);
                          //                     }}
                          //                   >
                          //                     {MainService.camelCaseToWords(item)}
                          //                   </p>
                          //                   <hr className='m-0' />
                          //                 </div>
                          //               ))}
                          //             </div>
                          //           )}
                          //         </th>
                          //       ) : null}

                          //       <th className="text-nowrap">Token</th>
                          //       <th className="text-nowrap">Wallet</th>
                          //       <th className="text-nowrap">Status</th>
                          //       <th className="text-nowrap">Total Txn Fees</th>
                          //       <th className="text-nowrap">Cryllex Hedging Profit</th>
                          //       <th className="text-nowrap">Action</th>
                          //     </tr>
                          //   </thead>
                          //   <tbody>
                          //     {transactions.map((transaction, index) => {
                          //       const transactionValue = transaction.transactionValue;
                          //       const firstTxnValueKey = transactionValue && Object.keys(transactionValue)[0];
                          //       const displayValue = txnValueType ? transactionValue[txnValueType] : transactionValue[firstTxnValueKey];

                          //       return (
                          //         <tr key={index}>
                          //           <td>{index + 1}</td>
                          //           <td>{transaction.transactionId}</td>
                          //           <td className='text-nowrap'>{moment(MainService.convertUTCDateToLocalDate(new Date(transaction.createDate))).format("LL HH:mm")}</td>
                          //           <td>{transaction.payingCustomerFullName}</td>
                          //           <td>{transaction.agentFullName}</td>
                          //           <td>{transaction.trader}</td>
                          //           <td>{transactionValue ? parseFloat(displayValue).toFixed(2) : ""} {transaction.currency ? transaction.currency.name : ""}</td>
                          //           <td>{transaction.currency ? transaction.currency.name : ""}</td>
                          //           <td>{transaction.walletName}</td>
                          //           <td>{transaction.transactionStatus}</td>
                          //           <td>ToDo</td>
                          //           <td>ToDo</td>
                          //           <td>
                          //             <div>
                          //               <Button
                          //                 type="button"
                          //                 color="primary"
                          //                 className='shadow-sm me-2 text-nowrap'
                          //                 onClick={() => {
                          //                   if (transaction.id) {
                          //                     props.history.push(`/${language}/transaction/view/${transaction.id}`);
                          //                   }
                          //                 }}
                          //               >
                          //                 View Info
                          //               </Button>
                          //             </div>
                          //           </td>
                          //         </tr>
                          //       );
                          //     })}
                          //   </tbody>
                          // </Table>

                          <Table striped bordered hover responsive>
                            <thead>
                              <tr>
                                <th className="text-nowrap">#</th>
                                <th className="text-nowrap">On-Chain Txn ID</th>
                                <th className="text-nowrap">Txn Date</th>
                                <th className="text-nowrap">Paying Customer</th>
                                <th className="text-nowrap">Agent</th>
                                <th className="text-nowrap">Merchant / Sole Trader</th>
                                {transactions[0].transactionValue && transactions[0].transactionValue.length ? (
                                  <th className="text-nowrap position-relative">
                                    <span
                                      className="cursor-pointer"
                                      onClick={() => setIsShowChangeTxnValuePopup(!isShowChangeTxnValuePopup)}
                                    >
                                      {txnValueType || transactions[0].transactionValue[0].name}
                                      &nbsp;
                                      <b>(<i className="bx bxs-down-arrow"></i>)</b>
                                    </span>
                                    {isShowChangeTxnValuePopup && (
                                      <div
                                        className="position-absolute shadow border rounded bg-white"
                                        style={{ top: '33px', left: '0' }}
                                        ref={wrapperRef}
                                      >
                                        {transactions[0].transactionValue.map((item, index) => (
                                          <div key={index}>
                                            <p
                                              className="mb-0 px-3 py-2 cursor-pointer"
                                              onClick={() => {
                                                setTxnValueType(item.name);
                                                setIsShowChangeTxnValuePopup(false);
                                              }}
                                            >
                                              {item.name}
                                            </p>
                                            <hr className="m-0" />
                                          </div>
                                        ))}
                                      </div>
                                    )}
                                  </th>
                                ) : null}
                                <th className="text-nowrap">Token</th>
                                <th className="text-nowrap">Wallet</th>
                                <th className="text-nowrap">Status</th>
                                <th className="text-nowrap">Total Txn Fees</th>
                                <th className="text-nowrap">Cryllex Hedging Profit</th>
                                <th className="text-nowrap">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {transactions.map((transaction, index) => {
                                const transactionValue = transaction.transactionValue;
                                const selectedValue = transactionValue.find(item => item.name === txnValueType);
                                const firstTransactionValue = transactionValue[0];
                                const isFirstValueCrypto = firstTransactionValue && firstTransactionValue.name === "Crypto";
                                const displayValue = selectedValue ?
                                  (selectedValue.name !== "Crypto" ? `${parseFloat(selectedValue.amount).toFixed(2)} ${selectedValue.currency}` : `${selectedValue.amount} ${selectedValue.currency}`)
                                  :
                                  (isFirstValueCrypto ? `${firstTransactionValue.amount} ${firstTransactionValue.currency}` : `${parseFloat(firstTransactionValue.amount).toFixed(2)} ${firstTransactionValue.currency}`);

                                return (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td> <span className='max-line-1' title={transaction.transactionId}>{transaction.transactionId}</span></td>
                                    <td className='text-nowrap'>{moment(MainService.convertUTCDateToLocalDate(new Date(transaction.createDate))).format("LL HH:mm")}</td>
                                    <td>{transaction.payingCustomerFullName}</td>
                                    <td>{transaction.agentFullName}</td>
                                    <td>{transaction.trader}</td>
                                    <td>{displayValue}</td>
                                    <td>{transaction.currency ? transaction.currency.name : ""}</td>
                                    <td>{transaction.walletName}</td>
                                    <td>{transaction.transactionStatus}</td>
                                    <td>ToDo</td>
                                    <td>ToDo</td>
                                    <td>
                                      <div>
                                        <Button
                                          type="button"
                                          color="primary"
                                          className='shadow-sm me-2 text-nowrap'
                                          onClick={() => {
                                            if (transaction.id) {
                                              props.history.push(`/${language}/transaction/view/${transaction.id}`);
                                            }
                                          }}
                                        >
                                          View Info
                                        </Button>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        ) : (
                          <NoData />
                        )
                      }
                    </CardBody>
                    {
                      pagination ?
                        <div className='p-3 d-flex justify-content-end'>
                          <Row>
                            <Col>
                              <ReactPaginate
                                nextLabel={t("Next")}
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={3}
                                marginPagesDisplayed={2}
                                pageCount={pagination.TotalPages}
                                previousLabel={t("Previous")}
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                containerClassName="pagination"
                                activeClassName="active"
                                renderOnZeroPageCount={null}
                                forcePage={activePageNumber}
                              />
                            </Col>
                          </Row>
                        </div>
                        : null
                    }
                    {
                      isShowSpinner || isShowTableSpinner ?
                        <div className="dashboard-spinner-layer" >
                          <div className="sweet-loading">
                            <PuffLoader size={80} color={SPINNER_COLOR} />
                          </div>
                        </div>
                        : null
                    }
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default withTranslation()(PayingCustomers)