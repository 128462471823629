import React from 'react'
import {
  Col,
  Row,
  Input,
} from "reactstrap"
import ReactSelectOption from '../../../../components/SelectOptions/ReactSelectOption';
import { allValidFileTypes } from '../../../../Constants/MainKeys';
import MainService from '../../../../Services/MainService';
import FileComponent from '../../Merchant/Components/FileComponent';
import FilesComponent from '../../Merchant/Components/FilesComponent';
import ApiService from '../../../../Services/ApiService';

export default function LevelTwoRequirementsForm(props) {

  const { data, onChange, uploadFile, setZoomImagePath, onSelectOptionChange, bulkUploadFile, deleteDocument, countries, confirmProof, confirmLoaderId } = props;

  return (
    <Col sm={12}>
      <Row  >
        <Col md={6}>
          <label htmlFor='legalBusinessName' className='mt-3'>Legal Business Name:</label>
          <Input
            id='legalBusinessName'
            type="text"
            value={data.levelTwo.legalBusinessName || ""}
            name="legalBusinessName"
            placeholder='Ex. ABC Corporation'
            className={`form-control`}
            autoComplete='new-password'
            onChange={(event) => onChange(event, "levelTwo", "legalBusinessName")}
          />
        </Col>
        <Col md={6}>
          <label htmlFor='businessRegistrationNumber' className='mt-3'>Business Registration Number:</label>
          <Input
            id='businessRegistrationNumber'
            type="text"
            placeholder='Ex. ABC123456'
            value={data.levelTwo.businessRegistrationNumber || ""}
            name="businessRegistrationNumber"
            className={`form-control`}
            autoComplete='new-password'
            onChange={(event) => onChange(event, "levelTwo", "businessRegistrationNumber")}
          />
        </Col>

        <Col md={6}>
          <label htmlFor='businessRegisteredAddress' className='mt-3'>Business Registration Address:</label>
          <Input
            id='businessRegisteredAddress'
            type="text"
            placeholder='Ex. 789 Oak Road, Villageton, CA, 98765'
            value={data.levelTwo.businessRegisteredAddress || ""}
            name="businessRegisteredAddress"
            className={`form-control`}
            autoComplete='new-password'
            onChange={(event) => onChange(event, "levelTwo", "businessRegisteredAddress")}
          />
        </Col>

        <Col md={6}>
          <label htmlFor='businessCountryOfIncorporation' className='mt-3'>Country of registration/incorporation</label>
          {
            countries && countries.length ?
              <ReactSelectOption
                value={data.levelTwo.businessCountryOfRegistrationId}
                isSearchable={true}
                selectedValue={(() => {
                  const selectedValue = { ...countries.find(item => item.id === data.levelTwo.businessCountryOfRegistrationId) };
                  if (Object.keys(selectedValue).length) {
                    selectedValue.label = selectedValue.name;
                    selectedValue.value = selectedValue.id;
                    return selectedValue;
                  } else {
                    return { label: "Choose...", value: "" }
                  }
                })()}
                items={countries.map(item => ({ label: item.name, value: item.id }))}
                onChange={(item) => onSelectOptionChange(item.value, "levelTwo", "businessCountryOfRegistrationId")}
              />
              : null
          }
        </Col>
        <Col md={6}>
          <label htmlFor='primaryRegulatoryLicencingNumber' className='mt-3'>Primary Licensing Number:</label>
          <Input
            id='primaryRegulatoryLicencingNumber'
            type="text"
            placeholder='Ex. 1234567890'
            value={data.levelTwo.primaryRegulatoryLicencingNumber || ""}
            name="primaryRegulatoryLicencingNumber"
            className={`form-control`}
            autoComplete='new-password'
            onChange={(event) => onChange(event, "levelTwo", "primaryRegulatoryLicencingNumber")}
          />
        </Col>
        {/* <Col md={6}>
          <label htmlFor='apiKey_1' className='mt-3'>{`API Key (OTC -> Agent):`}</label>
          <Input
            id='apiKey_1'
            type="text"
            value={data.levelTwo.apiKey_1 || ""}
            name="apiKey_1"
            className={`form-control`}
            autoComplete='new-password'
            onChange={(event) => onChange(event, "levelTwo", "apiKey_1")}
          />
        </Col>
        <Col md={6}>
          <label htmlFor='apiKey_2' className='mt-3'>{`API Key (Agent -> Agent):`}</label>
          <Input
            id='apiKey_2'
            type="text"
            value={data.levelTwo.apiKey_2 || ""}
            name="apiKey_2"
            className={`form-control`}
            autoComplete='new-password'
            onChange={(event) => onChange(event, "levelTwo", "apiKey_2")}
          />
        </Col> */}
        <Col sm={12} className='mt-3'>
          <Row>
            <Col md={6} className='mb-3'>
              <FilesComponent
                data={data?.levelTwo}
                fieldName="businessRegulatoryLicencingNumberFile"
                labelValue="Primary regulatory licenses (if applicable)"
                setZoomImagePath={setZoomImagePath}
                alertMessage="Allow multiple"
                alertIcon="mdi-alert-circle-outline"
                alertType="info"
                multiple={true}
                deleteDocument={(item) =>
                  deleteDocument({
                    ...item,
                    getFileType: MainService.getOrganizationDetailsFileType,
                    fileObj: "levelTwo",
                    id: data.levelTwo.id
                  })}
                uploadFile={(item) =>
                  bulkUploadFile({
                    ...item,
                    obj: "levelTwo",
                    checkFiltType: MainService.getOrganizationDetailsFileType,
                    method: ApiService.bulkUploadAgentKycDocumentAsAdmin,
                    id: data.levelTwo.id
                  })}
              />
            </Col>
            <Col md={3} className='mb-3'>
              <FileComponent
                data={data?.levelTwo}
                fieldName="businessRegisteredAddressFile"
                labelValue="Business Registration Address Proof"
                setZoomImagePath={setZoomImagePath}
                confirmLoaderId={confirmLoaderId}
                deleteDocument={(item) =>
                  deleteDocument({
                    ...item,
                    getFileType: MainService.getOrganizationDetailsFileType,
                    fileObj: "levelTwo",
                    id: data.levelTwo.id
                  })}
                uploadFile={(item) =>
                  uploadFile({
                    ...item,
                    obj: "levelTwo",
                    checkFiltType: MainService.getOrganizationDetailsFileType,
                    method: ApiService.uploadAgentKycDocumentAsAdmin,
                    id: data.levelTwo.id
                  })}
                confirmProof={(item) =>
                  confirmProof({
                    ...item,
                    fileObj: "levelTwo",
                    fieldName: "otherProofFile",
                  })}
              />
            </Col>
            <Col md={3} className='mb-3'>
              <FileComponent
                data={data?.levelTwo}
                fieldName="businessRegistrationCertificateFile"
                labelValue="Business Registration Certificate Proof"
                setZoomImagePath={setZoomImagePath}
                confirmLoaderId={confirmLoaderId}
                deleteDocument={(item) =>
                  deleteDocument({
                    ...item,
                    getFileType: MainService.getOrganizationDetailsFileType,
                    fileObj: "levelTwo",
                    id: data.levelTwo.id,
                  })}
                uploadFile={(item) =>
                  uploadFile({
                    ...item,
                    obj: "levelTwo",
                    checkFiltType: MainService.getOrganizationDetailsFileType,
                    method: ApiService.uploadAgentKycDocumentAsAdmin,
                    id: data.levelTwo.id
                  })}
                confirmProof={(item) =>
                  confirmProof({
                    ...item,
                    fileObj: "levelTwo",
                    fieldName: "otherProofFile",
                  })}
              />
            </Col>
            <Col md={3} className='mb-3'>
              <FileComponent
                data={data?.levelTwo}
                fieldName="certificateOfIncumbencyFile"
                labelValue="Certificate Of Incumbency Proof"
                setZoomImagePath={setZoomImagePath}
                confirmLoaderId={confirmLoaderId}
                deleteDocument={(item) =>
                  deleteDocument({
                    ...item,
                    getFileType: MainService.getOrganizationDetailsFileType,
                    fileObj: "levelTwo",
                    id: data.levelTwo.id,
                  })}
                uploadFile={(item) =>
                  uploadFile({
                    ...item,
                    obj: "levelTwo",
                    checkFiltType: MainService.getOrganizationDetailsFileType,
                    method: ApiService.uploadAgentKycDocumentAsAdmin,
                    id: data.levelTwo.id
                  })}
                confirmProof={(item) =>
                  confirmProof({
                    ...item,
                    fileObj: "levelTwo",
                    fieldName: "otherProofFile",
                  })}
              />
            </Col>
            <Col md={3} className='mb-3'>
              <FileComponent
                data={data?.levelTwo}
                fieldName="certificateOfIncorporationFile"
                labelValue="Certificate Of Incorporation Proof"
                setZoomImagePath={setZoomImagePath}
                confirmLoaderId={confirmLoaderId}
                deleteDocument={(item) =>
                  deleteDocument({
                    ...item,
                    getFileType: MainService.getOrganizationDetailsFileType,
                    fileObj: "levelTwo",
                    id: data.levelTwo.id,
                  })}
                uploadFile={(item) =>
                  uploadFile({
                    ...item,
                    obj: "levelTwo",
                    checkFiltType: MainService.getOrganizationDetailsFileType,
                    method: ApiService.uploadAgentKycDocumentAsAdmin,
                    id: data.levelTwo.id
                  })}
                confirmProof={(item) =>
                  confirmProof({
                    ...item,
                    fileObj: "levelTwo",
                    fieldName: "otherProofFile",
                  })}

              />
            </Col>
            <Col md={3} className='mb-3'>
              <FileComponent
                data={data?.levelTwo}
                fieldName="otherProofFile"
                labelValue="Other Proof Of Business"
                setZoomImagePath={setZoomImagePath}
                confirmLoaderId={confirmLoaderId}
                deleteDocument={(item) =>
                  deleteDocument({
                    ...item,
                    getFileType: MainService.getOrganizationDetailsFileType,
                    fileObj: "levelTwo",
                    id: data.levelTwo.id,
                  })}
                uploadFile={(item) =>
                  uploadFile({
                    ...item,
                    obj: "levelTwo",
                    checkFiltType: MainService.getOrganizationDetailsFileType,
                    method: ApiService.uploadAgentKycDocumentAsAdmin,
                    id: data.levelTwo.id
                  })}
                confirmProof={(item) =>
                  confirmProof({
                    ...item,
                    fileObj: "levelTwo",
                    fieldName: "otherProofFile",
                  })}
              />
            </Col>

            <Col sm={12}><small className='fw-500'>Allowed file types {allValidFileTypes.map((item, index) => <i key={index}>{item}. </i>)}</small></Col>
          </Row>
        </Col>
      </Row>
    </Col>
  )
}
