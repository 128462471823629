import React from "react";
import PuffLoader from "react-spinners/PuffLoader";
import { SPINNER_COLOR } from "../../Constants/MainKeys";
import useElementSize from "../hooks/useElementSize";

function RightSectionSpinner(props) {

  const { width } = useElementSize('vertical-menu');

  return props.spinner && props.spinner.length ?
    <div
      className="right-section-spinner-layer"
      style={{ left: width, width: `calc(100vw - ${width}px)` }}
    >
      <div className="sweet-loading">
        <PuffLoader size={80} color={SPINNER_COLOR} />
      </div>
    </div>
    : null
}

export default RightSectionSpinner;