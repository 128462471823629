import React, { useCallback, useEffect } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import ReactApexChart from "react-apexcharts"
import {
  Card,
  CardBody,
  Col,
  Row,
  Label
} from "reactstrap"
import { Button } from "react-bootstrap"
import { withTranslation } from "react-i18next"
import { ERROR_KEY } from '../../../Constants/MainKeys'
import { addPageSpinner, addRightSectionSpinner, removePageSpinner, removeRightSectionSpinner } from '../../../store/spinner/actions'
import AlertService from '../../../Services/alertService'
import { useDispatch, useSelector } from 'react-redux'
import HtmlHead from '../../../components/HtmlHead/HtmlHead'
import RightSectionSpinner from '../../../components/Spinners/RightSectionSpinner'
import uuid from 'react-uuid'
import ApiService from '../../../Services/ApiService'
import { useState } from 'react'
import moment from 'moment'
import ReactSelectOption from '../../../components/SelectOptions/ReactSelectOption'

const AdminDashboard = (props) => {

  const { t } = props;
  const dispatch = useDispatch();
  const title = "Admin Dashboard";
  const description = "";
  const { language } = useSelector(state => state.Languages);
  const { rightSectionSpinners } = useSelector(state => state.Spinners);

  const [apexFields, setApexFields] = useState(null);

  useEffect(() => {
    setApexFields({
      options: {
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: ["Jan", "Mar", "Jun", "Sep", "Dec"],
        }
      },
      series: [{
        name: '',
        data: generateRandomNumbers(5)
      }],
    })
  }, []);

  const generateRandomNumbers = (length) => {
    const numbers = [];
    for (let i = 0; i < length; i++) {
      const randomNumber = Math.floor(Math.random() * (100 - 10 + 1)) + 10;
      numbers.push(randomNumber);
    }
    return numbers;
  }

  const setRightSectionSpinner = useCallback(spinner => {
    dispatch(addRightSectionSpinner(spinner));
  }, []);

  const extractRightSectionSpinner = useCallback(spinner => {
    dispatch(removeRightSectionSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractRightSectionSpinner(spinnerId);
  }

  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className='page-content position-relative min-h-100vh'>
        <RightSectionSpinner spinner={rightSectionSpinners} />
        <div className='container-fluid'>

        
        <Breadcrumbs
          title="Cryllex"
          breadcrumbItem="Admin Dashboard"
        />
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <Row>
                  <Col sm={12}>
                    {
                      apexFields ?
                        <div id="area-chart" dir="ltr" className='border'>
                          <ReactApexChart
                            options={apexFields?.options}
                            series={apexFields?.series}
                            type="area"
                            height={350}
                            className="apex-charts"
                          />
                        </div>
                        : null
                    }
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        </div>
      </div>
    </>
  )
}

export default withTranslation()(AdminDashboard)