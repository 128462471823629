import {
  ADD_PAGE_SPINNER,
  REMOVE_PAGE_SPINNER,
  ADD_BUTTON_SPINNER,
  REMOVE_BUTTON_SPINNER,
  REMOVE_RIGHT_SECTION,
  ADD_RIGHT_SECTION
} from './actionTypes';

export function addRightSectionSpinner(spinnerId) {
  return {
    type: ADD_RIGHT_SECTION,
    payload: spinnerId
  };
};

export function removeRightSectionSpinner(spinnerId) {
  return {
    type: REMOVE_RIGHT_SECTION,
    payload: spinnerId
  };
};

export function addPageSpinner(spinnerId) {
  return {
    type: ADD_PAGE_SPINNER,
    payload: spinnerId
  };
};

export function removePageSpinner(spinnerId) {
  return {
    type: REMOVE_PAGE_SPINNER,
    payload: spinnerId
  };
};

export function addButtonSpinner(spinnerId) {
  return {
    type: ADD_BUTTON_SPINNER,
    payload: spinnerId
  };
};

export function removeButtonSpinner(spinnerId) {
  return {
    type: REMOVE_BUTTON_SPINNER,
    payload: spinnerId
  };
};



