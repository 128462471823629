// TransactionView

import React, { useCallback, useEffect, useState } from 'react'
import Breadcrumbs from '../../../../components/Common/Breadcrumb'
import ReactApexChart from "react-apexcharts"
import {
  Card,
  CardBody,
  Col,
  Row,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import { Table, Button } from "react-bootstrap"
import { withTranslation } from "react-i18next"
import { ERROR_KEY } from '../../../../Constants/MainKeys'
import { addPageSpinner, addRightSectionSpinner, removePageSpinner, removeRightSectionSpinner } from '../../../../store/spinner/actions'
import AlertService from '../../../../Services/alertService'
import { useDispatch, useSelector } from 'react-redux'
import HtmlHead from '../../../../components/HtmlHead/HtmlHead'
import RightSectionSpinner from '../../../../components/Spinners/RightSectionSpinner'
import uuid from 'react-uuid'
import ApiService from '../../../../Services/ApiService'
import moment from 'moment'
import ReactSelectOption from '../../../../components/SelectOptions/ReactSelectOption'
import NoData from '../../../../components/NoData/NoData'
import ReactPaginate from 'react-paginate'
import GetCurrentDate from '../../../../components/DateTime/GetCurrentDate'
import GetCurrentTime from '../../../../components/DateTime/GetCurrentTime'
import { Link } from 'react-router-dom'


const TransactionView = (props) => {

  const { transactionId } = props.match.params;
  const dispatch = useDispatch();
  const title = "Transaction View";
  const description = "";
  const { rightSectionSpinners } = useSelector(state => state.Spinners);
  const [transactionData, setTransactionData] = useState(null);

  const [isShowCustomerInformationModal, setIsShowCustomerInformationModal] = useState(false);

  useEffect(() => {
    if (!transactionId) {
      return false;
    }
    getTransactionData()
    return () => {
      setTransactionData(null)
    }
  }, [transactionId])

  const getTransactionData = () => {
    const spinnerId = uuid();
    addRightSectionSpinner(spinnerId);
    ApiService.getTransactionDataById(transactionId).then(response => {
      if (response && response.data) {
        setTransactionData(response.data);
      }
      extractRightSectionSpinner(spinnerId)
    }).catch(error => getFail(error, spinnerId))
  }

  const setRightSectionSpinner = useCallback(spinner => {
    dispatch(addRightSectionSpinner(spinner));
  }, []);

  const extractRightSectionSpinner = useCallback(spinner => {
    dispatch(removeRightSectionSpinner(spinner));
  }, []);

  const onHide = () => {
    setIsShowCustomerInformationModal(false);
  }

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractRightSectionSpinner(spinnerId);
  }

  return (
    <>
      <Modal
        isOpen={isShowCustomerInformationModal}
        size="lg"
        role="dialog"
        autoFocus={true}
        centered={true}
        tabIndex="-1"
        toggle={() => {
          setIsShowCustomerInformationModal(!isShowCustomerInformationModal)
        }}
      >
        <div className="modal-content">
          <ModalHeader
            toggle={() => {
              setIsShowCustomerInformationModal(!isShowCustomerInformationModal)
            }}
          >
            <span className='font-size-20'>Customer Information</span>
          </ModalHeader>
          <ModalBody>
            <Table bordered hover >

              <tbody>
                <tr>
                  <td className='bold font-size-16'>Name</td>
                  <td className='font-size-14'>Steven Steven</td>
                </tr>
                <tr>
                  <td className='bold font-size-16'>Email</td>
                  <td className='font-size-14'>mail@gmail.com</td>
                </tr>
                <tr>
                  <td className='bold font-size-16'>Wallet Address</td>
                  <td className='font-size-14'>0x97g3tid6hg83ojhosl7 ( <Link to="#" className="underline">view on blockscanner</Link> )</td>
                </tr>
                <tr>
                  <td className='bold font-size-16'>Phone Number</td>
                  <td className='font-size-14'>+852 8745 7465</td>
                </tr>
                <tr>
                  <td className='bold font-size-16'>Shipping Address</td>
                  <td className='font-size-14'>Hong Kong, Hong Kong, Sha Tin, City One Plaza, Unit 9F</td>
                </tr>
                <tr>
                  <td className='bold font-size-16'>Billing Address</td>
                  <td className='font-size-14'>Hong Kong, Hong Kong, Sha Tin, City One Plaza, Unit 9F</td>
                </tr>

              </tbody>
            </Table>
          </ModalBody>
          <ModalFooter className='d-flex justify-content-center'>
            <Button
              type="button"
              className='shadow-sm'
            >
              See More
            </Button>
            <Button
              type="button"
              className='shadow-sm'
              onClick={() => setIsShowCustomerInformationModal(false)}
            >
              Close
            </Button>
          </ModalFooter>
        </div>
      </Modal>

      <HtmlHead title={title} description={description} />
      <div className='page-content position-relative min-h-100vh'>
        <RightSectionSpinner spinner={rightSectionSpinners} />
        <div>
          <h2 className='mb-2 font-size-22'>
            <GetCurrentTime />
          </h2>
          <h2 className='mb-3 font-size-18'>
            <GetCurrentDate />
          </h2>
        </div>
        <Row className='mt-4'>
          <Col lg={12}>
            <Card>
              <div className='px-4 pt-4'>
                <h4>Transaction Summary</h4>
                <span>Order Reference #00001</span>
              </div>

              <div className='m-3 border rounded'>

                <div className='bg-light p-3 d-flex justify-content-between flex-wrap'>

                  <div className='d-flex align-items-center mt-2'>
                    <div className='bg-success me-3' style={{ width: "20px", height: "20px", borderRadius: "50%" }}></div>
                    <h3 className='text-success m-0'>Confirmed</h3>
                  </div>

                  <div className='mt-2'>
                    <h4 className='m-0'>05/27/2023 at 7:35 pm EST</h4>
                  </div>

                  <div className='mt-2'>
                    <Link to="#" className="underline font-size-18">View TXN</Link>
                  </div>

                </div>

                <Row className='p-3'>
                  <Col md={6} className='d-md-flex justify-content-center'>
                    <div className=''>
                      <p className='font-size-16'>Merchant: Gamestop</p>
                      <p className='font-size-16'>User Wallet: Metamask</p>
                      <p className='font-size-16'>Method: One time link</p>
                      <Link
                        to="#"
                        className="underline d-flex justify-content-end mt-4"
                        onClick={() => {
                          setIsShowCustomerInformationModal(true)
                        }}
                      >
                        Customer Information
                      </Link>
                    </div>
                  </Col>
                  <Col md={6} className='d-md-flex justify-content-center'>
                    <div className='border border-dark d-inline-block rounded p-3 px-5 text-center'>
                      <p>Order Total</p>
                      <h4>$247.89 USD</h4>
                      <span>(0.006857 BTC)</span>
                    </div>
                  </Col>
                </Row>


                <div className='bg-primary p-3'>
                  <span className='text-white font-size-16'>
                    Transaction Details
                  </span>
                </div>

                <div className='p-3'>
                  <Table striped bordered hover>
                    <tbody>
                      <tr>
                        <td>TXN Fee</td>
                        <td>15.34 USD (0.000037 BTC)</td>
                      </tr>
                      <tr>
                        <td>Currency</td>
                        <td>BTC</td>
                      </tr>
                      <tr>
                        <td>Exchange Rate</td>
                        <td>1 BTC = 26742.50</td>
                      </tr>
                      <tr>
                        <td>FX Revenue from Risk Pricing</td>
                        <td></td>
                      </tr>

                    </tbody>
                  </Table>
                </div>

              </div>


            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default TransactionView