import React, { useState } from 'react'
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Row,
} from "reactstrap"
import { useSelector } from 'react-redux'
import HtmlHead from '../../../components/HtmlHead/HtmlHead'
import RightSectionSpinner from '../../../components/Spinners/RightSectionSpinner'
import moment from 'moment'
import Table from 'react-bootstrap/Table';
import ReactPaginate from 'react-paginate'
import ReactSelectOption from '../../../components/SelectOptions/ReactSelectOption'
import Breadcrumbs from './../../../components/Common/Breadcrumb'

const TransactionsOverview = (props) => {

  const title = "Client Database";
  const description = "";

  const { rightSectionSpinners } = useSelector(state => state.Spinners);
  const { user } = useSelector(state => state.Login);
  const { language, languages } = useSelector(state => state.Languages);

  const [search, setSearch] = useState("")
  const [fieldId0, setFieldId0] = useState(1)
  const [fieldId1, setFieldId1] = useState(1)
  const [fieldId2, setFieldId2] = useState(1)
  const [fieldId3, setFieldId3] = useState(1)

  const data0 = [
    {
      id: 1,
      name: "All Clients 1"
    },
    {
      id: 2,
      name: "All Clients 2"
    },
    {
      id: 3,
      name: "All Clients 3"
    },
  ]

  const data1 = [
    {
      id: 1,
      name: "Lorem 1"
    },
    {
      id: 2,
      name: "Lorem 2"
    },
    {
      id: 3,
      name: "Lorem 3"
    },
  ]

  const data2 = [
    {
      id: 1,
      name: "Lorem 1"
    },
    {
      id: 2,
      name: "Lorem 2"
    },
    {
      id: 3,
      name: "Lorem 3"
    },
  ]

  const data3 = [
    {
      id: 1,
      name: "Lorem 1"
    },
    {
      id: 2,
      name: "Lorem 2"
    },
    {
      id: 3,
      name: "Lorem 3"
    },
  ]

  const onSelectOptionsChange = (item, cb) => {
    if (!item) { return false };
    cb(item.value)
  }

  const onChange = (event, cb, maxLength = Infinity) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    cb(event.target.value)
  }

  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className='page-content position-relative min-h-100vh'>
        <RightSectionSpinner spinner={rightSectionSpinners} />
        {/* 
        <div className="d-flex flex-wrap justify-content-between mb-3">
          <h4 className=" me-2">{moment(new Date()).format("LL")}</h4>
        </div> */}
        {/* <div className={`page-title-box d-sm-flex align-items-center justify-content-between`}>
          <div className="d-flex align-items-center">
            <h4 className="mb-0 font-size-22">{`Welcome ${user.fullName}, to your Transactions Overview.`}</h4>
          </div>
        </div> */}
        <Breadcrumbs
          title="Cryllex"
          breadcrumbItem="Transaction overview"
        />

        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <Row>
                  <Col sm={12} className='mb-2'>
                    <div className='d-flex justify-content-between align-items-center'>
                      <div className='w-100'>
                        <div className='fs-5'>
                          {/* <div className='d-flex justify-content-between flex-wrap'>
                            <p className='fw-500 mb-2'>[CLIENT_NAME] Transactions</p>
                            <div className='w-100' style={{ maxWidth: "150px" }}>
                              <ReactSelectOption
                                value={fieldId0}
                                className="fs-6"
                                selectedValue={(() => {
                                  const selectedValue = { ...data0.find(data => data.id === fieldId0) };
                                  if (selectedValue) {
                                    selectedValue.label = selectedValue.name;
                                    selectedValue.value = selectedValue.id;
                                  }
                                  return selectedValue;
                                })()}
                                items={data0.map(data => ({ label: data.name, value: data.id }))}
                                onChange={(item) => onSelectOptionsChange(item, setFieldId0)}
                              />
                            </div>

                          </div> */}
                          <div className='d-md-flex justify-content-between align-items-center'>
                            <div className='d-flex align-items-center'>
                              <p className='fs-6 fw-500 me-3 mb-0'>Filter:</p>
                              <div className='d-flex gap-2 flex-nowrap w-100'>
                                <div className='w-100' style={{ maxWidth: "150px" }}>
                                  <ReactSelectOption
                                    value={fieldId1}
                                    className="fs-6"
                                    selectedValue={(() => {
                                      const selectedValue = { ...data1.find(data => data.id === fieldId1) };
                                      if (selectedValue) {
                                        selectedValue.label = selectedValue.name;
                                        selectedValue.value = selectedValue.id;
                                      }
                                      return selectedValue;
                                    })()}
                                    items={data1.map(data => ({ label: data.name, value: data.id }))}
                                    onChange={(item) => onSelectOptionsChange(item, setFieldId1)}
                                  />
                                </div>
                                <div className='w-100' style={{ maxWidth: "150px" }}>
                                  <ReactSelectOption
                                    value={fieldId2}
                                    className="fs-6"
                                    selectedValue={(() => {
                                      const selectedValue = { ...data2.find(data => data.id === fieldId2) };
                                      if (selectedValue) {
                                        selectedValue.label = selectedValue.name;
                                        selectedValue.value = selectedValue.id;
                                      }
                                      return selectedValue;
                                    })()}
                                    items={data2.map(data => ({ label: data.name, value: data.id }))}
                                    onChange={(item) => onSelectOptionsChange(item, setFieldId2)}
                                  />
                                </div>
                                <div className='w-100' style={{ maxWidth: "150px" }}>
                                  <ReactSelectOption
                                    value={fieldId3}
                                    className="fs-6"
                                    selectedValue={(() => {
                                      const selectedValue = { ...data3.find(data => data.id === fieldId3) };
                                      if (selectedValue) {
                                        selectedValue.label = selectedValue.name;
                                        selectedValue.value = selectedValue.id;
                                      }
                                      return selectedValue;
                                    })()}
                                    items={data3.map(data => ({ label: data.name, value: data.id }))}
                                    onChange={(item) => onSelectOptionsChange(item, setFieldId3)}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className='app-search'>
                              <div className='position-relative'>
                                <Input
                                  id="search"
                                  type="text"
                                  placeholder='Search bar'
                                  className={`form-control resize-none`}
                                  value={search}
                                  onChange={event => onChange(event, setSearch)}
                                />
                                <span className="bx bx-search-alt"></span>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>

                    </div>
                  </Col>
                  <Col sm={12}>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Lorem 1</th>
                          <th>Lorem 2</th>
                          <th>Lorem 3</th>
                          <th>Lorem 4</th>
                          <th className='text-cneter'>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Lorem 1</td>
                          <td>Lorem 2</td>
                          <td>Lorem 3</td>
                          <td>Lorem 4</td>
                          <td className='text-cneter'>
                            <Button
                              type='button'
                              color="primary"
                              onClick={() => props.history.push(`/${language}/transactions-overview/${1}`)}
                            >
                              View Info
                            </Button>
                          </td>
                        </tr>
                        <tr>
                          <td>Lorem 1</td>
                          <td>Lorem 2</td>
                          <td>Lorem 3</td>
                          <td>Lorem 4</td>
                          <td className='text-cneter'>
                            <Button
                              type='button'
                              color="primary"
                              onClick={() => props.history.push(`/${language}/transactions-overview/${1}`)}
                            >
                              View Info
                            </Button>
                          </td>
                        </tr>
                        <tr>
                          <td>Lorem 1</td>
                          <td>Lorem 2</td>
                          <td>Lorem 3</td>
                          <td>Lorem 4</td>
                          <td className='text-cneter'>
                            <Button
                              type='button'
                              color="primary"
                              onClick={() => props.history.push(`/${language}/transactions-overview/${1}`)}
                            >
                              View Info
                            </Button>
                          </td>
                        </tr>

                      </tbody>
                    </Table>
                    <div className='py-3 d-flex justify-content-end'>

                      <ReactPaginate
                        nextLabel="Next"
                        onPageChange={() => { }}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        // pageCount={pagination.TotalPages}
                        pageCount={10}
                        previousLabel="Previous"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination mb-0"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                        // forcePage={activePageNumber}
                        forcePage={0}
                      />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

        </Row>
      </div>
    </>
  );
}

export default TransactionsOverview;

