import React from 'react'
import Breadcrumbs from './../../../components/Common/Breadcrumb'
import { Card, CardBody, Col, Row } from "reactstrap"

export default function AnnualTaxForms() {
  return (
    <div className='page-content'>
      <div className="container-fluid">
        <Breadcrumbs title="Cryllex" breadcrumbItem="Tax & Accouting" />
        <Row className="mb-3">
          <Col lg={12}>
            <div> <h4>2022</h4><hr /></div>
            <Card className="mini-stats-wid my-1">
              <CardBody className='p-3'>
                <div className="me-1 d-flex justify-content-between align-items-center">
                  <h5 className="mb-0">(2022 Tax Report Name)</h5>
                  <div> <b className='d-block'>4/05/2022</b></div>
                  <div className='d-flex'>
                    <div className="avatar-sm ms-auto mx-1">
                      <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                        <i className='bx bx-search'></i>
                      </div>
                    </div>
                    <div className="avatar-sm ms-auto mx-1">
                      <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                        <i className='bx bx-download'></i>
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
            <div className='mt-5'> <h4>2021</h4><hr /></div>
            <Card className="mini-stats-wid my-1">
              <CardBody className='p-3'>
                <div className="me-1 d-flex justify-content-between align-items-center">
                  <h5 className="mb-0">(2022 Tax Report Name)</h5>
                  <div> <b className='d-block'>4/05/2022</b></div>
                  <div className='d-flex'>
                    <div className="avatar-sm ms-auto mx-1">
                      <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                        <i className='bx bx-search'></i>
                      </div>
                    </div>
                    <div className="avatar-sm ms-auto mx-1">
                      <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                        <i className='bx bx-download'></i>
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
            <div className='mt-5'> <h4>2020</h4><hr /></div>
            <Card className="mini-stats-wid my-1">
              <CardBody className='p-3'>
                <div className="me-1 d-flex justify-content-between align-items-center">
                  <h5 className="mb-0">(2022 Tax Report Name)</h5>
                  <div> <b className='d-block'>4/05/2022</b></div>
                  <div className='d-flex'>
                    <div className="avatar-sm ms-auto mx-1">
                      <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                        <i className='bx bx-search'></i>
                      </div>
                    </div>
                    <div className="avatar-sm ms-auto mx-1">
                      <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                        <i className='bx bx-download'></i>
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
            <Card className="mini-stats-wid my-1">
              <CardBody className='p-3'>
                <div className="me-1 d-flex justify-content-between align-items-center">
                  <h5 className="mb-0">(2022 Tax Report Name)</h5>
                  <div> <b className='d-block'>4/05/2022</b></div>
                  <div className='d-flex'>
                    <div className="avatar-sm ms-auto mx-1">
                      <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                        <i className='bx bx-search'></i>
                      </div>
                    </div>
                    <div className="avatar-sm ms-auto mx-1">
                      <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                        <i className='bx bx-download'></i>
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}
