import React, { useState, useEffect } from 'react';
import moment from 'moment';

function GetCurrentDate() {
  const [currentDate, setCurrentDate] = useState(moment().format('dddd, MMMM Do, YYYY'));

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDate(moment().format('dddd, MMMM Do, YYYY'));
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return currentDate;
}

export default GetCurrentDate;
