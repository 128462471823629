import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

function OvalButtonSpinner(props) {

  return (
    props.spinner && props.spinner.length ?
      <div className="sweet-loading d-flex">
        <ClipLoader size={20} color={props.spinnerColor} />
      </div>
      : null
  );
}

export default OvalButtonSpinner;