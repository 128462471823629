import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import warningSvg from "../assets/icons/alert-icons/warning.svg"
import { toast } from 'react-toastify';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"

const getAlertType = (type, cb, error) => {
  if (!type) { cb.error(error) };
  switch (type) {
    case 'info':
      return cb.info(error);
    case 'success':
      return cb.success(error);
    case 'warning':
      return cb.warning(error);
    case 'error':
      return cb.error(error);
    default:
      break;
  }
}

export default class AlertService {

  static checkMessageType = (respcode) => {
    switch (respcode) {
      case 0:
        return "success"
      case 1:
        return "error"
      case 2:
        return "warning"
      case 3:
        return "info"
      case 4:
        return "warning"
      default:
        return "error"
    }
  };

  static alert = (type, error) => {
    const respMessage = typeof error === "object" ? error.message || error.respmess : error;
    if (!respMessage || (respMessage && respMessage.includes("401"))) { return false; }
    getAlertType(type, toast, respMessage);
  }

  static alertConfirm = (title, message, yes, no) => {
    return new Promise((resolve, reject) => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <Modal
              isOpen={true}
              toggle={onClose}
              scrollable={true}
              className='alert-confirm-modal'
            >
              <ModalHeader className=''>
                {
                  title ? title : null
                }
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={onClose} />
              </ModalHeader>
              {
                message ?
                  <ModalBody>
                    <p>{message}</p>
                  </ModalBody>
                  : null
              }
              <ModalFooter>
                <button
                  type="button"
                  className="btn btn-light px-4"
                  // onClick={() => { reject(); onClose(); }}
                  onClick={() => { onClose(); }}
                >
                  {no}
                </button>
                <button
                  type="button"
                  className="btn btn-primary px-4"
                  onClick={() => { resolve(); onClose(); }}
                >
                  {yes}
                </button>
              </ModalFooter>
            </Modal>
          )
        }
      })
    })
  }

}
