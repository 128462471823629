import React, { useCallback, useEffect } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import ReactApexChart from "react-apexcharts"
import {
  Card,
  CardBody,
  Col,
  Row,
  Label
} from "reactstrap"
import { withTranslation } from "react-i18next"
import { ERROR_KEY } from '../../../Constants/MainKeys'
import { addPageSpinner, addRightSectionSpinner, removePageSpinner, removeRightSectionSpinner } from '../../../store/spinner/actions'
import AlertService from '../../../Services/alertService'
import { useDispatch, useSelector } from 'react-redux'
import HtmlHead from '../../../components/HtmlHead/HtmlHead'
import RightSectionSpinner from '../../../components/Spinners/RightSectionSpinner'
import uuid from 'react-uuid'
import ApiService from '../../../Services/ApiService'
import { useState } from 'react'
import moment from 'moment'
import ReactSelectOption from '../../../components/SelectOptions/ReactSelectOption'

const Analytics = (props) => {

  const { t } = props;
  const dispatch = useDispatch();
  const title = "Analytics";
  const description = "";
  const { rightSectionSpinners } = useSelector(state => state.Spinners);
  const [data, setData] = useState(null);

  const series = [
    {
      name: "Current",
      data: [77, 36, 65, 47, 51, 32, 45, 28, 31, 56],
    },
  ]

  const options = {
    chart: {
      height: 350,
      type: "area",

    },
    colors: ["#3AFD6D"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.45,
        opacityTo: 0.05,
        stops: [20, 100, 100, 100],
      },
    },

    markers: {
      size: 3,
      strokeWidth: 3,

      hover: {
        size: 4,
        sizeOffset: 2,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
    },
  }

  const setRightSectionSpinner = useCallback(spinner => {
    dispatch(addRightSectionSpinner(spinner));
  }, []);

  const extractRightSectionSpinner = useCallback(spinner => {
    dispatch(removeRightSectionSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractRightSectionSpinner(spinnerId);
  }

  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className='page-content position-relative min-h-100vh'>
        <RightSectionSpinner spinner={rightSectionSpinners} />
        <Breadcrumbs
          title="Cryllex"
          breadcrumbItem="Analytics"
        />
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <Row>
                  <Col lg={12}>
                    <div className="d-flex flex-wrap justify-content-between mb-3">
                      <h4 className=" me-2">Historical Comparison</h4>
                    </div>
                  </Col>
                  <hr className="mb-4" />
                  <Col sm={12} className=' px-1'>
                    <div id="area-chart" dir="ltr" className='border'>
                      <ReactApexChart
                        options={options}
                        series={series}
                        type="area"
                        height={350}
                        className="apex-charts"
                      />
                    </div>
                  </Col>
                  {
                    data ?
                      <Col xl={4}>
                        <div>
                          <div className='mx-3 border px-3 py-4 rounded mb-2'>
                            <div className="text-muted">
                              <div className="d-flex justify-content-between flex-wrap align-items-center">
                                <h5 className='m-0'>{t("Fiat balance")}</h5>
                                <span className='badge badge-soft-success  font-size-14'>
                                  $ {data.fiat ? data.fiat : 0} USD
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className='mx-3 border px-3 py-4 rounded mb-2'>
                            <div className="text-muted">
                              <div className="d-flex justify-content-between flex-wrap align-items-center">
                                <h5 className='m-0'>{t("Payouts")}</h5>
                                <span className='badge badge-soft-info  font-size-14'>
                                  $ {data.payouts ? data.payouts : 0} USD
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      : null
                  }
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <Row>
                  <Col lg={12}>
                    <div className="d-flex flex-wrap justify-content-between mb-3">
                      <h4 className=" me-2">Key Performance Indicators</h4>
                    </div>
                  </Col>
                  <div className='d-flex justify-content-center font-size-18 border rounded justify-content-between p-3 my-1 flex-wrap'>
                    <span className='font-weight-500'>Gross Volume</span>
                    <div className='d-flex'>
                      <span className='font-size-18 text-danger me-4'>-64.00%</span>
                      <span className='font-size-18 text-info'>$573.24</span>
                    </div>
                  </div>
                  <div className='d-flex justify-content-center font-size-18 border rounded justify-content-between p-3 my-1 flex-wrap'>
                    <span className='font-weight-500'>New Customers</span>
                    <div className='d-flex'>
                      <span className='font-size-18 text-warning me-4'>23.00%</span>
                      <span className='font-size-18 text-info'>$12483.20</span>
                    </div>
                  </div>
                  <div className='d-flex justify-content-center font-size-18 border rounded justify-content-between p-3 my-1 flex-wrap'>
                    <span className='font-weight-500'>Net Volume from Sales</span>
                    <div className='d-flex'>
                      <span className='font-size-18 text-warning me-4'>48.00%</span>
                      <span className='font-size-18 text-info'>$24785.00</span>
                    </div>
                  </div>
                  <div className='d-flex justify-content-center font-size-18 border rounded justify-content-between p-3 my-1 flex-wrap'>
                    <span className='font-weight-500'>Average Revenue per Customer</span>
                    <div className='d-flex'>
                      <span className='font-size-18 text-success me-4'>55.00%</span>
                      <span className='font-size-18 text-info'>$1250.99</span>
                    </div>
                  </div>
                  <div className='d-flex justify-content-center font-size-18 border rounded justify-content-between p-3 my-1 flex-wrap'>
                    <span className='font-weight-500'>Customer Token Preference</span>
                    <div className='d-flex'>
                      <span className='font-size-18 text-danger me-4'>-17.00%</span>
                      <span className='font-size-18 text-info'>$563.23</span>
                    </div>
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default withTranslation()(Analytics)