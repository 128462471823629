import React, { useCallback } from 'react'
import uuid from 'react-uuid'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap"
import ActionButton from '../../../../components/Buttons/ActionButton'
import { ERROR_KEY, SUCCESS_KEY } from '../../../../Constants/MainKeys'
import AlertService from '../../../../Services/alertService'
import { addButtonSpinner, removeButtonSpinner } from '../../../../store/actions'
import { useDispatch } from 'react-redux'
import ApiService from '../../../../Services/ApiService'
import { withRouter } from "react-router-dom"
import { useSelector } from 'react-redux'

const spinnerId = uuid();
const ConfirmPayoutModal = (props) => {

  const {
    setIsShowConfirmPayoutModal,
    isShowConfirmPayoutModal,
    transactionAmount,
    setTransactionAmount,
    netValue,
    setNetValue,
    bankAccount,
  } = props;

  const dispatch = useDispatch();
  const { language } = useSelector(state => state.Languages);
  const { user } = useSelector(state => state.Login);
  const onPayOut = (event) => {
    event && event.preventDefault();

    setButtonSpinner(spinnerId);
    const form = {
      amount: transactionAmount,
      payoutSystemId: 1,
      organizationBankAccountId: bankAccount.id,
      // operationUserId: user.id,
    }
    ApiService.onPayOut(form).then(() => {
      AlertService.alert(SUCCESS_KEY, "Your request has been sent");
      setIsShowConfirmPayoutModal(false);
      setTransactionAmount("");
      extractButtonSpinner(spinnerId);
      setNetValue(null);
      props.history.push(`/${language}/balance/payouts`)
    }).catch(error => getFail(error, spinnerId))

  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractButtonSpinner(spinnerId);
  };


  return (
    <Modal
      isOpen={isShowConfirmPayoutModal}
      size="md"
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={() => {
        setIsShowConfirmPayoutModal(false)
      }}
    >
      <div className="modal-content">
        <ModalHeader
          toggle={() => {
            setIsShowConfirmPayoutModal(false)
          }}
        >
          <p className='h4'>Transaction information</p>
        </ModalHeader>
        <ModalBody className='py-0'>
          {
            bankAccount ?
              <div>
                <Table bordered striped responsive className='mt-3'>
                  <tbody>
                    {
                      bankAccount.bankName ?
                        <tr>
                          <th
                            scope="row"
                            style={{ width: "400px" }}
                            className={"text-capitalize"}
                          >
                            Bank name
                          </th>
                          <td className='w-50'>{bankAccount.bankName}</td>
                        </tr>
                        : null
                    }
                    {
                      bankAccount.accountNumber ?
                        <tr>
                          <th
                            scope="row"
                            style={{ width: "400px" }}
                            className={"text-capitalize"}
                          >
                            Account number
                          </th>
                          <td className='w-50'>{bankAccount.accountNumber}</td>
                        </tr>
                        : null
                    }
                    {
                      bankAccount.swiftCode ?
                        <tr>
                          <th
                            scope="row"
                            style={{ width: "400px" }}
                            className={"text-capitalize"}
                          >
                            SWIFT/BIC code
                          </th>
                          <td className='w-50'>{bankAccount.swiftCode}</td>
                        </tr>
                        : null
                    }
                    {
                      bankAccount.receiverName ?
                        <tr>
                          <th
                            scope="row"
                            style={{ width: "400px" }}
                            className={"text-capitalize"}
                          >
                            Receiver name
                          </th>
                          <td className='w-50'>{bankAccount.receiverName}</td>
                        </tr>
                        : null
                    }
                  </tbody>
                </Table>
                <hr />
                <Table bordered striped responsive className='mb-0'>
                  <tbody>
                    {
                      bankAccount.countryName ?
                        <tr>
                          <th
                            scope="row"
                            style={{ width: "400px" }}
                            className={"text-capitalize"}
                          >
                            Country
                          </th>
                          <td className='w-50'>{bankAccount.countryName}</td>
                        </tr>
                        : null
                    }
                    {
                      bankAccount.city ?
                        <tr>
                          <th
                            scope="row"
                            style={{ width: "400px" }}
                            className={"text-capitalize"}
                          >
                            City
                          </th>
                          <td className='w-50'>{bankAccount.city}</td>
                        </tr>
                        : null
                    }
                    {
                      bankAccount.bankName ?
                        <tr>
                          <th
                            scope="row"
                            style={{ width: "400px" }}
                            className={"text-capitalize"}
                          >
                            Bank Name
                          </th>
                          <td className='w-50'>{bankAccount.bankName}</td>
                        </tr>
                        : null
                    }
                    {
                      bankAccount.branchAddress ?
                        <tr>
                          <th
                            scope="row"
                            style={{ width: "400px" }}
                            className={"text-capitalize"}
                          >
                            Branch Address
                          </th>
                          <td className='w-50'>{bankAccount.branchAddress}</td>
                        </tr>
                        : null
                    }
                  </tbody>
                </Table>
              </div>
              : null
          }
          <div className='d-flex justify-content-between mt-4'>
            <p className='text-muted font-size-14 mb-0'>You will receive:</p>
            <b className='h3'>${transactionAmount && transactionAmount.toFixed(2)}</b>
          </div>
          <div className='d-flex justify-content-between'>
            <p className='text-muted font-size-14 mb-0'> </p>
            <p className='d-flex justify-content-between'> Commision: ${netValue && netValue.toFixed(2)}</p>
          </div>
        </ModalBody>
        <ModalFooter className='d-flex justify-content-center'>
          <ActionButton
            type="button"
            name="Confirm"
            buttonClassName="w-100"
            className="shadow-sm w-100 btn btn-primary"
            spinnerId={spinnerId}
            onClick={onPayOut}
          />
        </ModalFooter>
      </div>
    </Modal>
  )
}

export default withRouter(ConfirmPayoutModal)