import React, { useState } from 'react'
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Row,
  Table,
} from "reactstrap"
import { useSelector } from 'react-redux'
import HtmlHead from '../../../components/HtmlHead/HtmlHead'
import RightSectionSpinner from '../../../components/Spinners/RightSectionSpinner'
import moment from 'moment'
import { Link } from "react-router-dom"

const CurrentTransactionsOverview = (props) => {

  const title = "Client Database";
  const description = "";

  const { rightSectionSpinners } = useSelector(state => state.Spinners);
  const { user } = useSelector(state => state.Login);
  const { language, languages } = useSelector(state => state.Languages);


  const onChange = (event, cb, maxLength = Infinity) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    cb(event.target.value)
  }

  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className='page-content position-relative min-h-100vh'>
        <RightSectionSpinner spinner={rightSectionSpinners} />

        {/* <div className="d-flex flex-wrap justify-content-between mb-3">
          <h4 className=" me-2">{moment(new Date()).format("LL")}</h4>
        </div> */}
        <div className={`page-title-box d-sm-flex align-items-center justify-content-between`}>
          <div className="d-flex align-items-center">
            <h4 className="mb-0 font-size-22">{`Welcome ${user.fullName}, to your Transactions Overview.`}</h4>
          </div>
        </div>

        <Row>
          <Col lg={12}>
            <Card>
              <div className='overflow-hidden'>
                <Row className='bg-light p-3'>
                  <Col md={6} lg={3} className='mt-3  mt-lg-0 d-flex align-items-center'>
                    <div>
                      <h4 className="mb-0 font-size-20">{`Transaction Summary`}</h4>
                      <small>Order Reference #00001</small>
                    </div>
                  </Col>
                  <Col md={6} lg={3} className='mt-3  mt-lg-0 d-flex align-items-center'>
                    <div className='d-flex align-items-center'>
                      <div className='bg-success' style={{ width: "25px", height: "25px", borderRadius: "50%" }}></div>
                      <h4 className="font-size-22 text-success ms-3 pb-0 mb-0">Confirmed</h4>
                    </div>
                  </Col>
                  <Col md={6} lg={3} className='mt-3  mt-lg-0 d-flex align-items-center'>
                    <h4 className="mb-0 font-size-20 fw-500">05/27/2023 at 7:35 pm EST</h4>
                  </Col>
                  <Col md={6} lg={3} className='mt-3  mt-lg-0 d-flex align-items-center justify-content-end'>
                    <Link to="#" className="mb-0 font-size-20 fw-500 underline"> <i>View TXN</i></Link>
                  </Col>
                </Row>
              </div>
              <Row className='p-3'>
                <Col lg={4} className='mt-3  mt-lg-0'>
                  <div className='d-flex flex-column justify-content-center h-100'>
                    <div className='d-flex'>
                      <p className='me-5 ps-0 '>Bank Account Number: </p>
                      <span>[TEXT]</span>
                    </div>
                    <div className='d-flex'>
                      <p className='me-5 ps-0 '>Address:</p>
                      <span>[TEXT]</span>
                    </div>
                    <div className='d-flex'>
                      <p className='me-5 ps-0 '>Address Documentation*:</p>
                      <span>[TEXT]</span>
                    </div>
                  </div>
                </Col>
                <Col lg={4} className='mt-3  mt-lg-0'>
                  <Table striped bordered responsive>

                    <tbody>
                      <tr>
                        <td className='fw-500'>TXN Fee</td>
                        <td className='fw-500'>15.34 USD (0.000037 BTC)</td>
                      </tr>
                      <tr>
                        <td className='fw-500'>Currency</td>
                        <td className='fw-500'>BTC</td>
                      </tr>
                      <tr>
                        <td className='fw-500'>Exchange Rate</td>
                        <td className='fw-500'>1 BTC = 26742.50</td>
                      </tr>
                      <tr>
                        <td className='fw-500'>FX Revenue from Risk Pricing</td>
                        <td className='fw-500'></td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
                <Col lg={4} className='mt-3  mt-lg-0'>
                  <div className='d-flex flex-column justify-content-center h-100'>
                    <div className='d-flex flex-column justify-content-center align-items-center border p-3'>
                      <p className='fw-500 font-size-18'>Order Total</p>
                      <h4 className="font-size-22 ms-2 pb-0 mb-0">$247.89 USD</h4>
                      <p className='mt-2'><i>(0.006857 BTC)</i></p>

                    </div>
                  </div>
                </Col>
              </Row>
              <div className='overflow-hidden'><Row className='bg-primary p-3'>
                <Col>
                  <p className='text-white fw-500 font-size-18 mb-0'>Transaction Details</p>
                </Col>
              </Row></div>
              <Row className='p-3'>
                <Col md={6} className='mt-3'>
                  <div className='d-flex justify-content-between'>
                    <p className='me-5 ps-0 '>Paying Customer Txn Value:</p>
                    <span>[TEXT]</span>
                  </div>
                  <div className='d-flex justify-content-between'>
                    <p className='me-5 ps-0 '>Paying Customer Stablecoin Txn Value:</p>
                    <span>[TEXT]</span>
                  </div>
                  <div className='d-flex justify-content-between'>
                    <p className='me-5 ps-0 '>Client Stablecoin Txn Value:</p>
                    <span>[TEXT]</span>
                  </div>
                  <div className='d-flex justify-content-between'>
                    <p className='me-5 ps-0 '>Client Fiat Txn Value:</p>
                    <span>[TEXT]</span>
                  </div>
                </Col>
                <Col md={6} className='mt-3'>
                  <div className='d-flex justify-content-between'>
                    <p className='me-5 ps-0 '>Agent Net Stablecoin Txn Fees:</p>
                    <span>[TEXT]</span>
                  </div>
                  <div className='d-flex justify-content-between'>
                    <p className='me-5 ps-0 '>Agent Net Fiat Txn Fees:</p>
                    <span>[TEXT]</span>
                  </div>
                  <div className='d-flex justify-content-between'>
                    <p className='me-5 ps-0 '>Agent Partner Points:</p>
                    <span>[TEXT]</span>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>

        </Row>
      </div>
    </>
  );
}

export default CurrentTransactionsOverview;

