import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import uuid from 'react-uuid';
import { addPageSpinner, removePageSpinner } from './store/actions';
import ApiService from './Services/ApiService';
import { useDispatch } from 'react-redux';

const ErrorHandling = ({ history }) => {
  let isPending = false;
  const dispatch = useDispatch();

  useEffect(() => {
    window.onerror = function (msg, url, lineNo, columnNo, error) {
      if (!error || isPending) { return false; }
      isPending = true;
      sendError(msg, url, lineNo, columnNo, error);
    };
  }, []);

  const sendError = (msg, url, lineNo, columnNo, error) => {
    const spinnerId = uuid();
    const form = {
      column: columnNo,
      line: lineNo,
      url: url,
      message: msg,
      error: error,
    };
    dispatch(addPageSpinner(spinnerId));
    ApiService.sendError(form).then(() => {
      history.push(`/`);
      window.location.reload();
      dispatch(removePageSpinner(spinnerId));
    }).catch(() => {
      history.push(`/`);
      window.location.reload();
      dispatch(removePageSpinner(spinnerId));
    })
  };

  return <></>;
};

export default withRouter(ErrorHandling);