import React, { useCallback } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import ReactApexChart from "react-apexcharts"
import {
  Card,
  CardBody,
  Col,
  Row,
  Table
} from "reactstrap"
import { withTranslation } from "react-i18next"
import { ERROR_KEY, INACTIVE_ACCOUNT_INFO_MSG } from '../../../Constants/MainKeys'
import { addPageSpinner, removePageSpinner } from '../../../store/spinner/actions'
import AlertService from '../../../Services/alertService'
import { useDispatch, useSelector } from 'react-redux'
import HtmlHead from '../../../components/HtmlHead/HtmlHead'
import ReactPaginate from 'react-paginate'
import NoData from '../../../components/NoData/NoData'

const Logs = (props) => {

  const title = "Logs"
  const description = ""
  const { t } = props;
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.Login)

  const options = {
    chart: {
      id: 'apexchart-example',
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999]
    }
  };

  const series = [{
    name: 'series-1',
    data: [30, 40, 35, 50, 49, 60, 70, 91, 125]
  }]

  const setPageSpinner = useCallback(spinner => {
    dispatch(addPageSpinner(spinner));
  }, []);

  const extractPageSpinner = useCallback(spinner => {
    dispatch(removePageSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractPageSpinner(spinnerId);
  }

  return <>
    <HtmlHead title={title} description={description} />
    <div className='page-content position-relative'>
      <div className="container-fluid">
        <Breadcrumbs
          title="Cryllex"
          breadcrumbItem={<span>Logs <small className='font-size-10'>(In process)</small></span>}
        />
        <Row>
          <Col lg={12}>
            <Card className='p-3'>
              <NoData />
              {
                !user.isActive ?
                  <p className="my-3 text-center">{INACTIVE_ACCOUNT_INFO_MSG}</p>
                  : null
              }
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  </>

  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className='page-content position-relative'>
        <div className="container-fluid">
          <Breadcrumbs
            title="Cryllex"
            breadcrumbItem={<span>Logs <small className='font-size-10'>(In process)</small></span>}
          />
          <Row>
            <Col lg={12}>
              <Card className='p-3'>
                <div>
                  <div className="table-responsiv">
                    <Table className="table table-striped mb-0">
                      <thead>
                        <tr className='cursor-default'>
                          <th>Action</th>
                          <th>Create Date</th>
                          <th>Type</th>
                          <th>URL</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className='cursor-default'>
                          <th>
                            <p className='word-break-break-word max-line-2 m-0' >POST</p>
                          </th>
                          <td>
                            <p className='word-break-break-word max-line-2 m-0' >20-04-2022</p>
                          </td>
                          <td>
                            <p className='word-break-break-word max-line-2 m-0' >ERROR</p>
                          </td>
                          <td>
                            <p className='word-break-break-word max-line-2 m-0' >http://localhost:3001/en/logs</p>
                          </td>
                        </tr>
                        <tr className='cursor-default'>
                          <th>
                            <p className='word-break-break-word max-line-2 m-0' >POST</p>
                          </th>
                          <td>
                            <p className='word-break-break-word max-line-2 m-0' >20-04-2022</p>
                          </td>
                          <td>
                            <p className='word-break-break-word max-line-2 m-0' >ERROR</p>
                          </td>
                          <td>
                            <p className='word-break-break-word max-line-2 m-0' >http://localhost:3001/en/logs</p>
                          </td>
                        </tr>
                        <tr className='cursor-default'>
                          <th>
                            <p className='word-break-break-word max-line-2 m-0' >POST</p>
                          </th>
                          <td>
                            <p className='word-break-break-word max-line-2 m-0' >20-04-2022</p>
                          </td>
                          <td>
                            <p className='word-break-break-word max-line-2 m-0' >ERROR</p>
                          </td>
                          <td>
                            <p className='word-break-break-word max-line-2 m-0' >http://localhost:3001/en/logs</p>
                          </td>
                        </tr>
                        <tr className='cursor-default'>
                          <th>
                            <p className='word-break-break-word max-line-2 m-0' >POST</p>
                          </th>
                          <td>
                            <p className='word-break-break-word max-line-2 m-0' >20-04-2022</p>
                          </td>
                          <td>
                            <p className='word-break-break-word max-line-2 m-0' >ERROR</p>
                          </td>
                          <td>
                            <p className='word-break-break-word max-line-2 m-0' >http://localhost:3001/en/logs</p>
                          </td>
                        </tr>
                        <tr className='cursor-default'>
                          <th>
                            <p className='word-break-break-word max-line-2 m-0' >POST</p>
                          </th>
                          <td>
                            <p className='word-break-break-word max-line-2 m-0' >20-04-2022</p>
                          </td>
                          <td>
                            <p className='word-break-break-word max-line-2 m-0' >ERROR</p>
                          </td>
                          <td>
                            <p className='word-break-break-word max-line-2 m-0' >http://localhost:3001/en/logs</p>
                          </td>
                        </tr>
                        <tr className='cursor-default'>
                          <th>
                            <p className='word-break-break-word max-line-2 m-0' >POST</p>
                          </th>
                          <td>
                            <p className='word-break-break-word max-line-2 m-0' >20-04-2022</p>
                          </td>
                          <td>
                            <p className='word-break-break-word max-line-2 m-0' >ERROR</p>
                          </td>
                          <td>
                            <p className='word-break-break-word max-line-2 m-0' >http://localhost:3001/en/logs</p>
                          </td>
                        </tr>
                        <tr className='cursor-default'>
                          <th>
                            <p className='word-break-break-word max-line-2 m-0' >POST</p>
                          </th>
                          <td>
                            <p className='word-break-break-word max-line-2 m-0' >20-04-2022</p>
                          </td>
                          <td>
                            <p className='word-break-break-word max-line-2 m-0' >ERROR</p>
                          </td>
                          <td>
                            <p className='word-break-break-word max-line-2 m-0' >http://localhost:3001/en/logs</p>
                          </td>
                        </tr>
                        <tr className='cursor-default'>
                          <th>
                            <p className='word-break-break-word max-line-2 m-0' >POST</p>
                          </th>
                          <td>
                            <p className='word-break-break-word max-line-2 m-0' >20-04-2022</p>
                          </td>
                          <td>
                            <p className='word-break-break-word max-line-2 m-0' >ERROR</p>
                          </td>
                          <td>
                            <p className='word-break-break-word max-line-2 m-0' >http://localhost:3001/en/logs</p>
                          </td>
                        </tr>
                        <tr className='cursor-default'>
                          <th>
                            <p className='word-break-break-word max-line-2 m-0' >POST</p>
                          </th>
                          <td>
                            <p className='word-break-break-word max-line-2 m-0' >20-04-2022</p>
                          </td>
                          <td>
                            <p className='word-break-break-word max-line-2 m-0' >ERROR</p>
                          </td>
                          <td>
                            <p className='word-break-break-word max-line-2 m-0' >http://localhost:3001/en/logs</p>
                          </td>
                        </tr>
                        <tr className='cursor-default'>
                          <th>
                            <p className='word-break-break-word max-line-2 m-0' >POST</p>
                          </th>
                          <td>
                            <p className='word-break-break-word max-line-2 m-0' >20-04-2022</p>
                          </td>
                          <td>
                            <p className='word-break-break-word max-line-2 m-0' >ERROR</p>
                          </td>
                          <td>
                            <p className='word-break-break-word max-line-2 m-0' >http://localhost:3001/en/logs</p>
                          </td>
                        </tr>

                      </tbody>
                    </Table>
                  </div>

                </div>
                {
                  <div className='p-3 d-flex justify-content-end'>
                    <Row>
                      <Col>
                        <ReactPaginate
                          nextLabel="Next"
                          // onPageChange={handlePageClick}
                          pageRangeDisplayed={3}
                          marginPagesDisplayed={2}
                          pageCount={5}
                          previousLabel="Previous"
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakLabel="..."
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          containerClassName="pagination"
                          activeClassName="active"
                          renderOnZeroPageCount={null}
                          // forcePage={activePageNumber}
                          forcePage={0}
                        />
                      </Col>
                    </Row>
                  </div>
                }
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default withTranslation()(Logs)