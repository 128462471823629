import React, { useEffect, useRef } from "react"
import { useSelector } from "react-redux"
import { withRouter } from "react-router-dom"
import logo from "./../../assets/logo/logo.svg"
import logoLight from "./../../assets/logo/logoLight.svg"
import SidebarContent from "./SidebarContent"
import { Link } from "react-router-dom"

function useOutsideAlerter(ref, cb) {

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        cb()
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const Sidebar = props => {

  const removeBodyClasses = () => {
    // document.body.classList = '';
  }
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, removeBodyClasses);

  return (
    <React.Fragment>
      <div className="vertical-menu" ref={wrapperRef}>
        <div className="navbar-brand-box p-0">
          <Link to="/" className="logo logo-light">
            <span className="logo-sm">
              <img src={logoLight} alt="" height="17" />
            </span>
            <span className="logo-lg">
              <img src={logo} alt="" height="22" />
            </span>
          </Link>
        </div>
        <div data-simplebar>
          <SidebarContent />
        </div>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Sidebar)
