import {
  ADD_BUTTON_SPINNER,
  ADD_PAGE_SPINNER,
  REMOVE_BUTTON_SPINNER,
  REMOVE_PAGE_SPINNER,
  REMOVE_RIGHT_SECTION,
  ADD_RIGHT_SECTION
} from "./actionTypes";

const initialState = {
  rightSectionSpinners: [],
  pageSpinners: [],
  buttonSpinners: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PAGE_SPINNER:
      return {
        ...state,
        pageSpinners: [...state.pageSpinners, action.payload]
      };
    case REMOVE_PAGE_SPINNER:
      return {
        ...state,
        pageSpinners: state.pageSpinners.filter(data => data !== action.payload)
      };
    case ADD_BUTTON_SPINNER:
      return {
        ...state,
        buttonSpinners: [...state.buttonSpinners, action.payload]
      };
    case REMOVE_BUTTON_SPINNER:
      return {
        ...state,
        buttonSpinners: state.buttonSpinners.filter(data => data !== action.payload)
      };

    case ADD_RIGHT_SECTION:
      return {
        ...state,
        rightSectionSpinners: [...state.rightSectionSpinners, action.payload]
      };
    case REMOVE_RIGHT_SECTION:
      return {
        ...state,
        rightSectionSpinners: state.rightSectionSpinners.filter(data => data !== action.payload)
      };
    default:
      break;
  }
  return state;
};

export default reducer;