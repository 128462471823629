import React, { useCallback, useState } from 'react'
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  Label,
  Input,
  CardHeader
} from "reactstrap"
import { withTranslation } from "react-i18next"
import ApiService from '../../../Services/ApiService';
import AlertService from '../../../Services/alertService'
import { SUCCESS_KEY } from '../../../Constants/MainKeys'
import uuid from 'react-uuid'
import { addButtonSpinner, removeButtonSpinner } from '../../../store/spinner/actions'
import { useDispatch, useSelector } from 'react-redux'
import ActionButton from '../../../components/Buttons/ActionButton'

const buttonSpinnerId = uuid();

const InstantPaymentForm = (props) => {

  const { clearFilter } = props;
  const dispatch = useDispatch();
  const maxAmount = 1000000000;
  const transactionTextMaxCount = 200;
  let spinnerColor = "#556EE6";
  const { user } = useSelector(state => state.Login);
  const [isShowInstantPaymentForm, setIsShowInstantPaymentForm] = useState(false);

  const [values, setValues] = useState({
    amount: "",
    transactionText: "",
    endDate: "",
    endTime: "",
  })

  const onNumberChange = (event, fieldName, maxLength = null) => {
    if (typeof +event.target.value === "number" && Number(event.target.value) >= 0 && Number(event.target.value) <= maxLength) {
      setValues(values => ({ ...values, [fieldName]: +event.target.value ? +event.target.value : "" }))
    }
  };

  const onChange = (event, fieldName, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    setValues(values => ({ ...values, [fieldName]: event.target.value }))
  }

  const resetFields = () => {
    setValues({
      amount: "",
      transactionText: "",
      endDate: "",
      endTime: "",
    })
  }

  const getToday = () => {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd
    }
    if (mm < 10) {
      mm = '0' + mm
    }
    return today = yyyy + '-' + mm + '-' + dd;
  }

  const onSubmit = (event) => {
    event.preventDefault();
    setButtonSpinner(buttonSpinnerId);
    const data = {
      amount: values.amount,
      transactionText: values.transactionText,
      ExpireDateString: values.endDate,
      ExpireTimeString: values.endTime,
      timeZoneOffset: Number(new Date().getTimezoneOffset() / 60)
    }
    ApiService.createInstantPayment(data).then(response => {
      if (response && response.data) {
        AlertService.alert(SUCCESS_KEY, "Instant payment created successfully");
        resetFields();
        clearFilter && clearFilter();
      }
      extractButtonSpinner(buttonSpinnerId);
    }).catch(error => getFail(error, buttonSpinnerId));
  }

  const onDateChange = (event, fieldName) => {
    setValues(prevFilter => ({ ...prevFilter, [fieldName]: event.target.value }))
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || "error"), error);
    spinnerId && extractButtonSpinner(spinnerId);
  }

  return (
    <Col lg={12}>
      <Row className='mb-4'>

        <Col sm={12}>
          {
            user.isActive ?
              <Card className='position-relative p-3'>
                <CardHeader className="bg-transparent p-0">
                  <div className='d-md-flex align-items-end justify-content-between'>
                    <p className='text-md-start text-center m-0'>
                      You can make an instant payment link by clicking create.
                      <br />
                      You will get a link that you can forward or publish.
                    </p>
                    <div className='mt-md-0 mt-3 d-md-block d-flex align-items-center justify-content-center'>
                      <button
                        className="btn btn-outline-primary d-flex align-items-center nowrap"
                        type="button"
                        onClick={() => setIsShowInstantPaymentForm(!isShowInstantPaymentForm)}
                      >
                        <i className='bx bx-plus' ></i>
                        <span className='ms-1'>Create Instant payment</span>
                      </button>
                    </div>
                  </div>
                </CardHeader>

                {
                  isShowInstantPaymentForm ?
                    <CardBody className='p-0'>
                      <hr />
                      <div className="mb-3">
                        <form
                          onSubmit={onSubmit}
                          className='row flex-column'
                        >
                          <div className='col-12 col-md-6'>
                            <Label htmlFor='transactionText pb-0'>Transaction Text</Label>
                            <Input
                              id="transactionText"
                              type="textarea"
                              value={values.transactionText}
                              rows={3}
                              placeholder=''
                              className="form-control text-muted me-2 resize-none"
                              onChange={(event) => onChange(event, "transactionText", transactionTextMaxCount)}
                            />
                          </div>
                          <div className="mb-3 position-relative col-12 col-md-6 mt-3">
                            <Label htmlFor='transactionText pb-0'>Amount</Label>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text"

                                >
                                  $
                                </span>
                              </div>
                              <Input
                                id="transactionAmount"
                                type="text"
                                className="form-control"
                                value={values.amount}
                                onChange={(event) => onNumberChange(event, "amount", maxAmount)}
                              />
                            </div>
                          </div>

                          <div className='position-relative col-12 col-md-6 mt-3'>
                            <div className='row'>
                              <div className='col-12 col-md-6'>
                                <Label htmlFor='transactionText pb-0'>Expiration</Label>
                                <Input
                                  id="transactionText"
                                  type="date"
                                  value={values.endDate}
                                  min={getToday()}
                                  className="form-control text-muted me-2 resize-none"
                                  onChange={(event) => onDateChange(event, "endDate")}
                                />
                              </div>

                              <div className='col-12 col-md-6'>
                                <Label htmlFor='transactionText pb-0'>&nbsp;</Label>
                                <Input
                                  id="transactionText"
                                  type="time"
                                  value={values.endTime}
                                  disabled={!values.endDate}
                                  className="form-control text-muted me-2 resize-none"
                                  onChange={(event) => onChange(event, "endTime")}
                                />
                              </div>
                            </div>
                          </div>

                          <div className='col-12 col-md-6 d-flex justify-content-end align-items-center mt-4'>
                            <Button type="button" outline color="secondary" className='shadow-sm me-2' onClick={() => setIsShowInstantPaymentForm(false)}>
                              Cancel
                            </Button>
                            <ActionButton
                              type="submit"
                              name="Create"
                              className="btn btn-primary cursor-pointer nowrap px-4"
                              spinnerId={buttonSpinnerId}
                              spinnerColor={spinnerColor}
                              disabled={values.amount ? false : true}
                            />
                          </div>
                        </form>
                      </div>
                    </CardBody>
                    : null
                }
              </Card>
              : null
          }
        </Col>


      </Row>
    </Col>
  )
}

export default withTranslation()(InstantPaymentForm)