import React, { useCallback, useState, useEffect, useRef } from 'react'
import Breadcrumbs from './../../../components/Common/Breadcrumb'
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardTitle
} from "reactstrap"
import { Table } from "react-bootstrap"
import ApiService from './../../../Services/ApiService';
import AlertService from './../../../Services/alertService'
import { ERROR_KEY, fielsLengths, SUCCESS_KEY } from './../../../Constants/MainKeys'
import uuid from 'react-uuid'
import { addButtonSpinner, addRightSectionSpinner, removeButtonSpinner, removeRightSectionSpinner } from './../../../store/spinner/actions'
import { useDispatch, useSelector } from 'react-redux'
import HtmlHead from './../../../components/HtmlHead/HtmlHead'
import RightSectionSpinner from './../../../components/Spinners/RightSectionSpinner'
import NoData from '../../../components/NoData/NoData'
import ReactSelectOption from '../../../components/SelectOptions/ReactSelectOption';
import ActionButton from '../../../components/Buttons/ActionButton';

const buttonSpinnerId = uuid();

const OrganizationWallets = (props) => {

  const title = "Organization Wallets";
  const description = "";
  const { organizationId } = props.match.params;
  const dispatch = useDispatch();
  const { rightSectionSpinners } = useSelector(state => state.Spinners);
  const { language } = useSelector(state => state.Languages);
  const [wallets, setWallets] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [cryptoWallets, setCryptoWallets] = useState([]);
  const [isShowForm, setIsShowForm] = useState(false);
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);

  const [values, setValues] = useState({
    id: null,
    organizationId: +organizationId,
    currencyId: null,
    cryptoWalletId: null,
    walletAddress: "",
  });

  useEffect(() => {
    getOrganizationWallets();
    getCurrencies();
    getCryptoWallets();
  }, [])

  const getCurrencies = () => {
    const spinnerId = uuid();
    setRightSectionSpinner(spinnerId);
    ApiService.getCurrencies().then(response => {
      if (response && response.data) {
        const data = [...response.data];
        setCurrencies(data);
      }
      extractRightSectionSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId))
  }

  const getCryptoWallets = () => {
    const spinnerId = uuid();
    setRightSectionSpinner(spinnerId);
    ApiService.getCryptoWallets().then(response => {
      if (response && response.data) {
        const data = [...response.data];
        setCryptoWallets(data);
      }
      extractRightSectionSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId))
  }


  const getOrganizationWallets = () => {
    const spinnerId = uuid();
    setRightSectionSpinner(spinnerId);
    ApiService.getOrganizationWallets().then(response => {
      if (response && response.data) {
        setWallets(response.data);
      }
      extractRightSectionSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId))
  }

  const onSelectOptionChange = (item, fieldName) => {
    setValues((values) => ({
      ...values,
      [fieldName]: item,
    }));
    setIsInvalidSubmit(false);
  }

  const onChange = (event, fieldName, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    setValues((values) => ({
      ...values,
      [fieldName]: event.target.value,
    }));
    setIsInvalidSubmit(false);
  }

  const cancel = () => {
    setValues({
      id: null,
      organizationId: +organizationId,
      currencyId: null,
      cryptoWalletId: null,
      walletAddress: "",
    })
    setIsShowForm(false);
  }

  const onSubmit = (event) => {
    event && event.preventDefault();
    if (!values.walletAddress.trim().length || !values.currencyId || !values.cryptoWalletId) {
      setIsInvalidSubmit(true);
      return false;
    }
    setButtonSpinner(buttonSpinnerId);
    (values.id ? ApiService.updateOrganizationWallet(values) : ApiService.createOrganizationWallet(values)).then(response => {
      if (response && response.data) {
        setWallets([response.data, ...wallets]);
      } else {
        getOrganizationWallets();
      }
      AlertService.alert(SUCCESS_KEY, "Data saved");
      extractButtonSpinner(buttonSpinnerId);
    }).catch(error => {
      getFail(error, buttonSpinnerId);
    }).finally(() => {
      cancel();
    })
  }

  const setRightSectionSpinner = useCallback(spinner => {
    dispatch(addRightSectionSpinner(spinner));
  }, []);

  const extractRightSectionSpinner = useCallback(spinner => {
    dispatch(removeRightSectionSpinner(spinner));
  }, []);

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);


  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractRightSectionSpinner(spinnerId);
    spinnerId && extractButtonSpinner(spinnerId);
  }

  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className='page-content position-relative min-h-100vh'>
        <RightSectionSpinner spinner={rightSectionSpinners} />

        <div className="container-fluid">
          <Breadcrumbs
            title="Cryllex"
            breadcrumbItem="Organizaton Wallets"
            isShowGoBackButton={true}
            goBack={() => window.history.back()}
          />
          <Row>
            <Col lg={12}>
              <Row className='mb-4'>

                <Col sm={12}>
                  <Card id='table' className="position-relative">

                    <CardBody className="pb-0">


                      <CardTitle className="mb-4">
                        <div className='d-flex justify-content-between align-items-center'>
                          Wallets
                          <Button
                            color='primary'
                            className='px-3 d-flex align-items-end'
                            onClick={() => {
                              setIsShowForm(true);
                            }}
                          >
                            Create Wallet
                          </Button>
                        </div>
                        <hr />
                      </CardTitle>
                      {
                        wallets && wallets.length ? (
                          <Table striped bordered hover responsive>
                            <thead>
                              <tr>
                                <th className="text-nowrap">#</th>
                                <th className='text-nowrap'>Wallet Address</th>
                                <th className='text-nowrap'>Currency</th>
                                <th className='text-nowrap'>Crypto Wallet</th>
                                <th className='text-nowrap text-center'>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {wallets.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.walletAddress}</td>
                                    <td>
                                      {
                                        currencies && currencies.length ?
                                          currencies.map((_item, idx) => {
                                            if (_item.id === item.currencyId) {
                                              return <span key={idx}>{_item.name}</span>
                                            }
                                          })
                                          : null
                                      }
                                    </td>
                                    <td>
                                      {
                                        cryptoWallets && cryptoWallets.length ?
                                          cryptoWallets.map((_item, idx) => {
                                            if (_item.id === item.cryptoWalletId) {
                                              return <p key={idx}>{_item.name}</p>
                                            }
                                          })
                                          : null
                                      }
                                    </td>
                                    <td className='text-center'>
                                      <Button color="primary" className='ms-3 px-4'
                                        onClick={() => {
                                          setValues(item);
                                          setIsShowForm(true);
                                        }}
                                      >
                                        Edit
                                      </Button>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        ) : (
                          <div>
                            <NoData />
                            <p className='text-center mb-4'><b>The current organization does not have wallets yet.</b></p>
                          </div>
                        )
                      }
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>

      <Modal
        isOpen={isShowForm}
        size="lg"
        role="dialog"
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          cancel();
        }}
      >
        <form onSubmit={onSubmit}>
          <div className="modal-content">
            <ModalHeader
              toggle={() => {
                cancel()
              }}
            >
              {values.id ? "Update" : "Create"} wallet
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col sm={12}>
                  <Label htmlFor='rejectMessage'>Wallet Address*</Label>
                  <Input
                    id="rejectMessage"
                    type="textarea"
                    value={values.walletAddress}
                    rows={2}
                    autoFocus
                    style={{ resize: "none" }}
                    placeholder='Ex. 0xE9eFD4F63Cb1060E592415F7F0cef294F48B8899'
                    className={`form-control ${isInvalidSubmit && !values.walletAddress.trim().length ? "error-border" : ""}`}
                    onChange={(event) => onChange(event, "walletAddress", fielsLengths.length_120)}
                  />
                </Col>
                <Col sm={6} className='mt-3'>
                  <Label className='text-nowrap'>Currency*</Label>
                  {
                    currencies && currencies.length ?
                      <ReactSelectOption
                        value={values.currencyId}
                        className={`${(isInvalidSubmit && !values.currencyId) ? "error-border" : ""}`}
                        selectedValue={(() => {
                          const selectedValue = { ...currencies.find(item => item.id === values.currencyId) };
                          if (Object.keys(selectedValue).length) {
                            selectedValue.label = selectedValue.name;
                            selectedValue.value = selectedValue.id;
                            return selectedValue;
                          } else {
                            return { label: "Choose...", value: "" }
                          }
                        })()}
                        items={currencies.map(data => ({ label: data.name, value: data.id }))}
                        onChange={(item) => onSelectOptionChange(item.value, "currencyId")}
                      />
                      : null
                  }
                </Col>
                <Col sm={6} className='mt-3'>
                  <Label className='text-nowrap'>Wallet*</Label>
                  {
                    cryptoWallets && cryptoWallets.length ?
                      <div className='w-100 mb-4'>
                        <ReactSelectOption
                          value={values.cryptoWalletId}
                          className={`${(isInvalidSubmit && !values.cryptoWalletId) ? "error-border" : ""}`}
                          selectedValue={(() => {
                            const selectedValue = { ...cryptoWallets.find(item => item.id === values.cryptoWalletId) };
                            if (Object.keys(selectedValue).length) {
                              selectedValue.label = selectedValue.name;
                              selectedValue.value = selectedValue.id;
                              return selectedValue;
                            } else {
                              return { label: "Choose...", value: "" }
                            }
                          })()}
                          items={cryptoWallets.map(data => ({ ...data, label: data.name, value: data.id }))}
                          onChange={(item) => onSelectOptionChange(item.value, "cryptoWalletId")}
                        />
                      </div>
                      : null
                  }
                </Col>
              </Row>

            </ModalBody>
            <ModalFooter>
              <div className='d-flex justify-content-end gap-2'>
                <Button
                  color="primary"
                  className='px-4'
                  outline
                  onClick={() => {
                    setIsShowForm(false);
                    cancel()
                  }}
                >
                  Cancel
                </Button>
                <ActionButton
                  spinnerId={`${buttonSpinnerId}`}
                  color="primary"
                  className='px-4'
                >
                  Save
                </ActionButton>
              </div>
            </ModalFooter>
          </div>
        </form>
      </Modal>
    </>
  )
}

export default OrganizationWallets