import React, { useState, useEffect } from 'react';
import moment from 'moment';

function GetCurrentTime() {
  const [currentTime, setCurrentTime] = useState(moment().format('hh:mm A'));

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(moment().format('hh:mm A'));
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return currentTime;
}

export default GetCurrentTime;
