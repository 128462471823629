import PropTypes from "prop-types"
import React, { useState, useEffect, useRef } from 'react'
import { Button, Card, Form, Modal, Nav, NavDropdown, Accordion } from 'react-bootstrap'
import SimpleBar from "simplebar-react"
import MetisMenu from 'metismenujs';
import { Link, useLocation, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ADMIN_USER_TYPE_ID, AGENT_USER_TYPE_ID, DEVELOPER_USER_TYPE_ID, MERCHANT_USER_TYPE_ID } from '../../Constants/MainKeys';
import AlertService from '../../Services/alertService';

const SidebarContent = (props) => {

  const dispatch = useDispatch();
  const { language } = useSelector(state => state.Languages);
  const { user } = useSelector(state => state.Login);
  const { pathname } = useLocation();

  const [activeMenuItem, setActiveMenuItem] = useState(null);

  const logout = () => {
    AlertService.alertConfirm(
      "Log out",
      "This will log you out of your account. Proceed?",
      "Yes",
      "Close"
    ).then(() => {
      props.history.push(`/${language}/logout`)
    })
  }

  const ref = useRef()
  useEffect(() => {
    const sideMenu = new MetisMenu('#side-menu');
    const menuItem = document.querySelector(`#side-menu a[href='${pathname}']`);

    if (menuItem) {
      setActiveMenuItem(menuItem.closest('li'));
      menuItem.closest('li').classList.add('mm-active');
    }

    return () => {
      sideMenu.dispose();
    };
  }, []);

  const handleMenuItemClick = (menuItem) => {
    menuItem = menuItem.nodeName !== 'LI' ? menuItem.closest('li') : menuItem;
    if (activeMenuItem) {
      activeMenuItem.classList.remove('mm-active');
      setActiveMenuItem(menuItem);
    }
    menuItem.classList.add('mm-active');
  };


  useEffect(() => {
    if (activeMenuItem) {
      activeMenuItem.classList.remove('mm-active');
    }
  }, [pathname === '/'])

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu" style={{ height: "calc(100vh - 55px)" }} className='d-flex flex-column justify-content-between'>
          {
            user.userTypeId === MERCHANT_USER_TYPE_ID ?
              <ul className="metismenu list-unstyled" id="side-menu">
                {/* <li className="menu-title">Dashboard</li> */}
                <li onClick={(event) => handleMenuItemClick(event.target)}>
                  <Link to={`/${language}/dashboard`}>
                    <i className="bx bxs-dashboard" />
                    <span>Dashboard</span>
                  </Link>
                </li>
                <li onClick={(event) => handleMenuItemClick(event.target)}>
                  <Link to={`/${language}/bank-account`}>
                    <i className="bx bxs-bank" />
                    <span>Bank Account</span>
                  </Link>
                </li>
                {
                  user.classificationType !== 2 ?
                    <li onClick={(event) => handleMenuItemClick(event.target)}>
                      <Link to={`/${language}/significant-controllers`}>
                        <i className="bx bx-user-plus" />
                        <span>Significant controllers</span>
                      </Link>
                    </li> : null
                }
                <li className="menu-title">Payment management</li>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bx-transfer-alt" />
                    <span>Payments</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to={`/${language}/payments/transaction-history`} onClick={(event) => handleMenuItemClick(event.target)}>Transactions</Link>
                    </li>
                    <li>
                      <Link to={`/${language}/instant-payment`} onClick={(event) => handleMenuItemClick(event.target)}>Instant payments</Link>
                    </li>
                    {/* <li>
                      <Link to={`/${language}/payments/customers`} onClick={(event) => handleMenuItemClick(event.target)}>Customers</Link>
                    </li> */}
                    <li>
                      <Link to={`/${language}/payments/products`} onClick={(event) => handleMenuItemClick(event.target)}>Products</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bx-dollar-circle" />
                    <span>Balance</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to={`/${language}/balance/account-balances`} onClick={(event) => handleMenuItemClick(event.target)}>Overview</Link>
                    </li>
                    <li>
                      <Link to={`/${language}/balance/payouts`} onClick={(event) => handleMenuItemClick(event.target)}>Payouts</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bx-bar-chart" />
                    <span>Analysis</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to={`/${language}/analytics`} onClick={(event) => handleMenuItemClick(event.target)}>Overview</Link>
                    </li>
                    <li>
                      <Link to={`/${language}/analysis/key-performance-indicators`} onClick={(event) => handleMenuItemClick(event.target)}>Key performance indicators</Link>
                    </li>
                    <li>
                      <Link to={`/${language}/analysis/payments-analysis`} onClick={(event) => handleMenuItemClick(event.target)}>Payments analysis</Link>
                    </li>
                  </ul>
                </li>
                <li className="menu-title">Developer</li>
                <li>
                  <Link to={`/${language}/api-keys`} onClick={(event) => handleMenuItemClick(event.target)}>
                    <i className="bx bx-key" />
                    <span>API keys</span>
                  </Link>
                </li>
                <li>
                  <Link to={`/${language}/logs`} onClick={(event) => handleMenuItemClick(event.target)}>
                    <i className="bx bx-history" />
                    <span>Logs</span>
                  </Link>
                </li>
              </ul>
              : user.userTypeId === AGENT_USER_TYPE_ID ?
                <ul className="metismenu list-unstyled" id="side-menu">
                  <li className="menu-title">Dashboard</li>
                  <li>
                    <Link to={`/${language}/home`} onClick={(event) => handleMenuItemClick(event.target)}>
                      <i className="bx bxs-dashboard" />
                      <span>Overview</span>
                    </Link>
                  </li>
                  <li>
                    <Link to={`/${language}/bank-account`} onClick={(event) => handleMenuItemClick(event.target)}>
                      <i className="bx bxs-bank" />
                      <span>Bank Account</span>
                    </Link>
                  </li>
                  <li>
                    <Link to={`/${language}/client-database`} onClick={(event) => handleMenuItemClick(event.target)}>
                      <i className="bx bx-chevron-left-square" />
                      <span>Organizations</span>
                    </Link>
                  </li>
                  <li>
                    <Link to={`/${language}/significant-controllers`} onClick={(event) => handleMenuItemClick(event.target)}>
                      <i className="bx bx-user-plus" />
                      <span>Significant controllers</span>
                    </Link>
                  </li>
                  <li>
                    <Link to={`/${language}/client-invitations`} onClick={(event) => handleMenuItemClick(event.target)}>
                      <i className="bx bx-meteor" />
                      <span>Invitations</span>
                    </Link>
                  </li>
                  <li>
                    <Link to={`/${language}/transactions-overview`} onClick={(event) => handleMenuItemClick(event.target)}>
                      <i className="bx bx-transfer-alt" />
                      <span>Transactions</span>
                    </Link>
                  </li>
                </ul>
                : user.userTypeId === ADMIN_USER_TYPE_ID ?
                  <ul className="metismenu list-unstyled" id="side-menu">
                    <li onClick={(event) => handleMenuItemClick(event.target)}>
                      <Link to={`/${language}/dashboard`}>
                        <i className="bx bxs-dashboard" />
                        <span>Dashboard</span>
                      </Link>
                    </li>
                    <li className="menu-title">Customers</li>
                    <li onClick={(event) => handleMenuItemClick(event.target)}>
                      <Link to={`/${language}/agents`}>
                        <i className="bx bx-chevron-left-square" />
                        <span>Agents</span>
                      </Link>
                    </li>
                    <li onClick={(event) => handleMenuItemClick(event.target)}>
                      <Link to={`/${language}/organizations`}>
                        <i className="bx bx-chevron-up-circle" />
                        <span>Organizations</span>
                      </Link>
                    </li>

                    {/* <li onClick={(event) => handleMenuItemClick(event.target)}>
                      <Link to={`/${language}/paying-customers`}>
                        <i className="bx bx-user" />
                        <span>Paying customers</span>
                      </Link>
                    </li> */}
                    <li className="menu-title">Payment management</li>
                    <li>
                      <Link to={`/${language}/all-transactions`} onClick={(event) => handleMenuItemClick(event.target)}>
                        <i className="bx bx-transfer-alt" />
                        <span>Transactions</span>
                      </Link>
                    </li>
                    <li>
                      <Link to={`/${language}/payouts`} onClick={(event) => handleMenuItemClick(event.target)}>
                        <i className='bx bx-money'></i>
                        <span>Payouts</span>
                      </Link>
                    </li>
                    <li className="menu-title">Analytics</li>
                    <li>
                      <Link to={`/${language}/reports`} onClick={(event) => handleMenuItemClick(event.target)}>
                        <i className="bx bx-notepad" />
                        <span>Reports</span>
                      </Link>
                    </li>
                  </ul>
                  : user.userTypeId === DEVELOPER_USER_TYPE_ID ?
                    <ul className="metismenu list-unstyled" id="side-menu">
                      <li>
                        <Link to={`/${language}/pages`} onClick={(event) => handleMenuItemClick(event.target)}>
                          <i className="bx bx-chevron-left-square" />
                          <span>Pages</span>
                        </Link>
                      </li>
                    </ul> : null
          }
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <Link to="#" onClick={logout}>
                <i className="bx bx-log-out" />
                <span>Logout</span>
              </Link>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
}


export default withRouter(SidebarContent)