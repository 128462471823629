import React from 'react'
import ReactApexChart from "react-apexcharts"
import {
  Card,
  CardBody,
  Col,
  Row,
  Label
} from "reactstrap"
import { useSelector } from 'react-redux'
import HtmlHead from '../../../components/HtmlHead/HtmlHead'
import RightSectionSpinner from '../../../components/Spinners/RightSectionSpinner'
import { useState } from 'react'
import moment from 'moment'
import ReactSelectOption from '../../../components/SelectOptions/ReactSelectOption'
import { Link } from "react-router-dom"
import Breadcrumbs from './../../../components/Common/Breadcrumb'

const Home = () => {

  const title = "Home";
  const description = "";

  const { rightSectionSpinners } = useSelector(state => state.Spinners);
  const { user } = useSelector(state => state.Login);

  const [clientId, setClientId] = useState(1);
  const [timePeriodId, setTimePeriodId] = useState(3);
  const [timePeriodId1, setTimePeriodId1] = useState(3);
  const [arithmeticId, setArithmeticId] = useState(1);

  const clientsSelectOptionsData = [
    {
      id: 1,
      name: "All Clients"
    },
    {
      id: 2,
      name: "Client 1"
    },
    {
      id: 3,
      name: "Client 2"
    },
    {
      id: 4,
      name: "Client 3"
    },
  ]

  const timePeriodSelectOptionsData = [
    {
      id: 1,
      name: "Daily"
    },
    {
      id: 2,
      name: "Weekly"
    },
    {
      id: 3,
      name: "Monthly"
    },
  ]

  const arithmeticFunctionsSelectOptionsData = [
    {
      id: 1,
      name: "Total (Sum)"
    },
    {
      id: 2,
      name: "Average"
    },
    {
      id: 3,
      name: "Median"
    },
  ]

  const onSelectOptionsChange = (item, cb) => {
    if (!item) { return false };
    cb(item.value)
  }

  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className='page-content position-relative min-h-100vh'>
        <RightSectionSpinner spinner={rightSectionSpinners} />
        <div className='container-fluid'>

          <Breadcrumbs
            title="Cryllex"
            breadcrumbItem="Overview"
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col sm={12}>
                      <Row>
                        <Col lg={8}>
                          <div className='border border-2  p-3'>
                            <div className='d-flex justify-content-between mb-3 align-items-center'>
                              <p className='fs-5 d-flex justify-content-between mb-0'>
                                <b>Gross Volume | Transaction Fees | Registered Clients </b>
                              </p>
                              <div className='w-100' style={{ maxWidth: "200px" }}>
                                <ReactSelectOption
                                  value={timePeriodId1}
                                  selectedValue={(() => {
                                    const selectedValue = { ...timePeriodSelectOptionsData.find(data => data.id === timePeriodId1) };
                                    if (selectedValue) {
                                      selectedValue.label = selectedValue.name;
                                      selectedValue.value = selectedValue.id;
                                    }
                                    return selectedValue;
                                  })()}
                                  items={timePeriodSelectOptionsData.map(data => ({ label: data.name, value: data.id }))}
                                  onChange={(item) => onSelectOptionsChange(item, setTimePeriodId1)}
                                />
                              </div>
                            </div>
                            <ReactApexChart
                              series={
                                [{
                                  name: "Desktops",
                                  data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
                                }]
                              }
                              options={{
                                dataLabels: {
                                  enabled: false,
                                },
                                xaxis: {
                                  categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                                }
                              }}
                              type="area"
                              height={350}
                              className="apex-charts"
                            />
                          </div>

                          <div className='border border-2 p-3 mt-4'>
                            <div className='fs-5 d-flex justify-content-between mb-4 align-items-center'>
                              <div className='w-100'>
                                <b>Business Insights</b>
                              </div>
                              <div className='d-flex gap-2 w-100 justify-content-end fs-6'>
                                <div className='w-100' style={{ maxWidth: "200px" }}>
                                  <ReactSelectOption
                                    value={clientId}
                                    selectedValue={(() => {
                                      const selectedValue = { ...clientsSelectOptionsData.find(data => data.id === clientId) };
                                      if (selectedValue) {
                                        selectedValue.label = selectedValue.name;
                                        selectedValue.value = selectedValue.id;
                                      }
                                      return selectedValue;
                                    })()}
                                    items={clientsSelectOptionsData.map(data => ({ label: data.name, value: data.id }))}
                                    onChange={(item) => onSelectOptionsChange(item, setClientId)}
                                  />
                                </div>

                                <div className='w-100' style={{ maxWidth: "200px" }}>
                                  <ReactSelectOption
                                    value={timePeriodId}
                                    selectedValue={(() => {
                                      const selectedValue = { ...timePeriodSelectOptionsData.find(data => data.id === timePeriodId) };
                                      if (selectedValue) {
                                        selectedValue.label = selectedValue.name;
                                        selectedValue.value = selectedValue.id;
                                      }
                                      return selectedValue;
                                    })()}
                                    items={timePeriodSelectOptionsData.map(data => ({ label: data.name, value: data.id }))}
                                    onChange={(item) => onSelectOptionsChange(item, setTimePeriodId)}
                                  />
                                </div>

                              </div>

                            </div>

                            <Row>

                              <Col md={4}>
                                <Label className='mb-1'>Paying Customer Txn Value:</Label>
                                <p>[TEXT]</p>
                              </Col>
                              <Col md={4}>
                                <Label className='mb-1'>Paying Customer Stablecoin Txn Value:</Label>
                                <p>[TEXT]</p>
                              </Col>
                              <Col md={4}>
                                <Label className='mb-1'>Client Stablecoin Txn Value:</Label>
                                <p>[TEXT]</p>
                              </Col>
                              <Col md={4}>
                                <Label className='mb-1'>Client Fiat Txn Value:</Label>
                                <p>[TEXT]</p>
                              </Col>
                              <Col md={4}>
                                <Label className='mb-1'>Agent Net Stablecoin Txn Fees:</Label>
                                <p>[TEXT]</p>
                              </Col>
                              <Col md={4}>
                                <Label className='mb-1'>Agent Net Fiat Txn Fees:</Label>
                                <p>[TEXT]</p>
                              </Col>
                              <Col md={4}>
                                <Label className='mb-1'>Agent Partner Points:</Label>
                                <p>[TEXT]</p>
                              </Col>
                              <Col sm={12}>
                                <div className='d-flex justify-content-between'>
                                  <div className='w-100' style={{ maxWidth: "200px" }}>
                                    <ReactSelectOption
                                      value={arithmeticId}
                                      selectedValue={(() => {
                                        const selectedValue = { ...arithmeticFunctionsSelectOptionsData.find(data => data.id === arithmeticId) };
                                        if (selectedValue) {
                                          selectedValue.label = selectedValue.name;
                                          selectedValue.value = selectedValue.id;
                                        }
                                        return selectedValue;
                                      })()}
                                      items={arithmeticFunctionsSelectOptionsData.map(data => ({ label: data.name, value: data.id }))}
                                      onChange={(item) => onSelectOptionsChange(item, setArithmeticId)}
                                    />
                                  </div>
                                  <div className='fs-2 fw-500'>
                                    [FIGURES]
                                  </div>
                                </div>
                              </Col>

                            </Row>

                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className='border border-2 p-3 mt-4 mt-lg-0'>
                            <p className='fs-5 d-flex justify-content-between'>
                              <b>Clientele</b>
                              <b className='d-flex align-items-center'><i className='bx bx-up-arrow-alt fs-3'></i>1.8%</b>
                            </p>
                            <p className='fs-2 d-flex justify-content-between fw-500'>
                              38
                            </p>
                            <p className='fs-6 d-flex justify-content-between fw-500 mb-0'>
                              Total registered clients
                            </p>
                          </div>

                          <div className='border border-2  p-3 mt-4'>
                            <p className='fs-5 d-flex justify-content-between flex-wrap'>
                              <b>Top Performing Clients</b>
                              <span className='d-flex align-items-center '>
                                <Link to="#" className="fs-6 text-underline">
                                  See All Clients
                                </Link>
                                <i className='bx bx-right-arrow-alt fs-3'></i>
                              </span>
                            </p>
                            <p className='fs-3 d-flex justify-content-center fw-500'>
                              [TOP 3 PERFORMERS]
                            </p>
                          </div>

                          <div className='border border-2  p-3 mt-4'>
                            <p className='fs-5 d-flex justify-content-between'>
                              <b>Payments Analysis</b>
                            </p>

                            <div>
                              <p className='fs-2 d-flex justify-content-between fw-500 mb-0'>
                                38
                              </p>
                              <p className='fs-6 d-flex justify-content-between fw-500 mb-0'>
                                <span>Successful Payments</span>
                                <span> <b className='d-flex align-items-center'><i className='bx bx-up-arrow-alt fs-3'></i>5.0%</b></span>
                              </p>
                            </div>

                            <div className='mt-4'>
                              <p className='fs-2 d-flex justify-content-between fw-500 mb-0'>
                                11
                              </p>
                              <p className='fs-6 d-flex justify-content-between fw-500 mb-0'>
                                <span>Failed Payments </span>
                                <span> <b className='d-flex align-items-center'><i className='bx bx-down-arrow-alt fs-3'></i>1.1%</b></span>
                              </p>
                            </div>

                            <div className='mt-4'>
                              <p className='fs-2 d-flex justify-content-between fw-500 mb-0'>
                                06
                              </p>
                              <p className='fs-6 d-flex justify-content-between fw-500 mb-0'>
                                <span>Cancelled Payments</span>
                                <span> <b className='d-flex align-items-center'><i className='bx bx-up-arrow-alt fs-3'></i>0.8%</b></span>
                              </p>
                            </div>

                          </div>

                        </Col>
                      </Row>

                    </Col>


                  </Row>
                </CardBody>
              </Card>
            </Col>

          </Row>
        </div>
      </div>
    </>
  );
}

export default Home;
